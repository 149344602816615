import { Component } from '@angular/core';

@Component({
  selector: 'app-attending-basic-reportng',
  templateUrl: './attending-basic-reportng.component.html',
  styleUrls: ['./attending-basic-reportng.component.scss']
})
export class AttendingBasicReportngComponent {

}
