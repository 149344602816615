import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { per } from '../antares-persentage/antares-persentage.component';

@Component({
  selector: 'app-shipping-persentage',
  templateUrl: './shipping-persentage.component.html',
  styleUrls: ['./shipping-persentage.component.scss']
})
export class ShippingPersentageComponent {
  Myform!:FormGroup;
  InitialForm(){
    this.Myform=this.fb.group({
      attrId:[1011703],
      amount:[,Validators.required]
    })
  }
  constructor(private fb:FormBuilder,private http:HttpClient ){}
  ngOnInit(): void {
    this.InitialForm();
  }
  post(){
    var url=environment.Api+'d/e/p';
    var data=this.Myform.value as per;
    this.http.post(url,data).subscribe(x=>{
 
    })}

}
