<div class="container">
    <div class="section-title">
        <span class="sub-title"></span>
        <h2>{{'LndPgTitle002'|translate}}</h2>
        <i></i>
        <p></p>
    </div>
    <div class="row">
        <div class="col-lg-2 col-6 col-sm-4">
            <div class="single-features-box" >
                <a [routerLink]="'/category/1010204'">
                    <div class="icon">
                        <i class="fa-duotone fa-briefcase "></i>
                    </div>
                    <h3>{{'LndPgTitle002Cat001'|translate}}</h3>
                </a>
                <!-- <div class="icon">
                    <i class="fa-duotone fa-briefcase "></i>
                </div>
                <h3>Business</h3> -->
               <!-- <p>Learning top skills can bring an extra-ordinary outcome in a career.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>-->
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4">
            <div class="single-features-box">
                <a [routerLink]="'/category/1010201'">
                    <div class="icon">
                        <i class="fa-duotone fa-music "></i>
                    </div>
                    <h3>{{'LndPgTitle002Cat002'|translate}}</h3>
                </a>
                <!-- <div class="icon">
                    <i class="fa-duotone fa-music "></i>
                </div>
                <h3>Music</h3> -->
                <!-- <p>Learning top skills can bring an extra-ordinary outcome in a career.</p>
                 <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>-->
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4">
            <div class="single-features-box">
                <a [routerLink]="'/category/1010220'">
                    <div class="icon">
                        <i class="fa-duotone fa-car-building "></i>
                    </div>
                    <h3>{{'LndPgTitle002Cat003'|translate}}</h3>
                </a>
                <!-- <div class="icon">
                    <i class="fa-duotone fa-car-building "></i>
                </div>
                <h3>Travel & Outdoor</h3> -->
                <!-- <p>Learning top skills can bring an extra-ordinary outcome in a career.</p>
                 <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>-->
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4">
            <div class="single-features-box">
                <a [routerLink]="'/category/1010211'">
                    <div class="icon">
                        <i class="fa-duotone fa-monitor-waveform "></i>
                    </div>
                    <h3>{{'LndPgTitle002Cat004'|translate}}</h3>
                </a>
                <!-- <div class="icon">
                    <i class="fa-duotone fa-monitor-waveform "></i>
                </div>
                <h3>Health</h3> -->
             <!--   <p>Everyone prefers to enjoy learning at their own pace & that gives a great result.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>-->
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4">
            <div class="single-features-box">
                <a [routerLink]="'/category/1010202'">
                    <div class="icon">
                        <i class="fa-duotone fa-user-graduate "></i>
                    </div>
                    <h3>{{'LndPgTitle002Cat005'|translate}}</h3>
                </a>
                <!-- <div class="icon">
                    <i class="fa-duotone fa-user-graduate "></i>
                </div>
                <h3>Education</h3> -->
              <!--  <p>Experienced teachers can assist in learning faster with their best approaches!</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>-->
            </div>
        </div>
        <div class="col-lg-2 col-6 col-sm-4">
            <div class="single-features-box">
                <a [routerLink]="'/category/1010209'">
                    <div class="icon">
                        <i class="fa-duotone fa-burger-soda"></i>
                    </div>
                    <h3>{{'LndPgTitle002Cat006'|translate}}</h3>
                </a>
                <!-- <div class="icon">
                    <i class="fa-duotone fa-burger-soda"></i>
                </div>
                <h3>Food & Drinks</h3> -->
              <!--  <p>We are delighted to give you options to enjoy learning from anywhere in the world.</p>
                <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>-->
            </div>
        </div>
    </div>
</div>
