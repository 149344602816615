import {BrowserModule} from '@angular/platform-browser';
import {NgModule } from '@angular/core';
import {MenuModule} from 'primeng/menu';
//import {CarouselModule} from 'ngx-owl-carousel-o';
import {CarouselModule} from 'primeng/carousel';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CountUpModule} from 'ngx-countup';
import {StickyNavModule} from 'ng2-sticky-nav';
import {TabsModule} from 'ngx-tabset';
import {NgxScrollTopModule} from 'ngx-scrolltop';
import {LightboxModule} from 'ngx-lightbox';
import {AccordionModule} from 'primeng/accordion';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LightgalleryModule} from 'lightgallery/angular';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './components/common/navbar/navbar.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {EventsComponent} from './components/pages/events/events.component';
import {EventsDetailsComponent} from './components/pages/events-details/events-details.component';
import {FeaturesStyleOneComponent} from './components/common/features-style-one/features-style-one.component';
import {UpcomingEventsComponent} from './components/common/upcoming-events/upcoming-events.component';
import {CoursesTypesComponent} from './components/common/courses-types/courses-types.component';
import {LandingPageComponent} from './components/pages/landing-page/landing-page.component';
import {SiBannerComponent} from './components/pages/landing-page/si-banner/si-banner.component';
import {TableModule} from 'primeng/table';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {DataViewModule} from 'primeng/dataview';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {RatingModule} from 'primeng/rating';
import {BasicInfoComponent} from './components/pages/events/event-steps/basic-info/basic-info.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FieldsetModule} from 'primeng/fieldset';
import {EventStepsComponent} from './components/pages/events/event-steps/event-steps.component';
import {StepsModule} from 'primeng/steps';
import {InputTextModule} from 'primeng/inputtext';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {NgbOffcanvas} from '@ng-bootstrap/ng-bootstrap';
import {BypassHTMLPipe} from './BypassHTML.pipe';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CreateEventProfileComponent} from './components/pages/events/event-steps/create-event-profile/create-event-profile.component';
import {FileUploadModule} from 'primeng/fileupload';
import {DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ToastModule} from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';   
import {ConfirmationService, MessageService} from 'primeng/api';
import {CropperComponent} from './components/shared/cropper/cropper.component';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {OredataTableComponent} from './components/common/oredata-table/oredata-table.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {EditorModule} from 'primeng/editor';
import {PartnerComponent} from './components/common/partner/partner.component';
import {TicketsComponent} from './components/pages/events/event-steps/tickets/tickets.component';
import {CalendarModule} from 'primeng/calendar';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {CheckboxModule} from 'primeng/checkbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {RippleModule} from 'primeng/ripple';
import {GMapModule} from 'primeng/gmap';
import {TreeSelectModule} from 'primeng/treeselect';
import { TicketsFormComponent } from './components/pages/events/event-steps/tickets/tickets-form/tickets-form.component';
import { ImageUploaderComponent } from './components/shared/image-uploader/image-uploader/image-uploader.component';
import {ListboxModule} from 'primeng/listbox';
import { EventDateTimeComponent } from './components/pages/events/event-steps/event-date-time/event-date-time.component';

import { EventAddressComponent } from './components/pages/events/event-steps/event-address/event-address.component';
import { UploadPhotoComponent } from './components/shared/upload-photo/upload-photo.component';

import { OrderFormComponent } from './components/pages/order-form/order-form.component';
import { JsonFormComponent } from './components/shared/json-form/json-form.component';
import { OrderFormBuilderComponent } from './components/pages/events/event-steps/order-form-builder/order-form-builder.component';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {RadioButtonModule} from 'primeng/radiobutton';
import { QuestionFormComponent } from './components/pages/events/event-steps/order-form-builder/question-form/question-form/question-form.component';
import { AddOnsComponent } from './components/pages/events/event-steps/tickets/add-ons/add-ons.component';
import {PasswordModule} from 'primeng/password';

import { AddOnsService } from './services/add-ons.service';
import { PanelMenuModule} from 'primeng/panelmenu';
import { FullCalendarModule } from '@fullcalendar/angular';
import { TicketsStepsComponent } from './components/pages/events/event-steps/tickets/tickets-steps/tickets-steps.component';
import {TabMenuModule} from 'primeng/tabmenu';
import { InfoComponent } from './components/pages/events/event-steps/tickets/tickets-form/info/info.component';
import { TicketDateComponent } from './components/pages/events/event-steps/tickets/tickets-form/ticket-date/ticket-date.component';
import { TicketSettingsComponent } from './components/pages/events/event-steps/tickets/tickets-form/ticket-settings/ticket-settings.component';
import { InitialTicketFormComponent } from './components/pages/events/event-steps/tickets/tickets-form/initial-ticket-form/initial-ticket-form.component';
import { AddonFormComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-form.component';
import { AddonGroupFormComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-group-form/addon-group-form.component';
import { GroupInfoComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-group-form/group-info/group-info.component';
import { VarietiesComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-group-form/varieties/varieties.component';
import { AddonInitComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-init/addon-init.component';
import { AddonInfoComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-info/addon-info.component';
import { AddonSalesDateComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-sales-date/addon-sales-date.component';
import { AddonSettingsComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-settings/addon-settings.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { AutocompleteComponent } from './components/shared/autocomplete/autocomplete.component';
import {SidebarModule} from 'primeng/sidebar';
import {DialogModule} from 'primeng/dialog';
import { PromoteComponent } from './components/pages/events/event-steps/tickets/promote/promote.component';
import { PromoteformComponent } from './components/pages/events/event-steps/tickets/promoteform/promoteform.component';
import { Step1Component } from './components/pages/events/event-steps/tickets/promoteform/step1/step1.component';
import { Step2Component } from './components/pages/events/event-steps/tickets/promoteform/step2/step2.component';
import { Step3Component } from './components/pages/events/event-steps/tickets/promoteform/step3/step3.component';
import { InterestComponent } from './components/pages/interest/interest.component';
import { ProgressBarModule } from 'primeng/progressbar';
import {KnobModule} from 'primeng/knob';
import { TextEditorComponent } from './components/shared/text-editor/text-editor.component';
import { MyImageUploaderComponent } from './components/shared/my-image-uploader/my-image-uploader.component';
import { CommonInterceptor } from './services/common.interceptor';
import { EventPageComponent } from './components/pages/event-page/event-page.component';
import { CalenderFormStep1Component } from './components/pages/events/event-steps/calender/calender-steps/calender-form-step1/calender-form-step1.component';
import { CalenderFormStep2Component } from './components/pages/events/event-steps/calender/calender-steps/calender-form-step2/calender-form-step2.component';
import { CalenderFormStep3Component } from './components/pages/events/event-steps/calender/calender-steps/calender-form-step3/calender-form-step3.component';
import { CalenderStepsComponent } from './components/pages/events/event-steps/calender/calender-steps/calender-steps.component';
import { ClenderStepsPageComponent } from './components/pages/events/event-steps/calender/clender-steps-page/clender-steps-page.component';
import { CalenderStep4Component } from './components/pages/events/event-steps/calender/calender-steps/calender-step4/calender-step4.component';
import { CalenderComponent } from './components/pages/events/event-steps/calender/calender.component';
import { OverlayModule } from 'primeng/overlay';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OrganizerProfileComponent } from './components/pages/organizer-profile/organizer-profile.component';
import { LoginComponent } from './components/shared/login/login.component';
import { RegisterComponent } from './components/shared/register/register.component';
import { OnlineEventPageComponent } from './components/pages/events/event-steps/online-event-page/online-event-page.component';
import { ClenderUpdateSingleInstansComponent } from './components/pages/events/event-steps/calender/clender-update-single-instans/clender-update-single-instans.component';
import { OrganizerFormComponent } from './components/pages/organizers/organizer-form/organizer-form.component';
import { OrganizerFromStepService } from './services/organizer-from-step.service';
import { FormStep2Component } from './components/pages/organizers/organizer-form/form-step2/form-step2.component';
import { FormStep1Component } from './components/pages/organizers/organizer-form/form-step1/form-step1.component';
import { OrganizersComponent } from './components/pages/organizers/organizers.component';
import { EntrepriseComponent } from './components/pages/entreprise/entreprise.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { RedirectComponent } from './components/shared/redirect/redirect.component';
import { TeamMembersComponent } from './components/pages/organizers/team-manage/team-members/team-members.component';
import { TeamRolesComponent } from './components/pages/organizers/team-manage/team-roles/team-roles.component';
import { TeamManageComponent } from './components/pages/organizers/team-manage/team-manage.component';
import { PreviewComponent } from './components/pages/organizers/preview/preview.component';
import { AvatarModule } from 'primeng/avatar';
import { AddOnsStepComponent } from './components/pages/events/event-steps/tickets/add-ons/add-ons-step/add-ons-step.component';
import { PromoCodesStepsComponent } from './components/pages/events/event-steps/tickets/promote/promo-codes-steps/promo-codes-steps.component';
import { TimelineModule } from 'primeng/timeline';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import {InviteMemberComponent} from './components/pages/organizers/team-manage/team-members/invite-member/invite-member.component';
import {RolesTableComponent} from './components/pages/organizers/team-manage/team-roles/roles-table/roles-table.component';
import {RolesFormComponent} from './components/pages/organizers/team-manage/team-roles/roles-form/roles-form.component';
import {InviteTableComponent} from './components/pages/organizers/team-manage/invite-table/invite-table.component';
import {AssignRoleComponent} from './components/pages/organizers/team-manage/team-members/assign-role/assign-role.component';
import {BrowseEventsComponent} from './components/pages/browse-events/browse-events.component';
import { PublishEventComponent } from './components/pages/events/event-steps/publish-event/publish-event.component';
import {ComingSoonComponent} from './components/shared/coming-soon/coming-soon.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { OrderEventComponent } from './components/pages/order-event/order-event.component';
import {OrganizerStepsComponent} from './components/pages/organizers/organizer-steps/organizer-steps.component';
import { InviteTeamMemberComponent } from './components/pages/organizers/invite-team-member/invite-team-member.component';
import { NotAllowedComponent } from './components/pages/not-allowed/not-allowed.component';
import { EmailCheckComponent } from './components/shared/register/email-check/email-check.component';
import { RolesStepsComponent } from './components/pages/organizers/team-manage/team-roles/roles-steps/roles-steps.component';
import { AddDateComponent } from './components/pages/add-date/add-date.component';
import {SurveyComponent} from './components/shared/survey/survey.component';
import { PreviewEventComponent } from './components/pages/events/event-steps/order-form-builder/preview-event/preview-event.component';
import { OnlineComponent } from './components/pages/online/online.component';
import { FavoritesComponent } from './components/pages/favorites/favorites.component';
import { EventCardComponent } from './components/shared/event-card/event-card.component';
import {UserOrderPreviewComponent} from './components/pages/user-order/user-order-preview/user-order-preview.component';
import {UserOrderComponent} from './components/pages/user-order/user-order.component';
import {TabViewModule} from 'primeng/tabview';
import { OrganizerCardComponent } from './components/shared/organizer-card/organizer-card.component';
import { StepOneSearchComponent } from './components/pages/browse-events/step-one-search/step-one-search.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ResetPasswordFormComponent } from './components/shared/register/reset-password/reset-password-form/reset-password-form.component';
import { ResetPasswordComponent } from './components/shared/register/reset-password/reset-password.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { SearchEventCardComponent } from './components/shared/search-event-card/search-event-card.component';
import { DatePipe } from '@angular/common';
import {ChartModule} from 'primeng/chart';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { FeaturesFilterComponent } from './components/common/features-style-one/features-style-filter/features-filter/features-filter.component';
import { SubEventsforMaineventsComponent } from './components/pages/events/event-steps/sub-eventsfor-mainevents/sub-eventsfor-mainevents.component';
import { EventDashboardComponent } from './components/pages/events/event-steps/event-dashboard/event-dashboard.component';
import { CopyAddOnsGroupComponent } from './components/pages/events/event-steps/tickets/add-ons/copy-add-ons-group/copy-add-ons-group.component';
import { OrderConfirmationComponent } from './components/pages/events/event-steps/order-confirmation/order-confirmation.component';
import { WaitListComponent } from './components/pages/events/event-steps/wait-list/wait-list.component';
import { TicketsMsgComponent } from './components/pages/order-event/tickets-msg/tickets-msg.component';
import { ConfirmationComponent } from './components/pages/order-event/confirmation/confirmation.component';
import { LangugesComponent } from './components/pages/events/event-steps/languges/languges.component';
import { EventTypeComponent } from './components/Admin/event-type/event-type.component';
import { TypeFormComponent } from './components/Admin/type-form/type-form.component';
import { CategoryEventComponent } from './components/Admin/category-event/category-event.component';
import { CategoryEventformComponent } from './components/Admin/category-eventform/category-eventform.component';
import { SubCategoryEventformComponent } from './components/Admin/sub-category-eventform/sub-category-eventform.component';
import { SubCategoryEventComponent } from './components/Admin/sub-category-event/sub-category-event.component';
import { LocalstringComponent } from './components/Admin/localstring/localstring.component';
import { LocalstringformComponent } from './components/Admin/localstringform/localstringform.component';
import { EventTagComponent } from './components/Admin/event-tag/event-tag.component';
import { EventTagformComponent } from './components/Admin/event-tagform/event-tagform.component';
import { CurrencyMangmaentComponent } from './components/Admin/currency-mangmaent/currency-mangmaent.component';
import { CurrencyFormComponent } from './components/Admin/currency-form/currency-form.component';
import { SystemLanguegesmangComponent } from './components/Admin/system-languegesmang/system-languegesmang.component';
import { SystemLanguegesFormComponent } from './components/Admin/system-langueges-form/system-langueges-form.component';
import { AdminDashboardComponent } from './components/Admin/admin-dashboard/admin-dashboard.component';

import { GeoIPServiceService } from './services/geo-ipservice.service';
import { appInitializerFactory } from './Interfaces/app-initializer';
import { AttendeesMAngmentComponent } from './components/pages/events/event-steps/attendees-mangment/attendees-mangment.component';
import { OrdersReportComponent } from './components/pages/events/event-steps/attendees-mangment/orders-report/orders-report.component';
import { TryrComponent } from './components/shared/tryr/tryr.component';
import { CompositKeyPipe } from './composit-key.pipe';
import { AntaresPersentageComponent } from './components/Admin/antares-persentage/antares-persentage.component';
import { PaymentPersentageComponent } from './components/Admin/payment-persentage/payment-persentage.component';
import { ShippingPersentageComponent } from './components/Admin/shipping-persentage/shipping-persentage.component';
import { EditAttendeesInfoComponent } from './components/pages/events/event-steps/attendees-mangment/edit-attendees-info/edit-attendees-info.component';
import { FormForEditAttendesComponent } from './components/shared/form-for-edit-attendes/form-for-edit-attendes.component';
import { ProfileFormComponent } from './components/shared/profile-form/profile-form.component';
import { UserProfileFormComponent } from './components/pages/user-profile-form/user-profile-form.component';
import { JsonGetShippingAddressComponent } from './components/shared/json-get-shipping-address/json-get-shipping-address.component';
import { AddNewAttendeesComponent } from './components/pages/events/event-steps/attendees-mangment/add-new-attendees/add-new-attendees.component';
import { AddnewAteendeesinfoComponent } from './components/pages/events/event-steps/attendees-mangment/add-new-attendees/addnew-ateendeesinfo/addnew-ateendeesinfo.component';
import { AddnewAttendessOfflineComponent } from './components/pages/events/event-steps/attendees-mangment/add-new-attendees/addnew-ateendeesinfo/addnew-attendess-offline/addnew-attendess-offline.component';
import { DesingCoComponent } from './components/shared/desing-co/desing-co.component';
import { AddOnsCardReportComponent } from './components/shared/add-ons-card-report/add-ons-card-report.component';
import { UserHomeProfileComponent } from './components/pages/user-home-profile/user-home-profile.component';
import { UserProfileHomeEditComponent } from './components/pages/user-home-profile/user-profile-home-edit/user-profile-home-edit.component';
import { LocationSertchComponent } from './components/shared/location-sertch/location-sertch.component';
import {InputMaskModule} from 'primeng/inputmask';
import {SplitButtonModule} from 'primeng/splitbutton';
import { AdduserprofilephotoComponent } from './components/shared/adduserprofilephoto/adduserprofilephoto.component';
import { USerProfilePrviewComponent } from './components/pages/user-home-profile/user-profile-prview/user-profile-prview.component';
import { UserByTicketsComponent } from './components/pages/user-by-tickets/user-by-tickets.component';
import { UserByAddonsComponent } from './components/pages/user-by-addons/user-by-addons.component';
import { LogomangmentComponent } from './components/Admin/logomangment/logomangment.component';
import { QRCodeModule } from 'angularx-qrcode';
import { USerpriviewOrderinstanceComponent } from './components/pages/userpriview-orderinstance/userpriview-orderinstance.component';
import { ConfirmationOfflineOrderComponent } from './components/pages/events/event-steps/attendees-mangment/add-new-attendees/addnew-ateendeesinfo/confirmation-offline-order/confirmation-offline-order.component';
import { PermissiontransateMangmentComponent } from './components/Admin/permissiontransate-mangment/permissiontransate-mangment.component';
import { UploadAntaresLogoComponent } from './components/Admin/upload-antares-logo/upload-antares-logo.component';
import { LogoAddFormComponent } from './components/Admin/logo-add-form/logo-add-form.component';
import { AttendinglistcoComponent } from './components/pages/events/event-steps/attendees-mangment/attendinglistco/attendinglistco.component';
import { AttendeelistCardComponent } from './components/shared/attendeelist-card/attendeelist-card.component';
import { ReportingMenuComponent } from './components/pages/reporting-menu/reporting-menu.component';
import { SalesReportingComponent } from './components/pages/reporting-menu/sales-reporting/sales-reporting.component';
import { AttendingBasicReportngComponent } from './components/pages/reporting-menu/attending-basic-reportng/attending-basic-reportng.component';
import { BoxOfficeReportingComponent } from './components/pages/reporting-menu/box-office-reporting/box-office-reporting.component';
import { InventoryReportingComponent } from './components/pages/reporting-menu/inventory-reporting/inventory-reporting.component';
import {CardModule} from 'primeng/card';
import { SalesByTicketTypeReportComponent } from './components/pages/reporting-menu/sales-reporting/sales-by-ticket-type-report/sales-by-ticket-type-report.component';
import { GlobalReportDesginComponent } from './components/shared/global-report-desgin/global-report-desgin.component';
import { UpdateAttendeesGlobalComponent } from './components/pages/reporting-menu/sales-reporting/update-attendees-global/update-attendees-global.component';
import { SalesByAddonsTypeReportComponent } from './components/pages/reporting-menu/sales-reporting/sales-by-addons-type-report/sales-by-addons-type-report.component';
@NgModule({
    declarations: [
        FeaturesFilterComponent,
        UserOrderComponent,
        UserOrderPreviewComponent,
        ResetPasswordFormComponent,
        ResetPasswordComponent,
        SurveyComponent,
        OrganizerStepsComponent,
        BrowseEventsComponent,
        TeamMembersComponent,
        TeamRolesComponent,
        TeamManageComponent,
        PreviewComponent,
        EntrepriseComponent,
        PricingComponent,
        OrganizersComponent,
        OrganizerFormComponent,
        FormStep1Component,
        FormStep2Component,
        OrganizerProfileComponent,
        AppComponent,
        OredataTableComponent,
        NavbarComponent,
        FooterComponent,
        ErrorComponent,
        EventsComponent,
        EventsDetailsComponent,
        FeaturesStyleOneComponent,
        UpcomingEventsComponent,
        CoursesTypesComponent,
        SiBannerComponent,
        LandingPageComponent,
        BasicInfoComponent,
        EventStepsComponent,
        ComingSoonComponent,        BypassHTMLPipe,
        CreateEventProfileComponent,
        CropperComponent,
        PartnerComponent,
        TicketsComponent,
        TicketsFormComponent,
        ImageUploaderComponent,
        EventDateTimeComponent,
        EventAddressComponent,
        UploadPhotoComponent,
        PromoteComponent,
        PromoteformComponent,
        OrderFormComponent,
        JsonFormComponent,
        OrderFormBuilderComponent,
        AddOnsComponent,
        CalenderComponent,
        QuestionFormComponent,
        CalenderStepsComponent,
        CalenderFormStep1Component,
        CalenderFormStep2Component,
        CalenderFormStep3Component,
        ClenderStepsPageComponent,
        CalenderStep4Component,
        TicketsStepsComponent,
        InfoComponent,
        TicketDateComponent,
        TicketSettingsComponent,
        InitialTicketFormComponent,
        AddonFormComponent,
        AddonGroupFormComponent,
        GroupInfoComponent,
        VarietiesComponent,
        AddonInitComponent,
        AddonInfoComponent,
        AddonSalesDateComponent,
        AddonSettingsComponent,
        AutocompleteComponent,
        Step1Component,
        Step2Component,
        Step3Component,
        InterestComponent,
        TextEditorComponent,
        MyImageUploaderComponent,
        EventPageComponent,
        LoginComponent,
        RegisterComponent,
        OnlineEventPageComponent,
        ClenderUpdateSingleInstansComponent,
        RedirectComponent,
        AddOnsStepComponent,
        PromoCodesStepsComponent,
        InviteMemberComponent,
        RolesTableComponent,
        RolesFormComponent,
        InviteTableComponent,
        AssignRoleComponent,
        PublishEventComponent,
        OrderEventComponent,
        InviteTeamMemberComponent,
        NotAllowedComponent,
        EmailCheckComponent,
        RolesStepsComponent,
        AddDateComponent,
        PreviewEventComponent,
        OnlineComponent,
        FavoritesComponent,
        EventCardComponent,
        OrganizerCardComponent,
        StepOneSearchComponent,
         SearchEventCardComponent,
         SubEventsforMaineventsComponent,
         EventDashboardComponent,
         CopyAddOnsGroupComponent,
         OrderConfirmationComponent,
         WaitListComponent,
         TicketsMsgComponent,
         ConfirmationComponent,
         LangugesComponent,
         EventTypeComponent,
         TypeFormComponent,
         CategoryEventComponent,
         CategoryEventformComponent,
         SubCategoryEventformComponent,
         SubCategoryEventComponent,
         LocalstringComponent,
         LocalstringformComponent,
         EventTagComponent,
         EventTagformComponent,
         CurrencyMangmaentComponent,
         CurrencyFormComponent,
         SystemLanguegesmangComponent,
         SystemLanguegesFormComponent,
         AdminDashboardComponent,
         AttendeesMAngmentComponent,
         OrdersReportComponent,
         TryrComponent,
         CompositKeyPipe,
         AntaresPersentageComponent,
         PaymentPersentageComponent,
         ShippingPersentageComponent,
         EditAttendeesInfoComponent,
         FormForEditAttendesComponent,
         ProfileFormComponent,
         UserProfileFormComponent,
         JsonGetShippingAddressComponent,
         AddNewAttendeesComponent,
         AddnewAteendeesinfoComponent,
         AddnewAttendessOfflineComponent,
         DesingCoComponent,
         AddOnsCardReportComponent,
         UserHomeProfileComponent,
         UserProfileHomeEditComponent,
         LocationSertchComponent,
         AdduserprofilephotoComponent,
         USerProfilePrviewComponent,
         UserByTicketsComponent,
         UserByAddonsComponent,
         LogomangmentComponent,
         USerpriviewOrderinstanceComponent,
         ConfirmationOfflineOrderComponent,
         PermissiontransateMangmentComponent,
         UploadAntaresLogoComponent,
         LogoAddFormComponent,
         AttendinglistcoComponent,
         AttendeelistCardComponent,
         ReportingMenuComponent,
         SalesReportingComponent,
         AttendingBasicReportngComponent,
         BoxOfficeReportingComponent,
         InventoryReportingComponent,
         SalesByTicketTypeReportComponent,
         GlobalReportDesginComponent,
         UpdateAttendeesGlobalComponent,
         SalesByAddonsTypeReportComponent,
     
      


    ],
    imports: [
        CardModule,
        QRCodeModule,
        SplitButtonModule,
        InputMaskModule,
        AutoCompleteModule,
        TranslateModule.forRoot({
            defaultLanguage: '',
            
        }),
        FieldsetModule,
        ChartModule,
        MenuModule,
        ConfirmPopupModule,
        OverlayPanelModule,
        KnobModule,
        KeyFilterModule,
        AvatarModule,
        PasswordModule,
        ProgressBarModule,
        SidebarModule,
        DialogModule,
        GMapModule,
        BrowserModule,
        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        CountUpModule,
        StickyNavModule,
        TabsModule,
        NgxScrollTopModule,
        LightboxModule,
        AccordionModule,
        FormsModule,
        LightgalleryModule,
        TableModule,
        HttpClientModule,
        DataViewModule,
        DropdownModule,
        ButtonModule,
        RatingModule,
        NgSelectModule,
        SelectButtonModule,
        StepsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        InputTextModule,
        ImageCropperModule,
        FileUploadModule,
        ToastModule,
        DynamicDialogModule,
        InputTextareaModule,
        EditorModule,
        ToolbarModule,
        ReactiveFormsModule,
        AppRoutingModule,
        CarouselModule,
        BrowserAnimationsModule,
        CountUpModule,
        StickyNavModule,
        TabsModule,
        NgxScrollTopModule,
        LightboxModule,
        AccordionModule,
        FormsModule,
        LightgalleryModule,
        HttpClientModule,
        DataViewModule, DropdownModule, ButtonModule, RatingModule,
        NgSelectModule, SelectButtonModule, StepsModule, ReactiveFormsModule,
        InputTextModule,
        ToolbarModule, TableModule,
        DynamicDialogModule,
        CalendarModule,
        InputTextareaModule,
        InputNumberModule,
        InputSwitchModule,
        MultiSelectModule,
        RippleModule,
        TreeSelectModule,
        CheckboxModule,
        ToggleButtonModule,
        RadioButtonModule,
        PanelMenuModule,
        FullCalendarModule,
        TabMenuModule,
        ListboxModule,
        GoogleMapsModule, TimelineModule, ProgressSpinnerModule, TabViewModule,NgxYoutubePlayerModule
        
    ],
    providers: [
        DatePipe,
        NgbOffcanvas,
        MessageService,
        DialogService,
        DynamicDialogRef,
        DynamicDialogConfig,
        TranslateService,
        ConfirmationService,
        AddOnsService,
        GeoIPServiceService,
        {provide: HTTP_INTERCEPTORS, useClass: CommonInterceptor, multi: true}
       
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
