import { Component, OnInit ,Inject} from '@angular/core';
import { TypeStepformService } from './type-stepform.service';
import { AttrbuteService } from 'src/app/services/attrbute.service';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { LoaderService } from 'src/app/services/loader.service';
import { AttrDto } from 'src/app/Interfaces/attrDto';
import { MessageService } from 'primeng/api';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-type-form',
  templateUrl: './type-form.component.html',
  styleUrls: ['./type-form.component.scss'],
  animations: [fadeOut]
})
export class TypeFormComponent implements OnInit {

  submitted:boolean=false;
  tickTy:DropDownItem[]=[]
  Langueses:DropDownItem[]=[{id:'en',name:'English'}]
  typeId: string;
  mylocal:AttrDto[]=[]
  constructor(public srv:TypeStepformService,private attrsrv:AttrbuteService, private route:Router,private actvitedRoute:ActivatedRoute,
    @Inject(DOCUMENT) public document: Document,public loader : LoaderService,private messageService: MessageService){}
  ngOnInit(): void {
    this.loader.showLoader1();
    var param= this.actvitedRoute.paramMap.subscribe((param)=>{
      this.typeId=param.get('id');
    
    if(this.typeId=="0"){
    this.srv.initialForm("0",10101,"",0,0,'en')
    }else{
      this.attrsrv.GetAllSystemLang().subscribe(x=>{
        this.Langueses=x;})
      this.attrsrv.GetAtrrSingle(this.typeId).subscribe(y=>{
        this.mylocal=y;
        var data=y.find(m=>m.cultureId=='en')
        this.srv.initialForm(data.attrId,data.parentId,data.name,data.status!=0?true:false,data.order,data.cultureId)
      })



    }});
    
  }
  sumbit(){
    //console.log(this.srv.typeform.value)
    var data:AttrDto={
   attrId       :this.srv.typeform.controls['attrId'].value,
   cultureId      :this.srv.typeform.controls['cultureId'].value,
    desc          :"",
    misc01        :"",
    misc02        :"",
    misc03        :"",
    misc04        :"",
    misc05        :"",
    misc06         :"",
    misc07          :"",
    name            :this.srv.typeform.controls['name'].value,
    order            :this.srv.typeform.controls['order'].value,
    parentId        :this.srv.typeform.controls['parentId'].value,
    parentName      :"",
    status          :this.srv.typeform.controls['status'].value?1:0}
   // console.log(data)
    this.attrsrv.PostnewAttr(data).subscribe(x=>{

      
      this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

    },(error)=>{
      this.messageService.add({ severity: 'danger', summary: error });
    });
  }
 
  cancel(){
    this.srv.clear();
    this.route.navigate(['Type'], { relativeTo: this.actvitedRoute.parent.parent});
  }

  changform(e){
   // console.log(e);
     var data=this.mylocal.find(x=>x.cultureId==e.value);
     if(data==undefined){
      this.srv.typeform.controls['name'].setValue("");
      this.srv.typeform.controls['order'].setValue(0);
      this.srv.typeform.controls['status'].setValue(false);
     }else{
      this.srv.typeform.controls['name'].setValue(data.name);
      this.srv.typeform.controls['order'].setValue(data.order);
      this.srv.typeform.controls['status'].setValue(data.status==0?false:true);
     }
  }


}
