import { DOCUMENT, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,Inject} from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
    providers: [
    Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
    }
]

})

export class FooterComponent implements OnInit, OnChanges {

    @Input() showPart1: boolean = true;
    @Input() showPart2: boolean = true;
  currentYear: number;
  location2 = '';
  routerSubscription2: any;
  visible: boolean ;

  countries: any[];

  selectedCountry: string;

  @Output() OnLanguageChange = new EventEmitter<string>();
  constructor(private router: Router,@Inject(DOCUMENT) public document: Document) {
    this.currentYear = new Date().getFullYear();

  }
    ngOnChanges(changes: SimpleChanges): void {

    }

    selectedChange(e){
        this.OnLanguageChange.next(e.value);
    }

  ngOnInit(): void {
    this.recallJsFuntions();
    this.countries = [

        { name: 'Arabic', code: 'ar' },

        { name: 'English', code: 'en' }
    ];
  }




  getIfShown(x){
    return !(this.location2.includes(x) );
  }
  recallJsFuntions() {
    this.routerSubscription2 = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
    .subscribe(event => {

        this.location2 = this.router.url;
       // console.log(this.location2);
        if (!(event instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0);
    });
}
}
