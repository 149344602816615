import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LocalstringFormService {
   myForm!:FormGroup
  constructor(private fb:FormBuilder) {
    this.initializForm("","","")
   }
  initializForm(resKey:string,lan:string,text:string){
    this.myForm=this.fb.group({
      resKey:[resKey],
      cultureId:[lan],
      text:[text]
    })
  }
  clear(){
    this.initializForm("","","");
  }
}
