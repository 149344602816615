<form [formGroup]="form1" (ngSubmit)="onSubmit()" autocomplete="off">

    <div class="container">
        <div class="card custom-table-border">
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'BscInfoTitle'|translate}}</h1>
                </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center" style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


                    </div>
                    <div class="row d-flex justify-content-center ">
                        <div class="col-sm-12 col-md-12 mb-3">
                            <h3 class="ms-2">{{'BscInfoTitle001'|translate}}</h3>
                        </div>

                        <div class="col-12">
                            <div class="row justify-content-center">
                                <small></small>
                                <div class="p-input-filled col-sm-12 col-md-4 mb-3">
                                    <span class="p-float-label">
                                        <p-dropdown [showClear]="true"  [autoDisplayFirst]="false" pTooltip="{{'BscInfoTitle001Slct001Pop'|translate}}" showDelay="1000"  tooltipPosition="bottom" [filter]="true" inputId="organizer" id="organizer" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                    form1.get('organizerId')?.touched &&
                                                    form1.get('organizerId')?.invalid || (form1.get('organizerId')?.invalid && Submitted)
                                            }" [styleClass]="'w-100'" formControlName="organizerId"
                                            [options]="organizers" optionLabel="name"
                                            optionValue="id"></p-dropdown>
                                        <label class="font-size-14" for="organizer">{{'BscInfoTitle001Slct001'|translate}}</label>
                                    </span>
                                    <small class="ps-3" > <a [routerLink]="'/organizers'" ><u>{{'BscInfoTitle001Slct001AsidBtn'|translate}}</u></a>
                                         {{'BscInfoTitle001Slct001Asid'|translate}} </small>
                                    <small class="p-error" *ngIf="form1.get('organizerId')?.touched &&
                                    form1.get('organizerId')?.invalid || (form1.get('organizerId')?.invalid && Submitted)">{{'BscInfoErrMsg'|translate}}</small>
                                </div>
                                <div class="p-input-filled col-sm-12 col-md-4 mb-3">
                                    <span class="p-float-label">
                                        <p-dropdown [showClear]="true" inputId="currencyId" id="event-type" [filter]="true"
                                        pTooltip="{{'BscInfoTitle001Slct002Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                    form1.get('curId')?.touched &&
                                                    form1.get('curId')?.invalid || (form1.get('curId')?.invalid && Submitted)
                                            }" [styleClass]="'w-100'" formControlName="curId"
                                            [autoDisplayFirst]="false" [options]="curData" optionLabel="name"
                                            optionValue="Id" #typeId></p-dropdown>
                                        <label class="form-label font-size-14" for="curId">{{'BscInfoTitle001Slct002'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="form1.get('curId')?.touched &&
                                    form1.get('curId')?.invalid || (form1.get('curId')?.invalid && Submitted)">{{'BscInfoErrMsg'|translate}}</small>
                                </div>

                                <div class="p-input-filled col-sm-12 col-md-4 mb-3 border-primary pb-4">
                                    <span class="p-float-label">
                                        <p-dropdown [showClear]="true" inputId="TzId" id="TzId"  pTooltip="{{'BscInfoTitle001Slct003Pop'|translate}}" showDelay="1000" tooltipPosition="bottom" [filter]="true" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                    form1.get('tzId')?.touched &&
                                                    form1.get('tzId')?.invalid || (form1.get('tzId')?.invalid && Submitted)
                                            }" [styleClass]="'w-100'" formControlName="tzId"
                                            [autoDisplayFirst]="false" [options]="tzData" optionLabel="name"
                                            optionValue="Id"></p-dropdown>
                                        <label class="form-label font-size-14" for="TzId">{{'BscInfoTitle001Slct003'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="form1.get('tzId')?.touched &&
                                    form1.get('tzId')?.invalid || (form1.get('tzId')?.invalid && Submitted)">{{'BscInfoErrMsg'|translate}}</small>
                                </div>
                            </div>
                        </div>

                        <div class="row justify-content-center"  [ngClass]="{'justify-content-center': hide} ">
                            <div class="col-sm-12 col-md-6 mb-3" *ngIf="hide">
                                <label class="block form-label font-size-14"   for="is-online">{{'BscInfoTitle001Swtch001'|translate}}</label>
                                <p-inputSwitch  formControlName="isOnline" pTooltip="{{'BscInfoTitle001Swtch001Pop'|translate}}" showDelay="1000" tooltipPosition="top" [disabled]="true" inputId="is-online"></p-inputSwitch>
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3" >
                                <label class="block form-label font-size-14" for="is-private">{{'BscInfoTitle001Swtch002'|translate}}</label>
                                <p-inputSwitch formControlName="isPrivate" pTooltip="{{'BscInfoTitle001Swtch002Pop'|translate}}" showDelay="1000" tooltipPosition="top" inputId="is-private"></p-inputSwitch>
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3" *ngIf="hide">
                                <label class="block form-label font-size-14"  for="is-recurring">{{'BscInfoTitle001Swtch003'|translate}}</label>
                                <p-inputSwitch formControlName="isRecurring" pTooltip="{{'BscInfoTitle001Swtch003Pop'|translate}}"
                                showDelay="1000" tooltipPosition="top"  inputId="is-recurring" [readonly]="!canCreateRequring" ></p-inputSwitch>
                                <small class="p-error" *ngIf="!canCreateRequring">The main Event you are create does not provide the ability to create a recurring event as sub event of it because to its time is one day.</small>
                            </div>
                            <div class="col-sm-12 col-md-6 mb-3" *ngIf="hide">
                                <label class="block form-label font-size-14"  for="is-recurring">{{'BscInfoTitle001Swtch004'|translate}}</label>
                                <p-inputSwitch formControlName="isColection" pTooltip="{{'BscInfoTitle001Swtch004Pop'|translate}}"
                                showDelay="1000" tooltipPosition="top"  inputId="is-recurring"></p-inputSwitch>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8 mb-3">
                            <input type="text " hidden name="Id" formControlName="id" />
                        </div>
                        <div class="row p-0">
                            <div class="col-12">
                                <div class="col-12 mb-3">
                                    <h3>{{'BscInfoTitle002'|translate}}</h3>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="p-input-filled col-12 col-md-12 mb-3">
                                        <span class="p-float-label">
                                            <textarea #evTitle  rows="1" type="text" formControlName="title" pTooltip="{{'BscInfoTitle002Inpt001Pop'|translate}}"
                                            showDelay="1000" tooltipPosition="bottom" id="event-title" pInputText
                                                class="form-control" [ngClass]="{
                            'ng-invalid ng-dirty':
                                form1.get('title')?.touched &&
                                form1.get('title')?.invalid || (form1.get('title')?.invalid && Submitted)
                        }" [class.ng-invalid.ng-dirty]="" [ngClass]="{'small-text': evTitle.value.length > 90 }"></textarea>
                                            <label class="font-size-14" for="event-title">{{'BscInfoTitle002Inpt001'|translate}}</label>
                                        </span>
                                        <small class="p-error" *ngIf="form1.get('title')?.touched &&
                                        form1.get('title')?.invalid || (form1.get('title')?.invalid && Submitted)">{{'BscInfoErrMsg'|translate}}</small>
                                    </div>


                                </div>
                                <div class="row justify-content-center">
                                    <div class="p-input-filled col-12 col-md-4 mb-3">
                                        <span class="p-float-label">
                                            <p-dropdown inputId="TicketType1" [filter]="true" filterBy="name" [showClear]="true" 
                                             pTooltip="{{'BscInfoTitle002Slct001Pop'|translate}}"showDelay="1000" tooltipPosition="bottom" id="event-type" [ngClass]="{
                            'ng-invalid ng-dirty':
                                form1.get('typeId')?.touched &&
                                form1.get('typeId')?.invalid || (form1.get('typeId')?.invalid && Submitted)
                        }" [styleClass]="'w-100'" formControlName="typeId" [autoDisplayFirst]="false"
                                                [options]="types" optionLabel="name" optionValue="Id"
                                                #typeId></p-dropdown>
                                            <label class="form-label font-size-14" for="event-type">{{'BscInfoTitle002Slct001'|translate}}</label>
                                        </span>

                                        <small class="p-error" *ngIf="form1.get('typeId')?.touched &&
                                        form1.get('typeId')?.invalid || (form1.get('typeId')?.invalid && Submitted)">{{'BscInfoErrMsg'|translate}}</small>
                                    </div>
                                    <div class="p-input-filled col-12 col-md-4 mb-3">
                                        <span class="p-float-label">
                                            <p-dropdown inputId="TicketType4"[filter]="true" filterBy="name" [showClear]="true" id="event-category"
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                    form1.get('categoryId')?.touched &&
                                                    form1.get('categoryId')?.invalid || (form1.get('categoryId')?.invalid && Submitted)
                                            }" [styleClass]="'w-100'"
                                             pTooltip="{{'BscInfoTitle002Slct002Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                                                [styleClass]="'w-100'" formControlName="categoryId"
                                                [autoDisplayFirst]="false" [options]="categories" optionLabel="name"
                                                optionValue="Id" (onChange)="onCategoryChange($event)"></p-dropdown>
                                            <label class="form-label font-size-14" for="event-category">{{'BscInfoTitle002Slct002'|translate}}</label>
                                        </span>
                                        <small class="p-error" *ngIf="form1.get('categoryId')?.touched &&
                                        form1.get('categoryId')?.invalid || (form1.get('categoryId')?.invalid && Submitted)">{{'BscInfoErrMsg'|translate}}</small>
                                    </div>
                                    <div class="p-input-filled col-12 col-md-4 mb-3  border-primary pb-4">
                                        <span class="p-float-label">
                                            <p-dropdown inputId="event-sub-category"[filter]="true" filterBy="name"   [showClear]="true" id="event-sub-category"
                                            [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                    form1.get('subCatId')?.touched &&
                                                    form1.get('subCatId')?.invalid || (form1.get('subCatId')?.invalid && Submitted)
                                            }" [styleClass]="'w-100'"
                                             pTooltip="{{'BscInfoTitle002Slct003Pop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                                                [styleClass]="'w-100'" formControlName="subCatId"
                                                [autoDisplayFirst]="false" [options]="subCategories" optionLabel="name"
                                                optionValue="Id"></p-dropdown>
                                            <label class="form-label font-size-14"
                                                for="event-sub-category">{{'BscInfoTitle002Slct003'|translate}}</label>
                                        </span>
                                        <small class="p-error" *ngIf="form1.get('subCatId')?.touched &&
                                        form1.get('subCatId')?.invalid || (form1.get('subCatId')?.invalid && Submitted)">{{'BscInfoErrMsg'|translate}}</small>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mb-3">
                                <h3 class="ms-2">{{'BscInfoTitle003Inpt001'|translate}}</h3>
                            </div>
                            <!-- <div class="col-sm-12 col-md-12 mb-3">
                                        <p>Add tags to help people find your event</p>
                                    </div> -->
                                     <!-- <input type="text" #myTags id="event-title" pInputText class="form-control"
                                     pTooltip="Add tags to help people find your event" showDelay="1000" tooltipPosition="top"
                                        maxlength="25"  /> -->
                            <div class="p-input-filled col-xl-11 col-sm-10 col-12 mb-3">
                                <span class="p-float-label">

                                    <p-autoComplete  #myTags id="event-title"   [inputStyleClass]="'form-control w-100'" [styleClass]="'form-control m-0 p-0'"
                                    pTooltip="{{'BscInfoTitle003Inpt001Pop'|translate}}" appendTo="body" showDelay="1000" tooltipPosition="top"
                                       maxlength="25" [suggestions]="resulttag" (completeMethod)="onTagSearch($event)"></p-autoComplete>

                                   
                                    <label class="font-size-14" for="event-title">{{'BscInfoTitle003Inpt001'|translate}}</label>
                                </span>

                            </div>
                            <div class=" banner-btn col-xl-1 col-sm-2 col-12 mb-3 text-sm-end text-center">
                                <button type="button" class="rounded-pill addTag-btn" (click)="AddTags(myTags)">
                                   {{'BscInfoTitle003Btn'|translate}} <span></span>
                                </button>
                            </div>
                            <div class="d-inline-block  col-md-11 col-12">
                                <button pButton type="button" class="p-button-raised p-button-rounded mx-1 my-1"
                                    *ngFor="let t of newTags; let i = index">
                                    {{ t }}
                                    <span class="tagCancel ms-1" (click)="removeTag(i)">
                                        <i class="fa-solid fa-circle-xmark"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" @fadeOut *ngIf="!loader.show1">
            <div class="col-12 d-flex justify-content-lg-center justify-content-center">
                <div class="banner-btn rounded-pill mx-2" *ngIf='id=="0"'>
                    <button class="rounded-pill save-btn" pTooltip="Cancel and dismiss changes"  showDelay="1000" tooltipPosition="left"   type="button" (click)="goBack()">
                     {{'CanclBtn'|translate}} <span></span>
                    </button>
                </div>
                <div class="">
                    <button [disabled]="isSubmitting"  type="submit" pTooltip="Save your event basic information " showDelay="1000" tooltipPosition="right" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}">
                        <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                        {{'SavBtn'|translate}} <span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
