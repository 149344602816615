<!-- <div class="events-area py-3">
    <div #myDiv class="container">
        <div class="card custom-table-border">
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">Events</h1>
            </div>

            <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
                style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


            </div>

            <p-toast></p-toast>

            <div class="mt-4">
                <div>

                    <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                        <div class="  ms-md-4 mb-sm-0  mb-2 ">
                            <button *ngIf="auth.securityObject.checkClaim(10105030101)" pButton pRipple
                                class="rounded-pill add-new-btn mr-2" (click)="openNew()"><i
                                    class="fa-solid fa-plus"></i> New<span></span></button>
                        </div>
                        <div class="  ms-md-4 mb-sm-0  mb-2 ">
                            <button pButton pRipple class=" p-button-outlined rounded-pill  mr-2"
                                (click)="chang(false)">
                                <i class="fa-solid fa-list mx-2"></i> List<span></span></button>
                            <button pButton pRipple class="p-button-outlined rounded-pill  mr-2" (click)="chang(true)">
                                <i class="fa-sharp fa-solid fa-calendar-clock mx-2"></i> Calendar <span></span></button>
                        </div>



                        <div class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="form-control"
                                    (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="container my-3" #mydiv>
                    <full-calendar #cal *ngIf='calendarVisible' [options]='calendarOptions'>
                    </full-calendar>
                    <p-overlayPanel #op [style]="{'width': '200px'}" [showCloseIcon]="true">
                        <ng-template pTemplate="content">
                            <p-table [value]="plugin" selectionMode="single" [(selection)]="selectedProduct"
                                (onRowSelect)="onRowSelect($event, op)" responsiveLayout="scroll">
                                <ng-template pTemplate="body" let-rowData let-product>
                                    <tr [pSelectableRow]="rowData">
                                        <td>{{product.name}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </ng-template>
                    </p-overlayPanel>
                    <p-overlayPanel #op1 appendTo="body" [autoZIndex]="true"
                        [style]="{'width': '300px','border-radius':'20px'}" [showCloseIcon]="true">
                        <ng-template pTemplate="content">
                            <div class="single-courses-box bg-color border-45">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-1" class="d-block image ">
                                        <img class=""
                                            [src]="eventPrv.photoLink==null?'assets/img/courses/3SUBiaP.png':url+eventPrv.photoLink"
                                            alt="image">
                                    </a>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-1">{{eventPrv.title}}</a></h3>
                                    <div class="">
                                    </div>
                                    <div class="card-footer-font-size  mb-2 mt-2 ">
                                        <div class="mt-3 d-md-flex justify-content-between border-top border-primary"
                                            style="color:var(--mainColor);">
                                            <span class="mt-2 fw-bold" Style="font-size: 13px"><small><i
                                                        class="far fa-clock "></i>
                                                    Start</small> {{ gettime(eventPrv.startDate)}}</span><br />
                                            <span class="mt-2 fw-bold" Style="font-size: 13px"><small><i
                                                        class="far fa-clock"></i> End:</small>
                                                {{gettime(eventPrv.endDate)}}</span>
                                        </div>
                                    </div>
                                    <div class="card-footer-font-size d-block align-items-center ">
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-overlayPanel>
                </div>
                <div *ngIf="!calendarVisible">
                    <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="events" [rows]="5"
                        [paginator]="events.length>5" [globalFilterFields]="['title']" [rowHover]="true" dataKey="id"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" class="">

                        <ng-template pTemplate="header" class="">
                            <tr>
                                <th></th>
                                <th class="fw-bold text-center" style="min-width: 9rem;">Image</th>
                                <th pSortableColumn="title" class="fw-bold text-center">Title
                                    <p-sortIcon field="titel"></p-sortIcon>
                                </th>
                                <th pSortableColumn="totalQty" class="fw-bold text-center">
                                    Quantity <p-sortIcon field="totalQty"></p-sortIcon></th>
                                <th pSortableColumn="gross" class="fw-bold text-center">Gross <p-sortIcon
                                        field="gross"></p-sortIcon></th>
                                <th class="fw-bold text-center">Status </th>
                                <th class="fw-bold text-center" style="min-width: 9rem;"> Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-event let-expanded="expanded">
                            <tr>
                                <td>
                                    <button *ngIf="event.eventInstance != null" type="button" pButton pRipple
                                        [pRowToggler]="event" class="p-button-text p-button-rounded p-button-plain"
                                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                </td>
                                <td class="text-md-center padding-custom text-end Image-cutom"><span
                                        class=" text-start fw-bold "></span>
                                    <img [src]="event.photoLink==null?'assets/img/courses/3SUBiaP.png':url+event.photoLink"
                                        [alt]="event.title" width="100" class="shadow-4 rounded-2" />
                                </td>
                                <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                        class="headers-cutom text-start fw-bold ">Tilte</span>
                                    <span class="text-lenght"> {{event.isRecurring?event.title+"
                                        "+getrecDate(event.startDate):event.title}}</span>
                                </td>
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">Quantity</span>{{event.soldTickets}}/{{event.totalQty}}
                                </td>
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">Gross</span>{{event.gross+""+event.curSymbol}}
                                </td>

                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">Status</span><span [ngClass]="{
                                    'text-bg-green': event.isPublished , 'text-bg-dark' : !event.isPublished
                                }" class="badge rounded-pill"> {{getstatus(event.isPublished)}}</span></td>

                                <td class="text-md-center">
                                    <p-overlayPanel #op>
                                        <button pButton pRipple icon="pi pi-pencil"
                                            class="p-button-rounded p-button-primary m-1"
                                            *ngIf="auth.securityObject.checkClaim(10105030101)"
                                            (click)="editProduct(event)"></button><br>
                                        <button *ngIf="event.isPublished" pButton pRipple icon="pi pi-eye"
                                            class="p-button-rounded p-button-primary m-1"
                                            (click)="preview(event.id)"></button><br *ngIf="event.isPublished">
                                        <p-toast></p-toast>
                                        <p-confirmPopup></p-confirmPopup>
                                        <button pButton pRipple icon="pi pi-trash"
                                            class="p-button-rounded p-button-danger m-1"
                                            (click)="remove($event,event.id)"></button>
                                    </p-overlayPanel>
                                    <button pButton pRipple (click)="op.toggle($event)" icon="far fa-ellipsis-v"
                                        class="p-button-rounded p-button-primary m-1"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-event>
                            <tr>
                                <td colspan="12">

                                    <p-table responsiveLayout="stack" [breakpoint]="'991px'"
                                        [value]="event.eventInstance" dataKey="id" class="table-responsive">

                                        <ng-template pTemplate="body" let-ev>
                            <tr>
                                <td class="text-md-center padding-custom text-end Image-cutom"><span
                                        class=" text-start fw-bold "></span>
                                    <img [src]="ev.photoLink==null?'assets/img/courses/3SUBiaP.png':url+ev.photoLink"
                                        [alt]="ev.title" width="100" class="shadow-4 rounded-2" />
                                </td>
                                <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                        class="headers-cutom text-start fw-bold ">Tilte & Date</span><span
                                        class="text-lenght">{{ev.isRecurring?ev.title+"
                                        "+getrecDate(ev.startDate):ev.title}}</span></td>
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">Quantity</span>{{ev.soldTickets}}/{{ev.totalQty}}
                                </td>
                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">Gross</span>{{ev.gross+""+ev.curSymbol}}
                                </td>

                                <td class="text-md-center padding-custom text-end"><span
                                        class="headers-cutom text-start fw-bold ">Status</span><span [ngClass]="{
                                                        'text-bg-green': ev.isPublished , 'text-bg-dark' : !ev.isPublished
                                                    }" class="badge rounded-pill"> {{getstatus(ev.isPublished)}}</span>
                                </td>
                                <td class="text-center">
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-primary mr-2"
                                        *ngIf="auth.securityObject.checkClaim(10105030101)"
                                        (click)="editProduct(ev)"></button>
                                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                        (click)="remove($event,ev.id)"></button>
                                </td>
                            </tr>
                        </ng-template>


                    </p-table>

                    </td>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <div class="flex align-items-center justify-content-between">
                            In total there are {{allEventForPrev ? allEventForPrev.length : 0 }} events.
                        </div>
                    </ng-template>
                    </p-table>
                </div>
            </div>

        </div>
    </div>
</div> -->

<div class="events-area py-3">
    <div #myDiv class="container">
        <div class="card custom-table-border">
            <div
                class="card-header border-0 text-center bg-primary rounded-top-23"
            >
                <h1 class="fw-bold text-white mt-2">
                    {{ "EvntTblTittle001" | translate }}
                </h1>
            </div>

            <p-toast></p-toast>

            <div class="mt-4">
                <div>
                    <div
                        class="d-sm-flex d-block align-items-center justify-content-sm-between text-center"
                    >
                        <div class="ms-md-4 mb-sm-0 mb-2">
                            <button
                                *ngIf="
                                    auth.securityObject.checkClaim(10105030101)
                                "
                                pButton
                                pRipple
                                class="rounded-pill add-new-btn mb-lg-0 mb-sm-2 mr-2"
                                (click)="openNew()"
                            >
                                <i class="fa-solid fa-plus"></i>
                                {{ "EvntTblBtn001" | translate }}<span></span>
                            </button>
                            <button
                                *ngIf="
                                    auth.securityObject.checkClaim(10105030101)
                                "
                                pButton
                                pRipple
                                class="rounded-pill add-new-btn mr-2"
                                (click)="menu.toggle($event)"
                            >
                                <i class="fa-solid fa-filter-list"></i>
                                {{ selectedFilter }}<span></span>
                            </button>
                            <p-menu
                                #menu
                                appendTo="body"
                                [popup]="true"
                                [model]="items"
                            ></p-menu>
                            <button
                                *ngIf="
                                    auth.securityObject.checkClaim(10105030101)
                                "
                                pButton
                                pRipple
                                class="rounded-pill add-new-btn mr-2"
                                (click)="orgMenu.toggle($event)"
                            >
                                <i class="fa-solid fa-users"></i>
                                {{ selectedFilter1 }}<span></span>
                            </button>
                            <p-menu
                                #orgMenu
                                appendTo="body"
                                [popup]="true"
                                [model]="orgnizerData"
                            ></p-menu>
                        </div>

                        <div class="ms-md-4 mb-sm-0 mb-2">
                            <button
                                pButton
                                pRipple
                                class="rounded-pill mb-lg-0 mb-sm-2 mr-2"
                                [ngClass]="{
                                    'p-button-outlined': calendarVisible
                                }"
                                (click)="chang(false)"
                            >
                                <i class="fa-solid fa-list mx-2"></i>
                                {{ "EvntTblBtn003" | translate }}<span></span>
                            </button>
                            <button
                                pButton
                                pRipple
                                class="rounded-pill mr-2"
                                [ngClass]="{
                                    'p-button-outlined': !calendarVisible
                                }"
                                (click)="chang(true)"
                            >
                                <i
                                    class="fa-sharp fa-solid fa-calendar-clock mx-2"
                                ></i>
                                {{ "EvntTblBtn004" | translate }} <span></span>
                            </button>
                        </div>

                        <div class="p-input-filled me-md-3">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input
                                    pInputText
                                    type="text"
                                    class="form-control"
                                    (input)="
                                        dt.filterGlobal(
                                            $event.target.value,
                                            'contains'
                                        )
                                    "
                                    placeholder="{{ 'Search...' | translate }}"
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="container my-3" #mydiv>
                    <full-calendar
                        #cal
                        *ngIf="calendarVisible"
                        [options]="calendarOptions"
                    >
                    </full-calendar>
                    <p-overlayPanel
                        #op
                        [style]="{ width: '200px' }"
                        [showCloseIcon]="true"
                    >
                        <ng-template pTemplate="content">
                            <p-table
                                [value]="plugin"
                                selectionMode="single"
                                [(selection)]="selectedProduct"
                                (onRowSelect)="onRowSelect($event, op)"
                                responsiveLayout="scroll"
                            >
                                <ng-template
                                    pTemplate="body"
                                    let-rowData
                                    let-product
                                >
                                    <tr [pSelectableRow]="rowData">
                                        <td>{{ product.name }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </ng-template>
                    </p-overlayPanel>
                    <p-overlayPanel
                        #op1
                        appendTo="body"
                        [autoZIndex]="true"
                        [style]="{ width: '300px', 'border-radius': '20px' }"
                        [showCloseIcon]="true"
                    >
                        <ng-template pTemplate="content">
                            <div class="single-courses-box bg-color border-45">
                                <div class="courses-image">
                                    <a
                                        routerLink="/single-courses-1"
                                        class="d-block image"
                                    >
                                        <img
                                            class=""
                                            [src]="
                                                eventPrv.photoLink == null
                                                    ? 'assets/img/courses/3SUBiaP.png'
                                                    : url + eventPrv.photoLink
                                            "
                                            alt="image"
                                        />
                                    </a>
                                </div>
                                <div class="courses-content">
                                    <!-- <h3><a routerLink="/single-courses-1">{{eventPrv.title}}</a></h3> -->
                                    <h3>
                                        <a
                                            routerLink="/single-courses-1"
                                            style="word-wrap: break-word"
                                            >{{ eventPrv.title }}</a
                                        >
                                    </h3>
                                    <div class=""></div>
                                    <div
                                        class="card-footer-font-size mb-2 mt-2"
                                    >
                                        <div
                                            class="mt-3 d-md-flex justify-content-between border-top border-primary"
                                            style="color: var(--mainColor)"
                                        >
                                            <span
                                                class="mt-2 fw-bold"
                                                Style="font-size: 13px"
                                                ><small
                                                    ><i
                                                        class="far fa-clock"
                                                    ></i>
                                                    {{
                                                        "Start:" | translate
                                                    }}</small
                                                >
                                                {{
                                                    " " +
                                                        getDateOntomeZoneformat(
                                                            eventPrv.startDate,
                                                            "full"
                                                        )
                                                }}</span
                                            ><br />
                                            <span
                                                class="mt-2 fw-bold"
                                                Style="font-size: 13px"
                                                ><small
                                                    ><i
                                                        class="far fa-clock"
                                                    ></i>
                                                    {{
                                                        "End:" | translate
                                                    }}</small
                                                >
                                                {{
                                                    " " +
                                                        getDateOntomeZoneformat(
                                                            eventPrv.endDate,
                                                            "full"
                                                        )
                                                }}</span
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="card-footer-font-size d-block align-items-center"
                                    ></div>
                                </div>
                            </div>
                        </ng-template>
                    </p-overlayPanel>
                </div>
                <div *ngIf="!calendarVisible">
                    <p-table
                        #dt
                        responsiveLayout="stack"
                        [breakpoint]="'991px'"
                        [value]="events"
                        [rows]="5"
                        [paginator]="totalRecourd > 5"
                        [lazy]="true"
                        [totalRecords]="totalRecourd"
                        (onLazyLoad)="getEvent($event)"
                        [globalFilterFields]="['title']"
                        [rowHover]="true"
                        dataKey="id"
                        currentPageReportTemplate="{{
                            'Showing' | translate
                        }} {first} {{ to | translate }} {last} {{
                            'of' | translate
                        }} {totalRecords} {{ 'events' | translate }}"
                        [showCurrentPageReport]="true"
                        class=""
                    >
                        <ng-template pTemplate="header" class="">
                            <tr>
                                <th
                                    class="fw-bold text-start"
                                    style="min-width: 9rem"
                                >
                                    {{ "EvntTblTh001" | translate }}
                                </th>
                                <!-- <th class="d-lg-none d-block"></th> -->
                                <th
                                    pSortableColumn="totalQty"
                                    class="fw-bold text-center"
                                >
                                    {{ "EvntTblTh002" | translate }}
                                </th>
                                <th
                                    pSortableColumn="gross"
                                    class="fw-bold text-center"
                                >
                                    {{ "EvntTblTh003" | translate }}
                                </th>
                                <th class="fw-bold text-center">
                                    {{ "EvntTblTh004" | translate }}
                                </th>
                                <th
                                    class="fw-bold text-center"
                                    style="min-width: 9rem"
                                >
                                    {{ "EvntTblTh005" | translate }}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template
                            pTemplate="body"
                            let-event
                            let-expanded="expanded"
                        >
                            <tr>
                                <td
                                    class="text-md-start padding-custom text-end Image-cutom"
                                >
                                    <div class="d-flex">
                                        <div class="static-width">
                                            <span
                                                class="text-center row align-items-center d-lg-flex d-none fw-bold me-md-2"
                                                style="width: 100px"
                                            >
                                                <h5 class="text-primary col-12">
                                                    {{
                                                        getDateOntomeZoneformat(
                                                            event.startDate,
                                                            "month"
                                                        )
                                                    }}
                                                </h5>
                                                <h5 class="col-12">
                                                    {{
                                                        getDateOntomeZoneformat(
                                                            event.startDate,
                                                            "day"
                                                        )
                                                    }}
                                                </h5>
                                            </span>
                                            <div
                                                class="d-lg-flex d-block align-items-center me-lg-2"
                                            >
                                                <img
                                                    [src]="
                                                        event.photoLink == null
                                                            ? 'assets/img/courses/3SUBiaP.png'
                                                            : url +
                                                              event.photoLink
                                                    "
                                                    [alt]="event.title"
                                                    width="100"
                                                    class="shadow-4 min-size rounded-2"
                                                />
                                            </div>
                                        </div>

                                        <div class="d-lg-block d-none">
                                            <span
                                                class="text-lenght d-lg-block"
                                            >
                                                {{
                                                    event.isRecurring
                                                        ? event.title +
                                                          "
                                                " +
                                                          getrecDate(
                                                              event.startDate
                                                          )
                                                        : event.title
                                                }}</span
                                            >
                                            <span
                                                class="text-secondary d-lg-block"
                                                >{{ event.address }}</span
                                            >
                                            <span class="text-secondary">{{
                                                getDateOntomeZoneformat(
                                                    event.startDate,
                                                    "full"
                                                )
                                            }}</span>
                                            <span>
                                                <!-- <button *ngIf="event.eventInstance != null&&event.isRecurring&&event.isSeries" type="button" pButton
                                                    pRipple [pRowToggler]="event"
                                                    class="p-button-text p-button-rounded d-lg-block d-none"
                                                    [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">
                                                    <i class="fa-solid fa-layer-group ticket"></i>

                                                    <i class="fa-solid fa-arrow-rotate-left arrow"></i> Reccuring event series </button> -->
                                                <!-- <button *ngIf="!event.isRecurring&&event.isSeries" type="button" pButton
                                                   pRipple [pRowToggler]="event"
                                                   class="p-button-text p-button-rounded d-lg-block d-none"
                                                   [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">
                                                   <i class="fa-sharp fa-light fa-sitemap sitemap"></i>
                                                   <i class="fa-solid fa-arrow-rotate-left arrowSit"></i> Event series</button> -->
                                                <button
                                                    *ngIf="event.isSeries"
                                                    type="button"
                                                    pButton
                                                    pRipple
                                                    class="p-button-text p-button-rounded d-lg-block d-none"
                                                    [icon]="
                                                        'fa-sharp fa-light fa-sitemap'
                                                    "
                                                    (click)="goto(event.id)"
                                                >
                                                    {{
                                                        "Event series"
                                                            | translate
                                                    }}
                                                </button>
                                                <!-- <button *ngIf="event.eventInstance != null&&event.isRecurring" type="button" pButton -->
                                                <button
                                                    *ngIf="event.isRecurring"
                                                    type="button"
                                                    pButton
                                                    pRipple
                                                    [pRowToggler]="event"
                                                    class="p-button-text p-button-rounded d-lg-block d-none"
                                                    [icon]="
                                                        expanded
                                                            ? 'fa-regular fa-chevron-down'
                                                            : 'fa-solid fa-layer-group ticket fa-rotate-90'
                                                    "
                                                    (click)="
                                                        getinstanse(event.id)
                                                    "
                                                >
                                                    {{
                                                        "Reccuring event"
                                                            | translate
                                                    }}
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td
                                    class="text-md-center padding-custom text-lenght-larg text-end d-lg-none d-flex"
                                >
                                    <span
                                        class="headers-cutom text-start fw-bold"
                                        >{{ "Title" | translate }}</span
                                    ><span class="text-lenght">
                                        {{
                                            event.isRecurring
                                                ? event.title +
                                                  "
                                        " +
                                                  getrecDate(event.startDate)
                                                : event.title
                                        }}</span
                                    >
                                </td>
                                <td
                                    class="text-md-center padding-custom text-end d-lg-none d-flex"
                                >
                                    <span
                                        class="headers-cutom text-start fw-bold"
                                        >{{ "Date" | translate }}</span
                                    >
                                    <span class="text-lenght text-secondary">{{
                                        getDateOntomeZoneformat(
                                            event.startDate,
                                            "full"
                                        )
                                    }}</span>
                                </td>
                                <td
                                    class="text-md-center padding-custom text-end d-lg-none d-flex"
                                >
                                    <span
                                        class="headers-cutom text-start fw-bold"
                                        >{{ "Location" | translate }}</span
                                    >
                                    <span class="text-lenght text-secondary">
                                        {{ event.address }}</span
                                    >
                                </td>
                                <td
                                    class="text-md-center padding-custom text-end"
                                >
                                    <span
                                        class="headers-cutom text-start fw-bold"
                                        >{{ "EvntTblTh002" | translate }}</span
                                    >{{ event.soldTickets }}/{{
                                        event.totalQty
                                    }}
                                </td>
                                <td
                                    class="text-md-center padding-custom text-end"
                                >
                                    <span
                                        class="headers-cutom text-start fw-bold"
                                        >{{ "EvntTblTh003" | translate }}</span
                                    >{{ event.gross + "" + event.curSymbol }}
                                </td>
                                <!-- <td><p-rating [ngModel]="event.gross" [readonly]="true" [cancel]="false"></p-rating></td> -->
                                <!-- <td><span [class]="'product-badge status-' + (product.inventoryStatus ? product.inventoryStatus.toLowerCase() : '')">{{product.inventoryStatus}}</span></td> -->
                                <td
                                    class="text-md-center padding-custom text-end"
                                >
                                    <span
                                        class="headers-cutom text-start fw-bold"
                                        >{{ "EvntTblTh004" | translate }}</span
                                    ><span
                                        [ngClass]="{
                                            'text-bg-green':
                                                event.isPublished &&
                                                !event.isCompleted,
                                            'text-bg-dark':
                                                !event.isPublished &&
                                                !event.isCompleted,
                                            'text-bg-danger': event.isCompleted
                                        }"
                                        class="badge rounded-pill"
                                    >
                                        {{
                                            getstatus(
                                                event.isPublished,
                                                event.isCompleted
                                            ) | translate
                                        }}</span
                                    >
                                </td>

                                <td class="text-md-center">
                                    <button
                                        *ngIf="event.eventInstance != null"
                                        type="button"
                                        pButton
                                        pRipple
                                        [pRowToggler]="event"
                                        class="p-button-text p-button-rounded d-lg-none d-block"
                                        [icon]="
                                            expanded
                                                ? 'fa-regular fa-chevron-down'
                                                : 'fa-regular fa-ticket fa-rotate-90'
                                        "
                                    >
                                        {{
                                            "EvntTblBtn002Chois004" | translate
                                        }}
                                    </button>
                                    <p-overlayPanel #op>
                                        <button
                                            pButton
                                            pRipple
                                            icon="pi pi-pencil"
                                            class="p-button-rounded p-button-primary m-1"
                                            *ngIf="
                                                auth.securityObject.checkClaim(
                                                    10105030101
                                                )
                                            "
                                            (click)="editProduct(event)"
                                        ></button
                                        ><br />
                                        <button
                                            *ngIf="event.isPublished"
                                            pButton
                                            pRipple
                                            icon="pi pi-eye"
                                            class="p-button-rounded p-button-primary m-1"
                                            (click)="preview(event.id)"
                                        ></button
                                        ><br *ngIf="event.isPublished" />
                                        <p-toast></p-toast>
                                        <p-confirmPopup></p-confirmPopup>
                                        <button
                                            pButton
                                            pRipple
                                            icon="pi pi-trash"
                                            class="p-button-rounded p-button-danger m-1"
                                            (click)="remove($event, event.id)"
                                        ></button>
                                    </p-overlayPanel>

                                    <button
                                        pButton
                                        pRipple
                                        (click)="op.toggle($event)"
                                        icon="far fa-ellipsis-v"
                                        class="p-button-rounded p-button-primary m-1"
                                    ></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="rowexpansion" let-event>
                            <tr>
                                <td colspan="12" class="padding-sum-small">
                                    <p-table
                                        responsiveLayout="stack"
                                        [breakpoint]="'991px'"
                                        [value]="Subevents"
                                        dataKey="id"
                                        class="table-responsive"
                                        [rows]="5"
                                        [paginator]="totalRecins > 5"
                                        [lazy]="true"
                                        [totalRecords]="totalRecins"
                                        (onLazyLoad)="
                                            getinstanse(event.id, $event)
                                        "
                                    >
                                        <ng-template pTemplate="body" let-ev>
                                            <tr>
                                                <td
                                                    class="text-md-center padding-custom text-end Image-cutom"
                                                >
                                                    <div class="d-flex">
                                                        <div
                                                            class="static-width"
                                                        >
                                                            <span
                                                                class="text-center row align-items-center d-lg-flex d-none fw-bold me-md-2"
                                                                style="
                                                                    width: 100px;
                                                                "
                                                            >
                                                                <h5
                                                                    class="text-primary col-12"
                                                                >
                                                                    {{
                                                                        getDateOntomeZoneformat(
                                                                            ev.startDate,
                                                                            "month"
                                                                        )
                                                                    }}
                                                                </h5>
                                                                <h5
                                                                    class="col-12"
                                                                >
                                                                    {{
                                                                        getDateOntomeZoneformat(
                                                                            ev.startDate,
                                                                            "day"
                                                                        )
                                                                    }}
                                                                </h5>
                                                            </span>
                                                            <div
                                                                class="d-lg-flex d-block align-items-center me-md-2"
                                                            >
                                                                <img
                                                                    [src]="
                                                                        ev.photoLink ==
                                                                        null
                                                                            ? 'assets/img/courses/3SUBiaP.png'
                                                                            : url +
                                                                              ev.photoLink
                                                                    "
                                                                    [alt]="
                                                                        ev.title
                                                                    "
                                                                    width="100"
                                                                    class="shadow-4 min-size-sub rounded-2"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="d-lg-block d-none text-start"
                                                        >
                                                            <span
                                                                class="text-lenght d-lg-block"
                                                            >
                                                                {{
                                                                    ev.isRecurring
                                                                        ? ev.title +
                                                                          "
                                                " +
                                                                          getrecDate(
                                                                              ev.startDate
                                                                          )
                                                                        : ev.title
                                                                }}</span
                                                            >
                                                            <span
                                                                class="text-secondary d-lg-block"
                                                                >{{
                                                                    ev.address
                                                                }}</span
                                                            >
                                                            <span
                                                                class="text-secondary"
                                                                >{{
                                                                    getDateOntomeZoneformat(
                                                                        ev.startDate,
                                                                        "full"
                                                                    )
                                                                }}</span
                                                            >
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    class="text-md-center padding-custom text-lenght-larg text-end d-lg-none d-flex"
                                                >
                                                    <span
                                                        class="headers-cutom text-start fw-bold"
                                                        >{{
                                                            "Title" | translate
                                                        }}</span
                                                    ><span class="text-lenght">
                                                        {{
                                                            ev.isRecurring
                                                                ? ev.title +
                                                                  "
                                        " +
                                                                  getrecDate(
                                                                      ev.startDate
                                                                  )
                                                                : ev.title
                                                        }}</span
                                                    >
                                                </td>

                                                <td
                                                    class="text-md-center padding-custom text-end d-lg-none d-flex"
                                                >
                                                    <span
                                                        class="headers-cutom text-start fw-bold"
                                                        >{{
                                                            "Date" | translate
                                                        }}</span
                                                    >
                                                    <span
                                                        class="text-lenght text-secondary"
                                                        >{{
                                                            getDateOntomeZoneformat(
                                                                ev.startDate,
                                                                "full"
                                                            )
                                                        }}</span
                                                    >
                                                </td>
                                                <td
                                                    class="text-md-center padding-custom text-end d-lg-none d-flex"
                                                >
                                                    <span
                                                        class="headers-cutom text-start fw-bold"
                                                        >{{
                                                            "Location"
                                                                | translate
                                                        }}</span
                                                    >
                                                    <span
                                                        class="text-lenght text-secondary"
                                                    >
                                                        {{ ev.address }}</span
                                                    >
                                                </td>
                                                <td
                                                    class="text-md-center padding-custom text-end"
                                                >
                                                    <span
                                                        class="headers-cutom text-start fw-bold"
                                                        >{{
                                                            "EvntTblTh002"
                                                                | translate
                                                        }}</span
                                                    >{{ ev.soldTickets }}/{{
                                                        ev.totalQty
                                                    }}
                                                </td>
                                                <td
                                                    class="text-md-center padding-custom text-end"
                                                >
                                                    <span
                                                        class="headers-cutom text-start fw-bold"
                                                        >{{
                                                            "EvntTblTh003"
                                                                | translate
                                                        }}</span
                                                    >{{
                                                        ev.gross +
                                                            "" +
                                                            ev.curSymbol
                                                    }}
                                                </td>

                                                <td
                                                    class="text-md-center padding-custom text-end"
                                                >
                                                    <span
                                                        class="headers-cutom text-start fw-bold"
                                                        >{{
                                                            "EvntTblTh004"
                                                                | translate
                                                        }}</span
                                                    ><span
                                                        [ngClass]="{
                                                            'text-bg-green':
                                                                ev.isPublished,
                                                            'text-bg-dark':
                                                                !ev.isPublished
                                                        }"
                                                        class="badge rounded-pill"
                                                    >
                                                        {{
                                                            getstatus(
                                                                ev.isPublished
                                                            ) | translate
                                                        }}</span
                                                    >
                                                </td>
                                                <td class="text-center">
                                                    <div
                                                        [class.action-custom]="
                                                            document
                                                                .documentElement
                                                                .dir === 'ltr'
                                                        "
                                                        [class.action-custom-rtl]="
                                                            document
                                                                .documentElement
                                                                .dir === 'rtl'
                                                        "
                                                    >
                                                        <button
                                                            pButton
                                                            pRipple
                                                            icon="pi pi-pencil"
                                                            class="p-button-rounded p-button-primary mr-2"
                                                            *ngIf="
                                                                auth.securityObject.checkClaim(
                                                                    10105030101
                                                                )
                                                            "
                                                            (click)="
                                                                editProduct(ev)
                                                            "
                                                        ></button>
                                                        <button
                                                            pButton
                                                            pRipple
                                                            icon="pi pi-trash"
                                                            class="p-button-rounded p-button-danger"
                                                            (click)="
                                                                remove(
                                                                    $event,
                                                                    ev.id
                                                                )
                                                            "
                                                        ></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>

                                        <!-- <ng-template pTemplate="summary">
                                                                    <div class="flex align-items-center justify-content-between">
                                                                        In total there are {{event.eventInstance ? event.eventInstance.length : 0 }} instance.
                                                                    </div>
                                                                </ng-template> -->
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="6">
                                                    {{
                                                        "EmpMsgSubEvent"
                                                            | translate
                                                    }}
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="summary">
                            <div
                                class="flex align-items-center justify-content-between"
                            >
                                {{ "EvntTblFooter" | translate }}
                                {{
                                    allEventForPrev ? allEventForPrev.length : 0
                                }}
                                {{ "EvntTblFooter01" | translate }}
                            </div>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
