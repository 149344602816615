import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemlangServService } from './systemlang-serv.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DOCUMENT } from '@angular/common';
import { systemlangDto } from 'src/app/Interfaces/attrDto';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-system-languegesmang',
  templateUrl: './system-languegesmang.component.html',
  styleUrls: ['./system-languegesmang.component.scss'],
  animations: [fadeOut]
})
export class SystemLanguegesmangComponent  implements OnInit{
  data:systemlangDto[]=[];
  constructor(private activatedRoute:ActivatedRoute,
    private route:Router, private attrSrv:SystemlangServService,public loader : LoaderService, public messageService : MessageService,
    @Inject(DOCUMENT) public document: Document,private conSrv: ConfirmationService){

  }
  ngOnInit(): void {
    this.loader.showLoader1();
    this.loader.showLoader();
    this.attrSrv.getAlltype();
    this.attrSrv.$getSubject.subscribe(x=>{
      this.data=x;
      this.loader.hideLoader();
      this.loader.hideLoader1();

    })
  }
  publish(id){
   this.attrSrv.postattrstauts(id).subscribe(x=>{
 //console.log(x);
 this.attrSrv.getAlltype();
   })
  }

  changPrimary(id){
    this.attrSrv.changPrimary(id).subscribe(x=>{
      this.attrSrv.getAlltype()
    })
  }
  remove(event, id) {

    this.conSrv.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
     var del=    this.attrSrv.DeletAttr(id).subscribe(x => {
        this.attrSrv.getAlltype();
          this.messageService.add({ severity: 'success', summary: 'Deleted successfully' });
        },(eror)=>{
          this.messageService.add({ severity: 'danger', summary: eror.message });
        });
     
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {
        this.messageService.add({ severity: 'danger', summary: 'you not confirm delete operation' });
      }
    });
  }
  edit(id){
    this.route.navigate([id],{relativeTo: this.activatedRoute});
  }
  show(){
    this.route.navigate(["0"],{relativeTo: this.activatedRoute});
  }


}
