<div class="container  mt-3">

    <div class="love-btn ">

        <button type="buttom" (click)="Like(data.id)"><i
                class="{{liked? 'fa-solid text-danger':'fa-regular' }} fa-heart "></i></button>
    </div>
    <div class="hate-btn ">
        <button type="button" (click)="goBack()"><i class="fa-solid fa-left"></i></button>
    </div>

    <div class="card border border-primary bg-modified rounded-25 ">
        <div class="no-gutters ">

            <!-- Event Photo -->


            <div class="col-12 p-0" id="event-img-div">
                <img [src]='url+data.coverImg' class="card-img img-fluid d-lg-flex d-block " alt="..." id="event-image">
                <div id="timer" class="flex-wrap d-flex justify-content-center" *ngIf="!isStarted&&!isEnded">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}
                        <span>{{'EvntPgClock001'|translate}}</span>
                    </div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}}
                        <span>{{'EvntPgClock002'|translate}}</span>
                    </div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}}
                        <span>{{'EvntPgClock003'|translate}}</span>
                    </div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}}
                        <span>{{'EvntPgClock004'|translate}}</span>
                    </div>
                </div>
                <div id="timer" class="flex-wrap d-flex justify-content-center" *ngIf="isStarted||isEnded">
                    <div id="days" class="align-items-center w-100 flex-column d-flex justify-content-center">
                        {{isEnded?('EvntPgTiming002'|translate):'EvntPgTiming001'|translate}}
                        <span></span>
                    </div>


                </div>

            </div>

            <!-- Event-Next-To-img-Info -->

            <div class="col-lg-12 col-12 mt-0 pt-0 events-top-details ">
                <div class="card-body mt-0 pt-0 ">
                    <div class="events-details-header">
                        <ul class="p-0">
                            <li *ngIf="data.startDate"><i class="bx bx-calendar"></i> {{'EvntPgTimDsc001'|translate}} {{ " "+
                                getDateOntomeZoneformat2(data.startDate)}}</li>
                            <li *ngIf="data.endDate"><i class="bx bx-calendar"></i> {{'EvntPgTimDsc002'|translate}}{{" "+
                                getDateOntomeZoneformat2(data.endDate)}}</li>
                            <li *ngIf="!data.isOnline"><i class="bx bx-map"></i>{{data.location}}</li>

                        </ul>
                    </div>
                    <span class="event-title text-primary mb-5">{{data.title}}</span>
                    <span class="organizer-name mt-4"><a [routerLink]="['/organizers','preview',data.orgId]"><i
                                class="fal fa-user"></i> {{data.organizerName}}</a></span>
                    <div>
                        <ul class="mt-2 fs-5 p-0">
                            <li *ngFor="let x of data.organizerLinks" style="display:inline;margin-right:5px;">
                                <a [href]="x.linkUrl" target="_blank" rel="noopener noreferrer" class="text-primary"><i
                                        [class]="x.icon"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="d-flex justify-content-md-center mt-1 d-lg-flex">
                        <span class="mt-2"><i class="fad fa-users"></i> {{'EvntPgTimDsc003'|translate}}<span
                                class="text-primary">{{" "+data.organizerFollowers}}
                            </span> </span>
                        <div class="rounded-pill mx-2 p-0">
                            <button (click)="follow(data.orgId,data.id)"
                                class="follow-btn mt-md-0 rounded-pill">{{followed?
                                ('FlwoBtn002'|translate) : 'FlwoBtn001' |translate}}<span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-lg-flex no-gutters ">
            <!-- Event Sections -->

            <div class="col-lg-8 col-12 container  m-0">

                <!-- Google Map Section -->

                <section class="col-12 mb-5 event-section-text-size" id="accordion" *ngIf="!mapProblem&&!data.isOnline">

                    <div #gmap style="width:100%;height:400px"></div>

                </section>

                <ng-container *ngFor="let item of data.description">
                    <!-- Regular Pargraph -->

                    <section *ngIf="item.type ==='text'" class="col-12 mb-5 event-section-text-size">
                        <div [innerHTML]="makeItSafeHtml(item.value1)">

                        </div>
                    </section>

                    <!-- Image Section -->
                    <section *ngIf="item.type ==='image'" class="col-12 mb-5">
                        <div>
                            <img class="img-fluid w-100" [src]="item.value1" alt="" />
                        </div>
                    </section>
                    <!--YouTube Video Section -->
                    <section *ngIf="item.type ==='video'" class="col-12 mb-5">
                        <div class="iframe-container w-100">

                            <youtube-player [videoId]="getUrlid(item.value1)" width="100%"
                                height="360"></youtube-player>
                            <!-- <iframe  [src]="makeItSafe(item.value1)"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen ></iframe> -->
                        </div>
                    </section>
                </ng-container>
                <!-- Tags Section* -->
                <section class="col-12 mb-5 event-section-text-size">
                    <div>
                        <h4 class="mb-4 fw-bold">
                            #{{'EvntPgTitl001'|translate}}
                        </h4>
                        <div class="event-section-text-size">
                            <ul class="list-unstyled" style="font-size:12px;">
                                <li class="list-inline-item py-0 px-1 mb-3 " *ngFor="let tag of data.tags">
                                    <button class="rounded-pill tag-btn">{{tag}}
                                        <span></span></button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section class="col-12 mb-5 event-section-text-size">
                    <div>
                        <h4 class="mb-4 fw-bold">
                            {{'EvntPgTitl002'|translate}}
                        </h4>
                        <div class="">
                            <ul class="list-unstyled d-flex ">
                                <li class="list-inline-item text-primary py-1 pe-2 mb-3 rounded-pill fs-2"
                                    *ngFor="let item of data.eventLinks">
                                    <a [href]="item.linkUrl"><i [class]="item.icon"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>

            <!-- Reserv a Spot on Right Side -->
            <div class="col-lg-4 row d-lg-flex justify-content-end custom-hide">
                <div class="card  sub-evnet-box border-0 " *ngIf="data.isReccuring||data.isSeries||data.isSub">
                    <ng-container *ngFor="let item of SubEvnt">



                        <div class="card sub-evnet-card  border-0 shadow-3 my-3   ">
                            <img [src]="url+ item.photo" class="event-back-img" alt="">
                            <div class="card-body p-0 m-0 position-relative">
                                <div [class.price-parent-rtl]="document.documentElement.dir === 'rtl'"
                                    [class.price-parent]="document.documentElement.dir === 'ltr'">
                                    <div [class.price-child-rtl]="document.documentElement.dir === 'rtl'"
                                        [class.price-child]="document.documentElement.dir === 'ltr'"
                                        class=" text-center   m-0">
                                        <p class=" text-white">{{item.price.minValue!=item.price.maxValue?('ShdlrFrmTitl004Dsc002'|translate) +
                                            item.price.minValue+item.curSymbol:item.price.maxValue==0?('OrdrsTblTotlAmnt'|translate):item.price.maxValue+item.curSymbol}}
                                        </p>
                                    </div>
                                </div>
                                <div class="">

                                    <p class="fw-bold sub-evnet-title  ms-1 col-11"><a class="text-white"
                                            [routerLink]="'/event/'+item.id">{{item.title}}</a></p>


                                </div>
                                <div class="date-bg border-primary border-top text-center text-white ">
                                    <span class="mx-2  d-block fw-bold"><small>
                                            <i class="far fa-clock"></i> {{'Start :'|translate}} </small>
                                        {{" "+getDateOntomeZoneformat2(item.startDate)}}
                                    </span>
                                    <span class="mx-2 fw-bold"><small>
                                            <i class="far fa-clock"></i> {{'End :'|translate}} </small>
                                        {{" "+getDateOntomeZoneformat2(item.endDate)}}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </ng-container>

                </div>
                <div class="events-details-width mb-3  px-0" *ngIf="!isEnded">
                    <div class=" events-details-info">
                        <ul class="info">
                            <li class="price">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{'OnlinEvntPgPrice'|translate}}</span>
                                    <!-- {{data?.priceRange?.minValue+""+data.curSymbol }} - {{data?.priceRange?.maxValue+""+data.curSymbol }} -->
                                    {{data?.priceRange?.minValue==data?.priceRange?.maxValue?data?.priceRange?.maxValue+""+data.curSymbol:data?.priceRange?.minValue+""+data.curSymbol+"-"+data?.priceRange?.maxValue+""+data.curSymbol}}

                                </div>
                            </li>
                        </ul>
                        <div class="btn-box">
                            <a [routerLink]="['order']" class="rounded-pill save-btn">{{'RsrvSptBtn'|translate}}
                                <span></span></a>
                            <!-- <p>You must <a routerLink="/login">login</a> before buy a ticket.</p> -->
                        </div>
                        <div class="btn-box mt-" *ngIf="data.isOnline">
                            <a [routerLink]="['online']" class="rounded-pill save-btn">
                                {{'OnlinEvntPgBtn'|translate}}
                                <span></span></a>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="card w-100 border-0 bg-primary-10 ">
            <div class="card-body d-flex justify-content-center align-items-center">
                <div class="d-block text-center">
                    <div class="event-details-content-organizer-logo ">
                        <img [src]="OrgData?.imageUrl==''?url + OrgData?.imageUrl:LogoUrl"
                            class="logo-size-larg" alt="Antares">
                    </div>
                    <h6 class="text-primary fw-bold w-100 mb-2 text-mlenght text-center"><a
                            [routerLink]="['/organizers','preview',data.orgId]">{{data.organizerName}}</a></h6>
                    <aside class="bio-text-lenght">{{OrgData?.bio}}</aside><br />
                    <!-- <aside>
                        <i class="far fa-calendar-alt"></i>
                    </aside> -->
                    <div class="mt-2">
                        <button (click)="follow(data.orgId,data.id)"
                            class="follow-btn mt-md-0 me-2 rounded-pill">{{followed?
                            ('FlwoBtn002'|translate) : 'FlwoBtn001' |translate}}<span></span></button>
                        <!-- <button class="rounded-pill ticket-btn me-2">
                            Follow <span></span>
                        </button> -->
                        <button class="rounded-pill ticket-btn" (click)="showContactModal()"> <i
                                class="fas fa-headset"></i> {{'CntactBtn'|translate}}
                            <span></span></button>


                    </div>
                </div>
                <!-- <p-dialog header="How to start" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                [draggable]="false" [resizable]="false" [style]="{width: '40vw'}">
                <div class="m-1" style="text-align: start;">
                    <p class=""><i class="fa-solid fa-phone m-1"></i> <a href="tel:{{OrgData?.phoneNumber}}">{{OrgData?.phoneNumber}}</a> </p>
                    <p class=""><i class="fa-solid fa-envelope m-1"></i><a href="mailto:{{OrgData?.email}}">{{OrgData?.email}}</a></p>
                </div>

            </p-dialog> -->
                <!-- <p-dialog [closable]="true" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                [draggable]="false" [resizable]="false" [showHeader]="false" [breakpoints]="{'960px': '540px', '600px': '320px'}" [style]="{'width': '540px','border-radius':'25px'}">
                <div class="position-relative " style="text-align: start;" >
                    <img class="call-cont-img" src="../../../../assets/img/Logo/Contact-Us-on.png" alt="">
                    <button class="btn border-0 contact-close-btn" type="button" (click)="helpStepsModal=false" ><i class="fa-solid fa-xmark"></i></button>
                    <div class="contact-info">
                        <p class="cont-num text-primary"><i class="fa-solid fa-phone  m-2"></i> <a class="" href="tel:{{OrgData?.phoneNumber}}">{{OrgData?.phoneNumber}}</a> </p>
                        <p class="cont-email text-primary"><i class="fa-solid fa-envelope m-2"></i><a class="" href="mailto:{{OrgData?.email}}">{{OrgData?.email}}</a></p>
                    </div>
                </div>

            </p-dialog> -->
                <p-dialog [closable]="true" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                    [draggable]="false" [resizable]="false" [showHeader]="false"
                    [breakpoints]="{'960px': '540px', '600px': '320px'}"
                    [style]="{'width': '540px','border-radius':'25px'}">
                    <div class="position-relative " style="text-align: start;">
                        <img class="call-cont-img" [src]="document.documentElement.dir === 'ltr'?'assets/img/Logo/Contact-Us-on.jpg':'assets/img/Logo/Contact-Us-on-rtl.jpg'" alt="ssda">
                        <!-- <img class="call-cont-img" src="../../../../assets/img/Logo/Contact-Us-on-rtl.jpg" alt=""> -->
                        <button class="btn border-0 contact-close-btn" type="button" (click)="helpStepsModal=false"><i
                                class="fa-solid fa-xmark"></i></button>
                        <h1 class="cont-frase">{{'CntactMdl'|translate}}</h1>
                        <div class="contact-info">
                            <p class="cont-num text-white"><i class="fa-solid fa-phone  m-2"></i> <a class=""
                                    href="tel:{{OrgData?.phoneNumber}}">{{OrgData?.phoneNumber}}</a> </p>
                            <p class="cont-email text-white"><i class="fa-solid fa-envelope m-2"></i><a class=""
                                    href="mailto:{{OrgData?.email}}">{{OrgData?.email}}</a></p>
                        </div>
                    </div>
                </p-dialog>
            </div>
        </div>

        <section class=" container" *ngIf="SubEvnt.length != 0">
            <div class="row">
                <h3 class="my-5 fw-bold" *ngIf="data.isReccuring&&!data.isSeries">{{'EvntPgTitl00006'|translate}}</h3>
                <h3 class="my-5 fw-bold" *ngIf="data.isReccuring&&data.isSeries">{{'eventPgtitle00007'|translate}}</h3>
                <h3 class="my-5 fw-bold" *ngIf="!data.isReccuring&&data.isSeries">{{'eventpgtitle0008'|translate}}</h3>
                <div *ngFor="let item of SubEvnt; index as i;" class="col-lg-4 col-md-6">
                    <app-event-card class="flex-card-small" [item]="item"
                        (likedClicked)="likeEvent($event)"></app-event-card>
                </div>
            </div>
        </section>
        <section class=" container" *ngIf="orgEvents.length != 0">
            <div class="row">
                <h3 class="my-5 fw-bold">{{'EvntPgTitl003'|translate}}</h3>
                <div *ngFor="let item of orgEvents; index as i;" class="col-lg-4 col-md-6">
                    <app-event-card class="flex-card-small" [item]="item"
                        (likedClicked)="likeEvent($event)"></app-event-card>
                </div>
            </div>
        </section>

    </div>
    <div class="events-details-width2  d-lg-none" *ngIf=" !isEnded">
        <div class=" events-details-info2 d-lg-flex justify-content-between align-items-center">
            <ul class="info">
                <li class="price pb-sm-0 pb-4">
                    <div class="d-flex justify-content-center align-items-center ">
                        <span class="fs-4 me-1">{{'OnlinEvntPgPrice'|translate}} </span>
                        <span
                            class="text-primary fs-4">{{data?.priceRange?.minValue==data?.priceRange?.maxValue?data?.priceRange?.maxValue+""+data.curSymbol:data?.priceRange?.minValue+""+data.curSymbol+"-"+data?.priceRange?.maxValue+""+data.curSymbol}}</span>

                    </div>
                </li>
            </ul>
            <div class="btn-box">
                <a [routerLink]="['order']" class="rounded-pill save-btn">{{'RsrvSptBtn'|translate}}
                    <span></span></a>
                <!-- <p>You must <a routerLink="/login">login</a> before buy a ticket.</p> -->
            </div>
            <div class="btn-box mt-lg-0 mt-2" *ngIf="data.isOnline">
                <a [routerLink]="['online']" class="rounded-pill save-btn">
                    {{'OnlinEvntPgBtn'|translate}}
                    <span></span></a>
            </div>
        </div>
    </div>
</div>
