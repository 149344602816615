import { DOCUMENT, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit,Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EditAttendeesServiceService } from '../edit-attendees-info/edit-attendees-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AddnewAttendeesOfflineService } from './addnew-attendees-offline.service';
import { min } from 'moment';
import { validateLocaleAndSetLanguage } from 'typescript';
 export interface addattendees{
  ticket:string,
  qty:number,
  solditem:number,
  Price:number,
  Value:number,
  cursymbole:string
 }
 export interface eventTicketforOrderoffline{
         id :string;
         name:string;
         description:string;
         eTicket:boolean;
         willCall:boolean;
         maxPerOrder:number;
         minPerOrder:number;
         maxPerTicket:number;
         minPerTicket:number;
         price:number;
         type:string;
         ticketStart:Date;
         ticketEnd  :Date;
         qty  :number;
         soldItem:number;
         curSymbol:string;
 }



@Component({
  selector: 'app-add-new-attendees',
  templateUrl: './add-new-attendees.component.html',
  styleUrls: ['./add-new-attendees.component.scss']
})
export class AddNewAttendeesComponent implements OnInit {
  orderType: any[]=[];
  selectedtype:string='Order type'
  selectedtype01:string='Ordertype'
  form1!:FormGroup;
  Submitted:boolean=false;
  allTickets: eventTicketforOrderoffline[]=[];
  types:DropDownItem[];
  id: string;
  //selectedtype01: any;
  constructor(private fb:FormBuilder,

    private translate: TranslateService,
    private http: HttpClient,
    public EventSrv: EventStepsService,
    private datePipe: DatePipe,
    private editeSrv: EditAttendeesServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private srv:AddnewAttendeesOfflineService,
    @Inject(DOCUMENT) public document: Document
) {
    this.translate.onLangChange.subscribe(() => {
        this.updateLang();
    });
}

setdata(e){
 // console.log(e);

var t=this.allTickets.find(x=>x.id==e.value);
//console.log(t)
this.data[0]={ticket:t.name,
qty:t.qty,
Value:0,cursymbole:t.curSymbol,Price:t.price,solditem:t.soldItem}
//console.log(this.form1.value)
this.srv.form1.controls['ticketName'].setValue(t.name)
this.srv.form1.controls['cursymbol'].setValue(t.curSymbol)

}
  ngOnInit(): void {

   this.initializeForm();
   this.updateLang();
   var sub = this.EventSrv.state$.subscribe(x=>{
   // console.log(x)
    this.srv.form1.controls['eventId'].setValue(x.id);
    this.id=this.srv.form1.controls['eventId'].value;
   // console.log(this.srv.form1.value)
    // console.log(this.id)
     this.GetallEventTicket();
   })
  
  }

  data:addattendees[]=[{ticket:'',
    qty:0,
    Value:0,cursymbole:'$',Price:0,solditem:0}];
initializeForm(){
this.form1=this.fb.group({
  ticket:[null,Validators.required],
  qty:[0,[Validators.required,Validators.min(1)]],
  value:[0,Validators.required],
  type:[null,Validators.required],
  totalVal:[]
  


})
this.form1.controls['qty'].valueChanges.subscribe(x=>{
  if(this.form1.controls['type'].value=='Complimentary'){
    this.form1.controls['value'].setValue(0)
    this.form1.controls['value'].clearValidators();
    this.form1.controls['totalVal'].setValue(this.form1.controls['qty'].value *this.form1.controls['value'].value )
  }else{
    this.form1.controls['value'].addValidators(Validators.min(this.data[0].Price))
    this.form1.controls['totalVal'].setValue(this.form1.controls['qty'].value *this.form1.controls['value'].value )
  }
  this.form1.controls['value'].updateValueAndValidity();


 
})
this.form1.controls['value'].valueChanges.subscribe(x=>{
  if(this.form1.controls['type'].value=='Complimentary'){
    this.form1.controls['value'].setValue(0)
    this.form1.controls['totalVal'].setValue(this.form1.controls['qty'].value *this.form1.controls['value'].value )
  }else{
    this.form1.controls['totalVal'].setValue(this.form1.controls['qty'].value *this.form1.controls['value'].value )
  }
 
})

this.form1.controls['type'].valueChanges.subscribe(x=>{
  if(this.form1.controls['type'].value=='Complimentary'){
    this.form1.controls['value'].clearValidators();
  }
  this.form1.controls['value'].updateValueAndValidity();
 
})
// this.form1.controls['ticket'].valueChanges.subscribe(x=>{
//   if(this.form1.controls['type'].value=='Complimentary'){
//     this.form1.controls['value'].clearValidators();
//   }else{
//     this.form1.controls['value'].addValidators(Validators.min(this.data[0].Price));
//   }
//   this.form1.controls['value'].updateValueAndValidity();
 
// })


}
  updateLang() {
   
 this.orderType = [
    {
        id: 'check',
        label: this.translate.instant('Paidwithcheck'),
        command: (item) => {
         // console.log(item)
            this.form1.controls['type'].setValue(item.item.id)
           // console.log(this.form1.value)
            this.selectedtype="Paid with check"
            this.selectedtype01='Paidwithcheck'
        },
    },
    {
        id: 'cash',
        label: this.translate.instant('Paidwithcash'),
        command: (item) => {
         // console.log(item)
          this.form1.controls['type'].setValue(item.item.id)
         // console.log(this.form1.value)
          this.selectedtype="Paid with cash"
          this.selectedtype01='Paidwithcash'
        },
    },
    {
      id: 'Complimentary',
      label: this.translate.instant('Complimentary'),
      command: (item) => {
      //  console.log(item)
        this.form1.controls['type'].setValue(item.item.id)
        //console.log(this.form1.value)
        this.selectedtype="Complimentary"
        this.selectedtype01="Complimentary"

      },
  },
    {
        id: 'Other',
        label: this.translate.instant('Other'),
        command: (item) => {
         // console.log(item)
          this.form1.controls['type'].setValue(item.item.id)

          //console.log(this.form1.value)
          this.selectedtype="Other"
          this.selectedtype01="Other"
           
        },
    },
    ];
  }
 GetallEventTicket(){
   this.http.get<eventTicketforOrderoffline[]>(environment.Api+'d/At/g/e/t/'+this.id).subscribe(x=>{this.allTickets=x
    this.types=this.allTickets.map(x=>{
      var t:DropDownItem={id:x.id,name:x.name}
      return t
     })});
 }

 gotosteptow(){

  console.log(this.form1.value)
  console.log(this.validateticketinfo())
  if(this.validateticketinfo()){
   var tic=this.allTickets.find(x=>x.id==this.form1.controls['ticket'].value);
  this.srv.form1.controls['ticketId'].setValue(this.form1.controls['ticket'].value);
  this.srv.form1.controls['qty'].setValue(this.form1.controls['qty'].value);
  this.srv.form1.controls['value'].setValue(this.form1.controls['totalVal'].value);
  this.srv.form1.controls['type'].setValue(this.form1.controls['type'].value);
  this.srv.form1.controls['cursymbol'].setValue(tic.curSymbol);
  this.srv.form1.controls['willcall'].setValue(tic.willCall);
  this.srv.form1.controls['eticket'].setValue(tic.eTicket);
//console.log(this.srv.form1.value);
  this.router.navigate(['AttendeesInfo'] ,{relativeTo: this.activatedRoute.parent});

 }else{
this.Submitted=true;
 }
}

validateticketinfo(){
  //  console.log(this.srv.myform.get("price"))
      return   this.form1.controls['ticket'].valid&&this.form1.controls['qty'].valid
         &&this.form1.controls['value'].valid  &&this.form1.controls['type'].valid
         &&this.form1.controls['totalVal'].valid ;
     }
}