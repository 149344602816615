<!-- <div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'AttendesslistTitle'|translate}}</h1>
        </div> -->



        <!-- <p-toast></p-toast>
        <div class="row ms-3 ">
            <div class="col-sm-12 col-md-12 mb-3">
                <div class="d-flex  flex-column justify-content-start">
                    <h4 class="fw-bold  mt-2">{{'AttendesslistTitle01'|translate}}</h4>
                  
                </div>
            </div>
        </div>
        <div class="mt-4">



            <div>
              
           
            </div>

            <div  [ngClass]="{'card-base':TicketsData.length>3}">
                <ng-container *ngFor="let i of TicketsData">
                    <app-attendeelist-card [item]="i" [AttendeesCol]="_DynmicOrderForm"
                       ></app-attendeelist-card>
                </ng-container>
            </div>

        </div>



    </div>
</div> -->

<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'AttendesslistTitle'|translate}}</h1>
        </div>



        <p-toast></p-toast>
        <div class="row ms-3 ">
            <div class="col-sm-12 col-md-12 mb-3">
                <div class="d-flex  flex-column justify-content-start">
                    <h4 class="fw-bold  mt-2">{{'AttendesslistTitle01'|translate}}</h4>
                    <p>{{'AttendessOrdersExplain'|translate}}</p>
                </div>
            </div>
        </div>
        <div class="mt-4">



            <div>
                <div class="row">
                    <div class="col-12 ms-md-4 ms-2 mb-sm-0  mb-2">
                        <div  class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #tickfir pInputText type="text" class="form-control" (input)="getFilter($event)"
                                    placeholder="{{'Search...'|translate}}" />
                            </span>
                        </div>
                       
                  
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 ms-md-4  mb-sm-0  mb-2">
                        <button pButton pRipple class="rounded-pill add-new-btn  m-2 ms-2"
                            (click)="menu2.toggle($event)"> {{ReportType|translate}}<span></span>
                        </button>
                        <p-menu #menu2 appendTo="body" [popup]="true" [model]="reportType"></p-menu>

                       
                    
                        
                        <button  pButton pRipple class="rounded-pill add-new-btn ms-2 m-2"
                            (click)="menu4.toggle($event)">
                            {{filtrDate|translate}}<span></span>
                        </button>
                        <p-menu #menu4 appendTo="body" [popup]="true" [model]="dateFilter"></p-menu>

                      
                    </div>
                </div>
                <div class="d-sm-flex d-block align-items-center justify-content-sm-between ">
                    <div class="  ms-md-4 mb-sm-0  mb-2 ">



                        <button  pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2"  (click)="exportsalesToExcel()">
                            <!-- (click)=" exportToExcel(datatt,'bbb')" -->
                            {{'ExporttoExcel'|translate}}<span></span>
                        </button>
                        <button  pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" (click)="exportTikToCSv()" >
                            <!-- (click)="exportTikToCSv()" -->
                            {{'exporttoCSV'|translate}}<span></span>
                        </button>
                       
                  
                    </div>

                </div>
            </div>

            <div calss="row">
                <div class="col-12 ms-md-4 mb-sm-0  mb-2">
                    <div class="row ">
                        <div class="p-input-filled  col-sm-12 col-md-4 pe-2" *ngIf="cusytomrang">
                            <span class="p-float-label">
                                <p-calendar #cli touchUI="true" selectionMode="range" [(ngModel)]="rangeDates"
                                    pTooltip="{{'Pickdateattendees'|translate}}" showDelay="1000"
                                    tooltipPosition="bottom" appendTo="body" inputId="salesStart"
                                    [styleClass]="'form-control m-0 p-0 '" [inputStyleClass]="'form-control'"
                                    (onClose)="filterdata($event)">
                                </p-calendar>
                                <label class="form-label font-size-14" for="salesStart">
                                    {{'SrhPgFltrTitl001Inpt001'|translate}}</label>
                            </span>
                        </div>
                       
                    </div>


                </div>
            </div>

            <div >

                <p-table #ticketsTable  [columns]="cols" [value]="datatt" [totalRecords]="datatt.length"
                    [paginator]="datatt.length>5" [rows]="5"  
                    sortField="Order#" sortMode="single" dataKey="sortId" [tableStyle]="{'max-width':'100%'}">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th *ngFor="let col of columns">
                                {{col.header|translate}}
                            </th>
                            <th>{{Action|translate}}</th>
                        </tr>
                    </ng-template>
                  
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" 
                        let-columns="columns">
                        <tr>
                           
                            <td *ngFor="let col of columns">
                                <ng-container *ngIf="col.header=='Qr code'">
                                    <div class="d-flex">
                                        <div >
                                            <!-- <span class=" text-center row align-items-center d-lg-flex d-none fw-bold me-md-2 " style="width: 100px;">
                                                <h5 class="text-primary col-12">{{getDateOntomeZoneformat(event.startDate,'month')}}</h5>
                                                <h5 class=" col-12">{{getDateOntomeZoneformat(event.startDate,'day')}}</h5>
                                            </span> -->
                                            <div class="d-lg-flex d-block align-items-center ">
                                                <img [src]="rowData[col.field]==null?'assets/img/courses/3SUBiaP.png':url+rowData[col.field]"
                                                    [alt]="rowData['Attendee #']" width="100" class="shadow-4 min-size rounded-2" />
                                            </div>
                                        </div>

                                     
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="col.field=='Attendee Status'||col.field=='OrderType'">
                                    {{ rowData[col.field]|translate}}
                                
                                </ng-container>
                                <ng-container *ngIf="col.field=='Order Date'">
                                    {{ getDateOntomeZoneformat(rowData[col.field],'full')}}
                                
                                </ng-container>
                                <ng-container *ngIf="col.field!='Order Date'
                                &&col.header!='Qr code'&&col.field!='Attendee Status'&&col.field!='OrderType'">
                                    {{ rowData[col.field]}}
                                
                                </ng-container>
                                
                                
                               
                            </td>
                            <!-- <ng-container *ngFor="let col of cols">

                                <td *ngIf="rowgroup&&(col.field!='Attendee Status'&&col.field!='Attendee #')"
                                    [attr.rowspan]="rowspan">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                            <td *ngIf="checkifhaveattend(columns)">
                                {{rowData['Attendee #']}}
                            </td>
                            <td *ngIf="checkifhaveattend(columns)">
                                {{rowData['Attendee Status']}}
                            </td>
                            <ng-container *ngFor="let col of selectedColumns">
                                <td>
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container> -->
                            <td>
                                <p-overlayPanel #op2>
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-primary m-1" (click)="edit(rowData)">{{'Edit attendee info'|translate}}</button><br>
                                    <p-toast></p-toast>
                                    <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger m-3"
                                        (click)="canseld(rowData)">{{'Cancel this attendee'|translate}}</button>
                                </p-overlayPanel>
                                <button pButton pRipple (click)="op2.toggle($event)" icon="far fa-ellipsis-v"
                                    class="p-button-rounded p-button-primary m-3"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>


         
          
        </div> 


        </div>



    </div>
<!-- </div> -->


