import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AttrbuteService } from 'src/app/services/attrbute.service';

@Injectable({
  providedIn: 'root'
})
export class TypeStepformService {
   typeform!:FormGroup;
  constructor( private fb:FormBuilder,private attrsrv:AttrbuteService ) {

    this. initialForm(0,0,"",0,0,'en')
   }
 initialForm(Id,ParentId,name,checked,order,lan){
 this.typeform=this.fb.group({
  attrId: [Id,Validators.required],
  parentId: [ParentId,Validators.required],
  name: [name,Validators.required],
  status: [checked],
  order: [order],
  cultureId:[lan,Validators.required]
 })
 }
  clear(){
    this. initialForm(0,0,"",0,0,'en')
  }


}
