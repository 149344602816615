import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import * as m from 'moment';
import 'moment/locale/pt-br';

import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AuthService } from 'src/app/services/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { GlobalOrder } from '../sales-reporting.component';
import { HttpClient } from '@angular/common/http';
import { Ticket } from '../../../events/event-steps/attendees-mangment/orders-report/orders-report.component';
import { EditAttendeesServiceService } from '../../../events/event-steps/attendees-mangment/edit-attendees-info/edit-attendees-service.service';
import { Table } from 'primeng/table';
import * as moment from 'moment';


@Component({
    selector: 'app-sales-by-ticket-type-report',
    templateUrl: './sales-by-ticket-type-report.component.html',
    styleUrls: ['./sales-by-ticket-type-report.component.scss'],
})
export class SalesByTicketTypeReportComponent {
    @ViewChild('dataTable2') dataTable2: Table;
    subs: Subscription[] = [];
    organizers: DropDownItem[] = [];
    events: DropDownItem[] = [];
    datatable: any[] = [];
    orderNum: number = 0;
    totalFees: number = 0;
    totalsales: number = 0;
    numofSales: number = 0;
    Dropdowntickets: any[] = [];
    //the prop hold all orderform col name
    dynmiccols: any[] = [];
    _DynmicOrderForm: any[] = [];
    editeSrv: any;
    dataforedit: GlobalOrder[] = [];
    //dataforfiletr: any;
    dataforfilter: any[]=[];
    @Input() get selectedColumns(): any[] {
        return this._DynmicOrderForm;
    }
    set selectedColumns(val: any[]) {
        //restore original order
        this._DynmicOrderForm = this.dynmiccols.filter((col) =>
            val.includes(col)
        );
    }

    edit(s) {
        console.log('s', s);
        // var orderNumindex = s.SortId.indexOf('-');
        // var orderNum = s.SortId.substring(0, orderNumindex) as number;
        // var ticket = s.SortId.substring(orderNumindex + 1);

        // console.log(orderNum);
        //  console.log(ticket);
        var orderNum = s[0]['Order'] as number;
        var ticket = s[0]['Ticket type'];

        console.log('orderNum', orderNum);
        console.log('ticket', ticket);

        var t = this.dataforedit.find((x) => x.orderNum == orderNum).tickets;
        var event = this.dataforedit.find((x) => x.orderNum == orderNum).event
            .entryId;
        console.log('t', t);
        var selectedtickets = t.find((x) => x.name == ticket).attendees;
        console.log('selectedtickets', selectedtickets);
        var mmm = JSON.parse(selectedtickets);

        this.editeSrve.myticket = mmm;
        this.editeSrve.attendeesnum = s[1]['Attendee #'];

        this.editeSrve.ticketID = t.find((x) => x.name == ticket).id;
        //  console.log(this.editeSrv.attendeesnum);
        //  console.log(t);
        //  console.log(selectedtickets);
        // console.log(mmm);
        this.router.navigate(
            ['edit-attendees', event, this.editeSrve.attendeesnum],
            {
                relativeTo: this.ActiveRout.parent.parent,
            }
        );
    }
    canseld(s) {
        //console.log(s)
        var url = environment.Api + 'd/At/c/a';
        //var orderNumindex = s.SortId.indexOf('-');
        //var orderNum = s.SortId.substring(0, orderNumindex) as number;
        //  var ticket = s.SortId.substring(orderNumindex + 1);
        var orderNum = s[0]['Order'] as number;
        var ticket = s[0]['Ticket type'];
        var t = this.dataforedit.find((x) => x.orderNum == orderNum).tickets;
        var selectedtickets = t.find((x) => x.name == ticket).attendees;
        var mmm = JSON.parse(selectedtickets);
        // console.log(mmm)
        var l = mmm.order.find((x) => x.attendeesNum == s[1]['Attendee #']);
        //  console.log(l)
        l.status = 'canceled';
        var f = {
            ticket: t.find((x) => x.name == ticket).id,
            order: JSON.stringify(mmm),
        };
        this.Http.post(url, f).subscribe((x) => {
            this.getdatafromdp(this.selectedevents);
        });
    }

    GenerateColsFotTable(x) {
        // console.log(x)
        var newCol = JSON.parse(x[0].orderFormAn);
        // console.log(newCol)
        //console.log(newCol.order[0])
        var filteredPropertyNames;
        // console.log(Array.isArray(newCol))
        if (Array.isArray(newCol)) {
            filteredPropertyNames = Object.keys(
                newCol[0].order[0].Answer
            ).filter((key) => newCol[0].order[0].Answer[key] !== null);
        } else {
            filteredPropertyNames = Object.keys(newCol.order[0].Answer).filter(
                (key) => newCol.order[0].Answer[key] !== null
            );
        }
        var orderformcols = filteredPropertyNames.map((p) => ({
            field: p,
            header: this.translate.instant(
                p.charAt(0).toUpperCase() + p.slice(1)
            ),
        }));
        this.dynmiccols = [];
        this.dataforfilter=[];
        orderformcols.forEach((x) => {
            //this.cols.push(x)
            this.dynmiccols.push(x);
            this.dataforfilter.push(x.header);

        });
        this._DynmicOrderForm = this.dynmiccols;

        // this.allColumn = [...this.cols, ...this._DynmicOrderForm];
        //console.log(this.allColumn)
        //console.log(this._DynmicOrderForm)
    }
    constructor(
        public editeSrve: EditAttendeesServiceService,
        public eventService: EventsService,
        @Inject(DOCUMENT) public document: Document,
        public loader: LoaderService,
        private router: Router,
        private ActiveRout: ActivatedRoute,
        private translate: TranslateService,
        public auth: AuthService,
        public Http: HttpClient
    ) {
        this.translate.onLangChange.subscribe((x) => {
            m.locale(this.translate.currentLang);
            this.updateLang();
        });
    }
    ngOnDestroy(): void {}
    private subject: Subject<any[]> = new ReplaySubject<any[]>(1);

    get $getSubject(): Observable<any> {
        return this.subject.asObservable();
    }

    ReportType: string = 'AtTendees status';
    filtrDate: string = 'DATE';
    cusytomrang: boolean = false;
    rangeDates: Date[];
    reportType: any[] = [];
    dateFilter: any[] = [];
    updateLang() {
        this.reportType = [
            {
                id: 'Attending',
                label: this.translate.instant('Attending'),
                command: (e) => {
                    // console.log(e)
                    this.subject.next(this.data);
                    this.ReportType = e.item.label;
                    var t = this.datatable.filter( (item) => {
                        return item.Atendees.some((attendee) => {
                          const firstNameMatch = attendee['Attendee Status']== e.item.id
                        
                          return firstNameMatch 
                        });
                      });

                    this.subject.next(t);

                    // this.allColumn = [...this.cols, ...this._DynmicOrderForm];
                },
            },
            {
                id: 'canceled',
                label: this.translate.instant('Not attending'),
                command: (e) => {
                    this.subject.next(this.data);
                    this.ReportType = e.item.label;
                    var t = this.datatable.filter( (item) => {
                        return item.Atendees.some((attendee) => {
                          const firstNameMatch = attendee['Attendee Status']== e.item.id
                        
                          return firstNameMatch 
                        });
                      });


                    this.subject.next(t);
                },
            },
        ];

        this.dateFilter = [
            {
                id: 'Any Time',
                label: this.translate.instant('Any Time'),
                command: (e) => {
                    this.filtrDate = e.item.id;
                    this.cusytomrang = false;
                    var t = this.data;

                    //   console.log(m)

                 this.subject.next(t);

                    //this.update();
                },
            },
            {
                id: 'This Month',
                label: this.translate.instant('This Month'),
                command: (e) => {
                    this.subject.next(this.data);
                    this.filtrDate = e.item.id;
                    this.cusytomrang = false;
                    // console.log(this.getDateOntomeZone(this.data[0].orderDate).getMonth())
                    // console.log(this.getDateOntomeZone(new Date(Date.now())).getMonth())
                    var t = this.datatable.filter(
                        (x) =>
                            this.getDateOntomeZone(
                                x['orderDate']
                            ).getMonth() ==
                            this.getDateOntomeZone(
                                new Date(Date.now())
                            ).getMonth()
                    );

                    this.subject.next(t);

                    //this.update();
                },
            },
            {
                id: 'This week',
                label: this.translate.instant('This week'),
                command: (e) => {
                    this.subject.next(this.data);
                    this.filtrDate = e.item.id;
                    this.cusytomrang = false;
                    //var dateoflastdayofweek=new Date(Date.now()).setDate(new Date(Date.now()).getDate()+7)
                    var t = this.datatable.filter(
                        (x) =>
                            this.getDateOntomeZone(x['orderDate']).getDate() >=
                                this.getDateOntomeZone(
                                    new Date(Date.now())
                                ).getDate() &&
                            this.getDateOntomeZone(x['orderDate']).getDate() <
                                this.getDateOntomeZone(
                                    new Date(Date.now())
                                ).getDate() +
                                    7
                    );

                    this.subject.next(t);

                    //this.update();
                },
            },
            {
                id: 'Custom Date Range',
                label: this.translate.instant('Custom Date Range'),
                command: (e) => {
                    this.filtrDate = e.item.id;
                    this.cusytomrang = true;
                },
            },
            {
                id: 'This Year',
                label: this.translate.instant('This Year'),
                command: (e) => {
                    this.subject.next(this.data);
                    this.filtrDate = e.item.id;
                    this.cusytomrang = false;
                    var t = this.datatable.filter(
                        (x) =>
                            this.getDateOntomeZone(
                                x['orderDate']
                            ).getFullYear() ==
                            new Date(Date.now()).getFullYear()
                    );

                    //this.subject2.next(t);

                    //this.update();
                },
            },
        ];
        // this.GetDataFromDb();

        //    this.GenerateColsFotTable(this.dataFromDb)
    }
    ngOnInit(): void {
        var atr3 = this.eventService.GetOrganizersByUser().subscribe((x) => {
            this.organizers = x;
        });
        this.subs.push(atr3);
        var att4 = this.eventService.GetUserOrgEvents([]).subscribe((x) => {
            this.events = x;
        });
        this.subs.push(att4);
        this.$getSubject.subscribe((x) => {
            console.log(x);
            this.Dropdowntickets = x
                .map((obj) => obj['Ticket type'])
                .filter((name, index, array) => array.indexOf(name) === index);

            console.log( 'Dropdowntickets',this.Dropdowntickets);
            this.datatable = x;
            this.dataforfilter=x;
            this.datatable.forEach((y) => {
                this.totalsales = this.totalsales + y.totalprice;
                this.totalFees = this.totalFees + y.totalnetsales;
            });
            this.numofSales = x.length;
        });
    }
    filterdata(e) {
        this.subject.next(this.data)
        //  console.log(this.rangeDates);
          var t = this.datatable.filter(
              (x) =>
                  moment(this.getDateOntomeZone(x['orderDate'])).isSameOrAfter(
                      this.rangeDates[0]
                  ) &&
                  moment(this.getDateOntomeZone(x['orderDate'])).isSameOrBefore(
                      this.rangeDates[1]
                  )
          );
          this.subject.next(t);

      }
    Getfilter(e,t) {
       // this.dataTable2.filter(e, '', 'contains');
       console.log(t)
       console.log(t!="")
        if(t!=""){
            var m= this.datatable.filter((item) => {
                return item.Atendees.some((attendee) => {
                  const firstNameMatch = attendee['First name'].includes(t);
                  const lastNameMatch = attendee['Last name'].includes(t);
                  const emailMatch = attendee['Email'].includes(t);
                  return firstNameMatch || lastNameMatch || emailMatch;
                });
              });
            this. subject.next(m)
        }
        else{
            this.subject.next(this.data)
        }
       
    }
    getevent(e) {
        console.log(e);
        var att4 = this.eventService
            .GetUserOrgEvents(e.value)
            .subscribe((x) => {
                this.events = x;
            });
        this.subs.push(att4);
    }
    selectedevents: string[] = [];
    getReport(e) {
        console.log(e);

        this.selectedevents = e.value;
        this.getdatafromdp(this.selectedevents);
    }

    getdatafromdp(x) {
        var url = environment.Api + 'd/At/g/t/sales';
        var l = this.Http.post<GlobalOrder[]>(url, x).subscribe((x) => {
            console.log(x);
            this.data = [];
            this.datatable = [];
            this.dataforfilter=[];
            this.dataforedit = x;
            this.GenerateColsFotTable(x);
            this.Dropdowntickets = [];
            this.orderNum = x.length;
            x.forEach((u) => {
                this.GeTticketsOrderProprtDate(u);
            });

            this.data.forEach((y) => {
                this.totalsales = +y.totalprice;
                this.totalFees = +y.totalnetsales;
                this.numofSales = +y.Qty;
            });

            this.subject.next(this.data);
        });
    }
    data = [];

    getReportfilterd(e) {
        console.log(e);

        var tes = e.value;
        var m = this.datatable.filter(
            (x) =>
                x['Ticket type'] == tes 
        );
        this.subject.next(m);
    }

    getDateOntomeZone(d: Date | number) {
        // console.log(d)
        var eventOffset = new Date(Date.now()).getTimezoneOffset();
        // console.log(eventOffset)
        // var eventOffset = 60;
        var MyDate = new Date(d).setMinutes(
            new Date(d).getMinutes() + eventOffset
        );
        return new Date(MyDate);
    }
    getDateOntomeZoneformat2(d) {
        m.locale();
        let t = new Date(Date.now()).getTimezoneOffset();
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - t);
        var l = '';
        if (
            new Date(MyDate).getFullYear() == new Date(Date.now()).getFullYear()
        ) {
            l = m(new Date(MyDate)).format('ddd MMMM D , hh:mm a');
        } else {
            l = m(new Date(MyDate)).format('ddd MMMM D, y , hh:mm a');
        }
        return l;
    }

    //function for get data for tickets bu tickets type
    GeTticketsOrderProprtDate(item: GlobalOrder) {
        var d = [];
        item.tickets.forEach((x) => {
            var l = {
                eventName: item.eventName,
                startdate: item.event.startDate,
                paymentType: item.orderType,
                SalesChannal:
                    item.orderType == 'payment'
                        ? 'onlineSales'
                        : 'offlinesales',
                totalFees: x.antaresFees + x.paymentFees + x.shippingFees,
                totalprice: x.finalPrice,
                totalnetsales:
                    x.finalPrice -
                    (x.antaresFees + x.paymentFees + x.shippingFees),
                'Ticket type': x.name,
                Qty: x.qty,
                price: x.finalPrice,
                date: item.orderDate,
                Order: item.orderNum,
                orderDate: item.orderDate,
                'Attendees Count': 0,
                'Order Type': item.status == 1 ? 'Complete' : 'Canceled',
                'Shipping Address':
                    x.shippingAddress != null
                        ? x.shippingAddress.address
                        : item.userEmail,
                Country:
                    x.shippingAddress != null
                        ? x.shippingAddress.country
                        : item.userEmail,
                City:
                    x.shippingAddress != null
                        ? x.shippingAddress.city
                        : item.userEmail,
                Atendees: [],
            };
            var orderFormAn = this.GetTicketsAttendess(x);
            l['Atendees'] = orderFormAn;
            l['Attendees Count'] = orderFormAn.length;
            this.data.push(l);
        });
    }

    //function to get tickets all atenDees
    GetTicketsAttendess(item: Ticket) {
        //console.log(item)
        var orderformData = JSON.parse(item.attendees);
        //console.log( orderformData)
        // console.log(orderformData)
        var filteredPropertyNames = Object.keys(
            orderformData.order[0].Answer
        ).filter((key) => orderformData.order[0].Answer[key] !== null);
        //  console.log(filteredPropertyNames);
        var resOrderForm = [];
        orderformData.order.forEach((x) => {
            var l = {
                'Attendee #': x.attendeesNum,
                'Attendee Status': x.status,
            };
            this.AddProperties(filteredPropertyNames, l, x.Answer);
            resOrderForm.push(l);
        });

        return resOrderForm;
    }
    AddProperties(prop, object, item): void {
        return prop.reduce((obj, propertyName) => {
            obj[propertyName] = this.GetObjectPropertyValue(propertyName, item);
            return obj;
        }, object);
    }
    //fun to return all  object prop
    GetObjectPropertyValue(propertyName: string, item): any {
        return item[propertyName];
    }
}
