<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Reports(Sales summary)'|translate}}</h1>
        </div>



        <p-toast></p-toast>
        <div class="row ms-3 ">
            <div class="col-sm-12 col-md-12 mb-3">
                <div class="d-flex  flex-column justify-content-start">
                    <h4 class="fw-bold  mt-2">{{' Sales summary'|translate}}</h4>
                    <p>{{' Sales summary'|translate}}</p>
                </div>
            </div>
        </div>
        <div class="mt-4 ">



            <div class="mx-3">
                <div class="row">
                    <div  class="p-input-filled col-12  col-md-4 pe-2">
                        <span class="p-float-label">

                            <p-multiSelect pTooltip="{{'Select your orgnizer that you want to get sales summary for there events'|translate}}" showDelay="1000"
                                tooltipPosition="top" [options]="organizers" [styleClass]="'w-100'"
                                [optionLabel]="'name'" display="chip" optionValue="id" selectedItemsLabel="{0} columns selected" inputId="ogrId" (onChange)="getevent($event)"
                               ></p-multiSelect>
                            <label class="b-5 ms-2" for="ogrId">{{'Orgnizer'|translate}}</label>
                        </span>
                    </div>
                    <div  class="p-input-filled col-12  col-md-4 pe-2">
                        <span class="p-float-label">

                            <p-multiSelect pTooltip="{{'Search and select eventthat you want to get sales summary for it'|translate}}" showDelay="1000"
                                tooltipPosition="top" [options]="events" [styleClass]="'w-100'"
                                [optionLabel]="'name'" optionValue="id" display="chip" selectedItemsLabel="{0} columns selected" inputId="eventId" (onChange)="getReport($event)"
                                ></p-multiSelect>
                            <label class="b-5 ms-2" for="eventId">{{'Events'|translate}}</label>
                        </span>
                    </div>
                 
                </div>


                
                    <div class="row">
                        <div class="col-lg-3 col-12 col-sm-6 ">
                            
                            <p-card subheader="{{'Total sales USD'|translate}}" >
                                <p>{{totalsales}}</p>
                            </p-card>
                        </div>
                        <div class="col-lg-3 col-12 col-sm-6">
                            <p-card subheader="{{'Total net sales USD '|translate}}" [style]="{'width': '25rem', 'margin-bottom': '2em'}">
                                <!-- <ng-template pTemplate="header">
                                    <img alt="Card" src="assets/showcase/images/usercard.png">
                                </ng-template> -->
                                <p>{{totalFees}}</p>
                            </p-card>
                        </div>
                        <div class="col-lg-3 col-12 col-sm-6">
                            <p-card subheader="{{'Tickets & Add-ons sold'|translate}}" [style]="{'width': '25rem', 'margin-bottom': '2em'}">
                                <!-- <ng-template pTemplate="header">
                                    <img alt="Card" src="assets/showcase/images/usercard.png">
                                </ng-template> -->
                                <p>{{numofSales}}</p>
                            </p-card>
                        </div>
                        <div class="col-lg-3 col-12 col-sm-6">
                            <p-card subheader="{{'Orders'|translate}}" [style]="{'width': '25rem', 'margin-bottom': '2em'}">
                                <!-- <ng-template pTemplate="header">
                                    <img alt="Card" src="assets/showcase/images/usercard.png">
                                </ng-template> -->
                                <p>{{orderNum}}</p>
                            </p-card>
                        </div>
                       
                    </div>
                
                <div class="d-sm-flex d-block align-items-center justify-content-sm-between ">
                    <div class="  ms-md-4 mb-sm-0  mb-2 ">



                        <button  pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" >
                            {{'ExporttoExcel'|translate}}<span></span>
                        </button>
                        <button  pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2">
                            {{'exporttoCSV'|translate}}<span></span>
                        </button>
                        <button  pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" >
                            {{'ExporttoExcel'|translate}}<span></span>
                        </button>
                       
                    
                    </div>

                </div>
            </div>
            <div>
                <p-table #dataTable [value]="datatable"  
                    [totalRecords]="data.length" sortField="ticketType" sortMode="single" dataKey=""
                      [globalFilterFields]="['name']">
                    <ng-template pTemplate="header">
                        <tr >
                            <th >{{'ReportEventname'|translate}}</th>
                            <th > {{'ReportStartdate'|translate}}</th>
                            <th > {{'ReportSaleschannel'|translate}}</th>
                            <th > {{'ReportTickettype'|translate}}</th>
                            <!-- <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'Remain01'|translate}}</th> -->
                            <th > {{' ReportPaymenttype'|translate}}</th>
                            <th > {{'ReportQuantity'|translate}}</th>
                            <th > {{'ReportPrice'|translate}}</th>
                            <th > {{'ReportTotalprice'|translate}}</th>
                            <th > {{'ReportTotalfees'|translate}}</th>
                            <th >{{'ReportTotalnetsales'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-c let-rowIndex="rowIndex">
                        <tr>
                         <td>{{c.eventName}}</td>
                         <td>{{getDateOntomeZoneformat2(c.startdate)}}</td>
                         <td>{{c.SalesChannal|translate}}</td>
                         <td>{{c.ticketType}}</td>
                         <td>{{c.paymentType|translate}}</td>
                         <td>{{c.Qty}}</td> 
                         <td>{{c.price}}{{"$"}}</td>
                         <td>{{c.totalprice}}{{"$"}}</td>
                         <td>{{c.totalFees}}{{"$"}}</td>
                         <td>{{c.totalnetsales}}{{"$"}}</td>


                        </tr>
                    </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                {{'TcktTblFooter01'|translate}} {{SalesSumrepData ? SalesSumrepData.length : 0 }} .
                            </div>
                        </ng-template>
                </p-table>
            </div>
           

        </div>



    </div>
</div>
