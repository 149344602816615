<div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="">

            <div class=" custom-table-border">
    <!-- <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">
            <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" ></button>

        </ng-template>

        <ng-template pTemplate="right">


        </ng-template>
    </p-toolbar> -->

    <p-table #dt [value]="data" [columns]="cols" [rows]="pageRow" [paginator]="isPagination"  sortMode="multiple" [globalFilterFields]="filterCol"
        [rowHover]="true" dataKey="id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="d-sm-flex d-block align-items-center justify-content-between">
                <h5 class=" mb-md-0 mb-2">{{title}}</h5>
                <div class="p-input-filled me-md-3 ">
                <span class="p-input-icon-left ">
                    <i class="pi pi-search"></i>
                    <input pInputText #se type="text" class="form-control" (input)="dt.filterGlobal(se.value ,'contains')" placeholder="Search..." />
                </span>
            </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns >
            <tr>
                <th *ngFor="let col of columns" [ngClass]="col.cssclass != ''?col.cssclass:'' " [pSortableColumn]="col.sort?col.field:null">{{col.header}}<p-sortIcon *ngIf="col.sort" field="{{col.field}}"></p-sortIcon></th>




            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" >
            <tr>

                <td *ngFor="let col of columns">
                    <span *ngIf="col.header!=''" innerHTML="{{col.render(rowData)}}"></span>


                        <div  *ngIf="col.header==''&&col.render(rowData)=='false'">
                            <button  *ngFor="let ac of action" pButton pRipple  icon={{ac.icon}} class={{ac.class}} (click)="show(rowData[col.field],ac.parent,ac.comonp,ac.bbbb)" ></button>
                        </div>


                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between my-3">

            </div>
        </ng-template>
    </p-table>
</div>
        </div>
    </div>
</div>

