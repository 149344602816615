<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="assets/img/error.png" alt="image">
                    <h3>{{'ErrorTitl'|translate}}</h3>
                    <p>{{'ErrorDisc'|translate}}</p>
                    <div class="btn-box">
                        <a routerLink="/" class="rounded-pill save-btn mx-2"><i class="flaticon-history mx-2"></i>{{'BackClick'|translate}}<span></span></a>
                        <a routerLink="/" class="rounded-pill save-btn"><i
                                class="flaticon-home mx-2"></i>{{'HomepageClick'|translate}}<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
