import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component,Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { localstringDto, localstringsingleDto } from 'src/app/Interfaces/attrDto';
import { LoaderService } from 'src/app/services/loader.service';
import { LocalStringSrvService } from 'src/app/services/local-string-srv.service';
import { LocalstringFormService } from './localstring-form.service';
import { ThisReceiver } from '@angular/compiler';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-localstringform',
  templateUrl: './localstringform.component.html',
  styleUrls: ['./localstringform.component.scss'],
  animations: [fadeOut]
})
export class LocalstringformComponent implements OnInit {
  submitted:boolean=false;
  tickTy:DropDownItem[]=[]
  Langueses:DropDownItem[]=[{id:'en',name:'English'}]
  typeId: string;
  mylocal:localstringsingleDto[]=[]
  constructor(public srv:LocalstringFormService,private attrsrv:LocalStringSrvService, private route:Router,private actvitedRoute:ActivatedRoute,
    @Inject(DOCUMENT) public document: Document,public loader : LoaderService,private messageService: MessageService){}
  ngOnInit(): void {
    // this.loader.showLoader();
    // this.loader.showLoader1();
    this.srv.initializForm('','en','')
    var param= this.actvitedRoute.paramMap.subscribe((param)=>{
      this.typeId=param.get('id');
     //  console.log(this.typeId);
    
   
      // this.attrsrv.GetAtrrSingle(this.typeId).subscribe(y=>{
      //   this.mylocal=y;
      //   var data=y.find(m=>m.cultureId=='en')
      //   this.srv.initializForm(data.resKey,'en',data.text)
      //   this.loader.hideLoader();
      //   this.loader.hideLoader1();
      // })



    });
  }
  sumbit(){
  
  //  console.log(this.srv.myForm.value)
    var data:localstringsingleDto={
      resKey:this.srv.myForm.controls['resKey'].value,
      cultureId:this.srv.myForm.controls['cultureId'].value,
      text:this.srv.myForm.controls['text'].value,
}
   // console.log(data)
    this.attrsrv.PostnewAttr(data).subscribe(x=>{

      
      this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
      this.route.navigate(['Local-string'], { relativeTo: this.actvitedRoute.parent.parent});

    },(error)=>{
      this.messageService.add({ severity: 'danger', summary: error });
    });

  }



  cancel(){
    this.srv.clear();
    this.route.navigate(['Local-string'], { relativeTo: this.actvitedRoute.parent.parent});
  }

}
