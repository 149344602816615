
<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Logo'|translate}}</h1>
        </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>

        <div class="">
            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data"   [rows]="5"
            [paginator]="data.length>5" [totalRecords]="data.length"   
             [tableStyle]="{'max-width':'100%'}"
                [rowHover]="true" dataKey="attrId"  
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true" class=""  >
                <ng-template pTemplate="caption">

                    <div>

                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                            <div class="  ms-md-3  mb-sm-0  mb-2">
                                <button pButton pRipple class="rounded-pill add-new-btn mr-2" (click)="show()"><i
                                        class="fa-solid fa-plus"></i> {{'New'|translate}}<span></span></button>
                            </div>
                            <!-- <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pTooltip="{{'type to search'|translate}}" showDelay="1000"
                                        tooltipPosition="left" pInputText type="text" class="form-control"
                                        [(ngModel)]="searchText"
                                        (input)="search($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                            </div> -->
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>
                        
                        <th class="fw-bold text-center">{{'Logo'|translate}}</th>
                        <th class="fw-bold text-center">{{'languages'|translate}}</th>
                            <!-- -->
                        <!-- <th class="fw-bold text-center "> {{'TcktTblTh006'|translate}}</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-t>
                    <tr >
                        <td  class="text-center adding-custom  Image-cutom">
                            <div class=" d-block align-items-center me-lg-2">
                                <img [src]="t.image==null?'assets/img/courses/3SUBiaP.png':url+t.image"
                                    [alt]="t.fileName" width="100" class="shadow-4 min-size rounded-2" />
                            </div>

                        </td>
                   <td  class="text-md-center padding-custom text-lenght-larg text-end">



                            <span
                                class="headers-cutom text-start fw-bold d-lg-none d-block">{{'languages'|translate}}</span> {{t.cultureId}}


                    </td>

                   

                <!-- <td class="text-md-center padding-custom text-end">
                    <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                    [class.action-custom-rtl]="document.documentElement.dir === 'rtl'"> -->
                    <!-- <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(t)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(t)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(t, ri)" class="p-button-rounded p-button-text p-button-danger"></button> -->


                    <!-- <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-primary mx-2" (click)="edit(t.attrId)"></button> -->
                        <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                            (click)="remove($event,t)"></button>
                    </div>
                    <p-confirmPopup></p-confirmPopup> -->
                    <!-- <button pButton pRipple icon="pi pi-trash"
                    class="p-button-rounded p-button-danger m-1"
                    (click)="remove($event,event.id)"></button> -->


                <!-- </td> -->




                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        {{'TcktTblFooter01'|translate}} {{data ? totalRecourd : 0 }} .
                    </div>
                </ng-template>
            </p-table>

        </div>

    </div>
</div>


