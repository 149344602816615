import { DOCUMENT } from '@angular/common';
import { Component,Inject, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit,OnDestroy {
  eventId: string;
  sub: Subscription[] = [];
  constructor(private auth: AuthService,
      private router: Router,
      private activerout: ActivatedRoute,
      public loader : LoaderService,
private translate:TranslateService,
      @Inject(DOCUMENT) public document: Document) {

        var l=  this.translate.onLangChange.subscribe(() => {
           
              this.updateItem433();
          
            });
            this.sub.push(l)
       }
  updateItem433() {

   
          this.items = [
              { label: this.translate.instant('Type mangment'), icon: 'pi pi-pw fa-regular fa-circle-info', routerLink: ['Type'], visible: true, clicked: false },
             
              { id: "timing", label:this.translate.instant('Category mangment') , icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['categories'], visible: true, clicked: false},
              { id: "Scheduler", label: this.translate.instant('Currency mangment'), icon: 'pi pi-pw fa-regular fa-calendar-days', routerLink: ['Currencies'], visible: true, clicked: false},
              { id: "Subevent", label: this.translate.instant('Tag mangment'), icon: 'pi pi-pw fa-solid fa-list-tree', routerLink: ['Tags'], visible: true, clicked: false },
              { id: "location", label: this.translate.instant('Language mangment'), icon: 'pi pi-pw fa-regular fa-map-location-dot', routerLink: ['languages'], visible: true, clicked: false },
              { id: "logo", label: this.translate.instant('logo mangment'), icon: 'pi pi-pw fa-regular fa-image', routerLink: ['logoes'], visible: true, clicked: false },
             
              { label: this.translate.instant('Local string mangment'), icon: 'pi pi-pw fa-regular fa-star-shooting', routerLink: ['Local-string'], visible: true, clicked: false },
              { label:this.translate.instant('AntaresPercentage') , icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['persentage'], visible: true, clicked: false},
              {  label:this.translate.instant('PayementgatwayPercentage') , icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['Payement-Persentage'], visible: true, clicked: false},
              {  label:this.translate.instant('Shippingcost') , icon: 'pi pi-pw fa-regular fa-stopwatch', routerLink: ['Shipping-Cost'], visible: true, clicked: false},
           ];
      
      
   


  


     

  }

 
  ngOnDestroy(): void {
      this.sub.forEach(e => {
          e.unsubscribe();
      })
  }





  canSeeTickets = this.auth.securityObject.checkClaim(10105030103);
  canPublish = this.auth.securityObject.checkClaim(10105030102);
  visibleSidebar1;
  items = [];
  items2 = [];
  ngOnInit() {

    this.loader.showLoader1()
    this.loader.showLoader()
    this.updateItem433();
    this.Navigate1(this.activerout.snapshot?.firstChild?.routeConfig?.path);
    this.loader.hideLoader1()
    this.loader.hideLoader()
  }
  itemClicked(item) {
      if (!item.clicked) {
          this.router.navigate(item.routerLink, { relativeTo: this.activerout })
          this.items.forEach(x => {
              x.clicked = false;
              if (x.items) {
                  x.items.forEach(y => {
                      y.clicked = false;
                  })
              }

          })
          item.clicked = true;

      }
  }
  Navigate1(x: string) {
      if (x != null) {
          this.router.navigate([x], { relativeTo: this.activerout });
      }
      else {
          this.router.navigate([

              'Type'

          ], { relativeTo: this.activerout });
      }
  }


 
}
