<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Manageattendees(Orders)'|translate}}</h1>
        </div>



        <p-toast></p-toast>
        <div class="row ms-3 ">
            <div class="col-sm-12 col-md-12 mb-3">
                <div class="d-flex  flex-column justify-content-start">
                    <h4 class="fw-bold  mt-2">{{'EvntMnuChois016'|translate}}</h4>
                    <p>{{'AttendessOrdersExplain'|translate}}</p>
                </div>
            </div>
        </div>
        <div class="mt-4">



            <div>
                <div class="row">
                    <div class="col-12 ms-md-4 ms-2 mb-sm-0  mb-2">
                        <div *ngIf="isticketsSales&&!isSalesReport" class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #tickfir pInputText type="text" class="form-control" (input)="getFilter($event)"
                                    placeholder="{{'Search...'|translate}}" />
                            </span>
                        </div>
                        <div *ngIf="!isticketsSales&&!isSalesReport" class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="form-control" (input)="filterAddOns($event)"
                                    placeholder="{{'Search...'|translate}}" />
                            </span>
                        </div>
                        <div *ngIf="isSalesReport" class="p-input-filled me-md-3 ">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" class="form-control"
                                    (input)="filterGlobal($event.target.value)"
                                    placeholder="{{'Search...'|translate}}" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 ms-md-4  mb-sm-0  mb-2">
                        <button pButton pRipple class="rounded-pill add-new-btn  m-2 ms-2"
                            (click)="menu2.toggle($event)"> {{ReportType|translate}}<span></span>
                        </button>
                        <p-menu #menu2 appendTo="body" [popup]="true" [model]="reportType"></p-menu>

                         <button *ngIf="!isSalesReport" pButton pRipple class="rounded-pill add-new-btn ms-2 m-2"
                            (click)="menu3.toggle($event)">
                            {{OrderType|translate}}<span></span>
                        </button>
                        <p-menu #menu3 appendTo="body" [popup]="true" [model]="orderType"></p-menu>
                        <button *ngIf="!isSalesReport" pButton pRipple class="rounded-pill add-new-btn ms-2 m-2"
                            (click)="menu.toggle($event)">
                            {{Perpage2 |translate}}<span></span>
                        </button>
                        <p-menu #menu appendTo="body" [popup]="true" [model]="perpageitem"></p-menu>
                        <button *ngIf="!isSalesReport" pButton pRipple class="rounded-pill add-new-btn ms-2 m-2"
                            (click)="menu4.toggle($event)">
                            {{filtrDate|translate}}<span></span>
                        </button>
                        <p-menu #menu4 appendTo="body" [popup]="true" [model]="dateFilter"></p-menu>

                        <button *ngIf="!isSalesReport" pButton pRipple class="rounded-pill add-new-btn ms-2 m-2"
                            (click)="menu6.toggle($event)">
                            {{ConfigureSalestype|translate}}<span></span>
                        </button>
                        <p-menu #menu6 appendTo="body" [popup]="true" [model]="SalesType"></p-menu>
                    </div>
                </div>
                <div class="d-sm-flex d-block align-items-center justify-content-sm-between ">
                    <div class="  ms-md-4 mb-sm-0  mb-2 ">



                        <button *ngIf="isticketsSales&&!isSalesReport" pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" (click)=" exportToExcel(datatt,'bbb')">
                            {{'ExporttoExcel'|translate}}<span></span>
                        </button>
                        <button *ngIf="isticketsSales&&!isSalesReport" pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" (click)="exportTikToCSv()">
                            {{'exporttoCSV'|translate}}<span></span>
                        </button>
                        <button *ngIf="!isticketsSales&&!isSalesReport" pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" (click)=" exportAddToExcel()">
                            {{'ExporttoExcel'|translate}}<span></span>
                        </button>
                        <button *ngIf="!isticketsSales&&!isSalesReport" pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" (click)="exportToCsv()">
                            {{'exporttoCSV'|translate}}<span></span>
                        </button>
                        <button *ngIf="isSalesReport" pButton pRipple class="rounded-pill add-new-btn ms-2 m-2"
                            (click)="dataTable.exportCSV()">
                            {{'exporttoCSV'|translate}}<span></span>
                        </button>
                        <button *ngIf="isSalesReport" pButton pRipple class="rounded-pill add-new-btn ms-2 m-2"
                            (click)="exportsalesToExcel()">
                            {{'ExporttoExcel'|translate}}<span></span>
                        </button>
                        <!-- <button *ngIf="isSalesReport" pButton pRipple class="rounded-pill add-new-btn ms-2 m-2" (click)=" exportToExcel(SalesSumrepData,'bbb')">
                        {{'export'|translate}}<span></span>
                   </button> -->
                    </div>

                </div>
            </div>

            <div calss="row">
                <div class="col-12 ms-md-4 mb-sm-0  mb-2">
                    <div class="row ">
                        <div class="p-input-filled  col-sm-12 col-md-4 pe-2" *ngIf="cusytomrang">
                            <span class="p-float-label">
                                <p-calendar #cli touchUI="true" selectionMode="range" [(ngModel)]="rangeDates"
                                    pTooltip="{{'Pickdateattendees'|translate}}" showDelay="1000"
                                    tooltipPosition="bottom" appendTo="body" inputId="salesStart"
                                    [styleClass]="'form-control m-0 p-0 '" [inputStyleClass]="'form-control'"
                                    (onClose)="filterdata($event)">
                                </p-calendar>
                                <label class="form-label font-size-14" for="salesStart">
                                    {{'SrhPgFltrTitl001Inpt001'|translate}}</label>
                            </span>
                        </div>
                        <div *ngIf="isticketsSales&&!isSalesReport" class="p-input-filled col-12  col-md-4 pe-2">
                            <span class="p-float-label">

                                <p-multiSelect pTooltip="{{'selectcols001'|translate}}" showDelay="1000"
                                    tooltipPosition="top" [options]="dynmiccols" [styleClass]="'w-100'"
                                    [optionLabel]="'header'" selectedItemsLabel="{0} columns selected" inputId="uuu"
                                    [(ngModel)]="selectedColumns"></p-multiSelect>
                                <label class="b-5 ms-2" for="uuu">{{'ConfigureColumns'|translate}}</label>
                            </span>
                        </div>
                    </div>


                </div>
            </div>

            <div class="d-none">

                <div *ngIf="!isticketsSales&&!isSalesReport">
                    <p-table #addonsTable [columns]="AddOnsCols" [value]="DataForReport"
                        [totalRecords]="DataForReport.length" [paginator]="DataForReport.length>perpage"
                        [rows]="perpage" rowGroupMode="rowspan" [groupRowsBy]="'SortId'" sortField="SortId"
                        sortMode="single" dataKey="SortId" [tableStyle]="{'max-width':'100%'}">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th>#</th>
                                <ng-container *ngFor="let co of columns">
                                    <th>
                                        {{co.header|translate}}
                                    </th>
                                </ng-container>
                                <th>Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="footer" let-columns>
                            <tr>
                                <td>total</td>
                                <td>total</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-rowgroup="rowgroup"
                            let-rowspan="rowspan" let-columns="columns">
                            <tr>
                                <td>{{rowIndex}}
                                </td>
                                <td *ngIf="rowgroup " [attr.rowspan]="rowspan">
                                    {{rowData['Order #']}}
                                </td>
                                <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                                    {{rowData['Order Date']}}
                                </td>
                                <ng-container *ngFor="let col of columns">

                                    <td *ngIf="col.field!='Order #'&& col.field!='Order Date'">
                                        {{rowData[col.field]}}
                                    </td>

                                </ng-container>
                                <td>
                                    <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger m-3"
                                        (click)="canseldAddons(rowData)">{{'Cancel this attendee'}}</button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="d-none">

                <p-table #ticketsTable [columns]="allColumn" [value]="datatt" [totalRecords]="datatt.length"
                    [paginator]="datatt.length>perpage" [rows]="perpage" rowGroupMode="rowspan" [groupRowsBy]="'SortId'"
                    sortField="SortId" sortMode="single" dataKey="SortId" [tableStyle]="{'max-width':'100%'}">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>#</th>
                            <ng-container *ngFor="let co of cols">
                                <th>
                                    {{co.header|translate}}
                                </th>
                            </ng-container>
                            <ng-container *ngFor="let c of selectedColumns">
                                <th>
                                    {{c.header|translate}}
                                </th>
                            </ng-container>
                            <th>{{Action|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer" let-columns>
                        <tr>
                            <td>total</td>
                            <td>total</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-rowgroup="rowgroup"
                        let-rowspan="rowspan" let-columns="columns">
                        <tr>
                            <td>{{rowIndex}}
                            </td>
                            <ng-container *ngFor="let col of cols">

                                <td *ngIf="rowgroup&&(col.field!='Attendee Status'&&col.field!='Attendee #')"
                                    [attr.rowspan]="rowspan">
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                            <td *ngIf="checkifhaveattend(columns)">
                                {{rowData['Attendee #']}}
                            </td>
                            <td *ngIf="checkifhaveattend(columns)">
                                {{rowData['Attendee Status']}}
                            </td>
                            <ng-container *ngFor="let col of selectedColumns">
                                <td>
                                    {{rowData[col.field]}}
                                </td>
                            </ng-container>
                            <td>
                                <p-overlayPanel #op2>
                                    <button pButton pRipple icon="pi pi-pencil"
                                        class="p-button-rounded p-button-primary m-1" (click)="edit(rowData)">{{'Edit attendee info'|translate}}</button><br>
                                    <p-toast></p-toast>
                                    <button pButton pRipple icon="pi pi-trash"
                                        class="p-button-rounded p-button-danger m-3"
                                        (click)="canseld(rowData)">{{'Cancel this attendee'|translate}}</button>
                                </p-overlayPanel>
                                <button pButton pRipple (click)="op2.toggle($event)" icon="far fa-ellipsis-v"
                                    class="p-button-rounded p-button-primary m-3"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>


            <!-- <div *ngIf="isticketsSales&&!isSalesReport&&false">
            <p-table #dt [columns]="allColumn" [value]="datatt" [totalRecords]="datatt.length"
                [paginator]="datatt.length>perpage" [rows]="perpage" rowGroupMode="rowspan" [groupRowsBy]="'SortId'"
                sortField="SortId" sortMode="single" dataKey="SortId" [tableStyle]="{'max-width':'100%'}">
                <ng-template pTemplate="header" >
                    <tr>
                        <th>#</th>
                        <ng-container *ngFor="let co of cols">
                            <th>
                                {{co.header|translate}}
                            </th>
                        </ng-container>
                        <ng-container *ngFor="let c of selectedColumns">
                            <th>
                                {{c.header|translate}}
                            </th>
                        </ng-container>
                        <th>{{Action|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-columns>
                    <tr>
                        <td>total</td>
                        <td>total</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-rowgroup="rowgroup"
                    let-rowspan="rowspan" let-columns="columns">
                    <tr>
                        <td>{{rowIndex}}
                        </td>
                        <ng-container *ngFor="let col of cols">

                            <td *ngIf="rowgroup&&(col.field!='Attendee Status'&&col.field!='Attendee #')" [attr.rowspan]="rowspan">
                                {{rowData[col.field]}}
                            </td>
                        </ng-container>
                                      <td *ngIf="checkifhaveattend(columns)">
                                        {{rowData['Attendee #']}}
                                    </td>
                                      <td *ngIf="checkifhaveattend(columns)">
                                        {{rowData['Attendee Status']}}
                                    </td>
                        <ng-container *ngFor="let col of selectedColumns">
                            <td>
                                {{rowData[col.field]}}
                            </td>
                        </ng-container>
                        <td>
                            <p-overlayPanel #op2>
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary m-1"
                                  (click)="edit(rowData)"
                                   >{{'Edit attendee info'|translate}}</button><br>
                                <p-toast></p-toast>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger m-3" (click)="canseld(rowData)"
                                    >{{'Cancel this attendee'|translate}}</button>
                            </p-overlayPanel>
                            <button pButton pRipple (click)="op2.toggle($event)" icon="far fa-ellipsis-v"
                                class="p-button-rounded p-button-primary m-3"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div> -->
            <!-- <div *ngIf="!isticketsSales&&!isSalesReport">
            <p-table #dt [columns]="AddOnsCols" [value]="DataForReport" [totalRecords]="DataForReport.length"
                [paginator]="DataForReport.length>perpage" [rows]="perpage" rowGroupMode="rowspan" [groupRowsBy]="'SortId'"
                sortField="SortId" sortMode="single" dataKey="SortId" [tableStyle]="{'max-width':'100%'}">
                <ng-template pTemplate="header" let-columns  >
                    <tr>
                        <th>#</th>
                        <ng-container *ngFor="let co of columns">
                            <th>
                                {{co.header|translate}}
                            </th>
                        </ng-container>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-columns>
                    <tr>
                        <td>total</td>
                        <td>total</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-rowgroup="rowgroup"
                    let-rowspan="rowspan" let-columns="columns">
                    <tr>
                        <td>{{rowIndex}}
                        </td>
                            <td *ngIf="rowgroup " [attr.rowspan]="rowspan">
                                {{rowData['Order #']}}
                            </td>
                            <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                                {{rowData['Order Date']}}
                            </td>
                        <ng-container *ngFor="let col of columns">

                            <td *ngIf="col.field!='Order #'&& col.field!='Order Date'">
                                {{rowData[col.field]}}
                            </td>

                        </ng-container>
                        <td>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-rounded p-button-danger m-3" (click)="canseldAddons(rowData)"
                                    >{{'Cancel this attendee'}}</button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div> -->


            <div *ngIf="isSalesReport">
                <p-table #dataTable [value]="SalesSumrepData" [columns]="Salescols" [scrollable]="true" scrollHeight="600px"
                    [totalRecords]="SalesSumrepData.length" sortField="salesType" sortMode="single" dataKey="salesType"
                    rowGroupMode="subheader" groupRowsBy="salesType" [globalFilterFields]="['name']"
                     >
                    <ng-template pTemplate="header">
                        <tr >
                            <th style="background-color:rgba(238, 92, 7, 0.03)">{{'Sales'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'Price01'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'Quantity01'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'Sold01'|translate}}</th>
                            <!-- <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'Remain01'|translate}}</th> -->
                            <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'Gross'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'AntaresFees'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'PaymentFees01'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'ShippingFees01'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)"> {{'NetSales'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)">{{'TicketsRefunded'|translate}}</th>
                            <th style="background-color:rgba(238, 92, 7, 0.03)">{{'GrossRefunded'|translate}}</th>

                        </tr>
                        <tr style="background-color: rgba(238, 92, 7, 0.03); border-bottom: 2px solid rgba(238, 92, 7, 1) ; ">
                        <th colspan="13" style="background-color:rgba(238, 92, 7, 0.03)" >{{'AllSales'|translate}}</th>

                    </tr>
                    </ng-template>
                    <ng-template pTemplate="groupheader" let-c>
                        <tr pRowGroupHeader  >
                            <td colspan="13"  >
                                <span class="font-bold ml-2">{{c.salesType|translate}}</span>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="groupfooter" let-c>
                        <tr class="p-rowgroup-footer " >
                            <td colspan="2" style="min-width: 80%">
                                <div style="text-align: start; width: 100%">
                                <!-- {{(c.salesType|translate)+" "+ -->
                                {{('TotalSold'|translate)}} </div>
                            </td>
                            <td colspan="10" style="text-align: start; width: 100%">{{getsum2Total(c.salesType)}}</td>
                        </tr>
                        <tr class="p-rowgroup-footer" >
                            <td colspan="2" style="min-width: 80%">
                                <div style="text-align: start; width: 100%">
                                    <!-- {{(c.salesType|translate)+" "+ -->
                                    {{('TotalNetSales'|translate)}} 
                                </div>
                            </td>
                            <td colspan="10" style="text-align: start; width: 100%">{{getsumTotal(c.salesType)}}</td>
                        </tr>
                        <tr class="p-rowgroup-footer" >
                            <td colspan="2" style="min-width: 80%">
                                <div style="text-align: start; width: 100%">
                                    <!-- {{(c.salesType|translate)+" "+ -->
                                    {{('TotalRemaining'|translate)}} 
                                </div>
                            </td>
                            <td colspan="10" style="text-align: start; width: 100%">{{getRemainAMount(c.salesType)}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-c let-rowIndex="rowIndex">
                        <tr>
                            <td>
                                {{c.name}}
                            </td>
                            <td>

                                {{c.price}}
                            </td>
                            <td>
                                {{c.soldItem}} 
                               <!-- {{ getremainperQty(c)}} -->
                           </td>
                            <td>
                                <!-- {{c.qty}} -->
                                {{ getremainperQty(c)}}
                            </td>
                            
                            <!-- <td>
                                {{c.remainQty}}
                            </td> -->
                            <td>
                                {{c.gross}}
                            </td>
                            <td>
                                {{c.antaresFees}}
                            </td>
                            <td>
                                {{c.paymentfees}}
                            </td>
                            <td>
                                {{c.shippingfees}}
                            </td>
                            <td>
                                {{c.fees}}
                            </td>
                            <td>
                                {{0}}
                            </td>
                            <td>
                                {{0}}
                            </td>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr style="background-color: rgba(238, 92, 7, 0.03); border-top: 2px solid rgba(238, 92, 7, 1) ;" >
                            <td colspan="2" style="background-color:rgba(238, 92, 7, 0.03)">{{'GrandTotalSales'|translate}}</td>
                            <td colspan="10" style="background-color:rgba(238, 92, 7, 0.03)">{{getallSales2Total()}}</td>


                            

                        </tr>
                        <tr style="background-color: rgba(238, 92, 7, 0.03); ">
                            <td  colspan="2" style="background-color:rgba(238, 92, 7, 0.03)">{{'GrandTotalNetSales'|translate}}</td>
                            <td  colspan="10" style="background-color:rgba(238, 92, 7, 0.03)">{{getallSalesTotal()}}</td>

                    </tr>
                    <tr style="background-color: rgba(238, 92, 7, 0.03); ">
                            <td  colspan="2" style="background-color:rgba(238, 92, 7, 0.03)">{{'GrandTotalReamin'|translate}}</td>
                            <td  colspan="10" style="background-color:rgba(238, 92, 7, 0.03)">{{ getallRemainTotal()}}</td>

                    </tr>
                    </ng-template>

                 
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                {{'TcktTblFooter01'|translate}} {{SalesSumrepData ? SalesSumrepData.length : 0 }} .
                            </div>
                        </ng-template>
                   
                    
                </p-table>
            </div>
            <div *ngIf="isticketsSales&&!isSalesReport" class="p-2">
                <div [ngClass]="{'card-base':ddggrr.length>3}">
                    <ng-container *ngFor="let i of ddggrr">
                        <app-desing-co [item]="i" [cols]="allColumn" [AttendeesCol]="_DynmicOrderForm"
                            (update)="edit($event)" (Canceld)="canseld($event)"></app-desing-co>
                    </ng-container>
                </div>

            </div>

            <div *ngIf="!isticketsSales&&!isSalesReport" [ngClass]="{'card-base':DataForReport.length>3}">
                <ng-container *ngFor="let i of DataForReport">
                    <app-add-ons-card-report [item]="i" [cols]="AddOnsCols" (update)="edit($event)"
                        (Canceld)="canseld($event)"></app-add-ons-card-report>
                </ng-container>
            </div>

        </div>



    </div>
</div>
