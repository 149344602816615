import { Component } from '@angular/core';

@Component({
  selector: 'app-inventory-reporting',
  templateUrl: './inventory-reporting.component.html',
  styleUrls: ['./inventory-reporting.component.scss']
})
export class InventoryReportingComponent {

}
