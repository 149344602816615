import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AttrDto } from 'src/app/Interfaces/attrDto';
import { AttrbuteService } from 'src/app/services/attrbute.service';
import { LoaderService } from 'src/app/services/loader.service';

const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])
const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-permissiontransate-mangment',
  templateUrl: './permissiontransate-mangment.component.html',
  styleUrls: ['./permissiontransate-mangment.component.scss'],
  animations: [fadeOut]
})
export class PermissiontransateMangmentComponent {
  data:AttrDto[]=[];
  totalRecourd:number=0;
  typertform!: FormGroup;
  submitted:boolean=false;
 Langueses: DropDownItem[]=[];
 mylocal: AttrDto[];
  index:number=0
  Subs:Subscription[]=[]
 searchText: string="";
 order: number=1;
 constructor(private activatedRoute:ActivatedRoute,
   private route:Router, private attrSrv:AttrbuteService,public loader : LoaderService, public messageService : MessageService,
   @Inject(DOCUMENT) public document: Document,private conSrv: ConfirmationService,private fb:FormBuilder ,private translate:TranslateService){
     this. initialForm(0,0,"",0,0,'en')
   var t=  this.translate.onLangChange.subscribe(() => {
       this.updateLang();
     });
     this.Subs.push(t)
 }



 updateLang() {
  this.attrSrv.getAllpermisiion(10101,this.index,this.searchText,this.order);
}
ngOnDestroy(): void {
this.Subs.forEach(x=>{
  x.unsubscribe();
})
}
changform(e){
  var data=this.mylocal.find(x=>x.cultureId==e.value);
  if(data==undefined){
   this.typertform.controls['name'].setValue("");
   //this.typertform.controls['order'].setValue(0);
   this.typertform.controls['status'].setValue(false);
  }else{
   this.typertform.controls['name'].setValue(data.name);
  // this.typertform.controls['order'].setValue(data.order);
   this.typertform.controls['status'].setValue(data.status==0?false:true);
  }
}

checkComplet(id){

  
  if(id==this.Langueses.length){
    return true
  }
  else{
    return false;
  }

}
initialForm(Id,ParentId,name,checked,order,lan){
  this.typertform=this.fb.group({
   attrId: [Id,Validators.required],
   parentId: [ParentId,Validators.required],
   name: [name,Validators.required],
   status: [checked],
   order: [order],
   cultureId:[lan,Validators.required],
    desc:[],        
    misc01 :[],     
    misc02 :[],
    misc03 :[],    
    misc04 :[],    
    misc05 :[],    
    misc06 :[],    
    misc07 :[],      
    parentName:[],  
       
  })
  }
ngOnInit(): void {
 
  this.loader.showLoader1();
  this.loader.showLoader();
  this.attrSrv.getAlltype(10101);
 var l= this.attrSrv.GetAllSystemLang().subscribe(x=>{
    this.Langueses=x;
  })
  this.Subs.push(l)
var m=  this.attrSrv.$getSubject.subscribe(x=>{
    //console.log(x)
    this.data=x.data;
    this.totalRecourd=x.totalAcount;
    this.loader.hideLoader1();
    this.loader.hideLoader();
  })
  this.Subs.push(m)
}
search(e,t){
  this.attrSrv.getAllpermisiion(10105,0,this.searchText);
  
}
publish(id){
var t= this.attrSrv.postattrstauts(id).subscribe(x=>{
// console.log(x);
this.attrSrv.getAllpermisiion(10105,this.index,this.searchText);
 })
 this.Subs.push(t)
}
remove(event, id) {

  this.conSrv.confirm({
    target: event.target,
    message: 'Are you sure that you want to proceed?',
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
   var del=    this.attrSrv.DeletAttr(id).subscribe(x => {
      this.attrSrv.getAllpermisiion(10105);
        this.messageService.add({ severity: 'success', summary: 'Deleted successfully' });
      },(eror)=>{
        this.messageService.add({ severity: 'danger', summary: eror.message });
      });
   
      // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
    },
    reject: () => {
      this.messageService.add({ severity: 'danger', summary: 'you not confirm delete operation' });
    }
  });
}
// edit(id){
//   this.route.navigate([id],{relativeTo: this.activatedRoute});
//  // this.route.navigate([ id],{relativeTo: this.activatedRoute});
// }
show(){
  this.route.navigate(["0"],{relativeTo: this.activatedRoute});
}

onRowEditInit(p:AttrDto) {
  //console.log(p)

var o=  this.attrSrv.GetAtrrSingle(p.attrId).subscribe(y=>{
      this.mylocal=y;
      var data=y.find(m=>m.cultureId=='en')
      this.initialForm(data.attrId,data.parentId,data.name,data.status!=0?true:false,data.order,data.cultureId)
    })
    this.Subs.push(o)
// this.initialForm(p.attrId,p.parentId,p.name,p.status==0?false:true,p.order,p.cultureId);
//  console.log(this.typertform.value)
 // this.clonedProducts[product.id] = {...product};
}
onRowEditSave(product){
//console.log(this.typertform.value)

var data:AttrDto={
attrId       :this.typertform.controls['attrId'].value,
cultureId      :this.typertform.controls['cultureId'].value,
 desc          :"",
 misc01        :"",
 misc02        :"",
 misc03        :"",
 misc04        :"",
 misc05        :"",
 misc06         :"",
 misc07          :"",
 name            :this.typertform.controls['name'].value,
 order            :this.typertform.controls['order'].value,
 parentId        :this.typertform.controls['parentId'].value,
 parentName      :"",
 status          :this.typertform.controls['status'].value?1:0}
// console.log(data)
var i=  this.attrSrv.PostnewAttr(data).subscribe(x=>{

  this.attrSrv.getAllpermisiion(10105,this.index);
   this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

 },(error)=>{
   this.messageService.add({ severity: 'danger', summary: error });
 });
 this.Subs.push(i)
}
onRowEditCancel(product, ri){
this.initialForm("0","","",false,0,'en')
}
getEvent(e:LazyLoadEvent){
var l=e.first/5;
this.index=l;
var order=e.sortOrder;
this.order=order;
this.attrSrv.getAllpermisiion(10105,l,this.searchText,order);
}
}
