<div class="container">
    <div class=" text-center my-5">


    <h2 class="fw-bold ">Grow your events with confidence</h2>
    <h5 class="text-secondary mb-4">Reach new customers, drive demand, and build relationships with our white-able solution</h5>


    <button class="modified-btn rounded-pill ">Let us connect <span></span></button>

</div>

<div class=" row px-sm-4 px-2  justify-content-center">
    <div class="card row card-modified mb-md-0 mb-3 me-md-4 col-md-4 justify-content-center align-items-center">
        <h3 class="col-12 ">Use Antares enterprise</h3>
        <span class="col-12">Use our white-label platform to manage all types of events at every stage of your business.</span>


        <div class="Header">
            <svg class="Header__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1337.97 684.43">
              <path class="Header__shape bigSquare" fill="#16d5d2a4" d="M546.519 349.271l86.383-56.098 56.097 86.383-86.383 56.098z"/>
              <path class="Header__shape triangle" fill="none" stroke="#ff467773" stroke-width="8" d="M372.15 462.17L321 434.58l-4.88 56.16z"/>
              <circle class="Header__shape bigCircle" fill="#ff467773" cx="1076.52" cy="262.17" r="59"/>
              <path class="Header__shape littleSquare" fill="#ff850377" d="M285.523 262.61l12.372-53.59 53.59 12.372-12.372 53.59z"/>
              <circle class="Header__shape hoop" fill="none" stroke="#ffe33086" stroke-width="13" cx="905.52" cy="447.17" r="45"/>
            </svg>
          </div>
    </div>
    <div class="card  row card-modified mb-md-0 mb-3 me-md-4 col-md-4 justify-content-center align-items-center">
        <h3 class="col-12 ">Build your community</h3>
        <span class="col-12">Reach new customers, drive demand, and build relationships with our powerful, built-in tools.</span>
        <div class="Header">
            <svg class="Header__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1337.97 684.43">
              <path class="Header__shape bigSquare" fill="#16d5d2a4" d="M546.519 349.271l86.383-56.098 56.097 86.383-86.383 56.098z"/>
              <path class="Header__shape triangle" fill="none" stroke="#ff467773" stroke-width="8" d="M372.15 462.17L321 434.58l-4.88 56.16z"/>
              <circle class="Header__shape bigCircle" fill="#ff467773" cx="1076.52" cy="262.17" r="59"/>
              <path class="Header__shape littleSquare" fill="#ff850377" d="M285.523 262.61l12.372-53.59 53.59 12.372-12.372 53.59z"/>
              <circle class="Header__shape hoop" fill="none" stroke="#ffe33086" stroke-width="13" cx="905.52" cy="447.17" r="45"/>
            </svg>
          </div>
    </div>
    <div class="card row card-modified  col-md-4 justify-content-center align-items-center">
        <h3 class="col-12 ">Make the right decisions</h3>
        <span class="col-12">With the generated data, you will focus on what's working best with real-time.</span>
        <div class="Header">
            <svg class="Header__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1337.97 684.43">
              <path class="Header__shape bigSquare" fill="#16d5d2a4" d="M546.519 349.271l86.383-56.098 56.097 86.383-86.383 56.098z"/>
              <path class="Header__shape triangle" fill="none" stroke="#ff467773" stroke-width="8" d="M372.15 462.17L321 434.58l-4.88 56.16z"/>
              <circle class="Header__shape bigCircle" fill="#ff467773" cx="1076.52" cy="262.17" r="59"/>
              <path class="Header__shape littleSquare" fill="#ff850377" d="M285.523 262.61l12.372-53.59 53.59 12.372-12.372 53.59z"/>
              <circle class="Header__shape hoop" fill="none" stroke="#ffe33086" stroke-width="13" cx="905.52" cy="447.17" r="45"/>
            </svg>
          </div>
    </div>

</div>

    <div class="my-3 row card-2-modified p-0 m-0 justify-content-center align-items-center">
        <div class="col-md-6 col-12 p-4 ">
            <h3>Use Antares enterprise</h3>
            <h5 class="text-secondary">Event creation and ticketing tools</h5>
            <span>Tools you need to set up and sell tickets to all events.</span>
            <h5 class="text-secondary mt-3">Customizable event pages</h5>
            <span>Create beautifully designed event pages optimized to convert visitors into attendees.</span>
            <h5 class="text-secondary mt-3">Sell tickets from your website</h5>
            <span>Create a great checkout experience on your website.</span>
            <h5 class="text-secondary mt-3">Email Campaigns</h5>
            <span>Create customized email templates, send promos and email invites, and track performance.</span>
        </div>
        <div class="col-md-6 col-12 text-end p-0 m-0">
            <img class="w-100 rounded-25" src="../../../../assets/img/pexels-mikhail-nilov-6893804.jpg" alt="entreprise">
        </div>
    </div>

    <div class="my-3 row card-2-modified p-0 m-0 justify-content-center align-items-center">
        <div class="col-md-6 col-12 p-4 ">
            <h3>Build your community</h3>
            <h5 class="text-secondary">Boost your events</h5>
            <span>Targeted email campaigns all in one place.</span>
            <h5 class="text-secondary mt-3">Virtual events</h5>
            <span>Engage your customers with webinars, live streams, online classes, and workshops.</span>
            <h5 class="text-secondary mt-3">Attendee Marketplace</h5>
            <span>Sell products and services in the same way as events.</span>
            <h5 class="text-secondary mt-3">Community engagement</h5>
            <span>Keep your community engaged by listed promotion, jobs, and value-added services such as syndicate and affiliates programs.</span>
        </div>
        <div class="col-md-6 col-12 text-end p-0 m-0">
            <img class="w-100 rounded-25" src="../../../../assets/img/pexels-matheus-bertelli-2608517.jpg" alt="entreprise2">
        </div>
    </div>


    <div class="my-3 row card-2-modified p-0 m-0 justify-content-center align-items-center">
        <div class="col-md-6 col-12 p-4 ">
            <h3>Make the right decisions</h3>
            <h5 class="text-secondary">Analytics</h5>
            <span>Find where sales are coming from and what marketing efforts are best with real-time insights.</span>
            <h5 class="text-secondary mt-3">Reports</h5>
            <span>Get detailed information about your buyers, ticket sales, event attendance, and more.</span>

        </div>
        <div class="col-md-6 col-12 text-end p-0 py-md-2 m-0">
            <img class="w-100 rounded-25" src="../../../../assets/img/pexels-rodnae-productions-7947663.jpg" alt="entreprise3" >
        </div>
    </div>

    <div class="my-3">
        <div class="text-center">
            <h3>Contact us</h3>
            <span>Fill out the form below and we will contact you shortly with information</span>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-6 col-12">
                <form  [formGroup]="contactUsForm" (ngSubmit)="contactUs()">
                    <div class="row">
                        <div class="p-input-filled   col-md-6 col-12">
                            <span class="p-float-label">
                                <input type="text" formControlName="firstName" class="form-control" pInputText id="firstName" [ngClass]="{
                                    'ng-invalid ng-dirty':
                                    contactUsForm.get('firstName')?.touched &&
                                    contactUsForm.get('firstName')?.invalid
                                }" />
                                <label htmlFor="firstName">First Name</label>
                            </span>
                        </div>
                        <div class="p-input-filled   col-md-6 col-12">
                            <span class="p-float-label">
                                <input type="text" formControlName="lastName" class="form-control" pInputText id="lastName" [ngClass]="{
                                    'ng-invalid ng-dirty':
                                    contactUsForm.get('lastName')?.touched &&
                                    contactUsForm.get('lastName')?.invalid
                                }" />
                                <label htmlFor="lastName">Last Name</label>
                            </span>
                        </div>
                        <div class="p-input-filled   col-md-6 col-12">
                            <span class="p-float-label">
                                <input type="email" formControlName="email" class="form-control" pInputText id="email" [ngClass]="{
                                    'ng-invalid ng-dirty':
                                    contactUsForm.get('email')?.touched &&
                                    contactUsForm.get('email')?.invalid
                                }" />
                                <label htmlFor="email">Email adress</label>
                            </span>
                        </div>
                        <div class="p-input-filled   col-md-6 col-12">
                            <span class="p-float-label">
                                <input type="text" formControlName="organizationName" class="form-control" pInputText id="organizationName" [ngClass]="{
                                    'ng-invalid ng-dirty':
                                    contactUsForm.get('organizationName')?.touched &&
                                    contactUsForm.get('organizationName')?.invalid
                                }" />
                                <label htmlFor="organizationName">Organization name</label>
                            </span>
                        </div>
                        <div class="p-input-filled   col-12">
                            <span class="p-float-label">
                                <textarea  formControlName="message" class="form-control" pInputText id="message" [ngClass]="{
                                    'ng-invalid ng-dirty':
                                    contactUsForm.get('message')?.touched &&
                                    contactUsForm.get('message')?.invalid
                                }" ></textarea>
                                <label htmlFor="message">Message</label>
                            </span>
                        </div>
                    </div>
                    <div class="banner-btn rounded-pill mx-2 text-center">
                        <button type="submit" class="rounded-pill modified-btn" >Send <i class="fa-regular fa-paper-plane"></i><span></span></button>

                    </div>

                    </form>
            </div>


            <div class="col-lg-6 d-lg-flex d-none">
                <div class=" text-end pt-xl-4  m-0">
                    <img class="w-100   m-0 rounded-25" src="../../../../assets/img/pexels-olia-danilevich-4974913.jpg" alt="entreprise3" style="aspect-ratio: 2/1;" >
                </div>
            </div>
        </div>
    </div>

</div>

<div class="partner-area  ptb-70  ">
    <div class="mx-md-5">
        <app-partner></app-partner>
    </div>
</div>
