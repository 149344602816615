import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';
import { EventCard } from '../landing-page/landing-page.service';
import { debounceTime } from 'rxjs/operators';
import { GMap } from 'primeng/gmap';
import { BrowserEventService, eventSearch, searchestory } from './browser-event.service';
import { Router } from '@angular/router';
import { UserActionsService } from 'src/app/services/user-actions.service';
import { UserlocationService } from 'src/app/services/userlocation.service';
import { DatePipe } from '@angular/common';
import { MapMarkerClusterer } from '@angular/google-maps';
import MarkerClusterer, {MarkerClustererOptions} from '@googlemaps/markerclustererplus';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
declare let $: any;

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
]);

const fadeOut = trigger('fadeOut', [
    leaveTrans
]);

@Component({
    selector: 'app-browse-events',
    templateUrl: './browse-events.component.html',
    styleUrls: ['./browse-events.component.scss']
    ,
    animations : [fadeOut]

})
export class BrowseEventsComponent implements OnInit {
    THRESHOLD = 100;
    showloader:boolean=true;
    @HostListener('window:resize', ['$event'])
    showFilters:boolean=true;
    screenSize= window.innerWidth < this.THRESHOLD;
    IsDateSearch: boolean=false;
    IsPriceSearch: boolean=false;
    isLocationSearch: boolean=false;
    isorgnizerSearch: boolean=false;
    count=0;
    oldLocation: string[]=[];
    map2: google.maps.Map;
   
    sizeChange(event) {
        this.screenSize= window.innerWidth < this.THRESHOLD;
      }
    url = environment.Api;
    UpdateLocation=false;
    sidebarVisible: boolean;
    sidebarVisible2: boolean;
   
    mapProblem: boolean;
    markers: google.maps.Marker[] = [];
    markers2: google.maps.Marker[] = [];
    


  

 
 
    @ViewChild('gmap') gmapElement1: GMap;
    @ViewChild('gmap') gmapElement: any;
    @ViewChild('gmap1') gmapElement2: GMap;
    @ViewChild('gmap1') gmapElementt: any;
    userSearch:searchestory[]=[]
    Form1!: FormGroup;
    searchResults: any[];
    categories !: DropDownItem[];
    types!: DropDownItem[];
    curData !: DropDownItem[];
    dataEvents: eventSearch[] = [];
    map: google.maps.Map;
    ssss;

   
    //     this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe(x => {
    //         this.search(this.searchForm.value)

    //     })
    // }
    constructor(private fb:FormBuilder,private datePipe: DatePipe,public srv:BrowserEventService,private eventsService: EventsService,private route:Router,public ua:UserActionsService,private userLocSrv:UserlocationService, private translate:TranslateService) { 
        window.dispatchEvent(new Event('resize'));
        this.translate.onLangChange.subscribe(() => {
            this.updateLang();
          })
    }
    updateLang() {
        this.eventsService.GetAttributes(10102).subscribe(x => this.categories = x);
        this.eventsService.GetAttributes(10101).subscribe(x => this.types = x);
        this.eventsService.GetAttributes(203).subscribe(x => this.curData = x);
    }
    Like(id: string) {
        this.ua.postLike(id).subscribe(x => {
          this.ua.getLikedEventList().subscribe(y => {
            this.ua.LikedEvents = y as string[];
          });
        });
      }
      GetMoreData() {
        this.count++;
        this.srv.search(this.srv.searchForm.value,this.count).subscribe(x=>{
          this.dataEvents.push(...x)
          this.getAddress(this.Form1.controls['lat'].value,this.Form1.controls['lng'].value)

      
        })}

    // getDateOntomeZone(d){
    //     let t = new Date(Date.now()).getTimezoneOffset();
    //     let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    //     return new Date(MyDate).toLocaleString();
    //     }
    


    getByprice(){


    }
    changLocal(){
        this.UpdateLocation=!this.UpdateLocation
    }
    getAddressAuto(e){

        if(e.address_components.find(y => y.types.includes('administrative_area_level_1'))!=undefined){
            this.srv.searchForm.controls['address'].setValue(e.address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name);
            
        }else{
            if(e.address_components.find(y => y.types.includes('administrative_area_level_2'))?.long_name!=undefined){
                this.srv.searchForm.controls['address'].setValue(e.address_components.find(y => y.types.includes('administrative_area_level_2'))?.long_name);
                
            }else{
                this.srv.searchForm.controls['address'].setValue(e.address_components.find(y => y.types.includes('locality'))?.long_name);
            }
        }
        this.srv.searchForm.controls['online'].setValue(false);
        this.Form1.controls['lat'].setValue(e.geometry.location.lat());
        this.Form1.controls['lng'].setValue(e.geometry.location.lng());
        this.UpdateLocation=!this.UpdateLocation
        this.isLocationSearch=true;
        this.filter();
        //this.submit();
    }
    getProblem(e){}
    getDate(e){
        this.IsDateSearch=true;
         this.GetDate('anytime',true)
        this.srv.searchForm.controls['start'].setValue(this.Form1.controls['date'].value[0]);
        this.srv.searchForm.controls['end'].setValue(this.Form1.controls['date'].value[1]);
        this.filter();

    }
    ngOnInit() {

        this.srv.getUserSearchHistory().subscribe(x=>{
         //   console.log(x)
            this.userSearch=x;
            this.oldLocation=Array.from(new Set(x.map(item => item.address)));
          //  var t=JSON.parse(x as string);
           // console.log(t)
           })
        this.ua.getLikedEventList().subscribe(y => {
            this.ua.LikedEvents = y as string[];});
       // this.submit()
       this.filter();
        this.screenSize= window.innerWidth < this.THRESHOLD;
        this.Form1=this.fb.group({
            date:[],
            lat:[],
            lng:[]
        })
        this.userLocSrv.$getuserlocationLatLang.subscribe(x=>{
            
        this.Form1.controls['lat'].setValue(x.lat);
        this.Form1.controls['lng'].setValue(x.lng);
        })
        this.eventsService.GetAttributes(10102).subscribe(x => this.categories = x);
        this.eventsService.GetAttributes(10101).subscribe(x => this.types = x);
        this.eventsService.GetAttributes(203).subscribe(x => this.curData = x);


    }


    
    getAddress(LAT, LNG) {
        this.showMap(LAT, LNG);
    }

    showMap(LAT, LNG) {
        const mapStyle = [
            {
              featureType: "all",
              elementType: "labels",
              stylers: [{ visibility: "off" }]
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [{ visibility: "off" }]
            }
          ];
        const mapProp = {
            center: new google.maps.LatLng(LAT, LNG),
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: mapStyle
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        this.map2 = new google.maps.Map(this.gmapElementt.nativeElement, mapProp);
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat: LAT, lng: LNG } }, function (results, status) {
            if (status === 'OK') {
               // console.log(results);
           
            } else {
              //  alert('Geocode was not successful for the following reason: ' + status);
            }
        });
       
       

        this.markers.forEach(marker => marker.setMap(null));
        this.markers = [];

        
         // Add a marker for each item in dataEvents
       
        this.dataEvents.forEach((event,i) => {
          
            if(event.address!="online"){
                var icon = {
                    url:'../../../assets/img/antares2logomarker.png',

                    size: new google.maps.Size(32, 32),
                    origin: new google.maps.Point(0, 0),
                    scaledSize: new google.maps.Size(32, 32),
                     labelOrigin: new google.maps.Point(0, 0),
               
                    fillOpacity: 0.8,
                    strokeColor: "#FFFFFF",
                    strokeWeight: 2,
                    strokeOpacity: 0.8,
                    rotation: 0,
                    scale: 2,

                    anchor: new google.maps.Point(0, 0),

                };
                var icon2 = {
                    url:'../../../assets/img/antares1logomarker.png',
                    size: new google.maps.Size(32, 32),
                    origin: new google.maps.Point(1, 1),
                    scaledSize: new google.maps.Size(32, 32),
                     labelOrigin: new google.maps.Point(0, 20),
                    fillOpacity: 0.8,
                    strokeColor: "#FFFFFF",
                    strokeWeight: 2,
                    strokeOpacity: 0.8,
                    rotation: 0,
                    scale: 2,
                    anchor: new google.maps.Point(0, 0),
                };
                const contentString = `<div class="single-courses-box bg-color border-45">
                <div class="courses-content">
            
                    <h4 class="text-lenght"><a href="/event/${event.id}" target="_blank">${event.title}</a></h4>
                    <div class="">
                    <div class="mt-2 me-2 text-primary ">${event.price.minValue!=event.price.maxValue?event.price.minValue+event.curSymbol+"-"+event.price.maxValue+event.curSymbol:event.price.maxValue==0?'free':event.price.maxValue+event.curSymbol}</div>
                    </div>
            
                    <div class="card-footer-font-size  mb-2 mt-2 ">
                        <div class="mt-3  border-top border-primary"
                             style="color:var(--mainColor);">
                                                        <span class="mt-2 fw-bold"><small><i class="far fa-clock "></i>
                                                                Start</small> ${this.getDateOntomeZone(event.startDate)}</span><br />
                            <span class="mt-2 fw-bold"><small><i class="far fa-clock"></i> End:</small>
                            ${this.getDateOntomeZone(event.startDate)}</span>
                        </div>
                    </div>
                    <div class="card-footer-font-size d-block align-items-center ">
                        <div class="course-author d-flex align-items-center">
                            <span class="text-lenght-org"><i class="fal fa-user"></i> <a href="/organizers/preview/${event.orgId}" target="_blank">${event.orgName}</a></span>
                        </div>
                        <p class="card-footer-font-size "><i class="fa-solid fa-users"></i> Followers
                            <span style="color:var(--mainColor);">${event.orgFollowers}</span> </p>
                    </div>
                </div>
            </div>`;
const infoWindow = new google.maps.InfoWindow({
  content: contentString
});
      
                var la=event.lat+(0.0001*i);
                var ln=event.lng+(0.00001*i);
                //console.log(event.lat)
                // console.log(la)
                // console.log(event.lng)
                // console.log(ln)

                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(la, ln),
                    title: event.title+i,
                    draggable:true,
                    map:this.map,
                    icon:icon,
                    optimized:true,
                    visible:true,

                  
                });

                i++
                marker.addListener('mouseover', () => {
                    // Start a BOUNCE animation on the marker
                   marker.setIcon(icon2)
                   marker.setPosition(marker.getPosition())
             
                   // marker.setAnimation(google.maps.Animation.DROP);
                  });
                  marker.addListener('click', () => {
                    // Start a BOUNCE animation on the marker
                   
                 
                   infoWindow.open(this.map, marker);
                   // marker.setAnimation(google.maps.Animation.DROP);
                  });

                  marker.addListener('mouseout', () => {
                    // Start a BOUNCE animation on the marker
                   // marker.setAnimation(null);
                   marker.setIcon(icon)
                   
                  });

                
                  var marker2 = new google.maps.Marker({
                    position: new google.maps.LatLng(la, ln),
                    title: event.title+i,
                    draggable:true,
                    map:this.map2,
                    icon:icon,
                    optimized:true,
                    visible:true,
                  
                });
                i++
              marker2.addListener('mouseover', () => {
                    // Start a BOUNCE animation on the marker
                   marker2.setIcon(icon2)
                   marker2.setPosition(marker2.getPosition())
             
                   // marker.setAnimation(google.maps.Animation.DROP);
                  });
                  marker2.addListener('click', () => {
                    // Start a BOUNCE animation on the marker
                   
                 
                   infoWindow.open(this.map, marker2);
                   // marker.setAnimation(google.maps.Animation.DROP);
                  });

                  marker2.addListener('mouseout', () => {
                    // Start a BOUNCE animation on the marker
                   // marker.setAnimation(null);
                   marker.setIcon(icon)
                   
                  });

                this.markers.push(marker)
                this.markers2.push(marker2)
            }
          
              
        //       // Add a listener for the idle event on the map
      
       
    });
    var markerCluster=   new MarkerClusterer(this.map, this.markers, {
        gridSize:50,
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        minimumClusterSize:2,
        maxZoom:50,
      });
    markerCluster.addMarkers(this.markers);
    var markerCluster2=   new MarkerClusterer(this.map2, this.markers2, {
        gridSize:50,
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        minimumClusterSize:2,
        maxZoom:100,
      });
    markerCluster2.addMarkers(this.markers2);



      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        // Update the custom marker cluster with the markers
        markerCluster.clearMarkers();
        markerCluster.addMarkers(this.markers);
      });
      google.maps.event.addListenerOnce(this.map2, 'idle', () => {
        // Update the custom marker cluster with the markers
        markerCluster2.clearMarkers();
        markerCluster2.addMarkers(this.markers2);
      });

    }


    handleDragEnd(event) {
       // console.log(event)
    }


    GetDate(x,e) {

        this.IsDateSearch=true;
        switch (x) {
          case 'today': this.srv.searchForm.controls['start'].setValue(new Date(Date.now()));
                        this.srv.searchForm.controls['end'].setValue("");
                        this.Form1.controls['date'].setValue("")
                        break;
          case 'tomorrow': this.srv.searchForm.controls['start'].setValue(new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() +1)));
                           this.srv.searchForm.controls['end'].setValue("");
                           this.Form1.controls['date'].setValue("")
                           break; 
            case 'thisWeek': this.srv.searchForm.controls['start'].setValue(new Date(Date.now()));
                              this.srv.searchForm.controls['end'].setValue(new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() +7)));
                              this.Form1.controls['date'].setValue("")
                              break; 
            case 'thisMonth': this.srv.searchForm.controls['start'].setValue(new Date(Date.now()));
                              this.srv.searchForm.controls['end'].setValue(new Date(new Date(new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() +1)).setDate(1)));
                              this.Form1.controls['date'].setValue("")
                              break;
             case 'nextMonth': this.srv.searchForm.controls['start'].setValue(new Date(new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() +1)));
                               this.srv.searchForm.controls['end'].setValue("");
                               this.Form1.controls['date'].setValue("")
            break; 
            case 'anytime': this.srv.searchForm.controls['start'].setValue("");
                               this.srv.searchForm.controls['end'].setValue("");
                               if(!e){
                                this.Form1.controls['date'].setValue("")
                               }
                               
            break; 
    
        }
      
      if(!e){
        this.filter();
      }  
       
        
       
    }
    getPrice(e){
        switch(e){
            case 'free':  this.srv.searchForm.controls['free'].setValue(true);
                                       this.srv.searchForm.controls['paid'].setValue(false);
            break;
            case 'paid': this.srv.searchForm.controls['paid'].setValue(true);
                         this.srv.searchForm.controls['free'].setValue(false);
            break;
            case 'all': this.srv.searchForm.controls['paid'].setValue(false);
                         this.srv.searchForm.controls['free'].setValue(false);
            break;

        }
    //  this.srv.searchForm.controls['free'].setValue(e);
     this.filter();
    }
    
    setlocationSearchonline(){
       this.filter();
     //   this.submit();
    }
    setDeFaultLocation(x){
        switch(x){
           case 'Dubai' :   this.srv.searchForm.controls['address'].setValue(x)
                            this.srv.searchForm.controls['online'].setValue(false);
                            break;
           case 'selectLocation'    :   this.srv.searchForm.controls['address'].setValue("")
                                        this.srv.searchForm.controls['online'].setValue(false);
                            break;           
        }
     
        this.changLocal();
    this.filter();
    }

    getorgEvent(){
        this.isorgnizerSearch=!this.isorgnizerSearch;
        this.filter();
    }
    // search(formValues: any) {
    //     const name = formValues.name;
    //     const org = formValues.org;
    //     const online = formValues.online;
    //     const start = formValues.start ? new Date(formValues.start) : null;
    //     const end = formValues.end ? new Date(formValues.end) : null;
    //     const free = formValues.free;
    //     const cat = formValues.cat;
    //     const typ = formValues.typ;
    //     const cur = formValues.cur;
    //     const address = formValues.address;
    //     let params = new HttpParams();
    //     if (name) { params = params.set('name', name); }
    //     if (org) { params = params.set('org', org.toString()); }
    //     if (online) { params = params.set('online', online.toString()); }
    //     if (start) { params = params.set('start', start.toISOString()); }
    //     if (end) { params = params.set('end', end.toISOString()); }
    //     if (free) { params = params.set('free', free.toString()); }
    //     if (cat) { params = params.set('cat', cat.toString()); }
    //     if (typ) { params = params.set('typ', typ.toString()); }
    //     if (cur) { params = params.set('cur', cur.toString()); }
    //     if (address) { params = params.set('address', address.toString()); }



    //     this.http.get(environment.Api + 'd/la/search', { params: params }).subscribe(x => {
    //         if (x) {
    //           
    //             this.dataEvents = x as EventCard[]
    //             console.log(x)
    //         }



    //     }
    //     )
    // }

    filter(){
        this.showloader=true;
        this.srv.Filter(this.srv.searchForm.value,0).subscribe(x=>{
            if(x){
                this.showloader=false;
              this.dataEvents=[];
                this.dataEvents=x ;
                if(!this.srv.searchForm.controls['online'].value){

                   // console.log(this.Form1.value)
                    if(this.Form1.controls['lat'].value!=null&&this.Form1.controls['lng'].value!=null){
                        this.getAddress(this.Form1.controls['lat'].value,this.Form1.controls['lng'].value)
                    }
                    else{
                        this.getAddress( this.dataEvents[0].lat,this.dataEvents[0].lng)
                    }
                  
                }
              
              
            }
        })
    }

    submit() {
        this.showloader=true;
        this.count=0;
        this.srv.search(this.srv.searchForm.value,0).subscribe(x=>{
            if(x){
                this.showloader=false;
                this.dataEvents=[];
                this.dataEvents=x ;
                if(!this.srv.searchForm.controls['online'].value){

                  //  console.log(this.Form1.value)
                    if(this.Form1.controls['lat'].value!=null&&this.Form1.controls['lng'].value!=null){
                        this.getAddress(this.Form1.controls['lat'].value,this.Form1.controls['lng'].value)
                    }
                    else{
                        if(this.dataEvents.length!=0){

                            this.getAddress( this.dataEvents[0].lat,this.dataEvents[0].lng)
                        }
                        this.getAddress( this.dataEvents[0].lat,this.dataEvents[0].lng)

                      
                    }
                  
                }
              
              
            }
        })

    }

    getcurrentLocation(){
        alert("You need to allow access to your current location to get the optimal search results.")
        this.userLocSrv.getLocation();
        var userLocSub = this.userLocSrv.$getuserlocation.subscribe(x => {
          //console.log(x)
              this.srv.searchForm.controls['address'].setValue(x)
             //this.userLocSrv. = x;
             this.srv.searchForm.controls['address'].setValue(x);
             this.srv.searchForm.controls['online'].setValue(false);
             this.changLocal();
             this.filter();

        });
       // this.sub.push(userLocSub);
    }
    getDateOntomeZone(d){
        let t = new Date(Date.now()).getTimezoneOffset();
        let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
        if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
            var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
            return l;
        }else{
            var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
            return l;
        }
       // return new Date(MyDate).toLocaleString();
    }
}
