import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'BypassHTML'
})
export class BypassHTMLPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    // tslint:disable-next-line:typedef
    transform(value) {
      return this.sanitizer.bypassSecurityTrustHtml(value);
    }

}
