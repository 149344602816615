<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="px-sm-5 px-2">

        <nav class="navbar navbar-expand navbar-light bg-light " data-spy="scroll" [class.active]="classApplied">

            <ng-content></ng-content>
              <!-- src="../../../../assets/img/Logo/Antares - Logo H (Colored).png" class="logo-size-larg" -->
            <a class="navbar-brand d-sm-flex d-none" routerLink="/"><img
                  
                    src="{{LogoUrl}}" class="logo-size-larg"
                    alt="logo"></a>
                    <!-- src="../../../../assets/img/Logo/Antares - Logomark (Colored).png" class="logo-size-small" -->
            <a class="navbar-brand d-sm-none d-flex" routerLink="/"><img
                src="{{LogoUrl}}" class="logo-size-small"
                    alt="logo"></a>


            <div class=" d-flex  align-items-center justify-content-lg-end justify-content-center padding-small-screen"
                style="flex-grow: 1;">

                <ul class="navbar-nav  ">
                    <a pTooltip="{{'NavSrchBtnPop'|translate}}" showDelay="1000" tooltipPosition="bottom"
                        class="nav-item " routerLink="/search"><i
                            class="fa-regular fa-magnifying-glass mx-sm-3 mx-2"></i></a>
                </ul>
                <ul class="navbar-nav border-end border-start">
                    <li class=" d-flex align-items-center text-end" pTooltip="{{'Language'|translate}}" showDelay="1000"
                        tooltipPosition="left">
                        <a class="text-center  log-list position-relative">
                            <i class="fa-light fa-globe on-hover-lang mx-sm-3 mx-2">
                            </i>
                            <ul class="log-list-ul">

                                <li [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                                'log-list-li': document.documentElement.dir === 'ltr'}" *ngFor="let lan of countries">
                                    <a (click)="selectLang($event,lan.id)">
                                        <span class="ps-2">{{lan.name}}</span>
                                    </a>
                                </li>
                            </ul>
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav   ">
                    <li><a pTooltip="{{'Createanevent'|translate}}" showDelay="1000" tooltipPosition="bottom"
                            [routerLink]="['/events/manage-event/', '0']" class="nav-item "><i
                                class="fa-light fa-calendar-plus mx-sm-3 mx-2"></i></a></li>
                </ul>

            </div>

            <div class="others-option d-flex align-items-center text-end">
                <a *ngIf="!auth.securityObject.firstName" [routerLink]="['login']"
                class="rounded-pill" [ngClass]="{'default-btn-rtl': document.documentElement.dir === 'rtl',
                'default-btn': document.documentElement.dir === 'ltr'}"><i
                        class="flaticon-user d-lg-none d-flex"></i>{{"NavStrtBtn"|translate}}<span></span></a>
                <a class="pe-lg-0 pe-sm-4 pe-2 text-center text-lenght log-list"
                    *ngIf="auth.securityObject.firstName">{{auth.securityObject.firstName}}
                    <ul [ngClass]="{'log-list-ul-rtl list-p-modif-rtl ': document.documentElement.dir === 'rtl',
                                      'log-list-ul  list-p-modif': document.documentElement.dir === 'ltr'}">

                        <!-- <li [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a [routerLink]="['/User-profile']">
                                <i class="fa-sharp fa-solid fa-user"></i><span class="ps-2">{{'Profile'|translate}}</span>
                            </a>
                        </li> -->
                        <li class="text-wrap w-100" [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a  routerLink="/search">
                                <i class="fa-sharp fa-solid fa-magnifying-glass "></i>
                                <span class="ps-2">{{'Browse events'|translate}}</span>
                            </a>
                        </li>
                        <li class="text-wrap w-100" [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a routerLink="/events" >
                                <i class="fa-sharp fa-solid fa-calendar-day "></i>
                                <span class="ps-2">{{'My events'|translate}}</span>
                            </a>
                        </li>
                        <li class="text-wrap w-100" [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a routerLink="/User-Tickets">
                                <i class="fa-sharp fa-solid fa-ticket "></i>
                                <span class="ps-2">{{'Tickets'|translate}}</span>
                            </a>
                        </li>
                        <li class="text-wrap w-100" [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a routerLink="/User-Add-ons">
                                <i class="fa-sharp fa-solid fa-puzzle-piece"></i>
                                <span class="ps-2">{{'Add-Ons'|translate}}</span>
                            </a>
                        </li>
                        <hr style="border: 1px solid #ff7803; margin-top: 10px !important; margin-bottom: 10px !important;">
                        <li class="text-wrap w-100" [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a routerLink="/favorites">
                                <i class="fa-sharp fa-solid fa-square-heart "></i>
                                <span class="ps-2">{{'Favorites'|translate}}</span>
                            </a>
                        </li>
                        <li class="text-wrap w-100" [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a [routerLink]="['interests']">
                                <i class="fa-sharp fa-solid fa-calendar-star "></i>
                                <span class="ps-2">{{'Interests'|translate}}</span>
                            </a>
                        </li>
                        <hr style="border: 1px solid #ff7803; margin-top: 10px !important; margin-bottom: 10px !important;">

                        <li class="text-wrap w-100" [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a routerLink="/User-profile">
                                <i class="fa-sharp fa-solid fa-gear "></i
                                    ><span class="ps-2">{{'NavLogOption001'|translate}}</span>
                            </a>
                        </li>

                        <li class="text-wrap w-100" [ngClass]="{'log-list-li-rtl ': document.documentElement.dir === 'rtl',
                        'log-list-li': document.documentElement.dir === 'ltr'}">
                            <a  (click)="logout()">
                                <i class="fa-solid fa-right-from-bracket "></i>
                                <span class="ps-2"> {{'NavLogOption002'|translate}}</span>

                            </a>
                        </li>
                    </ul>
                </a>
                <ul>
                    <li  class="d-none">
                        <!-- class="nav-item dropdown app-dropdown  dropstart   ms-lg-4  p-0 align-self-center d-lg-inline-block " -->
                        <a href="javascript:void(0);" class=" dropdown-toggle" id="AppDropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style="font-size: 24px;">
                            <i class="fa-light fa-grip-horizontal fs-24"></i>
                        </a>
                        <div [ngClass]="{'dropdown-menu-rtl position-absolute eq-animated eq-fadeInUp p-3': document.documentElement.dir === 'rtl',
                                      'dropdown-menu  position-absolute eq-animated eq-fadeInUp p-3': document.documentElement.dir === 'ltr'}"
                            aria-labelledby="AppDropdown">
                            <div class=" row row-cols-3 mb-3 mt-3">
                                <a href="javascript:;" data-ore="vega" class="col-4 text-center">
                                    <img src="../../../../assets/img/nav-logos/Vertical Logos/Colored/vega (v colored).png"
                                        class="img-fluid">
                                </a>
                                <a href="javascript:;" data-ore="sirius" class="col-4 text-center">
                                    <img src="../../../../assets/img/nav-logos/Vertical Logos/Colored/sirius (v colored).png"
                                        class="img-fluid">
                                </a>
                                <a href="javascript:;" data-ore="rigel" class="col-4 text-center">
                                    <img src="../../../../assets/img/nav-logos/Vertical Logos/Colored/rigel (v colored).png"
                                        class="img-fluid">
                                </a>
                            </div>
                            <div class=" row row-cols-3 mb-3">
                                <a href="javascript:;" data-ore="canopus" class="col-4 text-center">
                                    <img src="../../../../assets/img/nav-logos/Vertical Logos/Colored/canopus (v colored).png"
                                        class="img-fluid">
                                </a>
                                <a href="javascript:;" data-ore="antares" class="col text-center">
                                    <img src="../../../../assets/img/nav-logos/Vertical Logos/Colored/antares (v colored).png"
                                        class="img-fluid">
                                </a>
                                <a href="https://polaris.nejmy.net" target="_blank" class="col text-center">
                                    <img src="../../../../assets/img/nav-logos/Vertical Logos/Colored/polaris (v colored).png"
                                        class="img-fluid">
                                </a>
                            </div>
                            <hr class="bg-primary mt-5">
                            <div class=" row row-cols-3 mt-2">
                                <a href="javascript:;" data-ore="nejmy" class="col text-center">
                                    <img src="../../../../assets/img/nav-logos/Vertical Logos/Colored/nejmy (v colored).png"
                                        class="img-fluid">
                                </a>
                                <a href="https://lana.nejmy.net" target="_blank" class="col text-center">
                                    <img src="../../../../assets/img/nav-logos/Vertical Logos/Colored/lana (v colored).png"
                                        alt="Lana Incubator" class="img-fluid">
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>

            </div>

        </nav>
    </div>
    <p-overlayPanel #lan [style]="{'width': '200px'}" [showCloseIcon]="false">
        <ng-template pTemplate="content">
            <p-table [value]="countries" selectionMode="single" [(selection)]="selectedProduct"
                (onRowSelect)="onRowSelect($event, lan)" responsiveLayout="scroll">
                <ng-template pTemplate="body" let-rowData let-product>
                    <tr [pSelectableRow]="rowData">
                        <td>{{product.name}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-template>
    </p-overlayPanel>
</div>
