
<form  autocomplete="off" class="mt-3">
    <div class="container">
        <div class="card custom-table-border">


            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'Manageattendees(Add Attendees)'|translate}}</h1>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div class="row revers-cols">
                        <div class="col-lg-8 col-12">

                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <h2> {{'Registrationinformation'|translate}}</h2>
                                        </div>
                                    </div>
                                    <form>
                                        <div class="row">
                                            <h6>{{'Checkout0034'|translate}}</h6>
                                            <div class="p-input-filled col-lg-6 col-12">
                                                <!-- <div class="mb-3">
                                                    <input type="text" class="form-control" pInputText placeholder="{{'FirstName'|translate}}">
                                                </div> -->

                                                <span class="p-float-label">
                                                    <input type="text"  pTooltip="{{'First name'|translate}}"
                                                    showDelay="1000" tooltipPosition="top" pInputText class="form-control"  #firstname />
                                                    <label class="form-lable font-size-14" for="firstname">{{'First name'|translate}}</label>
                                                </span>
                                                <!-- <small class="p-error" *ngIf="srv.myform.get('name')?.touched &&
                                                srv.myform.get('name')?.invalid||(submitted&& srv.myform.get('name').invalid)
                                                    " >{{'TcktFrmInfoInpt001ErrMsg'|translate}}</small> -->
                                            </div>
                                            <div class="p-input-filled col-lg-6 col-12">
                                                <!-- <div class="mb-3">
                                                    <input type="text" class="form-control" pInputText placeholder="{{'LastName'|translate}}">
                                                </div> -->

                                                <span class="p-float-label">
                                                    <input type="text"  pTooltip="{{'Last name'|translate}}"
                                                    showDelay="1000" tooltipPosition="top" pInputText class="form-control"  #LastName />
                                                    <label class="form-lable font-size-14" for="LastName">{{'Last name'|translate}}</label>
                                                </span>
                                            </div>
                                            <div class="p-input-filled col-lg-12 col-12">
                                                <!-- <div class="mb-3">
                                                    <input type="email" pInputText class="form-control"
                                                        placeholder="{{'Email'|translate}}">
                                                </div> -->

                                                <span class="p-float-label">
                                                    <input type="email"  pTooltip="{{'Email'|translate}}"
                                                    showDelay="1000" tooltipPosition="top" pInputText class="form-control"  #Email />
                                                    <label class="form-lable font-size-14" for="Email">{{'Email'|translate}}</label>
                                                </span>
                                            </div>
                                            <div class="col-lg-12 col-12">
                                                <div class="mb-3 form-check">

                                                    <p-checkbox  [(ngModel)]="checked"   [binary]="true"
                                                    label="{{'attendessemailcheck'|translate}}"  [readonly]="srv.form1.get('eticket').value&&srv.form1.get('willcall').value"></p-checkbox>
                                                    <!-- <input type="checkbox"
                                                        > -->

                                                </div>
                                            </div>
                                        </div>
                                    </form>



                                    <div class="row" >
                                        <div class="col-12">
                                         <h4>{{'Atendeesinformationforticket'|translate }} {{srv.form1.get('ticketName').value}}</h4>
                                        </div>
                                      </div>
                                                <div class="row" >
                                                    <div class="col-12">
                                                    <app-json-form [jsonFormData]="formData" (result)="GetResult($event) "
                                                        (IsNotValid)="getValud($event)"></app-json-form>
                                                </div>
                                                </div>






                            <div class="row">
                                <ng-container *ngIf="!checked">
                                    <div class="col-12">
                                        <h5> {{'Shippingaddressfor'|translate}} {{ticKets.name}} </h5>
                                        <app-json-get-shipping-address [jsonFormData]="ticKets" (result)="getAddress($event) "></app-json-get-shipping-address>
                                    </div>
                                </ng-container>
                              </div>
                        </div>
                        <div class="col-lg-4 col-12">
                            <div class="row">
                                <div class="col-12">
                                    <img [src]="eventdata.eventImage==null?'assets/img/courses/3SUBiaP.png':url+eventdata.eventImage" alt="ddd" width="100%" class="shadow-4 rounded-addImage" />
                                </div>
                                <div class="col-12 ">
                                    <h5 class="d-block fw-bold">
                                        {{eventdata?.eventName}}
                                    </h5>
                                    <h6 class="text-primary d-block"> <i
                                            class="fa-solid fa-calendar-days text-primary"></i>
                                        {{'RsrvOrdrDsc001'|translate}} {{eventdata.eventStart==null?"":getDateOntomeZone(eventdata?.eventStart)}}
                                    </h6>
                                    <h6 class=" text-primary d-block"> <i
                                            class="fa-solid fa-calendar-days text-primary"></i>
                                        {{'RsrvOrdrDsc002'|translate}} {{eventdata.eventEnd==null?"":getDateOntomeZone(eventdata?.eventEnd)}} </h6>
                                </div>
                                <div class="col-12 d-lg-block d-none events-details-parent">
                                    <div class="events-details-info">
                                        <ul class="info">
                                            <ng-container>
                                                <li>
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span><span>{{srv.form1.get('qty').value}} X</span>
                                                        {{srv.form1.get('ticketName').value}}
                                                        </span>

                                                        
                                                        <span>
                                                            <span>
                                                                {{srv.form1.get('value').value}}
                                                            </span>
                                                            <span>
                                                                {{srv.form1.get('cursymbol').value}}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="!checked">
                                                <li >
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span><span>1 X</span>
                                                            {{'shipping'|translate}}</span>
                                                        <span>
                                                            <!-- <span [ngClass]="{
                                                        'text-decoration-line-through':
                                                        orderForm.controls[i]?.get('price').value!=orderForm.controls[i]?.get('oldPrice').value
                                                    }">
                                                                {{getitemPrice(
                                                                orderForm.controls[i]?.get('oldPrice').value ,
                                                                orderForm.controls[i]?.get('qty').value)}}{{orderForm.controls[i]?.get('curSymbol').value}}

                                                            </span> -->
                                                            <span>
                                                                {{srv.form1.get('type').value=='Complimentary'?0:shippingCost}}{{srv.form1.get('cursymbol').value}}

                                                            </span>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <li class="price">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span>{{'RsrvOrdrTotlSlt'|translate}}</span>
                                                    <span>
                                                        <span *ngIf="checked">
                                                            {{srv.form1.get('value').value}}
                                                        </span>
                                                        <span *ngIf="!checked">
                                                            {{getTotal()}}
                                                        </span>
                                                        <span>
                                                            {{srv.form1.get('cursymbol').value}}
                                                        </span>
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                        <div class="btn-box">
                                            <button class=" rounded-pill save-btn w-100" (click)="submitOrder()">
                                                {{'RegisterattendeesBtn'|translate}}<span></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="  d-lg-none events-details-width2 " >
                <div class="events-details-info2 ">
                    <ul class="info">
                        <div class="solts-small-display">
                            <ng-container>
                                <li>
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span><span>{{srv.form1.get('qty').value}} X</span>
                                        {{srv.form1.get('ticketName').value}}
                                        </span>
                                        <span>
                                            <span>
                                                {{srv.form1.get('value').value}}
                                            </span>
                                            <span>
                                                {{srv.form1.get('cursymbol').value}}
                                            </span>
                                        </span>
                                    </div>
                                </li>
                            </ng-container>

                        </div>
                        <ng-container *ngIf="!checked">
                            <li >
                                <div class="d-flex justify-content-between align-items-center">
                                    <span><span>1 X</span>
                                        {{'shipping'|translate}}</span>
                                    <span>
                                        <!-- <span [ngClass]="{
                                    'text-decoration-line-through':
                                    orderForm.controls[i]?.get('price').value!=orderForm.controls[i]?.get('oldPrice').value
                                }">
                                            {{getitemPrice(
                                            orderForm.controls[i]?.get('oldPrice').value ,
                                            orderForm.controls[i]?.get('qty').value)}}{{orderForm.controls[i]?.get('curSymbol').value}}

                                        </span> -->
                                        <span>
                                            <!-- {{srv.form1.get('type').value=='Complimentary'?0:shippingCost}}{{srv.form1.get('curSymbol').value}} -->

                                        </span>
                                    </span>
                                </div>
                            </li>
                        </ng-container>
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{'RsrvOrdrTotlSlt'|translate}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <button class=" rounded-pill save-btn w-100" (click)="submitOrder()">
                            {{'RegisterattendeesBtn'|translate}}<span></span>
                        </button>
                    </div>

                </div>
            </div>


        </div>
    </div>
    <div></div>
</form>
