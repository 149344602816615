
<div class="events-area py-3">
    <div #myDiv class="container">
        <div class="card custom-table-border ">
            <div class="card-header border-0 text-center bg-light rounded-top-23 ">
                <div class="row">
                    <div class=" col-12  " >
                        <div class="row">
                            <div class="col-md-6 col-12 text-start ">
                        <h3 class="text-primary font-s">{{'OrderRepCo001'|translate}} <span>{{item['Order']}}</span></h3>
                            </div>
                            <div class="col-md-6 col-12 text-md-end text-start ">
                                <h3 class="text-primary font-s "><i class="bx bx-calendar text-primary"></i>
                                    <span>{{getDateOntomeZoneformat(item['orderDate'],'full')}}</span></h3>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="row p-3 ">
                <div class="col-lg-4 col-12 ">
                    <h6 >{{'OrdeeventNameo002'|translate}} <span>{{item['eventName']}}</span></h6>
                    <h6 >{{'Ordereventstart009'|translate}} <span>{{getDateOntomeZoneformat(item['startdate'],'full')}}</span></h6>
                    <h6 >{{'Orderrepco002'|translate}} <span>{{item['Ticket type']}}</span></h6>
                    <h6 >{{'Orderrepco003'|translate}} <span>{{item['Attendees Count']}}</span></h6>
                    <h6 > {{'Orderrepco004'|translate}}<span>{{item['Qty']}}</span></h6>
                    <h6 > {{'Orderrepco005'|translate}} <span>{{item['price']}}</span></h6>
                </div>
                <div class="col-lg-4 col-12 ">
                    <h6 >{{'OrdertotalPrice002'|translate}} <span>{{item['totalprice']}}</span></h6>
                    <h6 > {{'Ordertotalfeeso007'|translate}}<span>{{item['totalFees']}}</span></h6>
                    <h6 > {{'Ordernetseles008'|translate}}<span>{{item['totalnetsales']}}</span></h6>
                    <h6 >{{'OrderrsalesChanal001'|translate}} <span>{{item['SalesChannal']|translate}}</span></h6>
                    <h6 >{{'OrderrPaymentWay001'|translate}} <span>{{item['paymentType']|translate}}</span></h6>
                    <h6 >{{'Orderrepco010'|translate}} <span>{{item['Order Type']}}</span></h6>
                    
                </div>
            </div>
            <div class="row p-3">

                <div class="col-12">
                    <div class=" col-12 p-0 pointer " [ngClass]="{'card-base':item.Atendees.length>2}">
                        <div class=" py-1" *ngFor="let i of item.Atendees ">
                            <div class="ticket">
                                <div class="row align-items-center">
                                    <div class="col-lg-8 col-md-8 col-12 ">
                                        <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12 text-primary" >{{'Orderrepco014'|translate}} <span>{{i['Attendee #']}}</span></div>
                                    <div class="col-lg-6 col-md-6 col-12 text-primary">{{'Orderrepco015'|translate}}<span>{{i['Attendee Status']|translate}}</span></div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12" *ngFor="let m of AttendeesCol ">
                                       {{m.header+" : "}}<span>{{i[m.field]}}</span>
                                    </div>
                                   </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-12 text-md-end text-center">
                                    <!-- <div class="col-lg-2 col-md-6 col-12"> -->
                                        <button *ngIf="" type="button" pButton pRipple [pRowToggler]="event"
                                            class="p-button-text p-button-rounded d-lg-none d-block"
                                            [icon]="expanded ? 'fa-regular fa-chevron-down' : 'fa-regular fa-ticket fa-rotate-90'">{{'EvntTblBtn002Chois004'|translate}}</button>
                                            <p-overlayPanel #op2>
                                                <button pButton pRipple icon="pi pi-pencil"
                                                pTooltip="{{'Edit attendee info'|translate}}" showDelay="1000" tooltipPosition="top"
                                                    class="p-button-rounded p-button-primary m-3"
                                                  (click)="edit(item,i)"
                                                   ></button><br>
                                                   <!--  -->
                                                <p-toast></p-toast>
                                                <button pButton pRipple icon="pi pi-trash"

                                                    pTooltip="{{'Cancel this attendee'|translate}}" showDelay="1000" tooltipPosition="top"
                                                    class="p-button-rounded p-button-danger m-3" (click)="canseld(item,i)"
                                                    ></button>
                                                    <!--  -->
                                            </p-overlayPanel>

                                        <button pButton pRipple (click)="op2.toggle($event)" icon="far fa-ellipsis-v"
                                            class="p-button-rounded p-button-primary m-1"></button>

                                    <!-- </div> -->
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
