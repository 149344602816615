


<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'PaymentGetWayPercentage'|translate}}</h1>
        </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>
       
        <div class="">
            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data"   [rows]="5"
              [totalRecords]="data.length"   [paginator]="data.length>5" [globalFilterFields]="['Per']" [tableStyle]="{'max-width':'100%'}"
                [rowHover]="true" dataKey="attrId"  editMode="row"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true" class=""  >
                <ng-template pTemplate="caption">
                    
                    <div class="row">
                           
                        <form [formGroup]="Myform" (submit)="post()" autocomplete="off" >
                            <div class="row mb-3">

                                <div  class="p-input-filled col-sm-12 col-md-6  mb-2">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon currency-custom ">
                                            %
                                            <!-- [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}">
                                            {{prosrvform.promoteCodeForm.get('isPercent').value?"%":eventStepSrv.currancysymbole}} -->
                                        </span>
                                        <span class="p-float-label">
                                            <!-- <input type="text" pInputText  /> -->
                                            <p-inputNumber pTooltip="{{'paymentPrmoFrmStp3Inpt002Pop'|translate}}" showDelay="1000"
                                                tooltipPosition="bottom" inputId="discountAmount" [useGrouping]="false" [styleClass]="'w-100'"
                                                class="w-100" [inputStyleClass]="'form-control'"  formControlName="amount">
                                            </p-inputNumber>
                                            <label class="form-label font-size-14" for="withoutgrouping">
                                                {{'GetWaypercentage'|translate}}
                                              <!--  {{
                                                 prosrvform.promoteCodeForm.get('isPercent').value
                                                ? ('PrmoFrmStp3Inpt002Titl002'|translate)
                                                : 'PrmoFrmStp3Inpt002Titl001'
                                                |translate}} --></label>
                                        </span>
                                    </div>
                                    
                                </div>
                             

                              
                               
                            
                            </div>

                            
                           
                            <div class="col-12 d-flex justify-content-center">
                                <div class="banner-btn rounded-pill">
                                    
                                    <button pButton pRipple type="Save and finish " showDelay="1000" tooltipPosition="bottom"
                                        class="rounded-pill save-btn text-center ms-1 mt-md-0 mt-1">{{'SavBtn'|translate}}
                                        <span></span></button>
                                </div>
                            </div>
                        </form>


                    </div>
               

                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>
                        <th class="fw-bold text-center">#</th>
                        <th class="fw-bold text-center">{{'Percentage'|translate}}</th>
                        <th class="fw-bold text-center">{{'Date of adding'|translate}}</th>
                   
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-t let-ri="rowIndex">
                    <tr >
                        <td  class="text-md-center padding-custom text-lenght-larg text-end">
                  
                   
                            <span
                                class="headers-cutom text-start fw-bold ">{{'#'|translate}}</span><span
                                class="text-lenght">{{ri}}</span>
                    
                    
                        </td>
                        <td  class="text-md-center padding-custom text-lenght-larg text-end">
                  
                   
                            <span
                                class="headers-cutom text-start fw-bold ">{{'Percentage'|translate}}</span><span
                                class="text-lenght">{{t.per+' '+'%'}}</span>
                    
                    
                    </td>
                   <td  class="text-md-center padding-custom text-lenght-larg text-end">
                  
                   
                            <span
                                class="headers-cutom text-start fw-bold ">{{'Date of adding'|translate}}</span><span
                                class="text-lenght">{{getDateOntomeZoneformat(t.date)}}</span>
                    
                    
                    </td>

     



                   
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        {{'TcktTblFooter01'|translate}} {{data ? totalRecourd : 0 }} .
                    </div>
                </ng-template>
            </p-table>

        </div>

    </div>
</div>
