import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { AddnewAttendeesOfflineService } from '../addnew-attendees-offline.service';
import { VMaddresShipping, VmOrderItem, itemshipingaddress, postAddOnsDto } from 'src/app/components/pages/order-event/order-event.component';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { TranslateService } from '@ngx-translate/core';
export interface EventDetailsAttendeesDataDto {

    displayEnd?: boolean;
    displayStart?: boolean
    eventEnd?: Date;
    eventId?: string;
    eventImage?: string;
    eventName?: string;
    eventStart?: Date;
  

  }
@Component({
  selector: 'app-addnew-ateendeesinfo',
  templateUrl: './addnew-ateendeesinfo.component.html',
  styleUrls: ['./addnew-ateendeesinfo.component.scss']
})
export class AddnewAteendeesinfoComponent implements OnInit  {
  formData: any[] = [];
  subs: Subscription[]=[];
  id: string='0';
  checked:boolean=true;
url=environment.Api;
  eventdata:EventDetailsAttendeesDataDto={};
  ticKets:{name?:string,id?:string}={}
  


  numOfattendees: number[] = [];
  shippingCost: number;
  userEmail: string;
  imageUrl: string;
  getHowManyAtt(x) {
      this.numOfattendees[0] = x.value;
     // console.log(this.numOfattendees[0]);
     // console.log(this.numOfattendees);
      this.OrderFormResult = [];
  }

  getValud(x){
   // console.log(x)

  }
  orderformanstikets: any;
  OrderFormResult: any ;
  OrderFormIsComplete = false;
  generateRandomNumber(): string {
    const length = 7;
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }

    return result;
}
  GetResult(x) {
      //    this.OrderFormResult.push(x)
     // console.log('getneew result of orderform')
     console.log(x)
      this.userEmail=x.Email
      this.srv.subject4.next(x.Email);
      this.OrderFormResult= {
          attendeesNum: this.generateRandomNumber(),
          status: 'Attending',
          Answer: x,
      }
      // // console.log(x)
      // console.log('the result is :',this.OrderFormResult)
      let dd = 0;
   
     
     // console.log('ticket order answer' + 0);
     // console.log(x);
     // console.log('order answer count ' + dd);
     // console.log('attendes count' + this.numOfattendees[0]);
     // console.log(this.numOfattendees[0] == dd);
      // if (this.numOfattendees[0] == dd) {
      //     this.OrderFormIsComplete = true;
      //     this.orderformanstikets[0] = {
      //         //ticets: item.id,//ticketsID
      //         order: this.OrderFormResult,
      //     };
      // } else {
      //     this.OrderFormIsComplete = false;
      // }
      this.orderformanstikets={
                ticets: this.srv.form1.controls['ticketId'].value,//ticketsID
                order: [this.OrderFormResult],
            }

            this.OrderFormIsComplete = true;
            // this.srv.form1.controls['orderForm'].setValue()
           // console.log(this.srv.form1.value)
  }
  changeform() {
    var p = this.http
        .get(environment.Api + 'd/e/ordform/' + this.id)

        .subscribe((x) => {
            //   console.log("sssssssss")
            //  console.log(x)
            this.formData = JSON.parse(x['form']);
        });
    this.subs.push(p);

  
}

getDateOntomeZone(d) {
  m.locale()
  var eventOffset = new Date(Date.now()).getTimezoneOffset();
  
 // var eventOffset = 60;
  var MyDate = new Date(d).setMinutes(
      new Date(d).getMinutes() - eventOffset
  );
  var l = '';
 
          if (
              new Date(MyDate).getFullYear() ==
              new Date(Date.now()).getFullYear()
          ) {
              l = m(new Date(MyDate)).format('ddd MMMM D hh:mm a')
              // this.datePipe.transform(
              //     new Date(MyDate),
              //     'EEE MMMM d hh:mm a'
              // );
          } else {
              l = m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a')
              //  this.datePipe.transform(
              //     new Date(MyDate),
              //     'EEE MMMM d,y hh:mm a'
              // );
            }
  
  return l;

  // return new Date(MyDate).toLocaleString();
}

// getDateOntomeZone(d) {
//   let t = new Date(Date.now()).getTimezoneOffset();
//   let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - t);
//   if (
//       new Date(MyDate).getFullYear() == new Date(Date.now()).getFullYear()
//   ) {
//       var l = this.datePipe.transform(
//           new Date(MyDate),
//           'EEE MMMM d hh:mm a'
//       );
//       return l;
//   } else {
//       var l = this.datePipe.transform(
//           new Date(MyDate),
//           'EEE MMMM d,y hh:mm a'
//       );
//       return l;
//   }

//   // return new Date(MyDate).toLocaleString();
// }
constructor(private http:HttpClient,private translate: TranslateService,
   private datePipe: DatePipe,
   public srv:AddnewAttendeesOfflineService, 
    private messageService: MessageService,
       private router: Router, private activatedRoute: ActivatedRoute, public EventSrv: EventStepsService,
       ){
        this.translate.onLangChange.subscribe(() => {
         
          m.locale(this.translate.currentLang)
      });
       }

  ngOnInit(): void {
    this.getShippingCost();
    this.id=this.srv.form1.controls['eventId'].value;
    //console.log(this.id)
    this.changeform();
    this.getEventDetails();
this.ticKets={
    name:this.srv.form1.controls['ticketName'].value,
    id:this.srv.form1.controls['ticketId'].value
}
this.checked=this.srv.form1.controls['eticket'].value
  }
  getEventDetails(){
    var p = this.http
    .get<EventDetailsAttendeesDataDto>(environment.Api + 'd/At/g/i/' + this.id)
    .subscribe((x) => {
          //console.log(x)
          this.eventdata=x;
    });
this.subs.push(p);
  }
  allitemShippingaddress: itemshipingaddress ;
  getAddress(e) {
        this.allitemShippingaddress=e;
}

getShippingCost(){
  return  this.http.get<number>( environment.Api + 'd/e/p/g').subscribe(x=>{
    this.shippingCost=x;
  });
}
// submitOrder(){

// }
Dataforpost: postAddOnsDto = {};
submitOrder() {


  // console.log(this.CheckOut)
  this.Dataforpost.eventId =this.srv.form1.controls['eventId'].value;
  this.Dataforpost.promo = "";
  if(this.srv.form1.controls['type'].value!="Complimentary"){
      this.Dataforpost.shippingCost=this.shippingCost
  }else{
      this.Dataforpost.shippingCost=0
  }
 
  
      var t: VmOrderItem[] = []; 
              var l: VmOrderItem = {
                  id: this.srv.form1.controls['ticketId'].value,
                  deleveryMethods: this.checked?'101040201':'101040202',
                  price:  this.srv.form1.controls['value'].value,
                  qty: this.srv.form1.controls['qty'].value,
              };
              l.orderForm =JSON.stringify(this.orderformanstikets);

              if(!this.checked){
                var loc=this.allitemShippingaddress;
                var m:VMaddresShipping={
                    address:loc.address,
                    city:loc.city,
                    country:loc.country,
                    isUsLocal:loc.isUsLocal,
                    lat:loc.lat==null?0:loc.lat,
                    lng:loc.lng==null?0:loc.lng
                }
                l.shipping=m;
            }
              t.push(l);
         
     
      this.Dataforpost.order = t;
      this.Dataforpost.formAnswer =JSON.stringify(this.orderformanstikets);
       this.Dataforpost.email=this.userEmail;
       this.Dataforpost.type=this.srv.form1.controls['type'].value

//console.log(this.Dataforpost)
  var f = this.http
      .post(this.url + 'd/or/c', this.Dataforpost)
      .subscribe((x) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Saved Successfully',
      });
      this.router.navigate(['confirm', x], {
          relativeTo: this.activatedRoute.parent,
      });
          var id:string=x as string
        //   this.GetOrderQrCode(id).subscribe(x=>{




        //     const base64String = x;
        //     this.imageUrl = `data:image/png;base64,${base64String}`;
        //     const filename = 'ticket.png'; // replace with your desired filename
        //     this.http.get(this.imageUrl, { responseType: 'blob' }).subscribe(blob => {
        //       saveAs(blob, filename);
        //     });
          
        // });
        //   this.router.navigate([''], {
        //       relativeTo: this.activatedRoute.parent,
        //   });
      });
  this.subs.push(f);
}
getTotal(){
  var t= Number.parseFloat(this.srv.form1.controls['value'].value) ;
  console.log(t)
  var l=this.shippingCost ;
  console.log(l)
  var m= t+l;
  console.log(m)
  return m
}
GetOrderQrCode(id:string){
  if(this.checked){
    return this.http.get<Uint8Array>(environment.Api + 'd/or/g/e/o/Qr/' + id+"/"+this.userEmail)
  }else{
    return this.http.get<Uint8Array>(environment.Api + 'd/or/g/e/o/Qr/' + id)
  }
  
 }
}
