import { Component, Inject, OnInit } from '@angular/core';
import {
    Router,
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
} from '@angular/router';
import {
    DOCUMENT,
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from './services/auth.service';
import { AppUserAuth } from './security/app-user-auth';
import { LoaderService } from './services/loader.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { UserlocationService } from './services/userlocation.service';
import * as rtlcss from 'rtlcss';
import { IGNORED_ENTRY_POINT } from '@angular/compiler-cli/ngcc/src/packages/entry_point';
import { inject } from '@angular/core/testing';
import { GeoIPServiceService } from './services/geo-ipservice.service';
import { Observable } from 'rxjs';

declare let $: any;

const leaveTrans = transition(':leave', [
    style({
        opacity: 1,
    }),
    animate(
        '1s ease-out',
        style({
            opacity: 0,
        })
    ),
]);

const fadeOut = trigger('fadeOut', [leaveTrans]);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
    animations: [fadeOut],
})
export class AppComponent implements OnInit {
    currentYear: number;
    location: any;
    routerSubscription: any;
    visible: boolean;
    langueges: string = 'en';
    securityObject: AppUserAuth | undefined;
    showHead: boolean;
    showFoot: boolean;
    apiLoaded: any;

    constructor(
        public loader: LoaderService,
        public auth: AuthService,
        private router: Router,
        private offcanvasService: NgbOffcanvas,
        public translate: TranslateService,
        private http: HttpClient,
        private userLocalSrv: UserlocationService,
        @Inject(DOCUMENT) private doc: Document
    ) //  private lopd: GeoIPServiceService
    {
        localStorage.setItem('loclizetionUI', 'en-US');
        this.loader.showLoader();
        // const clientIP = this.getIpCliente().subscribe(x=>{console.log(x)});
        this.currentYear = new Date().getFullYear();

        this.router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                // navbar
                if (
                    event.url.includes('/login') ||
                    event.url.includes('/register') ||
                    event.url.includes('/emailCheck') ||
                    event.url.includes('Password')
                ) {
                    this.showHead = false;
                } else {
                    this.showHead = true;
                }
                // footer
                if (
                    event.url.includes('organizer') ||
                    event.url.includes('/login') ||
                    event.url.includes('/register') ||
                    event.url.includes('/order') ||
                    event.url.includes('events') ||
                    event.url.includes('/emailCheck') ||
                    event.url.includes('Password') ||
                    event.url.charAt(1) != ''
                ) {
                    this.showFoot = false;
                } else {
                    this.showFoot = true;
                }
            }
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                //  console.log("dsdsafdsjd")
                var lan = localStorage.getItem('loclizetion');
                //   console.log("lan")
                //  console.log("dsdsafdsjd")
                this.getLang(lan);
            }
        });

        //    console.log("lan")
        //    console.log(lan)
        //    if(lan==null){
        //      this.langueges='en';
        //      localStorage.setItem('loclizetion','en')
        //    translate.setDefaultLang('en');
        //    translate.use('en');
        //     http.get<{ cultureId: string, dict: Object }[]>(environment.Api + 'd/loc/store1/en')
        //         .subscribe(y => {
        //             y.forEach(x => localStorage.setItem(x.cultureId, JSON.stringify(x.dict)));

        //             const s = localStorage.getItem('en') as string;

        //                 const x = JSON.parse(s);

        //                translate.setTranslation('en', x);
        //                translate.setDefaultLang('en');
        //                 translate.use('en');
        //                 this.loader.hideLoader()
        //                 this.switch('en');
        //              this.loader.hideLoader();

        //         }, err => this.loader.hideLoader());

        //    // this.switch('en')
        //    }

        //    else{
        //     console.log("lan in con else")
        //     console.log(lan)
        //    translate.setDefaultLang(lan);
        //    translate.use(lan);
        //     http.get<{ cultureId: string, dict: Object }[]>(environment.Api + 'd/loc/store1/'+lan)
        //         .subscribe(y => {
        //             y.forEach(x => localStorage.setItem(x.cultureId, JSON.stringify(x.dict)));
        //             const s = localStorage.getItem(lan) as string;
        //     const x = JSON.parse(s);
        //     translate.setTranslation(lan, x);
        //     translate.setDefaultLang(lan);
        //     translate.use(lan);
        //     this.langueges=lan;
        //     this.switch(this.langueges)
        //     this.loader.hideLoader()
        //         }, err => this.loader.hideLoader());
        //    }
    }

    deletall() {
        let mlink = this.doc.getElementsByTagName('link');
        //console.log(mlink)
        let stylelink: HTMLLinkElement | null = null;

        for (var i = 0; i < mlink.length; i++) {
            // console.log(mlink[i])
            if (mlink[i].href.includes('enboot') && mlink[i].id == '') {
                // console.log(mlink[i])
                //  console.log("delete enboot.css")
                mlink[i].remove();
            }
            if (mlink[i].href.includes('engtheme') && mlink[i].id == '') {
                // console.log(mlink[i])
                // console.log("delete theme.css")
                mlink[i].remove();
            }
            if (mlink[i].href.includes('artheme') && mlink[i].id == '') {
                // console.log(mlink[i])
                // console.log("delete artheme.css")
                mlink[i].remove();
            }
            if (mlink[i].href.includes('arboot') && mlink[i].id == '') {
                // console.log(mlink[i])
                //  console.log("delete arboot.css")
                mlink[i].remove();
            }
        }
    }

    switch(lang: string) {
        // console.log(lang)
        let htmltag = this.doc.getElementsByTagName(
            'html'
        )[0] as HTMLHtmlElement;
        htmltag.dir = lang === 'ar' ? 'rtl' : 'ltr';
        htmltag.lang = lang;
        let HeadTag = this.doc.getElementsByTagName(
            'head'
        )[0] as HTMLHeadElement;
        let mlink = this.doc.getElementsByTagName('link');
        // console.log(mlink)
        let stylelink: HTMLLinkElement | null = null;
        this.deletall();
        for (let i = 0; i < mlink.length; i++) {
            if (mlink[i].href.includes('style.css')) {
                stylelink = mlink[i];
                break;
            }
        }

        let existingboot = this.doc.getElementById(
            'bootcss'
        ) as HTMLLinkElement;
        let existingthem = this.doc.getElementById(
            'themcss'
        ) as HTMLLinkElement;
        let bootbundel = lang === 'ar' ? 'arboot.css' : 'enboot.css';
        let thembundel = lang === 'ar' ? 'artheme.css' : 'engtheme.css';
        if (existingboot) {
            existingboot.href = bootbundel;
        } else {
            let newbootlink = this.doc.createElement('link');
            newbootlink.rel = 'stylesheet';
            // newbootlink.type='text/css';
            newbootlink.id = 'bootcss';
            newbootlink.href = bootbundel;
            //HeadTag.prepend(newbootlink)
            HeadTag.insertBefore(newbootlink, stylelink);
        }
        if (existingthem) {
            existingthem.href = thembundel;
        } else {
            let newthemelink = this.doc.createElement('link');
            newthemelink.rel = 'stylesheet';
            // newthemelink.type='text/css';
            newthemelink.id = 'themcss';
            newthemelink.href = thembundel;
            // HeadTag.appendChild(newthemelink)
            HeadTag.insertBefore(newthemelink, stylelink);
        }

        this.deletall();
    }
    getLang(lang) {
        // console.log("lang");
        // console.log(lang);
        if (lang) {
            this.loader.showLoader();
            localStorage.setItem('loclizetion', lang);
            var zz = lang;
            //   console.log(" in if ");
            //    console.log(zz);

            this.http
                .get<{ cultureId: string; dict: Object; logo: string }[]>(
                    environment.Api + 'd/loc/store1/' + zz
                )
                .subscribe((y) => {
                    //       console.log(y);

                    y.forEach((u) => {
                        localStorage.setItem(
                            u.cultureId,
                            JSON.stringify(u.dict)
                        );
                        localStorage.setItem(u.cultureId + 1, u.logo);
                    });
                    var s = localStorage.getItem(zz) as string;

                    setTimeout(() => {
                        //     console.log("data of translate");
                        var x = JSON.parse(s);
                        //    console.log(x);
                        this.translate.setTranslation(zz, x);
                        this.translate.use(zz);
                        this.switch(lang);
                        this.loader.hideLoader();
                    }, 400);
                });
        } else {
            this.loader.showLoader();
            //  console.log("in else");
            localStorage.setItem('loclizetion', 'en');

            var z = 'en';
            //  console.log(z);

            this.http
                .get<{ cultureId: string; dict: Object }[]>(
                    environment.Api + 'd/loc/store1/' + z
                )
                .subscribe((y) => {
                    //      console.log(y);

                    y.forEach((u) => {
                        //   console.log(u)
                        localStorage.setItem(
                            u.cultureId,
                            JSON.stringify(u.dict)
                        );
                    });
                    var s = localStorage.getItem(z) as string;

                    setTimeout(() => {
                        //   console.log("data of translate");
                        var x = JSON.parse(s);
                        //   console.log(x);
                        this.translate.setTranslation('en', x);
                        this.translate.use('en');
                        this.switch('en');
                        this.loader.hideLoader();
                    }, 400);
                });
        }
    }
    ngOnInit() {
        this;
        //console.log(this.langueges)
        // this.switch(this.langueges)
        if (!this.apiLoaded) {
            // This code loads the IFrame Player API code asynchronously, according to the instructions at
            // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            document.body.appendChild(tag);
            this.apiLoaded = true;
        }
        // this.userLocalSrv.getLocation();
        this.visible = false;
        this.recallJsFuntions();
        this.getLang('en');
    }

    onDisplaySideBarClick(): void {
        this.visible = !this.visible;
    }
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    open(content) {
        this.offcanvasService
            .open(content, { ariaLabelledBy: 'offcanvas-basic-title' })
            .result.then(
                (result) => {
                    // this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                    // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                }
            );
    }

    logout() {
        this.offcanvasService.dismiss('Cross click');

        this.auth.logout();
    }

    // getIpCliente(): Observable<any> {
    //     return this.http
    //                .get("http://api.ipify.org/?format=json")
    //                .pipe(map((res) => {
    //                  console.log('res ', res);
    //                  console.log('res.json() ', res);
    //                  console.log('parseado  stringify ', JSON.stringify(res));
    //                  let ipVar = res;
    //                  console.log(ipVar)
    //                 //  let num = ipVar.indexOf(":");
    //                 //  let num2 = ipVar.indexOf("\"});");
    //                 //  ipVar = ipVar.slice(num+2,num2);

    //                  return ipVar
    //                }
    //     ));
    //   }
}
