import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { EventsService } from 'src/app/services/events.service';
import { environment } from 'src/environments/environment';
import { Price } from '../landing-page/landing-page.service';
export interface searchestory{
  cur:string;
  typ:string;
  address:string;
  cat:string;
  end:string;
  free:string;
  name:string;
 online:string;
  org:string;
 start:string;
}
 export interface eventSearch{
                     id : string;
                     title : string;
                     summary : string;
                     photo : string;
                     startDate : Date;
                     endDate : Date;
                     category : string;
                     subCategory : string;
                     address : string;
                     price : Price;
                     curSymbol :string;
                     orgName : string,
                     orgFollowers : number,
                     orgId :string,

                     lat : number,
                     lng : number
 }
@Injectable({
  providedIn: 'root'
})
export class BrowserEventService {
  searchForm!: FormGroup;
  inialForm() {
    this.searchForm = this.fb.group({
        name: [''],
        org: [false],
        online: [false],
        start: [''],
        end: [''],
        free: [false],
        paid: [false],
        cat: [0],
        typ: [0],
        cur: [0],
        address: ['Dubai']

    });
    this.searchForm.controls.name.valueChanges.pipe(debounceTime(1000)).subscribe(x => {
               this.search(this.searchForm.value,0)

      })
   
}
  constructor( private fb:FormBuilder,private http:HttpClient,private eventsService: EventsService,) {
    this.inialForm();
   }


   search(formValues: any,count) {

    const name = formValues.name;
    const org = formValues.org;
    const online = formValues.online;
    const start = formValues.start ? new Date(formValues.start) : null;
    const end = formValues.end ? new Date(formValues.end) : null;
    const free = formValues.free;
    const cat = formValues.cat;
    const typ = formValues.typ;
    const cur = formValues.cur;
    const paid = formValues.paid;
    const address = formValues.address;
    let params = new HttpParams();
   // params=params.set('vm',this.searchForm.value)
    if (name) { params = params.set('name', name); }
    if (org) { params = params.set('org', org.toString()); }
    if (online) { params = params.set('online', online.toString()); }
    if (start) { params = params.set('start', start.toISOString()); }
    if (end) { params = params.set('end', end.toISOString()); }
    if (free) { params = params.set('free', free.toString()); }
    if (cat) { params = params.set('cat', cat.toString()); }
    if (typ) { params = params.set('typ', typ.toString()); }
    if (cur) { params = params.set('cur', cur.toString()); }
    if (address) { params = params.set('address', address.toString()); }
    if (paid) { params = params.set('paid', paid.toString()); }
    if (count) { params = params.set('count', count); }
    else{
      params = params.set('count', 0);
    }

  //this.inialForm();

  return  this.http.get<eventSearch[]>(environment.Api + 'd/la/search/text', { params: params });
}

Filter(formValues: any,count){
  const name = formValues.name;
    const org = formValues.org;
    const online = formValues.online;
    const start = formValues.start ? new Date(formValues.start) : null;
    const end = formValues.end ? new Date(formValues.end) : null;
    const free = formValues.free;
    const cat = formValues.cat;
    const typ = formValues.typ;
    const cur = formValues.cur;
    const address = formValues.address;
    const paid = formValues.paid;
    let params = new HttpParams();
   // params=params.set('vm',this.searchForm.value)
    if (name) { params = params.set('name', name); }
    if (org) { params = params.set('org', org.toString()); }
    if (online) { params = params.set('online', online.toString()); }
    if (start) { params = params.set('start', start.toISOString()); }
    if (end) { params = params.set('end', end.toISOString()); }
    if (free) { params = params.set('free', free.toString()); }
    if (cat) { params = params.set('cat', cat.toString()); }
    if (typ) { params = params.set('typ', typ.toString()); }
    if (cur) { params = params.set('cur', cur.toString()); }
    if (address) { params = params.set('address', address.toString()); }
    if (paid) { params = params.set('paid', paid.toString()); }
    if (count) { params = params.set('count', count); }
    else{
      params = params.set('count', 0);
    }



  return  this.http.get<eventSearch[]>(environment.Api + 'd/la/search/basic', { params: params });
}

getlatestEvent(count){
  return  this.http.get(environment.Api + 'd/la/latest/'+count)
}
getUserSearchHistory(){
  return this.http.get<searchestory[]>(environment.Api + 'd/la/u/search');
}
}
