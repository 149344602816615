import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { datetime } from 'rrule';
import { Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { CurrencyDataDto, currencyesDatasingleDto } from 'src/app/Interfaces/attrDto';
import { AttrbuteService } from 'src/app/services/attrbute.service';
import { CurrenciesSrvService } from 'src/app/services/currencies-srv.service';
import { LoaderService } from 'src/app/services/loader.service';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-currency-mangmaent',
  templateUrl: './currency-mangmaent.component.html',
  styleUrls: ['./currency-mangmaent.component.scss'],
  animations: [fadeOut]
})
export class CurrencyMangmaentComponent implements OnInit{
  data:CurrencyDataDto[]=[];
  totalRecourd:number=0;
  typereform!:FormGroup;
  mylocal:currencyesDatasingleDto[]=[]
  Langueses:DropDownItem[]=[];
  index: number=0;
  submitted:boolean=false;
   subs:Subscription[]=[];
  searchText: string="";
  constructor(private activatedRoute:ActivatedRoute,
    private route:Router, private attrSrv:CurrenciesSrvService,public loader : LoaderService, public messageService : MessageService,
    @Inject(DOCUMENT) public document: Document,private conSrv: ConfirmationService,private fb:FormBuilder, private translate:TranslateService){


      this.translate.onLangChange.subscribe(() => {
        this.updateLang();
      });
      this. initialForm(0,"",0,"","",0,false,'en')
  }
  updateLang() {
    this.attrSrv.getAlltype(this.index,this.searchText);
  }

  initialForm(id,code,exRate,name,rateDate,order,status,lan){
    this.typereform=this.fb.group({
         id        :[id],
         code      :[code],
         name      :[name],
         exRate    :[exRate],
         rateDate  :[rateDate],
         status    :[status],
         order     :[order],
         cultureId:[lan]

    })
    }

    changform(e){
      //console.log(e);
       var data=this.mylocal.find(x=>x.cultureId==e.value);
       if(data==undefined){
        this.typereform.controls['code'].setValue("");
        this.typereform.controls['exRate'].setValue(0);
        this.typereform.controls['cultureId'].setValue(e.value);
        this.typereform.controls['name'].setValue("");
      
       }else{
        this.typereform.controls['code'].setValue(data.code);
        this.typereform.controls['exRate'].setValue(data.exRate);
        this.typereform.controls['cultureId'].setValue(data.cultureId);
        this.typereform.controls['name'].setValue(data.name);
     
       }
    }

    checkComplet(id){
 
    
      if(id==this.Langueses.length){
        return true
      }
      else{
        return false;
      }
  
    }

    getEvent(e:LazyLoadEvent){
      var l=e.first/5;
      this.index=l;
      this.attrSrv.getAlltype(l,this.searchText);
    }
  ngOnInit(): void {
    this.loader.showLoader1();
    this.loader.showLoader();
    this.attrSrv.GetAllSystemLang().subscribe(x=>{
      this.Langueses=x;})
    this.attrSrv.getAlltype(this.index,this.searchText);
    this.attrSrv.$getSubject.subscribe(x=>{
      this.data=x.data;
      this.totalRecourd=x.totalAcount
      this.loader.hideLoader();
      this.loader.hideLoader1();

    })
  }
  publish(id){
   this.attrSrv.postattrstauts(id).subscribe(x=>{
 //console.log(x);
 this.attrSrv.getAlltype();
   })
  }
  remove(event, id) {

    this.conSrv.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
     var del=    this.attrSrv.DeletAttr(id).subscribe(x => {
        this.attrSrv.getAlltype();
          this.messageService.add({ severity: 'success', summary: 'Deleted successfully' });
        },(eror)=>{
          this.messageService.add({ severity: 'danger', summary: eror.message });
        });
     
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {
        this.messageService.add({ severity: 'danger', summary: 'you not confirm delete operation' });
      }
    });
  }
  // edit(id){
  //   this.route.navigate([id],{relativeTo: this.activatedRoute});
  // }
  show(){
    this.route.navigate(["0"],{relativeTo: this.activatedRoute});
  }



  onRowEditInit(p:CurrencyDataDto) {
   // console.log(p)

   var k= this.attrSrv.GetAtrrSingle(p.id).subscribe(y=>{
        this.mylocal=y;
        var data=y.find(m=>m.cultureId=='en')
        this.initialForm(data.id,data.code,data.exRate,data.name,new Date(Date.now()) ,data.order,data.status!=0?true:false,data.cultureId)
      })
      this.subs.push(k);
  // this.initialForm(p.attrId,p.parentId,p.name,p.status==0?false:true,p.order,p.cultureId);
  //  console.log(this.typertform.value)
   // this.clonedProducts[product.id] = {...product};
}
onRowEditSave(product){
// console.log(this.typereform.value)


 var data:currencyesDatasingleDto={
  code:    this.typereform.controls['code'].value,
  exRate:  this.typereform.controls['exRate'].value,
  id:       this.typereform.controls['id'].value,
  rateDate:this.typereform.controls['rateDate'].value,
 cultureId:this.typereform.controls['cultureId'].value,
 name:     this.typereform.controls['name'].value,
 order:   this.typereform.controls['order'].value,
 status:  this.typereform.controls['status'].value?1:0}
// console.log(data)
 this.attrSrv.PostnewAttr(data).subscribe(x=>{
  this.attrSrv.getAlltype(this.index,this.searchText);
   
   this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

 },(error)=>{
   this.messageService.add({ severity: 'danger', summary: error });
 });

}


onRowEditCancel(product, ri){
  this. initialForm(0,"",0,"","",0,false,'en')
}
search(e,t){
  this.attrSrv.getAlltype(0,this.searchText);
  
}
}
