import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FormGroup } from '@angular/forms';

interface Ticket {
  name: string;
  qty: number;
  finalPrice: number;
  antaresFees: number;
  feesPaid: number;
  paymentFees: number;
  shippingFees: number;
  deliveryMethods: string;
  attendees:string;

}

interface Addon {
  aname: string;
  aqty: number;
  afinalPrice: number;
  aantaresFees: number;
  afeesPaid: number;
  apaymentFees: number;
  ashippingFees: number;
  adeliveryMethods: string;
}

interface Order {
  orderId: string;
  status: number;
  orderNum: number;
  orderDate: string;
  orderFormAn:string;
  tickets: Ticket[];
  addons: Addon[];
}

interface groupData{
  firstId:string;
  secondId:string;
}


export interface salessummaryDto{
  
        name:string;
       salesType:string;
        qty:number;
       remainQty:number;
       soldItem :number;
       price:number;
       fees :number;
       antaresFees:number;
       paymentfees:number;
       shippingfees:number;
}
@Component({
  selector: 'app-attendees-mangment',
  templateUrl: './attendees-mangment.component.html',
  styleUrls: ['./attendees-mangment.component.scss']
})
export class AttendeesMAngmentComponent implements OnInit  
 {

 
  constructor(private http:HttpClient){
   
  }
 


 




  
   
 

 


  
  
  ngOnInit(): void {
   
  
  }


}
