import { Component, OnDestroy, OnInit,Inject ,ElementRef, ViewChild, AfterViewInit, Renderer2, ViewChildren, QueryList} from '@angular/core';
import { environment } from 'src/environments/environment';

import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { OrdernotisignalRService } from 'src/app/services/ordernotisignal-r.service';
import { OrderConfigDto } from 'src/app/Interfaces/OrderIfo';
import { HttpClient } from '@angular/common/http';
import * as html2pdf from 'html2pdf.js';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import domtoimage from 'dom-to-image';
import { QRCodeComponent } from 'angularx-qrcode';
import { render } from '@fullcalendar/core/preact';
import { DomSanitizer } from '@angular/platform-browser';
import { OrderEmailTemplet, getQrCodeDto } from 'src/app/components/pages/order-event/confirmation/confirmation.component';
import { AddnewAttendeesOfflineService } from '../../addnew-attendees-offline.service';
import { Order, Ticket } from '../../../orders-report/orders-report.component';



@Component({
  selector: 'app-confirmation-offline-order',
  templateUrl: './confirmation-offline-order.component.html',
  styleUrls: ['./confirmation-offline-order.component.scss']
})
export class ConfirmationOfflineOrderComponent {
  @ViewChildren('ticket', { read: ElementRef }) ticketRef: QueryList<ElementRef>;
  // @ViewChild('qrcode', { static: true }) qrcode: QRCodeComponent;
   @ViewChildren('qrcode' ) qrcode: QueryList<QRCodeComponent>;
   // qrCodeComponent: QRCodeComponent;
   // @ViewChild('qrCodeImage', { static: true }) qrCodeImage: ElementRef<HTMLImageElement>;
   // @ViewChild('qrCodeImage2', { static: true }) qrCodeImage2: ElementRef<HTMLImageElement>;
   @ViewChildren('qrCodeImage', { read: ElementRef }) qrCodeImages: QueryList<ElementRef<HTMLImageElement>>;
 @ViewChildren('qrCodeImage2', { read: ElementRef }) qrCodeImages2: QueryList<ElementRef<HTMLImageElement>>;
  // @ViewChild('ticket') ticketRef: ElementRef;
  // @ViewChild('qrcode', { static: true }) qrcode: QRCodeComponent;
  // // qrCodeComponent: QRCodeComponent;
  // @ViewChild('qrCodeImage', { static: true }) qrCodeImage: ElementRef<HTMLImageElement>;
  // @ViewChild('qrCodeImage2', { static: true }) qrCodeImage2: ElementRef<HTMLImageElement>;
  url = environment.Api;
  mywebsit=environment.Website
  id: string;
  data: OrderConfigDto = {};
  imageUrl:string="";
  userEmail:string="";
  message:string="";
  Eventid: string='';
  eventName: string='';
  total: number=0;
  cur: string='$';
  orderdate: Date=new Date(Date.now());
  imageUrltest: any;
  DataofQr:string[]=[];
  emailData : OrderEmailTemplet
  orderDataforQr: any;
  constructor(private http: HttpClient,   private messageService: MessageService,
    @Inject(DOCUMENT) public document: Document,
    private activatedRoute: ActivatedRoute,
    private orderSrv:OrdernotisignalRService, private datePipe: DatePipe,private router:Router,private rendr:Renderer2,private dom:DomSanitizer, public srv:AddnewAttendeesOfflineService) {

  }

  GetDataforQrCode(id:string){
    return this.http.get<Order[]>(environment.Api+'d/or/g/e/or/qr/d/'+id)
  } 
  ngAfterViewInit(): void {
  
  }
  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('OrderId');
    this.Eventid = this.activatedRoute.parent.parent.parent.snapshot.paramMap.get('id');
this.srv.$getSubject4.subscribe(x=>{
  this.userEmail=x
  console.log(x)
})

console.log(this.Eventid)
    this.GetConfigInfo( this.Eventid ).subscribe(x=>{
      this.data=x;
    });
    this.GetOrderQrCode(this.id).subscribe(x=>{
         
  

    //this.imageUrl= `data:image/png;base64,${x.imageff}`;
    this.eventName=x.eventName;
    this.total=x.price;
    this.cur=x.currency;
    this.orderdate=x.date;
    // this.DataofQr=this.mywebsit+'your-order/'+this.id
    
    this.GetDataforQrCode(this.id).subscribe((y) => {
      console.log(y);

      this.orderDataforQr=y
      var l = y[0].tickets;
      y[0].tickets.forEach((item) => {
          var attendees = this.GetAttendess(item);
          let stringRepresentation = '';

          // Step 1: Iterate through the list of objects
          attendees.forEach((object, index) => {
              stringRepresentation += `${index + 1}-:`;
              // Step 2: Extract the properties of each object
              for (const prop in object) {
                  if (object.hasOwnProperty(prop)) {
                      // Step 3: Build the string representation
                      stringRepresentation += ` ${prop}: ${object[prop]} `;
                  }
              }
          });
          console.log(attendees);
          var t =
              '   Order #: ' +
              y[0].orderNum +
              ' \n   Event name: ' +
              x.eventName +
              '\n' +
              '  Order in :' +
              this.getDateOntomeZoneformat(y[0].orderDate, 'full') +
              '\n   ticket type :' +
              item.name +
              '\n    Total Cost : ' +
              item.finalPrice +
              '' +
              x.currency +
              '\n    all attendees is :\n  ' +
              stringRepresentation +
              '\n' +
              '  for more  infornmation go to ' +
              this.mywebsit +
              'your-order/' +
              this.id;
          console.log(t);
          this.DataofQr.push(t);
          console.log(this.DataofQr);
      });
  });
        // const base64String = x;
        // this.imageUrl = `data:image/png;base64,${base64String}`;
    })
  }

  downloadImage() {
    const filename = 'ticket.png'; // replace with your desired filename
    this.http.get(this.imageUrl, { responseType: 'blob' }).subscribe(blob => {
      saveAs(blob, filename);
    });
  }


  ngOnDestroy(): void {

  }
  GoTohomePage(){
    // this.router.navigate( [] );
    this.router.navigate(['new-Attendee'], { relativeTo: this.activatedRoute.parent.parent });

   }
 getUserwaitList(){
  return  this.http.get(environment.Api+'d/or/g/u/Waitlist'+this.id)
 }
 GetConfigInfo(Id:string){
  
 return this.http.get<OrderConfigDto>(environment.Api + 'd/or/g/e/o/co/' + Id);
}
GetOrderQrCode(id:string){
 return this.http.get<getQrCodeDto>(environment.Api + 'd/or/g/e/or/qr/' + id)
}

PostEmail(data:OrderEmailTemplet){
  return this.http.post(environment.Api + 'd/or/g/o/email',data)
}
// exportToPDF() {
//   // const ticketElement = this.ticketRef.nativeElement;


//   // html2pdf().from(ticketElement).save('ticket.pdf');

//   const ticketElement = this.ticketRef.nativeElement;
//   const computedStyles = window.getComputedStyle(ticketElement);

//   if (computedStyles.display === 'none') {
//     ticketElement.classList.remove('d-none');
//     ticketElement.classList.remove('d-md-none');
//   }

//   const tempElement = document.createElement('div');
//   tempElement.appendChild(ticketElement.cloneNode(true));

//   html2pdf().from(tempElement).save('ticket.pdf');




//   if (computedStyles.display === 'block') {
//     ticketElement.classList.add('d-none');
//     ticketElement.classList.add('d-md-none');
//   }
  
// }


exportToPDF(i) {
  // const ticketElement = this.ticketRef.nativeElement;

  var ticket = this.ticketRef.toArray();    
  // html2pdf().from(ticketElement).save('ticket.pdf');

  const ticketElement = ticket[i].nativeElement;
  const computedStyles = window.getComputedStyle(ticketElement);

  if (computedStyles.display === 'none') {
    ticketElement.classList.remove('d-none');
    ticketElement.classList.remove('d-md-none');
  }

  const tempElement = document.createElement('div');
  tempElement.appendChild(ticketElement.cloneNode(true));

  html2pdf().from(tempElement).save('ticket.pdf');




  if (computedStyles.display === 'block') {
    ticketElement.classList.add('d-none');
    ticketElement.classList.add('d-md-none');
  }
  
}

geturl(e,i){
  console.log(e)
  var qrCodeImageElements = this.qrCodeImages.toArray();
  var qrcoder=this.qrcode.toArray();
  var qrCodeImageElements2 = this.qrCodeImages2.toArray();
  var qrCodeImageElement = qrCodeImageElements[i].nativeElement;
  var qrCodeImageElement2 = qrCodeImageElements2[i].nativeElement;

  //const qrCodeImageElement = this.qrCodeImage.nativeElement;

  const canvas = qrcoder[i].qrcElement.nativeElement.querySelector('canvas');
  qrCodeImageElement.src = canvas.toDataURL();
  qrCodeImageElement2.src = canvas.toDataURL();
  this.imageUrltest=qrCodeImageElement.src;
  this.emailData={
    id:this.id,
    ticketId:this.orderDataforQr[0].tickets[i].id,
    qrImage:this.imageUrltest,
    email:this.userEmail
    
  };
  console.log( this.emailData)
  this.PostEmail(this.emailData).subscribe(x=>{
    console.log(x)
     })

  
}


  // geturl(e,i){
  //   console.log(e)

  //   var qrCodeImageElement = this.qrCodeImage.nativeElement;
  //   var qrCodeImageElement2 = this.qrCodeImage2.nativeElement;

  //   //const qrCodeImageElement = this.qrCodeImage.nativeElement;

  //   const canvas = this.qrcode.qrcElement.nativeElement.querySelector('canvas');
  //   qrCodeImageElement.src = canvas.toDataURL();
  //   qrCodeImageElement2.src = canvas.toDataURL();
  //   this.imageUrltest=qrCodeImageElement.src;


  //   this.emailData={
  //     id:this.id,
  //     qrImage:this.imageUrltest,
  //     email:this.userEmail
      
  //   };
  //   console.log( this.emailData)
  //   this.PostEmail(this.emailData).subscribe(x=>{
  //     console.log(x)
  //      })

    
  // }

  convertImageToBase64(url: string) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.imageUrltest = reader.result as string;console.log( this.imageUrltest)
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
getDateOntomeZoneformat(d,x){
  let t = new Date(Date.now()).getTimezoneOffset();
  let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
  var l="";
  switch(x){
    case 'full':if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
      l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');

  }else{
     l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');

  }
  break;
  case 'date': 
    l= this.datePipe.transform(new Date(MyDate), 'M/d/yy ');


  break;
  case 'time':l=this.datePipe.transform(new Date(MyDate), ' hh:mm a');
  break;
  }
  return l




 // return new Date(MyDate).toLocaleString();
}

GetAttendess(item: Ticket) {
  //console.log(item)
  var orderformData = JSON.parse(item.attendees);
 // console.log( orderformData)
 // console.log(orderformData)
  var filteredPropertyNames = Object.keys(
      orderformData.order[0].Answer
  ).filter((key) => orderformData.order[0].Answer[key] !== null);
//  console.log(filteredPropertyNames);
  var resOrderForm = [];
  orderformData.order.forEach((x) => {
      var l = { };
      this.AddProperties(filteredPropertyNames, l, x.Answer);
      resOrderForm.push(l);
  });
  return resOrderForm;
}

AddProperties(prop, object, item): void {
  return prop.reduce((obj, propertyName) => {
      obj[propertyName] = this.GetObjectPropertyValue(propertyName, item);
      return obj;
  }, object);
}
GetObjectPropertyValue(propertyName: string, item): any {
  return item[propertyName];
}
}
