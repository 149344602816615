import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit,Inject } from '@angular/core';
import { SyslangformsrvService } from './syslangformsrv.service';
import { SystemlangServService } from '../system-languegesmang/systemlang-serv.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { MessageService } from 'primeng/api';
import { DOCUMENT } from '@angular/common';
import { systemlangDto } from 'src/app/Interfaces/attrDto';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-system-langueges-form',
  templateUrl: './system-langueges-form.component.html',
  styleUrls: ['./system-langueges-form.component.scss'],
  animations: [fadeOut]
})
export class SystemLanguegesFormComponent implements OnInit {
  submitted:boolean=false;

  typeId: string;

  constructor(public srv:SyslangformsrvService,private attrsrv:SystemlangServService, private route:Router,private actvitedRoute:ActivatedRoute,
    @Inject(DOCUMENT) public document: Document,public loader : LoaderService,private messageService: MessageService){}
  ngOnInit(): void {
   this.loader.showLoader();
    this.loader.showLoader1();
    var param= this.actvitedRoute.paramMap.subscribe((param)=>{
      this.typeId=param.get('id');
       //console.log(this.typeId);
    
       if(this.typeId=="0"){
        this.srv.initializForm("","","",false,false)
        this.loader.hideLoader();
        this.loader.hideLoader1();
       }else{
        this.attrsrv.GetAtrrSingle(this.typeId).subscribe(y=>{
      
      
          this.srv.initializForm(y.cultureId,y.name,y.uiCulture,y.status==0?true:false,y.priority==0?true:false)
          this.loader.hideLoader();
          this.loader.hideLoader1();
        })
       }
      
     



    });
  }
  sumbit(){
  
   // console.log(this.srv.myForm.value)
    var data:systemlangDto={
      name:this.srv.myForm.controls['name'].value,
      priority:this.srv.myForm.controls['priority'].value?0:1,
      status:this.srv.myForm.controls['status'].value?0:1,
      uiCulture:this.srv.myForm.controls['uiCulture'].value,
      cultureId:this.srv.myForm.controls['cultureId'].value,
      
}
   // console.log(data)
    this.attrsrv.PostnewAttr(data).subscribe(x=>{

      
      this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
      this.route.navigate(['languages'], { relativeTo: this.actvitedRoute.parent.parent});

    },(error)=>{
      this.messageService.add({ severity: 'danger', summary: error });
    });

  }



  cancel(){
    this.srv.clear();
    this.route.navigate(['languages'], { relativeTo: this.actvitedRoute.parent.parent});
  }

}
