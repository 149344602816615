import { Component,Inject } from '@angular/core';
import { DropDownItem, Sort } from 'src/app/Interfaces/DropDownItem';
import { EventCard, LandingPageService } from '../../../../pages/landing-page/landing-page.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from 'src/app/services/auth.service';
import { IntersetService } from 'src/app/services/interset.service';
import { UserActionsService } from '../../../../../services/user-actions.service';
import { SearchbyCatService } from '../searchby-cat.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { end, start } from '@popperjs/core';
import { EventsService } from 'src/app/services/events.service';
import { BrowserEventService } from 'src/app/components/pages/browse-events/browser-event.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
 

export interface Category{
    name?:string;
    id?:string;
    subCategory?:Category[];
}
@Component({
    selector: 'app-features-filter',
    templateUrl: './features-filter.component.html',
    styleUrls: ['./features-filter.component.scss']
})
export class FeaturesFilterComponent {
    products: any[] = []
 
    // responsiveOptions = [
    //     {
    //         breakpoint: '1024px',
    //         numVisible: 3,
    //         numScroll: 3
    //     },
    //     {
    //         breakpoint: '768px',
    //         numVisible: 2,
    //         numScroll: 2
    //     },
    //     {
    //         breakpoint: '560px',
    //         numVisible: 1,
    //         numScroll: 1
    //     }
    // ];
    responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '840px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    SelectedCategory:Category={}
    id:string="";
    subId: string;
    types: DropDownItem[]=[];
    constructor(public int: IntersetService,
        public auth: AuthService,
        private loader: LoaderService,
        private srv: LandingPageService,
        private router:Router,
        private ActRout:ActivatedRoute,
        private  eventsService:EventsService,
        

        public ua: UserActionsService,
        private SrvSearch:SearchbyCatService,
        private BrowsSRv:BrowserEventService,
        public translate: TranslateService,
    @Inject(DOCUMENT) public document: Document,
    ) { 

        this.translate.onLangChange.subscribe(() => {
            this.updateTranslations();});
    }
    updateTranslations() {
        var atr2=  this.eventsService.GetAttributes(10101).subscribe(x => this.types = x);
        this.SrvSearch.getCatandSub(this.id).subscribe(x=>{this.SelectedCategory=x;})
        this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false,true)).subscribe(x=>{
            this.resently=x;
           })
           this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false,false,false,new Date(Date.now()).toISOString(),new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDay()+7)).toISOString())).subscribe(x=>{
               this.WeekluEvents=x;
              })
              this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false,false,false,new Date(Date.now()).toISOString(),"")).subscribe(x=>{
               this.todayEvents=x;
              })
              this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false ,false,true)).subscribe(x=>{
               this.OnlineEvents=x;
              })
              this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false,false,false,'','',true)).subscribe(x=>{
               this.FreeEvents=x;
              })
    }
    url = environment.Api;
    haveInterests = false;
    resently: EventCard[] = [];
    WeekluEvents: EventCard[] = [];
    OnlineEvents: EventCard[] = [];
    FreeEvents: EventCard[] = [];
    todayEvents: EventCard[] = [];
    data2: EventCard[] = [];
     

   
   
    ngOnInit(): void {
        var atr2=  this.eventsService.GetAttributes(10101).subscribe(x => this.types = x);

        var param= this.ActRout.paramMap.subscribe((param)=>{
            this.id=param.get('id');
           this.subId =param.get('SubId');
        
       

        //   console.log(this.subId)
         this.SrvSearch.getCatandSub(this.id).subscribe(x=>{this.SelectedCategory=x;})
        this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false,true)).subscribe(x=>{
         this.resently=x;
        })
        this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false,false,false,new Date(Date.now()).toISOString(),new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDay()+7)).toISOString())).subscribe(x=>{
            this.WeekluEvents=x;
           })
           this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false,false,false,new Date(Date.now()).toISOString(),"")).subscribe(x=>{
            this.todayEvents=x;
           })
           this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false ,false,true)).subscribe(x=>{
            this.OnlineEvents=x;
           })
           this.SrvSearch.getEventBySerach(this.setParamForearch(this.subId!=null?this.subId:this.id,false,false,false,'','',true)).subscribe(x=>{
            this.FreeEvents=x;
           })

           this.loader.showLoader();
        this.int.getUserInterests(this.auth.securityObject.id);
        this.int.$getUserInterests .subscribe(x => {
                if (x.length > 0) {
                    this.haveInterests = true;
                }
            }
            );
        this.ua.getLikedEventList().subscribe(x => {
            this.ua.LikedEvents = x as string[];

        });
        this.srv.getLandingPageEvents().subscribe(x => { this.products = x; this.loader.hideLoader(); //console.log(x);
     }, err => { }, () => { this.loader.hideLoader(); });
        })
        
       // this.srv.getMoreEvent(6, this.Count).subscribe(x => { this.data2.push(...x); this.loader.hideLoader(); console.log(x); }, err => { }, () => { this.loader.hideLoader(); });
    }
 
    // getDateOntomeZone(d: Date) {
    //     let t = new Date(Date.now()).getTimezoneOffset();
    //     let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    //     return new Date(MyDate).toLocaleString();
    // }

    Like(id: string) {
        this.ua.postLike(id).subscribe(x => {
            this.ua.getLikedEventList().subscribe(y => {
                this.ua.LikedEvents = y as string[];
            });
        });
    }

    setParamForearch( cat:string,org?:boolean,pubular?:boolean,online?:boolean,start?:string,end?:string,free?:boolean){
        let params = new HttpParams();
        // params=params.set('vm',this.searchForm.value)
   
         if (org) { params = params.set('org', org.toString()); }
         if (online) { params = params.set('online', online.toString()); }
         if (start) { params = params.set('start', new Date(start).toISOString()); }
         if (end) { params = params.set('end',  new Date(start).toISOString()); }
         if (free) { params = params.set('free', free.toString()); }
         if (cat) { params = params.set('cat', cat.toString()); }
         if (pubular) { params = params.set('pub', pubular.toString()); }
         return params;
        
    }
    gotosearch(x){
        // console.log(x)
        this.BrowsSRv.searchForm.controls['typ'].setValue(x)
       // console.log( this.BrowsSRv.searchForm.value)
        this.router.navigate(['/search/filter'])

    }


}
