import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category } from './features-filter/features-filter.component';
import { eventSearch } from 'src/app/components/pages/browse-events/browser-event.service';
import { EventCard } from 'src/app/components/pages/landing-page/landing-page.service';

@Injectable({
  providedIn: 'root'
})
export class SearchbyCatService {

  
  constructor(private http:HttpClient) { }


  getCatandSub(id:string){
    return this.http.get<Category>(environment.Api+'d/a/g/c/all/'+id)
  }
  getEventBySerach(param:HttpParams){
    return  this.http.get<EventCard[]>(environment.Api + 'd/la/search/cat', { params: param });
  }

}
