import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { EventPageDto } from './event-page.component';
import { EventCard } from '../landing-page/landing-page.service';
@Injectable({
  providedIn: 'root'
})
export class EventDetailsPageService {

    url = env.Api;
  constructor(private http: HttpClient) { }

  getEventDetails(eventId: string){
    return this.http.get<EventPageDto>(this.url + 'd/e/g/details/'+eventId);
  }
  getOrgEvents(x,except,size,num){
    return this.http.get<EventCard[]>(this.url + `d/e/orgevents/${x}/${except}/${size}/${num}`);
  }
  getOrgsubEvents(x,except,size,num){
    return this.http.get<EventCard[]>(this.url + `d/e/g/sub/${x}/${except}/${size}/${num}`);
  }
  postPageView(x:string){
    var data= {eventId:x};
    return this.http.post<number>(this.url+"d/e/c/pv",data);
  }

}
