
<div   class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'EditAttendeeinformation'|translate}}</h1>
            </div>
        <!-- <div class="card-header text-center bg-primary rounded-top-23">
            <h1 class="text-white my-1">Date & time</h1>
        </div> -->
        <div  class="card-body">
            <div class="card-body p-0">
              

               <div class="row">
                <div class="col-12">
                
                <app-form-for-edit-attendes [jsonFormData]="formData"  [FormValue]="value"  (result)="GetResult($event) "
                    (IsNotValid)="getValud($event)" ></app-form-for-edit-attendes>
            </div>
               </div>
               
            </div>
        </div>
    </div>

    <div class="row"  >
        <div class="col-12 d-flex justify-content-lg-center justify-content-center">
<!--                <div class="banner-btn rounded-pill mx-2">-->
<!--                    <button class="rounded-pill cancel-btn" pTooltip="Cancel and dismiss changes" showDelay="1000" tooltipPosition="left">-->
<!--                        <i class="pi pi-angle-left mt-1" ></i> Cancel<span></span>-->
<!--                    </button>-->
<!--                </div>-->
            <div class=" d-flex justify-content-center gap-1">
                <button [disabled]="isSubmitting" (click)="submit(1)" type="submit" class="rounded-pill save-btn {{isSubmitting? 'bg-disabled' : ''}}" pTooltip="Save changes" showDelay="1000" tooltipPosition="right">
                    <i  *ngIf="isSubmitting" class="fas fa-spinner fa-spin"></i>
                    {{'SavBtn'|translate}}  <span></span>
                </button>
              
            </div>
        </div>
    </div>
</div>
<!-- <div class="p-input-filled col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
            <label class="b-5 ms-2" for="RecTicketConf">Visibilty</label>
            <p-dropdown inputId="DelveryM" [styleClass]="'w-100'"  [autoDisplayFirst]="false"
                [options]="displayOption" optionLabel="name" optionValue="code"
                [ngClass]="{'ng-invalid ng-dirty':eventDateForm.get('hidden')?.touched && eventDateForm.get('hidden')?.invalid}" (onChange)="onvisiblityChange($event)"></p-dropdown>
        </div> -->



