<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'ReportsSalesbytickettypeMainTitle'|translate}}</h1>
        </div>



        <p-toast></p-toast>
        <div class="row ms-3 ">
            <div class="col-sm-12 col-md-12 mb-3">
                <div class="d-flex  flex-column justify-content-start">
                    <h4 class="fw-bold  mt-2">{{'SalesbytickettypereportTitle001'|translate}}</h4>
                    <p>{{'SalesbytickettypereportTitle002'|translate}}</p>
                </div>
            </div>
        </div>
        <div class="mt-4 ">



            <div class="mx-3">
                <div class="row">
                    <div  class="p-input-filled col-12  col-md-4 pe-2">
                        <span class="p-float-label">

                            <p-multiSelect pTooltip="{{'Select your orgnizer that you want to get sales summary for there events'|translate}}" showDelay="1000"
                                tooltipPosition="top" [options]="organizers" [styleClass]="'w-100'"
                                [optionLabel]="'name'" display="chip" optionValue="id" selectedItemsLabel="{0} columns selected" inputId="ogrId" (onChange)="getevent($event)"
                               ></p-multiSelect>
                            <label class="b-5 ms-2" for="ogrId">{{'Orgnizer'|translate}}</label>
                        </span>
                    </div>
                    <div  class="p-input-filled col-12  col-md-4 pe-2">
                        <span class="p-float-label">

                            <p-multiSelect pTooltip="{{'Search and select eventthat you want to get sales summary for it'|translate}}" showDelay="1000"
                                tooltipPosition="top" [options]="events" [styleClass]="'w-100'"
                                [optionLabel]="'name'" optionValue="id" display="chip" selectedItemsLabel="{0} columns selected" inputId="eventId" (onChange)="getReport($event)"
                                ></p-multiSelect>
                            <label class="b-5 ms-2" for="eventId">{{'Events'|translate}}</label>
                        </span>
                    </div>
               
                </div>
                <div class="row">

                    <div  class="p-input-filled col-12  col-md-4 pe-2">
                        <span class="p-float-label">

                            <p-multiSelect pTooltip="{{'Search and select ticket type you want to get sales summary for it'|translate}}" showDelay="1000"
                                tooltipPosition="top" [options]="Dropdowntickets" [styleClass]="'w-100'"
                                 display="chip" selectedItemsLabel="{0} columns selected" inputId="eventId" (onChange)="getReportfilterd($event)"
                                ></p-multiSelect>
                            <label class="b-5 ms-2" for="eventId">{{'ReportTickettypeSelectLabel'|translate}}</label>
                        </span>
                    </div>
                    <div class=" p-input-filled  col-12  col-md-4 pe-2">
                      
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input #tickfir pInputText type="text"  class="form-control " (input)="Getfilter($event,tickfir.value)"
                                    placeholder="{{'.First name, last name or email address...'|translate}}" />
                            </span>
                        
                       
                  
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 ms-md-4  mb-sm-0  mb-2">
                        <button pButton pRipple class="rounded-pill add-new-btn  m-2 ms-2"
                            (click)="menu2.toggle($event)"> {{ReportType|translate}}<span></span>
                        </button>
                        <p-menu #menu2 appendTo="body" [popup]="true" [model]="reportType"></p-menu>

                       
                    
                        
                        <button  pButton pRipple class="rounded-pill add-new-btn ms-2 m-2"
                            (click)="menu4.toggle($event)">
                            {{filtrDate|translate}}<span></span>
                        </button>
                        <p-menu #menu4 appendTo="body" [popup]="true" [model]="dateFilter"></p-menu>

                      
                    </div>
                </div>

                <div calss="row">
                    <div class="col-12 ms-md-4 mb-sm-0  mb-2">
                        <div class="row ">
                            <div class="p-input-filled  col-sm-12 col-md-4 pe-2" *ngIf="cusytomrang">
                                <span class="p-float-label">
                                    <p-calendar #cli touchUI="true" selectionMode="range" [(ngModel)]="rangeDates"
                                        pTooltip="{{'Pickdateattendees'|translate}}" showDelay="1000"
                                        tooltipPosition="bottom" appendTo="body" inputId="salesStart"
                                        [styleClass]="'form-control m-0 p-0 '" [inputStyleClass]="'form-control'"
                                        (onClose)="filterdata($event)">
                                    </p-calendar>
                                    <label class="form-label font-size-14" for="salesStart">
                                        {{'SrhPgFltrTitl001Inpt001'|translate}}</label>
                                </span>
                            </div>
                           
                        </div>
    
    
                    </div>
                </div>


                
             
                
                <div class="d-sm-flex d-block align-items-center justify-content-sm-between ">
                    <div class="  ms-md-4 mb-sm-0  mb-2 ">



                        <button  pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" >
                            {{'ExporttoExcel'|translate}}<span></span>
                        </button>
                        <button  pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2">
                            {{'exporttoCSV'|translate}}<span></span>
                        </button>
                        <button  pButton pRipple
                            class="rounded-pill add-new-btn ms-2 m-2" >
                            {{'ExporttoExcel'|translate}}<span></span>
                        </button>
                       
                    
                    </div>

                </div>
            </div>
            <div>
                <p-table #dataTable2 [value]="datatable"  
                    [totalRecords]="datatable.length" [paginator]="datatable.length>5"  [rows]="5"  sortField="ticketType" sortMode="single" dataKey=""
                      [globalFilterFields]="[dataforfilter]">
                    <ng-template pTemplate="header">
                        <tr >
                            <th >{{'SalesItem'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-c let-rowIndex="rowIndex">
                        <tr>
                         <td>
                            <app-global-report-desgin [item]="c" [cols]="allColumn" [AttendeesCol]="_DynmicOrderForm"
                            (update)="edit($event)" (Canceld)="canseld($event)"></app-global-report-desgin>
                         </td>
                         


                        </tr>
                    </ng-template>
                        <ng-template pTemplate="summary">
                            <div class="flex align-items-center justify-content-between">
                                {{'TcktTblFooter01'|translate}} {{SalesSumrepData ? SalesSumrepData.length : 0 }} .
                            </div>
                        </ng-template>
                </p-table>
            </div>
           

        </div>



    </div>
</div>

