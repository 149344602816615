<form [formGroup]="srv.searchForm">
    <div class="container-fluid p-0 mt-3 mb-3 ">
        <div class="row ">
            <div class="col-lg-12 col-12 p-0 m-0">
                <div class="row">
                    <!-- Filter-Section-Start -->
                    <div class="col-lg-4 col-md-4 d-block  position-sticky p-0 m-0 ">

                        <div class="filter-title top d-flex  rounded-custom ">
                            <div class="row d-flex col-12 ">

                                <div class="p-input-filled col-lg-10 col-12">
                                    <span class="p-input-icon-left w-100">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" formControlName="name" class="form-control"
                                            placeholder="Search for event" />
                                    </span>
                                </div>
                                <div class="col-lg-2 col-12  d-flex justify-content-lg-end justify-content-md-start justify-content-center  align-items-end">
                                    <div class=" d-flex justify-content-end algin-items-end">
                                        <p-button type="button" icon="pi pi-search" styleClass="p-button-rounded p-button-primary p-button-outlined" (onClick)="gotoserachPage()"></p-button>

                                    </div>
                                </div>
                           <div class="col-md-10 col-12  d-flex flex-column ">
                            <!-- <div class="p-input-filled mb-2 ">
                                <span class="p-input-icon-left w-100">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" formControlName="name" class="form-control"
                                        placeholder="Search..." />
                                </span>
                            </div> -->
                            <div class=" w-100 text-break" *ngIf="!UpdateLocation">

                                <span class=" text-primary d-block cursor-pointer" (click)="changLocal($event)">
                                    <i class="fa-sharp fa-solid fa-location-dot text-primary fs-1 pe-2"></i>
                                    {{userLocation==""?"Search by location":userLocation}}
                                </span>

                            </div>

                            <div class="d-flex  align-items-center"  *ngIf="UpdateLocation">
                                <span class=" text-primary d-block"  >
                                    <i class="fa-sharp fa-solid fa-location-dot text-primary fs-1 pe-2"></i>
                                </span>
                                <div class="p-input-filled w-100"  >

                                    <AutocompleteComponent (problem)="getProblem($event)"   (setAddress)="getAddress($event)" pTooltip="Enter to search with google map" showDelay="1000" tooltipPosition="top"  addressType="geocode" [addressValue]=""   ></AutocompleteComponent>

                                    <!-- <input pInputText type="text" formControlName="address" class="form-control"
                                        placeholder="location..." /> -->
                                </div>
                            </div>

                            <div class="d-flex   flex-column"  *ngIf="UpdateLocation">
                                <span class=" text-primary ps-sm-4 ps-3 ms-3  cursor-pointer d-block font-size-26 " (click)="getcurrentLocation()" >
                                    <i class="fa-solid fa-location-crosshairs text-primary pe-2" > Use current location</i>
                                </span>

                            </div>

                           </div>
                           <!-- <div class="col-md-2 col-12  d-flex justify-content-end align-items-end">
                            <div class=" d-flex justify-content-end algin-items-end">
                                <p-button type="button" icon="pi pi-search" styleClass="p-button-rounded p-button-primary p-button-outlined" (onClick)="gotoserachPage()"></p-button>

                            </div>
                           </div> -->







                            </div>
                        </div>
                        <div class="filter-base ">

                            <div class="">
                                <div class="d-flex flex-wrap w-100">
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="online"
                                            [value]="true"  (click)="GetDate('online')" />
                                        <label class="btn btn-outline-primary mb-2" for="online">Online</label>
                                    </div>
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check col" id="today" name="date"
                                            (click)="GetDate('today')" [value]="false" />
                                        <label class="btn btn-outline-primary mb-2" for="today">Today</label>
                                    </div>
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="tomorrow" name="date"
                                            (click)="GetDate('tomorrow')" [value]="false" />
                                        <label class="btn btn-outline-primary mb-2" for="tomorrow">Tomorrow</label>
                                    </div>


                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="thisWeek" name="date"
                                            [value]="false" (click)="GetDate('thisWeek')" />
                                        <label class="btn btn-outline-primary mb-2" for="thisWeek">This week</label>
                                    </div>

                                    <div class="pe-1">
                                        <input type="radio" class="btn-check " id="price"
                                            [value]="true" (click)="GetDate('free')"/>
                                        <label class="btn btn-outline-primary mb-2" for="price">Free</label>
                                    </div>


                                </div>



                            </div>

                            <!-- <div class="">
                                <div class="d-flex flex-wrap w-100">
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="online" formControlName="online"
                                            [value]="true" />
                                        <label class="btn btn-outline-primary mb-2" for="online">Online</label>
                                    </div>
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check col" id="today" name="date"
                                            (click)="GetDate('today')" [value]="false" />
                                        <label class="btn btn-outline-primary mb-2" for="today">Today</label>
                                    </div>
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="tomorrow" name="date"
                                            (click)="GetDate('tomorrow')" [value]="false" />
                                        <label class="btn btn-outline-primary mb-2" for="tomorrow">Tomorrow</label>
                                    </div>


                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="thisWeek" name="date"
                                            [value]="false" (click)="GetDate('thisWeek')" />
                                        <label class="btn btn-outline-primary mb-2" for="thisWeek">This week</label>
                                    </div>
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="thisMonth" name="date"
                                            [value]="false"  (click)="GetDate('thisMonth')"/>
                                        <label class="btn btn-outline-primary mb-2" for="thisMonth">This
                                            month</label>
                                    </div>
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="nextMonth" name="date"
                                            [value]="false"  (click)="GetDate('nextMonth')" />
                                        <label class="btn btn-outline-primary mb-2" for="nextMonth">Next
                                            month</label>
                                    </div>
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check " id="price" formControlName="free"
                                            [value]="true" />
                                        <label class="btn btn-outline-primary mb-2" for="price">Free</label>
                                    </div>
                                    <div class="pe-1">
                                        <input type="radio" class="btn-check" id="price1" formControlName="free"
                                            [value]="false" />
                                        <label class="btn btn-outline-primary mb-2" for="price1">Paid</label>
                                    </div>

                                </div>

                            </div> -->
                            <!-- <h6 class="fw-bold mt-3">Category</h6>
                            <div class="row">
                                <div class="p-input-filled col-12  mb-3 pe-4">
                                    <span class="p-float-label">
                                        <p-dropdown inputId="TicketType4" [autoDisplayFirst]="false" [filter]="true"
                                            filterBy="name" [showClear]="true" id="event-category"
                                            appendTo="body"
                                            [styleClass]="'w-100'" pTooltip="Select the category of your event"
                                            showDelay="1000" tooltipPosition="bottom" [styleClass]="'w-100'"
                                            formControlName="cat" [options]="categories" optionLabel="name"
                                            optionValue="Id"></p-dropdown>
                                        <label class="form-label font-size-14" for="event-category">Category</label>
                                    </span>

                                </div>

                            </div>


                            <h6 class="fw-bold ">Type</h6>
                            <div class="row ">
                                <div class="p-input-filled col-12 mb-3  pe-4">
                                    <span class="p-float-label">
                                        <p-dropdown inputId="TicketType1" [filter]="true" filterBy="name"
                                            [showClear]="true" pTooltip="Select the type of your event" showDelay="1000"
                                            tooltipPosition="bottom" id="event-type" [styleClass]="'w-100'"
                                            appendTo="body"
                                            formControlName="typ" [autoDisplayFirst]="false" [options]="types"
                                            optionLabel="name" optionValue="Id" #typeId></p-dropdown>
                                        <label class="form-label font-size-14" for="event-type">Type</label>
                                    </span>


                                </div>
                            </div> -->

                            <div class=" ">
                                <h4 class="fw-bold mt-3 px-3">Resent search</h4>
                                <div class="card  border border-primary history-base ">
                                <div class="py-1 " [ngClass]="{'card-base':userSearch.length>3}">
                                    <ng-container  *ngFor=" let item of userSearch" >
                                        <div   *ngIf="item.name!=null||(item.address!='')"
                                        class="card-2-modified p-0 mx-2 mb-3 "  >
                                        <div class="row d-flex    revers-cols col mb-2 pt-sm-3 " [ngClass]="{'justify-content-between ':item.name!=null&&item.address!='' ,
                                             'justify-content-start ':!(item.name!=null&&(item.address!=''))}">
                                            
                                            <div class="col-lg col-md-12 col  text-break" *ngIf="item.name!=null" >
                                                <a (click)="gotosearch(item)" >
                                                    <span class=" text-primary d-block " >

                                                        <i class="fa-light fa-clock-rotate-left text-primary pe-2"></i>
                                                        <span class=" text-black">
    
                                                      {{item.name}}
                                                        </span>
                                                    </span>
                                                </a>
                                               
                                            </div>
                                            <div class="col-lg col-md-12 col " *ngIf="item.address!=''"  [ngClass]="{'text-end  text-break':item.name!=null }">
                                              <a (click)="gotosearch(item)">
                                                <span class=" text-primary d-block " >
                                               
                                                    <i class="fa-regular fa-location-dot text-primary pe-2"></i>
                                                    <span class=" text-black">

                                                  {{item.online?"Online":item.address}}
                                                    </span>
                                                </span>
                                              </a>
                                              
                                            
                                                <!-- <span class=" text-primary d-block " *ngIf="item.address!=''&&item.online">
                                               
                                                    <i class="fa-regular fa-location-dot text-primary pe-2"></i>
                                                    <span class=" text-black">

                                                  Online
                                                    </span>
                                                </span> -->
                                            </div>

                                        </div>
                                    </div>
                                    </ng-container>

                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                        <div class="pagination-area text-center">

                                            <p-button label="See more" styleClass="p-button-link" (click)="GetMoreData()"></p-button>
                                        </div>
                                    </div>
                                    </div>


                                </div>
                            </div>



                            </div>
                            <!-- <h6 class="fw-bold ">Currency</h6>

                            <div class="row">

                                <div class="p-input-filled col-12 mb-3 pe-4">
                                    <span class="p-float-label">
                                        <p-dropdown [showClear]="true" inputId="currencyId" id="event-type"
                                            [filter]="true" pTooltip="Select the currency for this event"
                                            showDelay="1000" tooltipPosition="bottom" [styleClass]="'w-100'"
                                            appendTo="body"
                                            formControlName="cur" [autoDisplayFirst]="false" [options]="curData"
                                            optionLabel="name" appendTo="body" optionValue="Id" #typeId></p-dropdown>
                                        <label class="form-label font-size-14" for="curId">Currency</label>
                                    </span>

                                </div>


                            </div> -->

                        </div>



                    </div>
                    <!-- Filter-Section-End -->
                    <!-- Event-Cards-Start -->
                    <div class="col-lg-8 col-md-8 ">
                        <h4 class="fw-bold mt-3 px-3">section for multi things</h4>
                        <div class="py-1 " [ngClass]="{'card-base':dataEvents.length>3}">
                            <div *ngFor=" let item of dataEvents"
                                class="card-2-modified p-0 mx-2 mb-3 row d-flex  justify-content-between revers-cols">
                                <div class=" col-md-6 col-sm-12 mb-2 pt-sm-3 ">
                                    <div class=" w-100 text-break">
                                        <h3 class=" text-black text-lenght   pe-1 text-primary ">
                                            {{item.title}}
                                        </h3>
                                    </div>
                                    <div class=" w-100 text-break">
                                        <span class=" text-primary d-block ">
                                            <i class="fa-solid fa-calendar-days text-primary pe-2"></i>
                                            <span class=" text-black">
                                                {{getDateOntomeZone(item.startDate)}}

                                            </span>
                                        </span>
                                    </div>
                                    <div class=" w-100 text-break">

                                        <span class=" text-primary d-block">
                                            <i class="fa-sharp fa-solid fa-location-dot text-primary pe-2"></i>
                                            {{item.address}}
                                        </span>

                                    </div>
                                    <div class="w-100 text-break">
                                        <div class="text-sm-end ">
                                            <span class=" d-flex text-primary d-block">
                                                <i class="fa-solid fa-user text-primary pe-2"></i>
                                                <span class="text-black text-lenght">
                                                    {{item.orgName}}

                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="w-100 text-break">
                                        <span class=""><i class="fa-solid fa-users text-primary "></i> <span
                                                class="text-primary">
                                            </span> Followers</span>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-12 text-end py-3">
                                    <img class=" event-image img" [src]="url + item.photo" alt="Event Pic">
                                    <span class=" love-btn " (click)="Like(item.id)"><i class="{{ua.LikedEvents.includes(item.id)? 'fa-solid text-primary':'fa-regular' }} fa-heart "></i></span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                <div class="pagination-area text-center">

                                    <p-button label="See more" styleClass="p-button-link" (click)="GetMoreData()"></p-button>
                                </div>
                            </div>
                            </div>


                        </div>




                    </div>
                 </div>
                <!-- Event-Cards-End -->

            </div>


        </div>
    </div>
</form>
