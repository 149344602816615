import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddnewAttendeesOfflineService {
  form1: FormGroup;
  public subject4: Subject<string> = new ReplaySubject<string>(1);

  get $getSubject4(): Observable<any> {
     
      return this.subject4.asObservable();
  }
  initializeForm(){
    this.form1=this.fb.group({
      ticketId:[],
      qty:[0],
      value:[0],
      type:[],
      eventId:[],
      orderForm:[],
      ticketName:[],
      cursymbol:[],
      eticket:[],
      willcall:[]
    })
  
    }
  constructor( private fb:FormBuilder) { }
}
