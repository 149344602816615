
<div @fadeOut style="position: fixed; top: 0; right: 0; left: 0;bottom: 0; z-index: 9999; background-color: #fff;"
     class="d-flex justify-content-center align-items-center" *ngIf="loader.show">
    <p-progressSpinner></p-progressSpinner>

</div>

<div *ngIf="auth.securityObject.isAuthenticated" [style]="{'height': '3px'}">
    <p-progressBar @fadeOut *ngIf="loader.show1" mode="indeterminate" ></p-progressBar>
    </div>


<app-navbar *ngIf="showHead" (OnLanguageChange)="getLang($event)">
    <!-- <button (click)="onDisplaySideBarClick()">Open</button> -->
    <button class="btn px-sm-0 ps-1 pe-sm-2 pe-1 " type="button" (click)="open(content)" *ngIf="auth.securityObject.isAuthenticated">
        <i class="fal fa-bars fa-2x"></i>
    </button>
</app-navbar>

<!-- <ng-template #content let-offcanvas>

	<div class="offcanvas-body">
		<ul style="list-style: none " class=" p-0">
            <li class="menu mb-2 border-bottom d-flex justify-content-center">
                <button type="button" class="btn" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"><i class="fa-solid fa-arrow-left"></i></button>
            </li>

            <li class="menu mb-2 ">
                <a routerLink="/events" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-calendar-day fa-2x"></i>
                        <span class="">{{'Offcanvas001' | translate }}</span>

                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a routerLink="/user-order" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-calendar-check fa-2x"></i>
                        <span class="">{{'Offcanvas002' | translate }}</span>

                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a routerLink="/organizers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-table-columns fa-2x"></i>
                        <span class="">{{'Offcanvas003'|translate}}</span>

                    </div>
                </a>

            </li>

            <li class="menu mb-2 ">
                <a routerLink="/favorites" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-square-heart fa-2x"></i>
                        <span class="">{{'Offcanvas004'|translate}}</span>

                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a [routerLink]="['interests']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-calendar-star fa-2x"></i>
                        <span class="">{{'Offcanvas005'|translate}}</span>

                    </div>
                </a>

            </li>

            <li class="menu mb-2 " *ngIf="auth.checKAdmin()">
                <a [routerLink]="['Admin-Dashboard']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-calendar-star fa-2x"></i>
                        <span class="text-center">{{'Admin panal'|translate}}</span>

                    </div>
                </a>

            </li>
        
        </ul>

	</div>
</ng-template> -->



<ng-template #content let-offcanvas>
	<!-- <div class="offcanvas-header"> -->
		<!-- <h4 class="offcanvas-title" id="offcanvas-basic-title"></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button> -->
	<!-- </div> -->
	<div class="offcanvas-body">
		<ul style="list-style: none " class=" p-0">
            <li class="menu mb-2 border-bottom d-flex justify-content-center">
                <button type="button" class="btn" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"><i class="fa-solid fa-arrow-left"></i></button>
            </li>

            <!-- <li class="menu mb-2 border-bottom">
                <a routerLink="/events" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')" >
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-file-user fa-2x"></i>
                        <span class="">{{'Account'| translate}}</span>

                    </div>
                </a>

            </li> -->
            <li class="menu mb-2 ">
                <a routerLink="/home" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-house fa-2x"></i>
                        <span class="">{{'Home'|translate}}</span>
                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a routerLink="/events" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-calendar-day fa-2x"></i>
                        <span class="">{{'Offcanvas001' | translate }}</span>

                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a routerLink="/user-order" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-calendar-check fa-2x"></i>
                        <span class="">{{'Offcanvas002' | translate }}</span>

                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a routerLink="/organizers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-table-columns fa-2x"></i>
                        <span class="">{{'Offcanvas003'|translate}}</span>

                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a routerLink="" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-file-chart-column fa-2x"></i>
                        <span class="">{{'Report'|translate}}</span>

                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a routerLink="" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-coins fa-2x"></i>
                        <span class="">{{'Financial'|translate}}</span>

                    </div>
                </a>

            </li>
            <!-- <li class="menu mb-2 ">
                <a routerLink="/favorites" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-square-heart fa-2x"></i>
                        <span class="">{{'Offcanvas004'|translate}}</span>

                    </div>
                </a>

            </li>
            <li class="menu mb-2 ">
                <a [routerLink]="['interests']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-calendar-star fa-2x"></i>
                        <span class="">{{'Offcanvas005'|translate}}</span>

                    </div>
                </a>

            </li> -->

            <li class="menu mb-2 " *ngIf="auth.checKAdmin()">
                <a [routerLink]="['Admin-Dashboard']" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="offcanvas.dismiss('Cross click')">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-calendar-star fa-2x"></i>
                        <span class="text-center">{{'Admin panal'|translate}}</span>

                    </div>
                </a>

            </li>
            <!-- <li class="menu mb-2 border-bottom">
                <a  class="nav-link"  (click)="logout()">
                    <div class="d-flex flex-column align-items-center">
                        <i class="fa-light fa-right-from-bracket fa-2x"></i>
                        <span class="">Log out</span>

                    </div>
                </a>

            </li> -->
        </ul>

	</div>
</ng-template>


<!-- <pre>{{ closeResult }}</pre> -->

<div class="container-fluid" style="min-height: 84vh;" >
    <div class="row">

        <div *ngIf="visible" [ngClass]="visible? 'col-1 col-sm-2' : '' " >
            <ul style="list-style: none">
                <li class="menu mb-2 border-bottom">
                    <a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                        <div class="d-flex flex-column align-items-center">
                            <i class="fa-light fa-calendar-day fa-2x"></i>
                            <span class="">My Events</span>

                        </div>
                    </a>

                </li>
                <li class="menu mb-2 border-bottom">
                    <a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                        <div class="d-flex flex-column align-items-center">
                            <i class="fa-light fa-calendar-day fa-2x"></i>
                            <span class="">My Events</span>

                        </div>
                    </a>

                </li>
                <li class="menu mb-2 border-bottom">
                    <a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">
                        <div class="d-flex flex-column align-items-center">
                            <i class="fa-light fa-calendar-day fa-2x"></i>
                            <span class="">My Events</span>

                        </div>
                    </a>

                </li>
            </ul>

        </div>
        <div  [ngClass]="visible? 'col-11 col-sm-2 py-0' : 'col-12 py-0' ">
            <router-outlet></router-outlet>
        </div>

    </div>
</div>

<app-footer [showPart2]="showHead" [showPart1]="showFoot" (OnLanguageChange)="getLang($event)" >


</app-footer>


<ngx-scrolltop></ngx-scrolltop>
