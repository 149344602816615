import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component ,Inject, OnInit,} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { currencyesDatasingleDto } from 'src/app/Interfaces/attrDto';
import { CurrenciesSrvService } from 'src/app/services/currencies-srv.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CurenciySrvService } from './curenciy-srv.service';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])
const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-currency-form',
  templateUrl: './currency-form.component.html',
  styleUrls: ['./currency-form.component.scss'],
  animations: [fadeOut]
})
export class CurrencyFormComponent implements OnInit {
  submitted:boolean=false;
  tickTy:DropDownItem[]=[]
  Langueses:DropDownItem[]=[{id:'en',name:'English'}]
  typeId: string;
  mylocal:currencyesDatasingleDto[]=[]
  constructor(public srv:CurenciySrvService,private attrsrv:CurrenciesSrvService, private route:Router,private actvitedRoute:ActivatedRoute,
    @Inject(DOCUMENT) public document: Document,public loader : LoaderService,private messageService: MessageService){}
  ngOnInit(): void {
    this.loader.showLoader1();
    this.loader.showLoader();
    var param= this.actvitedRoute.paramMap.subscribe((param)=>{
      this.typeId=param.get('id');
    
    if(this.typeId=="0"){
    this.srv.initialForm(0,"",0,"","",0,false,'en')
    this.loader.hideLoader();
    this.loader.hideLoader1();
    }else{
      this.attrsrv.GetAllSystemLang().subscribe(x=>{
        this.Langueses=x;})
      this.attrsrv.GetAtrrSingle(this.typeId).subscribe(y=>{
        this.mylocal=y;
        var data=y.find(m=>m.cultureId=='en')
        this.srv.initialForm(data.id,data.code,data.exRate,data.name,new Date(Date.now()),data.order,data.status==0?true:false,data.cultureId)
        this.loader.hideLoader();
        this.loader.hideLoader1();
      })
     


    }});
    
  }
  sumbit(){
 //   console.log(this.srv.typeform.value)
    var data:currencyesDatasingleDto={
     code:    this.srv.typeform.controls['code'].value,
     exRate:  this.srv.typeform.controls['exRate'].value,
     id:       this.srv.typeform.controls['id'].value,
     rateDate:this.srv.typeform.controls['rateDate'].value,
    cultureId:this.srv.typeform.controls['cultureId'].value,
    name:     this.srv.typeform.controls['name'].value,
    order:    this.srv.typeform.controls['order'].value,
    status:   this.srv.typeform.controls['status'].value?1:0}
   // console.log(data)
    this.attrsrv.PostnewAttr(data).subscribe(x=>{
      this.attrsrv.GetAtrrSingle(this.typeId).subscribe(y=>{
        this.mylocal=y;});
      
      this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
      this.route.navigate(['Currencies'], { relativeTo: this.actvitedRoute.parent.parent});

    },(error)=>{
      this.messageService.add({ severity: 'danger', summary: error });
    });
  }
 
  cancel(){
    this.srv.clear();
    this.route.navigate(['Currencies'], { relativeTo: this.actvitedRoute.parent.parent});
  }

  changform(e){
    //console.log(e);
     var data=this.mylocal.find(x=>x.cultureId==e.value);
     if(data==undefined){
      this.srv.typeform.controls['code'].setValue("");
      this.srv.typeform.controls['exRate'].setValue(0);
      this.srv.typeform.controls['rateDate'].setValue(new Date(Date.now()));
      this.srv.typeform.controls['cultureId'].setValue(e.value);
      this.srv.typeform.controls['name'].setValue("");
      this.srv.typeform.controls['order'].setValue(0);
      this.srv.typeform.controls['status'].setValue(false);
     }else{
      this.srv.typeform.controls['code'].setValue(data.code);
      this.srv.typeform.controls['exRate'].setValue(data.exRate);
      this.srv.typeform.controls['rateDate'].setValue(data.rateDate);
      this.srv.typeform.controls['cultureId'].setValue(data.cultureId);
      this.srv.typeform.controls['name'].setValue(data.name);
      this.srv.typeform.controls['order'].setValue(data.order);
      this.srv.typeform.controls['status'].setValue(data.status!=0?false:true);
     }
  }


}
