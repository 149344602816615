<footer class="footer-area">
    <div class="px-5 ">
        <div *ngIf="showPart1" class="row justify-content-between">
            <div class="col-lg-6 col-md-6 col-sm-6 row">
                <!--  -->

                <div class="col-lg-8 col-md-12 col-sm-12">
                    <div class="single-footer-widget">
                        <a routerLink="/" class="logo">
                            <img routerLink="/" src="../../../../assets/img/Logo/Antares - Logo H (Colored).png"
                                class="logo-size-larg" alt="logo">
                        </a>
                        <p>{{'FooterSocialTitle001'|translate}}</p>
                        <ul 
                        [ngClass]="{'social-link-rtl ': document.documentElement.dir === 'rtl',
                                      'social-link': document.documentElement.dir === 'ltr'}">
                            <li><a href="#" class="d-block rounded-4" target="_blank"><i
                                        class="fa-brands fa-facebook"></i></a>
                            </li>
                            <li><a href="#" class="d-block rounded-4" target="_blank"><i
                                        class="fa-brands fa-twitter"></i></a>
                            </li>
                            <li><a href="#" class="d-block rounded-4" target="_blank"><i
                                        class="fa-brands fa-instagram"></i></a>
                            </li>
                            <li><a href="#" class="d-block rounded-4" target="_blank"><i
                                        class="fa-brands fa-linkedin-in"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 row">


                <div class="col-lg-3 col-md-6 col-sm-6 me-xl-0 me-lg-5 me-0">
                    <div class="single-footer-widget ">
                        <h3>{{'FooterUl001'|translate}}</h3>
                        <ul 
                        [ngClass]="{'footer-links-list-rtl ': document.documentElement.dir === 'rtl',
                                      'footer-links-list': document.documentElement.dir === 'ltr'}">
                            <li><a href="javascript:;">{{'FooterUl001Li001'|translate}}</a></li>
                            <li>
                                <p-dropdown class="on-hover" [options]="countries" [(ngModel)]="selectedCountry"
                                    (onChange)="selectedChange($event)" optionLabel="name" optionValue="code">
                                    <ng-template let-item pTemplate="selectedItem">
                                        <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
                                            <div>{{ item.code.toUpperCase()}}</div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-item pTemplate="item">
                                        <div class="flex align-items-center gap-2">
                                            <div>{{ item.name }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </li>

                        </ul>
                        <ul class="navbar-nav border-end border-start mx-4 pe-3">


                        </ul>

                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 ">
                    <div class="single-footer-widget">
                        <h3>{{'FooterUl002'|translate}}</h3>
                        <ul     [ngClass]="{'footer-links-list-rtl ': document.documentElement.dir === 'rtl',
                        'footer-links-list': document.documentElement.dir === 'ltr'}">
                            <li><a [routerLink]="['/','pricing']">{{'FooterUl002Li001'|translate}}</a></li>
                            <li><a [routerLink]="['/','enterprise']">{{'FooterUl002Li002'|translate}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>{{'FooterUl003'|translate}}</h3>
                        <ul      [ngClass]="{'footer-links-list-rtl ': document.documentElement.dir === 'rtl',
                        'footer-links-list': document.documentElement.dir === 'ltr'}">
                            <li><a href="javascript:;">{{'FooterUl003Li001'|translate}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>{{'FooterUl004'|translate}}</h3>
                        <ul      [ngClass]="{'footer-links-list-rtl ': document.documentElement.dir === 'rtl',
                        'footer-links-list': document.documentElement.dir === 'ltr'}">
                            <li><a href="javascript:;">email:event@nejmy.com</a></li>
                            <li><a href="javascript:;"><span dir="ltr">call: +962 79 5888388</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area" *ngIf="showPart2">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>{{'Copyright'|translate}}{{currentYear}} <a href="https://nejmy.com" target="_blank">{{'Nejmy'|translate}} </a>{{'AllRightsReserved'|translate}}</p>
                </div>
                <div class="col-lg-6 col-md-6" [ngClass]="{'ff': document.documentElement.dir === 'rtl',
                '': document.documentElement.dir === 'ltr'}" >
                    <ul   [ngClass]="{'left': document.documentElement.dir === 'rtl',
                    'right': document.documentElement.dir === 'ltr'}">
                        <li><a routerLink="/privacy-policy">{{'FooterBtm002'|translate}}</a></li>
                        <li><a routerLink="/terms-of-service">{{'FooterBtm003'|translate}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>


</footer>