import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DataForWaitList, EventCalenderDto, EventData, EventDateTimeDto, EventTableDto, EventTableInfo, EventTitleDto, EventWithData, OrderConformationDto, WaitListMemberDto, WatlistDto, dashboardDto} from '../Interfaces/EventData';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {DropDownItem} from '../Interfaces/DropDownItem';
import {map} from 'rxjs/operators';
import {DropdownItem} from 'primeng/dropdown';
import {EventBasicInfoDto, EventStateDto} from '../Interfaces/EventBasicInfoDto';
import {EventsDetailsComponent} from '../components/pages/events-details/events-details.component';
import {environment as env} from 'src/environments/environment';
import {MessageService} from 'primeng/api';
import {composeTaskCompletedCallbacks} from '@angular/compiler-cli/ngcc/src/execution/tasks/completion';

@Injectable({
    providedIn: 'root'
})
export class EventsService {
    url: string = env.Api;

    private subject: Subject<EventTableInfo> = new ReplaySubject<EventTableInfo>(1);

    get $getSubject(): Observable<EventTableInfo> {
        return this.subject.asObservable();
    }
  

    private CalenderSubject: Subject<EventCalenderDto> = new ReplaySubject<EventCalenderDto>(1);

    get $getClenderSubject(): Observable<EventCalenderDto> {
        return this.CalenderSubject.asObservable();
    }


    private RecInSubject: Subject<EventTableInfo> = new ReplaySubject<EventTableInfo>(1);

    get $getRegSubject(): Observable<EventTableInfo> {
        return this.RecInSubject.asObservable();
    }


    private subEvent: Subject<EventTableInfo> = new ReplaySubject<EventTableInfo>(1);

    get $getSubEvent(): Observable<EventTableInfo> {
        return this.subEvent.asObservable();
    }

    private EventDaashbord: Subject<EventTableInfo> = new ReplaySubject<EventTableInfo>(1);

    get $EventDaashbord(): Observable<EventTableInfo> {
        return this.subEvent.asObservable();
    }

    constructor(private http: HttpClient, private messageService: MessageService) {
    }

    GetEventProgressInfo(id: string) {
        return this.http.get<EventStateDto>(this.url + 'd/e/g/eventstate/' + id);
    }

    GetEventsTableLines(x:string,Org:string="",Skip:number=0) {
        let params = new HttpParams();
        // params=params.set('vm',this.searchForm.value)
         if (x) { params = params.set('filter', x); }
         if (Org) { params = params.set('orgId', Org); }
         if (Skip) { params = params.set('Skip', Skip); }
       return this.http.get<EventTableInfo>(this.url + 'd/e/test',{ params: params })
    //    .pipe(map(x=>{
    //     console.log(x)
    //        var t=x.map(y=>{
    //        var m: EventTableDto;
    //        m.id=y.id;
    //        m.curSymbol=y.curSymbol;
    //        m.endDate=y.endDate;
    //        if(y.salves.length!=0){
    //         m.eventInstance=null;
    //        }else{
    //         if(y.master.length==1){
    //             if(y.isRecurring&&y.isMaster){
                   
    //             }
    //         }else
    //         {

    //         }
         
    //        }
    //        })
    //    })).subscribe(x=>{x})
       .subscribe(
            x => this.subject.next(x),
            err => {
                this.messageService.add({severity: 'error', summary:err['error']['title'], detail: err['message']});
            }
            , () => {
               // console.log('complete')
            }

        );
    }
   getEventFOrCalender(x:string,orgId:string){

    let params = new HttpParams();
    // params=params.set('vm',this.searchForm.value)
     if (x) { params = params.set('filter', x); }
     if (orgId) { params = params.set('orgId', orgId); }
   
   return this.http.get<EventCalenderDto>(this.url + 'd/e/g/e/gc',{ params: params })
   .subscribe(
        x => this.CalenderSubject.next(x),
        err => {
            this.messageService.add({severity: 'error', summary:err['error']['title'], detail: err['message']});
        }
        , () => {
           // console.log('complete')
        }

    );

   }
    GetEventRecuringTableInstan(x:string,Skip:number=0){
        let params = new HttpParams();
        // params=params.set('vm',this.searchForm.value)
         if (x) { params = params.set('id', x); }
         if (Skip) { params = params.set('Skip', Skip); }
       return this.http.get<EventTableInfo>(this.url + 'd/e/g/e/r/in',{ params: params })
       .subscribe(
            x => this.RecInSubject.next(x),
            err => {
                this.messageService.add({severity: 'error', summary:err['error']['title'], detail: err['message']});
            }
            , () => {
              //  console.log('complete')
            }

        );
    }

    GetAttributes = (id): Observable<any[]> => this.http.get<any[]>(this.url + 'd/a/g/m/' + id).pipe(
        map(x => {
            // insert logic
            const data = x.map(obj => ({
                name: obj.name,
                Id: obj.attrId
            }));
            return data;
        })
    )

    getReccuringInstances(id: string) {
        return this.http.get<DropDownItem[]>(this.url + 'd/e/g/eis/' + id);
    }
    GetSubEvents(id:string){
        return this.http.get<DropDownItem[]>(this.url + 'd/e/g/ei/' + id);
    }

    GetTags(search): Observable<DropDownItem[]> {
        const url = this.url + 'd/a/g/mm/10103';

        const queryParams = new HttpParams().append('searchTerm', search);

        return this.http.get<DropDownItem[]>(url, {params: queryParams});


    }

    GetOrganizersByUser(): Observable<DropDownItem[]> {
        const url = this.url + 'd/o/g/u/all';
        return this.http.get<DropDownItem[]>(url);
    }

    postBasicInfo(data: EventBasicInfoDto) {
        const url = this.url + 'd/e/c/step1';
        return this.http.post<string>(url, data);
    }

    getEventInstanceDetail(data: string): Observable<EventTitleDto> {
        return this.http.get<EventTitleDto>(this.url + 'd/e/g/title/' + data);
    }

    getAllEventInstance = (id): Observable<any[]> => this.http.get<any>(this.url + 'd/e/g/Es/' + id).pipe(map(x => {
        return x.events;
    }), map(y => {
        const data = y.map(obj => {
            return obj.entryId;
        });
        return data;
    }))

    getEventDateTime(id: string): Observable<EventDateTimeDto> {
        return this.http.get<EventDateTimeDto>(this.url + 'd/e/g/date/' + id);
    }

    postEventdate(data: EventDateTimeDto) {
        const url = this.url + 'd/e/c/step3';
        return this.http.post(url, data);
    }

    getBasicInfoForEvent(id: string) {
        return this.http.get<EventBasicInfoDto>(this.url + 'd/e/g/step1/' + id);
    }

    // my Work
    GetTicketsAndAddons(id: string) {
        const url = this.url + 'd/or/g/i/' + id;
        return this.http.get<EventWithData[]>(url);
    }

    removeEntry(id: string) {
        return this.http.delete(this.url + 'd/e/remove/' + id);
    }

    getsubevent(id:string,Skip:number=0){
        return this.http.get<EventTableInfo>(this.url + 'd/e/subevent/'+id+"/"+Skip).subscribe(
            x => this.subEvent.next(x),
            err => {
                this.messageService.add({severity: 'error', summary:err['error']['title'], detail: err['message']});
            }
            , () => {
             //   console.log('complete')
            }

        );
    }
    getOrgEventAsDropdown(){
        return this.http.get<DropDownItem[]>(this.url + 'd/e/g/o/e/c');
    }

    getDashboardDetails(id:string){
        return this.http.get<dashboardDto>(this.url + 'd/e/g/D/'+id);
    }
    getEventWaitListDetails(id:string){
        return this.http.get<WatlistDto>(this.url + 'd/e/g/e/w/'+id);
    }
    postEventWaitlist(data:WatlistDto){
     const url = this.url + 'd/e/c/WaitList';
        return this.http.post(url, data);
    }

    GetEventOrderConfiramtion(Id:string){
        return this.http.get<OrderConformationDto>(this.url + 'd/e/g/orco/'+Id);
    }
    postEventOrderConfirmation(data:OrderConformationDto){
        const url = this.url + 'd/e/c/or/co';
        return this.http.post(url, data);
    }
    GetEventWaitListMember(eventId:string,skip:number=0){
        return this.http.get<DataForWaitList>(this.url + 'd/e/g/e/w/m/'+eventId+"/"+skip);
    }

    GetUserOrgEvents(ids:string[]){
        const url = this.url + 'd/e/g/or/drop';
        return this.http.post<DropDownItem[]>(url, ids);
        
    }
}

