import { Component, OnInit, ViewChild } from '@angular/core';
import { BrowserEventService, searchestory } from '../browser-event.service';
import { EventsService } from 'src/app/services/events.service';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { EventCard } from '../../landing-page/landing-page.service';
import { Router } from '@angular/router';
import { UserlocationService } from 'src/app/services/userlocation.service';
import { environment } from 'src/environments/environment';
import { UserActionsService } from 'src/app/services/user-actions.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { JsonObject } from '@angular/compiler-cli/ngcc/src/utils';

@Component({
  selector: 'app-step-one-search',
  templateUrl: './step-one-search.component.html',
  styleUrls: ['./step-one-search.component.scss']
})
export class StepOneSearchComponent implements OnInit {
  url=environment.Api;
  categories: DropDownItem[];
  types: DropDownItem[];
  curData: DropDownItem[];
  dataEvents: EventCard[] = [];
  UpdateLocation:boolean=false;
 userLocation:string="";
 userSearch:searchestory[]=[]
 @ViewChild('op') op: OverlayPanel;
 count:number=0;
  constructor(public srv:BrowserEventService
    ,private eventsService: EventsService
    ,private route:Router
    ,public ua:UserActionsService
    ,private userLocSrv:UserlocationService){

  }

  gotosearch(item:searchestory){
 // console.log(item)
  this.srv.searchForm.controls['start'].setValue("");
  this.srv.searchForm.controls['end'].setValue("");
  this.srv.searchForm.controls['name'].setValue(item.name);
  this.srv.searchForm.controls['address'].setValue(item.address);
  this.srv.searchForm.controls['online'].setValue(item.online);
  this.srv.searchForm.controls['free'].setValue("false");
  this.gotoserachPage();

  }
  ngOnInit(): void {

     this.srv.getUserSearchHistory().subscribe(x=>{
    //  console.log(x)
      this.userSearch=x;
    //  var t=JSON.parse(x as string);
     // console.log(t)
     })
    // this.eventsService.GetAttributes(10102).subscribe(x => this.categories = x);
    // this.eventsService.GetAttributes(10101).subscribe(x => this.types = x);
    // this.eventsService.GetAttributes(203).subscribe(x => this.curData = x);
    // this.userLocSrv.$getuserlocation.subscribe(x=>{
    //   console.log("x"+x)
    //   console.log( this. userLocation)
    //  this. userLocation=x;
    //  this.srv.searchForm.controls['address'].setValue(x);


    // });
    this.ua.getLikedEventList().subscribe(y => {
      this.ua.LikedEvents = y as string[];});
    this.srv.getlatestEvent(this.count).subscribe(x=>{
      this.dataEvents=x as EventCard[]
    })
  }
  getDateOntomeZone(d){
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    return new Date(MyDate).toLocaleString();
    }

  changLocal(){
    this.UpdateLocation=!this.UpdateLocation
  }
  GetDate(x) {
    switch (x) {
      case 'today': 

                    this.srv.searchForm.controls['start'].setValue(new Date(Date.now()));
                    this.srv.searchForm.controls['end'].setValue("");
                    this.srv.searchForm.controls['name'].setValue("");
                    this.srv.searchForm.controls['address'].setValue("");
                    this.srv.searchForm.controls['online'].setValue("false");
                    this.gotoserachPage();
                    break;
      case 'tomorrow': this.srv.searchForm.controls['start'].setValue(new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() +1)));
                       this.srv.searchForm.controls['end'].setValue("");
                       this.srv.searchForm.controls['name'].setValue("");
                       this.srv.searchForm.controls['address'].setValue("");
                       this.srv.searchForm.controls['online'].setValue("false");
                       this.gotoserachPage();
                    break; 
     case 'thisWeek': this.srv.searchForm.controls['start'].setValue(new Date(Date.now()));
                      this.srv.searchForm.controls['end'].setValue(new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() +7)));
                      this.srv.searchForm.controls['name'].setValue("");
                      this.srv.searchForm.controls['address'].setValue("");
                      this.srv.searchForm.controls['online'].setValue("false");
                      this.gotoserachPage();
                      break; 
        case 'online':
                        this.srv.searchForm.controls['online'].setValue(true);
                        this.srv.searchForm.controls['start'].setValue("");
                        this.srv.searchForm.controls['end'].setValue("");
                      
                        this.gotoserachPage();
        break;
         case 'free': 
                      this.srv.searchForm.controls['online'].setValue(false);
                      this.srv.searchForm.controls['start'].setValue("");
                        this.srv.searchForm.controls['end'].setValue("");
                    
                      this.srv.searchForm.controls['free'].setValue(true);
                           this.gotoserachPage();
                      break; 
        // case 'nextMonth': this.srv.searchForm.controls['start'].setValue(new Date(new Date(Date.now()).setMonth(new Date(Date.now()).getMonth() +1)));
        //                    this.srv.searchForm.controls['end'].setValue("");
        //                    break; 

    }
    
   
}

gotoserachPage(){
 // console.log(this.srv.searchForm.value)
  this.route.navigate(['/search/filter']);
}
getProblem(e){

//  this.mapProblem = e;
}
getAddress(x) {
  
      this.srv.searchForm.controls['address'].setValue(x.address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name);
      this.srv.searchForm.controls['online'].setValue(false);
      this.userLocation=x.address_components.find(y => y.types.includes('administrative_area_level_1'))?.long_name
      this.changLocal();
}
Like(id: string) {
  this.ua.postLike(id).subscribe(x => {
    this.ua.getLikedEventList().subscribe(y => {
      this.ua.LikedEvents = y as string[];
    });
  });
}
GetMoreData() {
  this.count++;
  this.srv.getlatestEvent(this.count).subscribe(x=>{
    this.dataEvents.push(...x as EventCard[])

  })}
getcurrentLocation(){
      alert("You need to allow access to your current location to get the optimal search results.")
      this.userLocSrv.getLocation();
      var userLocSub = this.userLocSrv.$getuserlocation.subscribe(x => {
       // console.log(x)
           this.userLocation = x;
           this.srv.searchForm.controls['address'].setValue(x);
           this.srv.searchForm.controls['online'].setValue(false);
           this.changLocal();
      });
     // this.sub.push(userLocSub);
  }
}
