import { Component } from '@angular/core';

@Component({
  selector: 'app-box-office-reporting',
  templateUrl: './box-office-reporting.component.html',
  styleUrls: ['./box-office-reporting.component.scss']
})
export class BoxOfficeReportingComponent {

}
