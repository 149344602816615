
<p-toast></p-toast>
<div class="container">
   
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Tags'|translate}}</h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="">
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <h2 class="fw-bold">{{PageTitle|translate}}</h2> -->

                <div>
                    <!-- <h6 class="fw-bold  ">Help <span class="text-primary help-btn" (click)="showHelpStepsModal()" ><i class="fa-regular fa-circle-question"></i></span></h6> -->
                    <!-- <p-dialog header="How to start" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                    [draggable]="false" [resizable]="false" >
                    <p class="m-0"><b class="text-primary">Step 1: </b> Hkas sakjfas fjkasf sajfs afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 2: </b> Okas sakjfas fjkasf sajfss adas s ad afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 3: </b> Lasfas sakjfas fjkasf sajfs ad sa dasd afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 4: </b> Kasdkas sakjfas fjkasf sajfsas dsa dsa sa d dsa dsa dsa d afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 5: </b> Bkas sakjfas fjkasf sajfssa dsa as  afjasf</p>
                        <ng-template pTemplate="footer">
                        <p-button icon="pi pi-check" (click)="helpStepsModal=false" label="Ok" styleClass="p-button-text"></p-button>
                        </ng-template>
                </p-dialog> -->
                </div>

                    </div>

                    <div class="row">
                           
                        <form [formGroup]="srv.typeform" (submit)="sumbit()" autocomplete="off" >
                            <div class="row mb-3">
                                <div class="p-input-filled col-sm-6 col-md-6 mb-2">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="name" pTooltip="{{'name'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  srv.typeform.get('name')?.touched &&
                                                    srv.typeform.get('name')?.invalid||(submitted&& srv.typeform.get('name').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #ticketName />
                                        <label class="form-lable font-size-14" for="TicketName">{{'TcktFrmInfoInpt001'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="srv.typeform.get('name')?.touched &&
                                    srv.typeform.get('name')?.invalid||(submitted&& srv.typeform.get('name').invalid)
                                        " >{{'TcktFrmInfoInpt001ErrMsg'|translate}}</small>
                        
                                </div>

                                <div class="p-input-filled col-sm-6 col-md-6 mb-2">
                                    <span class="p-float-label">
                                        <p-dropdown (onChange)="changform($event)"  inputId="langtype"
                                        pTooltip="{{'TcktFrmInfoSlct001Pop'|translate}}" showDelay="1000" tooltipPosition="top"
                                         [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                   srv.typeform.get('cultureId')?.touched &&
                                                    srv.typeform.get('cultureId')?.invalid||(submitted&& srv.typeform.get('cultureId').invalid)
                                            }" [styleClass]="'w-100'" formControlName="cultureId" [autoDisplayFirst]="false"
                                            [options]="Langueses" optionLabel="name" optionValue="id" #ticketsType></p-dropdown>
                                        <label class="form-lable font-size-14" for="langtype">{{'Language'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="srv.typeform.get('cultureId')?.touched &&
                                    srv.typeform.get('cultureId')?.invalid||(submitted&& srv.typeform.get('cultureId').invalid)
                                        " >{{'Language'|translate}}</small>
                        
                                </div>
                               
                            
                            </div>

                            <div class="row mb-3">
                                <div class="p-input-filled  col-md-6 col-12 mb-2">
                                    <span class="p-float-label">
                                        <p-inputNumber pTooltip="{{'Order'|translate}} " showDelay="1000"
                                            tooltipPosition="bottom" inputId="inputnumber1" [styleClass]="'w-100'" class="form-control m-0 p-0"
                                            [inputStyleClass]="'form-control'" formControlName="order"
                                            [ngClass]="{'ng-invalid ng-dirty':srv.typeform.get('order')?.touched && srv.typeform.get('order')?.invalid||(submitted&& srv.typeform.get('order').invalid)}"
                                            #minNumber></p-inputNumber>
                                        <label for="inputnumber1">{{'Order'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="(srv.typeform.get('order')?.touched &&
                                    srv.typeform.get('order')?.invalid)||(submitted&& srv.typeform.get('order').invalid)
                                        ">{{'Order'|translate}}</small>
                        
                                </div>
                                <div class="p-input-filled col-sm-6 col-md-6 mb-2">
                                    <div class="row justify-content-between mt-md-2">
                                        <div class="col-6">
                                            <p-inputSwitch inputId="Etic" ariaLabelledBy="lab1"
                                                pTooltip="{{'Is active'|translate}}" showDelay="1000"
                                                tooltipPosition="bottom" formControlName="status"   class="me-2"></p-inputSwitch>
                                            <label class="form-check-label  " id="lab1"
                                                for="Etic">{{'Is active'|translate}}</label>
                                        </div>
                                   
                                    </div>
                        
                                </div>
                            </div>
                           
                            <div class="col-12 d-flex justify-content-center">
                                <div class="banner-btn rounded-pill">
                                    <button pButton pRipple pTooltip="Cancel and dismiss changes" type="button" showDelay="1000"
                                        tooltipPosition="bottom" class="rounded-pill save-btn text-center ms-1"
                                        (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
                                    <button pButton pRipple type="Save and finish " showDelay="1000" tooltipPosition="bottom"
                                        class="rounded-pill save-btn text-center ms-1 mt-md-0 mt-1">{{'FnshBtn'|translate}}
                                        <span></span></button>
                                </div>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </div>

</div>







