import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventsService } from '../../../../../services/events.service';
import { EventBasicInfoDto } from '../../../../../Interfaces/EventBasicInfoDto';
import { DropDownItem } from '../../../../../Interfaces/DropDownItem';
import { FormBuilder, FormControl, FormGroup, SelectControlValueAccessor, SelectMultipleControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { transition, style, animate, trigger } from '@angular/animations';
import { OrganizerFromStepService } from 'src/app/services/organizer-from-step.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { AutoComplete } from 'primeng/autocomplete';
import { TranslateService } from '@ngx-translate/core';

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('1s ease-out', style({
        opacity: 0
    }))
])



const fadeOut = trigger('fadeOut', [
    leaveTrans
]);


@Component({
    selector: 'app-basic-info',
    templateUrl: './basic-info.component.html',
    styleUrls: ['./basic-info.component.scss'],
    animations: [fadeOut]
})
export class BasicInfoComponent implements OnInit ,OnDestroy{

    allTages:string[]=[];
    id: string;
    hide: boolean;
    form1!: FormGroup;resulttag: string[];
    categories !: DropDownItem[];
    basicInfo !: EventBasicInfoDto;
    selectedCategory: any;
    subCategories: DropDownItem[];
    organizers: DropDownItem[];
    types: DropDownItem[];
    subcat: string;
    selectedTags: any[];
    tags: DropDownItem[];
    value1 = false;
    value2 = false;
    value3 = false;
    tzData: DropDownItem[];
    category: bigint;
    newTags: string[] = [];
    curData: DropDownItem[];
    isSubmitting: boolean = false;
    Submitted: boolean = false;
    sub: Subscription[]=[];
    canCreateRequring: boolean=true;
    //resulttag: string[];
    textsearch: any;
    @ViewChild('myTags') myTags!: AutoComplete;

    constructor(
        public loader: LoaderService,
        private eventsService: EventsService,
        private fb: FormBuilder,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private eventStepsSrv: EventStepsService,
        private messageService: MessageService,
        private orgSrv: OrganizerFromStepService,
        private translate:TranslateService
    ) {
        var gk= this.translate.onLangChange.subscribe(() => {
            this.updateLang();
          });
          this.sub.push(gk)

    }
    updateLang() {
        var atr1= this.eventsService.GetAttributes(10102).subscribe(x => this.categories = x);
        this.sub.push(atr1)
        var hdfd= this.eventsService.GetAttributes(10103).subscribe(x => {
            var m=x.map(x=>x.name)
            this.allTages=m;
          //  console.log(this.allTages)
        });
        this.sub.push(hdfd)
      var atr2=  this.eventsService.GetAttributes(10101).subscribe(x => this.types = x);
        this.sub.push(atr2)
        var atr4= this.eventsService.GetAttributes(207).subscribe(x => this.tzData = x);
        this.sub.push(atr4)
        var atr5=this.eventsService.GetAttributes(203).subscribe(x => this.curData = x)
        this.sub.push(atr5)
    }
    ngOnDestroy(): void {
        this.sub.forEach(el=>{
            el.unsubscribe();
        })
    }
    compareTech(t1: DropDownItem, t2: DropDownItem): boolean {
        return t1 && t2 ? t1.id === t2.id : t1 === t2;
    }
    ngOnInit(): void {// 10101
       // this.loader.showLoader1();
        this.initializeForm('0', '', '', null, null, null, null, null, false, false, false, null,"");
       // console.log('first Initail')
        // var atr1= this.eventsService.GetAttributes(10102).subscribe(x => this.categories = x);
        // this.sub.push(atr1)
        // var hdfd= this.eventsService.GetAttributes(10103).subscribe(x => {
        //     var m=x.map(x=>x.name)
        //     this.allTages=m;
        //   //  console.log(this.allTages)
        // });
     //   this.sub.push(hdfd)
     this.updateLang();
      var atr2=  this.eventsService.GetAttributes(10101).subscribe(x => this.types = x);
        this.sub.push(atr2)
       var atr3= this.eventsService.GetOrganizersByUser().subscribe(x => { this.organizers = x;});
        this.sub.push(atr3)

     //  var atr4= this.eventsService.GetAttributes(207).subscribe(x => this.tzData = x);
      //  this.sub.push(atr4)
       // var atr5=this.eventsService.GetAttributes(203).subscribe(x => this.curData = x)
       // this.sub.push(atr5)
        this.id = this.activatedRoute.snapshot.parent.paramMap.get('id');
        if (this.id == null) {
            this.id = this.activatedRoute.snapshot.paramMap.get('id');
        }

        
     
        if (this.id === '0') {
            
             // console.log('step1')
                var Parent=this.eventStepsSrv.ParentId;

                if(Parent!=""){
                    this.initializeForm('0', '', '', null, null, null, null, null, false, false, false, null,Parent);
                    //  console.log('2 Initail')
                var atr6=    this.eventStepsSrv.ParentDate$.subscribe(x=>{
                 //   console.log('step2')
                        var Parentstamp= moment(x.end).diff(x.start);
                        var  ParentstampAshour=Number.parseInt((Parentstamp/(24*1000*60*60)).toString());
                        if(ParentstampAshour==0&&ParentstampAshour<2){
                           this.canCreateRequring=false;
                        }
                        else{
                            this.canCreateRequring=true;
                        }
                    });
                    this.sub.push(atr6)
                }else{
                //    console.log('step2 else')
                    this.initializeForm('0', '', '', null, null, null, null, null, false, false, false, null,"");
                    //  console.log('3 Initail')
                }
                this.hide = true;
                var l= this.eventsService.GetAttributes(203).subscribe(x => { this.curData = x; this.loader.hideLoader1(); });
                this.sub.push(l);
            
           
           
        }
        else {
        var m= this.eventsService.GetEventProgressInfo(this.id).subscribe(x => {
             
                this.eventsService.GetAttributes(x.categoryId).subscribe(x => this.subCategories = x);
                this.initializeForm(this.id, x.title, x.organizerId, x.typeId, x.categoryId, x.subCatId, x.tagsId, x.tzId, x.isPrivate, x.isRecurring, x.isOnline, x.curId,x.parentId);
               // console.log('4 Initail')
                this.newTags = x.tagsId
                this.eventStepsSrv.InitState(x);
                this.eventStepsSrv.setParentId(x.parentId);
                this.loader.hideLoader1();

            });
            this.sub.push(m)
            this.hide = false
        }
        if(this.orgSrv.orgId){
            this.form1.get("organizerId").patchValue(this.orgSrv.orgId);
            this.form1.get("organizerId").updateValueAndValidity();
            this.orgSrv.orgId = ""
       }
       else{

       }
    }

    initializeForm(id: string, name: string, orId: string, type: bigint, cat: bigint, subcat: bigint, tagsId: string[], tz: bigint, ispr: boolean, isRe: boolean, ison: boolean, curId: bigint,parent:string) {
        this.form1 = this.fb.group({
            id: [id],
            title: [name, [Validators.required,Validators.maxLength(110)]],
            organizerId: [orId, [Validators.required]],
            typeId: [type, [Validators.required]],
            subCatId: [subcat, [Validators.required]],
            tagsId: [tagsId],
            isPrivate: [ispr, [Validators.required]], 
            isRecurring: [isRe, [Validators.required]],
            isColection:[false],
            isOnline: [ison, [Validators.required]],
            tzId: [tz, [Validators.required]],
            categoryId: [cat, [Validators.required]],
            curId: [curId, [Validators.required]],
            parentId:[parent]
        });
        //console.log(this.form1)
    }
    AddTags(x) {

        if(x.value && x.value!= "" )
        {

            var l=this.newTags.map(x=>x.toLowerCase());
            var t =l.indexOf(x.value.toLowerCase())
            if(t==-1){

                this.newTags.push(x.value);
            }
           
            x.value = "";
        }else{


   //         console.log(this.textsearch)
            var l=this.newTags.map(x=>x.toLowerCase());
            var t =l.indexOf(this.textsearch.toLowerCase())
            if(t==-1){

                this.newTags.push(this.textsearch);
            }
            //this.newTags.push(this.textsearch);
        }
        this.myTags.clear()

    }
    // onCancelTagEnter(x){
    //     const w = x.target.innerText;
    //     x.target.innerText = 'remove';

    // }
    // onCancelTagLeave(x , w){

    //     x.target.innerText = w;
    // }
    removeTag(x) {
       // console.log(x);
        this.newTags.splice(x, 1)
       // x.remove()
    }
    onCategoryChange(e) {
        this.eventsService.GetAttributes(e.value).subscribe(x => this.subCategories = x);
    }
    onTagSearch(e: any) {
       
       // console.log(e.query);
       // console.log("Tages",this.allTages)
         this.resulttag=this.allTages.filter(x=>x.toLocaleLowerCase().includes(e.query.toLowerCase()));
       //  console.log("srarch result",this.resulttag)
        if(this.resulttag.length==0){
            this.resulttag=[e.query];
            this.textsearch=e.query;
        }

       // this.eventsService.GetTags(e.term).subscribe(x => this.tags = x);
    }
    onSubmit() {
       // console.log(this.form1)
        this.isSubmitting = true;
        const data = this.form1.value as EventBasicInfoDto;
        // console.log(data.tagsId );
        // const tagsAsString: string[] = [];
        // data.tagsId.forEach(item => {
        //     // @ts-ignore
        //     tagsAsString.push(typeof item === 'string' ? item : item?.label);
        // });
        // data.tagsId = tagsAsString;
        data.tagsId = this.newTags;
        if (this.form1.valid) {
            this.eventsService.postBasicInfo(data).subscribe(x => {
                this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
                this.eventsService.GetEventProgressInfo(x).subscribe(y => {
                    this.eventStepsSrv.InitState(y);
                    this.isSubmitting = false;
                    //   this.form1.get('id').setValue(x);
                    if (this.form1.controls['id'].value == "0") {
                        setTimeout(() => {
                            this.router.navigate(['/redirect', x])
                        }, 300);
                    }
                })
            })
        }
        else {
            this.Submitted = true;
            this.isSubmitting = false;
        }
    }
    goBack() {
       
        if(this.eventStepsSrv.ParentId!=""){
            this.router.navigate(['/events/manage-event/',this.eventStepsSrv.ParentId,'SubEvents']);
        }else{
            this.router.navigate(['/events']);
        }
        
    }
}
