import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SyslangformsrvService {

  myForm!:FormGroup
  constructor(private fb:FormBuilder) {
    this.initializForm("","","",0,0)
   }
  initializForm(cultureId:string,name:string,uiCulture:string,priority,status){
    this.myForm=this.fb.group({
      cultureId  :[cultureId  ],
       name      :[ name      ],
       priority  :[ priority  ],
       status    :[ status    ],
       uiCulture :[ uiCulture ],
    })
  }
  clear(){
    this.initializForm("","","",0,0);
  }
}
