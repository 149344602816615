import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Table,TableService } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { EditAttendeesServiceService } from '../edit-attendees-info/edit-attendees-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { distinct } from 'rxjs/operators';
//import fs from 'fs';
export interface Ticket {
    id: string;
    name: string;
    qty: number;
    finalPrice: number;
    antaresFees: number;
    feesPaid: number;
    paymentFees: number;
    shippingFees: number;
    deliveryMethods: string;
    attendees: string;
    shippingAddress:shippingAddressDto|null;
}
export interface shippingAddressDto{
  address:string;
  country:string;
  city:string;
}

export interface Addon {
    id: string;
    aname: string;
    aqty: number;
    afinalPrice: number;
    aantaresFees: number;
    afeesPaid: number;
    apaymentFees: number;
    ashippingFees: number;
    adeliveryMethods: string;
    shippingAddress:shippingAddressDto|null;
}

 export interface Order {
    orderId: string;
    status: number;
    orderNum: number;
    orderDate: string;
    orderFormAn: string;
    userEmail:string
    userFirstName:string
    userLastName:string
    tickets: Ticket[];
    addons: Addon[];
}
export interface salessummaryDto{
  
    name:string;
   salesType:string;
    qty:number;
   remainQty:number;
   soldItem :number;
   price:number;
   fees :number;
   gross:number;
   antaresFees:number;
   paymentfees:number;
   shippingfees:number;
   refunded:number;
   grossRefunded:number;
}
@Component({
    selector: 'app-orders-report',
    templateUrl: './orders-report.component.html',
    styleUrls: ['./orders-report.component.scss'],
})
export class OrdersReportComponent implements OnInit, OnDestroy {
   
    id:string="";
    @ViewChild('op') op: OverlayPanel;
    @ViewChild('dataTable') dataTable: Table;
    @ViewChild('addonsTable') addonsTable: Table;
    @ViewChild('ticketsTable') ticketsTable: Table;
    ddggrr: any[]=[];
    /*...seearch for sales summary report,,*/
    filterGlobal(value: string) {
     //   console.log(value)
        this.dataTable.filter(value, 'name', 'contains');
      }


      //exporrt to csv
      exportToCsv() {
        this.addonsTable.exportCSV();
      }
      exportTikToCSv(){
        this.ticketsTable.exportCSV();
      }
   
     
//dt.filterGlobal($event.target.value, 'contains')
/**
 * vareable decleration
 */  
      ReportType:string='Report type';
      OrderType:string='Order type';
      ConfigureSalestype:string='Configure Sales type'
      filtrDate:string='DATE'
      Perpage2:string='Per page'
    data: any[] = [];
    TicketsData:any[]=[];
    organizers: any;
    curData: any;
    reportType: any[] = [];//the prop that hold the item of dropdown item for  report type 
    orderType: any[] = [];//the prop that hold option of order type 
    dateFilter: any[] = [];//the prop that hold option for date filter
    dataFromDb: Order[];
    datatt: any[] = [];
    //prop that hold selected date range for filter data bu date
    cusytomrang: boolean = false;
    rangeDates: Date[];
    //prop that hold the  selected value of item in per page 
    perpage: number = 10;
    allColumn: any[] = []; //prop that hold all clumn of tickets sales type
    selectedconfigeSales: string[] = ['f', 'G', 'S'];
    //first coles for any tickets sales type
    cols: any[] = [
        { field: 'Order #', header: 'Order #' },
        { field: 'Order Date', header: 'Order Date' },
        { field: 'Ticket type', header: 'Ticket type' },
        { field: 'Quantity', header: 'Quantity' },
        { field: 'Attendees Count', header: 'Attendees count' },
        { field: 'Total', header: 'Total' },
        { field: 'Fees', header: 'Fees' },
        { field: 'Antares fees', header: 'Antares fees' },
        {
            field: 'Antares Payment Processing Fees',
            header: 'Antares Payment Processing Fees',
        },
     
    ];
    //the prop hold all orderform col name
    dynmiccols: any[] = [];
    _DynmicOrderForm: any[] = [];
    @Input() get selectedColumns(): any[] {
        return this._DynmicOrderForm;
    }
    set selectedColumns(val: any[]) {
        //restore original order
        this._DynmicOrderForm = this.dynmiccols.filter((col) =>
            val.includes(col)
        );
        this.allColumn = [...this.cols, ...this._DynmicOrderForm];
    }
    ConfigesalesOption: DropDownItem[] = [];
    SalesType: { id: string; label: any; command: (item: any) => void }[];
    subs: Subscription[] = [];
    //the prop  hold true if report for tickets or hold false if report for add_ons
     isticketsSales:boolean=true;
     //the prop for define that the report for sales summary
    isSalesReport: boolean=false;
   /** the prop will j-hold add ons datafor addons report
    */
   
    addonsData:any[]=[];
    DataForReport:any[]=[];
    private subject2: Subject<any[]> = new ReplaySubject<any[]>(1);
    
    get $getSubject2(): Observable<any[]> {
      return this.subject2.asObservable();
    }
    /**
    * prop for hold columns for add ons report
    */
    AddOnsCols:any[]=[
        { field: 'Order #', header: 'Order #' },
        { field: 'Order Date', header: 'Order Date' },
        { field: 'Add-Ons', header: 'Add-Ons' },
        { field: 'Quantity', header: 'Quantity' },
        {field: 'First name', header: 'First name'},
        {field: 'Last name', header: 'Last name'},
        {field: 'Email', header: 'Email'},
        { field: 'Total', header: 'Total' },
        { field: 'Fees', header: 'Fees' },
        { field: 'Antares fees', header: 'Antares fees' },
        {
            field: 'Antares Payment Processing Fees',
            header: 'Antares payment processing fees',
        },
     
    ]
/**
 * the sales smmary report prop and func
 */
    Salescols:any[]=[
        { field: 'salesType', header: ' grop'},
        { field: 'name', header: 'Sales '},
        { field: 'price', header: 'Price '},
        { field: 'qty', header: 'Quantity '},
        { field: 'gross', header: 'Gross '},
        { field: 'antaresFees', header: 'Antares Fees '},
        { field: 'paymentfees', header: 'Antares Payment Processing Fees '},
        { field: 'shippingfees', header: 'Shipping Fees '},
        { field: 'fees', header: 'Net Sales '},
        { field: 'refunded', header: 'Qty Tickets Refunded '},
        { field: 'grossRefunded', header: 'Gross Refunded '},
      ]
      SalesSumrepData:salessummaryDto[]=[];
      private subject1: Subject<salessummaryDto[]> = new ReplaySubject<salessummaryDto[]>(1);
      get $getSubject1(): Observable<salessummaryDto[]> {
        return this.subject1.asObservable();
      }
      GetSalesReportData(id){
        this.http.get<salessummaryDto[]>(environment.Api+'d/At/g/t/s/'+this.id).subscribe(x=>{
      console.log(x);
      this.subject1.next(x)
        })
      }
    //filtertickets reportdata
    getFilter(e){
      
      var tes=e.target.value
      var m=this.TicketsData.filter(x=>x['Order #']==tes||
      x['Ticket type'].includes(tes)||x['Shipping Address'].includes(tes)
      ||x['Country'].includes(tes)||x['City'].includes(tes)||
      x['Atendees'].some(item => {
        for (let prop in item) {
            if (item[prop] === tes||item[prop].includes(tes)) {
              return true;
            }
          }
          return false;
        
      })
      )
      this.subject4.next(m)
    }
/** perpage option  and  filter data for ticket sales  by date option */
    perpageitem = [
        {
            label: '5',
            id: '5',
            command: (item) => {
                this.Perpage2=item.item.label
                this.perpage = item.item.id;
            },
        },
        {
            label: '10',
            id: '10',
            command: (item) => {
                this.Perpage2=item.item.label
                this.perpage = item.item.id;
            },
        },
        {
            label: '20',
            id: '20',
            command: (item) => {
                this.Perpage2=item.item.label
                this.perpage = item.item.id;
            },
        },
        {
            label: '50',
            id: '50',
            command: (item) => {
                this.Perpage2=item.item.label
                this.perpage = item.item.id;
            },
        },
        {
            label: '100',
            id: '100',
            command: (item) => {
                this.Perpage2=item.item.label
                this.perpage = item.item.id;
            },
        },
        {
            label: '200',
            id: '200',
            command: (e) => {
                this.Perpage2=e.item.label
                this.perpage = e.item.id;
            },
        },
    ];

    filterAddOns(e){
        var tes=e.target.value
        var m=this.addonsData.filter(x=>x['Order #']==tes||
        x['Add-Ons'].includes(tes)||x['Shipping Address'].includes(tes)
        ||x['Country'].includes(tes)||x['City'].includes(tes)||
        x['First name'].includes(tes)|| x['Last name'].includes(tes)||
        x['Email'].includes(tes)
        )
        this.subject2.next(m)
    }
    filterdata(e) {
      //  console.log(this.rangeDates);
        var t = this.data.filter(
            (x) =>
                moment(this.getDateOntomeZone(x.orderDate)).isSameOrAfter(
                    this.rangeDates[0]
                ) &&
                moment(this.getDateOntomeZone(x.orderDate)).isSameOrBefore(
                    this.rangeDates[1]
                )
        );
        this.subject.next(t);
        var m= this.TicketsData.filter(
            (x) =>
                moment(this.getDateOntomeZone(x.date)).isSameOrAfter(
                    this.rangeDates[0]
                ) &&
                moment(this.getDateOntomeZone(x.date)).isSameOrBefore(
                    this.rangeDates[1]
                )
        );

        this.subject4.next(m);
        var s=this.addonsData.filter(
            (x) =>
                moment(this.getDateOntomeZone(x.date)).isSameOrAfter(
                    this.rangeDates[0]
                ) &&
                moment(this.getDateOntomeZone(x.date)).isSameOrBefore(
                    this.rangeDates[1]
                )
        );
        

         
           this.subject2.next(s)
    }
//** subject for ticket sales data  and methhodes that return it* */
    private subject: Subject<any[]> = new ReplaySubject<any[]>(1);

    get $getSubject(): Observable<any> {
        return this.subject.asObservable();
    }
    private subject4: Subject<any[]> = new ReplaySubject<any[]>(1);

    get $getSubject4(): Observable<any> {
       
        return this.subject4.asObservable();
    }
    constructor(
        private translate: TranslateService,
        private http: HttpClient,
        public EventSrv: EventStepsService,
        private datePipe: DatePipe,
        private editeSrv: EditAttendeesServiceService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
 
    ) {
        this.translate.onLangChange.subscribe(() => {
            this.updateLang();
            m.locale(this.translate.currentLang)
        });
    }
    ngOnDestroy(): void {
        this.subs.forEach((x) => {
            x.unsubscribe();
        });
    }


    setDisablyView(e) {
        // to show edit attendess and cancel option in sales tickets report
      //  console.log(e);
        this.op.toggle(e);
    }
    //func to great composit key for grouping the data in ticket sales report
    getDataKey(event) {
        return event.col1 + event.col2;
    }
    //fun to display date in tale
    getDateOntomeZoneformat(d, x) {
        m.locale()
        var eventOffset = this.EventSrv.offSet * 60 ;
        
       // var eventOffset = 60;
        var MyDate = new Date(d).setMinutes(
            new Date(d).getMinutes() + eventOffset
        );
        var l = '';
        switch (x) {
            case 'full':
                if (
                    new Date(MyDate).getFullYear() ==
                    new Date(Date.now()).getFullYear()
                ) {
                    l = m(new Date(MyDate)).format('ddd MMMM D hh:mm a')
                    // this.datePipe.transform(
                    //     new Date(MyDate),
                    //     'EEE MMMM d hh:mm a'
                    // );
                } else {
                    l = m(new Date(MyDate)).format( 'ddd MMMM D,y hh:mm a')
                    //  this.datePipe.transform(
                    //     new Date(MyDate),
                    //     'EEE MMMM d,y hh:mm a'
                    // );
                }
                break;
            case 'month':
                l = this.datePipe.transform(new Date(MyDate), 'MMM');
                break;
            case 'day':
                l = this.datePipe.transform(new Date(MyDate), 'dd');
                break;
        }
        return l;

        // return new Date(MyDate).toLocaleString();
    }
    //
    updateLang() {
       
        this.ConfigesalesOption = [
            { id: 'f', name: this.translate.instant('Include Fees') },
            {
                id: 'G',
                name: this.translate.instant('Display Gross Ticket Sales'),
            },
            { id: 'S', name: this.translate.instant('Display Shipping') },
        ];
        this.reportType = [
            {
                id: 'Orders',
                label: this.translate.instant('Orders'),
                command: (e) => {
                  // console.log(e)
                   this.ReportType=e.item.label
                    this.cols = [
                        { field: 'Order #', header: 'Order #' },
                        { field: 'Order Date', header: 'Order Date' },
                        { field: 'Ticket type', header: 'Ticket type' },
                        { field: 'Quantity', header: 'Quantity' },
                        { field: 'Total', header: 'Total' },
                        { field: 'Attendees Count', header: 'Attendees count' },
                        { field: 'Attendee #', header: 'Attendee #' },
                    ];
                    this.AddOnsCols=[
                        { field: 'Order #', header: 'Order #' },
                        { field: 'Order Date', header: 'Order Date' },
                        { field: 'Add-Ons', header: 'Add-Ons' },
                        {field: 'First name', header: 'First name'},
                        {field: 'Last name', header: 'Last name'},
                        {field: 'Email', header: 'Email'},
                        { field: 'Quantity', header: 'Quantity' },
                        { field: 'Total', header: 'Total' },
                        
                    ]
                    this.allColumn = [...this.cols, ...this._DynmicOrderForm];
                    this.isSalesReport=false;
                },
            },
            {id:"Sales Summary",label:this.translate.instant('Sales Summary'),command:(e)=>{
               this.isSalesReport=true;
               this.ReportType=e.item.label
             }
            },
            {
                id: 'AttendeeSummary',
                label: this.translate.instant('Attendee Summary'),
                command: (e) => {
                  //  console.log()
                    this.ReportType=e.item.label
                    this.cols = [
                        { field: 'Order #', header: 'Order #' },
                        { field: 'Order Date', header: 'Order Date' },
                        { field: 'Ticket type', header: 'Ticket type' },
                        { field: 'Quantity', header: 'Quantity' },
                        { field: 'Total', header: 'Total' },
                        { field: 'Order Type', header: 'Order Type' },
                        { field: 'Attendee #', header: 'Attendee #' },
                        { field: 'Attendees Count', header: 'Attendees count' },
                        { field: 'Attendee Status', header: 'Attendee Status' },
                        { field: 'Total', header: 'Total' },
                        { field: 'Fees', header: 'Fees' },
                        { field: 'Antares fees', header: 'Antares fees' },
                        {
                            field: 'Antares Payment Processing Fees',
                            header: 'Antares Payment Processing Fees',
                        },
                    ];
                    this.AddOnsCols=[
                        { field: 'Order #', header: 'Order #' },
                        { field: 'Order Date', header: 'Order Date' },
                        { field: 'Add-Ons', header: 'Add-Ons' },
                        { field: 'Quantity', header: 'Quantity' },
                        {field: 'First name', header: 'First name'},
                        {field: 'Last name', header: 'Last name'},
                        {field: 'Email', header: 'Email'},
                        { field: 'Total', header: 'Total' },
                        { field: 'Fees', header: 'Fees' },
                        { field: 'Antares fees', header: 'Antares fees' },
                        {
                            field: 'Antares Payment Processing Fees',
                            header: 'Antares Payment Processing Fees',
                        },
                    ]
                    this.allColumn = [...this.cols, ...this._DynmicOrderForm];
                    this.isSalesReport=false;
                },
            },
            {
                id: 'Custom ',
                label: this.translate.instant('Custom Questions Responses'),
                command: (e) => {
                    //  console.log()
                      this.ReportType=e.item.label
                      this.cols = [
                          { field: 'Order #', header: 'Order #' },
                          { field: 'Order Date', header: 'Order Date' },                         
                          { field: 'Order Type', header: 'Order Type' },
                          { field: 'Attendee #', header: 'Attendee #' },
                          { field: 'Attendees Count', header: 'Attendees count' },         
                         
                      ];
                      this.AddOnsCols=[
                          { field: 'Order #', header: 'Order #' },
                          { field: 'Order Date', header: 'Order Date' },
                          { field: 'Add-Ons', header: 'Add-Ons' },
                          { field: 'Quantity', header: 'Quantity' },
                          {field: 'First name', header: 'First name'},
                          {field: 'Last name', header: 'Last name'},
                          {field: 'Email', header: 'Email'},
                          { field: 'Total', header: 'Total' },
                          { field: 'Fees', header: 'Fees' },
                          { field: 'Antares fees', header: 'Antares fees' },
                          {
                              field: 'Antares Payment Processing Fees',
                              header: 'Antares Payment Processing Fees',
                          },
                      ]
                      this.allColumn = [...this.cols, ...this._DynmicOrderForm];
                      this.isSalesReport=false;
                  },
            },
            {
                id: 'SalesByTicket',
                label: this.translate.instant('Sales By Ticket Type'),
                command: (e) => {
                    this.ReportType=e.item.label
                  this.cols = [
                      { field: 'Order #', header: 'Order #' },
                      { field: 'Order Date', header: 'Order Date' },
                      { field: 'Ticket type', header: 'Ticket type' },
                      { field: 'Quantity', header: 'Quantity' },
                      { field: 'Total', header: 'Total' },
                      { field: 'Fees', header: 'Fees' },
                      { field: 'Antares fees', header: 'Antares fees' },
                      {
                          field: 'Antares Payment Processing Fees',
                          header: 'Antares Payment Processing Fees',
                      },
                      { field: 'Shipping Address', header: 'Shipping Address' },
                      { field: 'Country', header: 'Country' },
                      { field: 'City', header: 'City' },
                      { field: 'Attendees Count', header: 'Attendees count' },
                      { field: 'Attendee #', header: 'Attendee #' },
                  ];

                  this.AddOnsCols=[
                    { field: 'Order #', header: 'Order #' },
                    { field: 'Order Date', header: 'Order Date' },
                    { field: 'Add-Ons', header: 'Add-Ons' },
                    { field: 'Quantity', header: 'Quantity' },
                    {field: 'First name', header: 'First name'},
                    {field: 'Last name', header: 'Last name'},
                    {field: 'Email', header: 'Email'},
                    { field: 'Total', header: 'Total' },
                    { field: 'Fees', header: 'Fees' },
                    { field: 'Antares fees', header: 'Antares fees' },
                    {
                        field: 'Antares Payment Processing Fees',
                        header: 'Antares payment processing fees',
                    },
                    { field: 'Shipping Address', header: 'Shipping Address' },
                    { field: 'Country', header: 'Country' },
                    { field: 'City', header: 'City' },
                ]
                  this.allColumn = [...this.cols, ...this._DynmicOrderForm];
                  this.isSalesReport=false;
              },

            },
            { id: 'Attendees', label: this.translate.instant('Attendees')
            , command: (e) => {
                this.ReportType=e.item.label
              this.cols = [
                  { field: 'Order #', header: 'Order #' },
                  { field: 'Order Date', header: 'Order Date' },
                  { field: 'Ticket type', header: 'Ticket type' },
                  { field: 'Quantity', header: 'Quantity' },
                  { field: 'Total', header: 'Total' },
                  { field: 'Fees', header: 'Fees' },
                  { field: 'Antares fees', header: 'Antares fees' },
                  {
                      field: 'Antares Payment Processing Fees',
                      header: 'Antares Payment Processing Fees',
                  },
                  { field: 'Attendees Count', header: 'Attendees count' },
                  { field: 'Attendee #', header: 'Attendee #' },
              ];
              this.allColumn = [...this.cols, ...this._DynmicOrderForm];
              this.isSalesReport=false;
          },
           },
        ];
        this.orderType = [
            {
                id: 'Completed',
                label: this.translate.instant('All Completed Orders'),
                command: (e) => {
                    this.OrderType=e.item.label
                    var t = this.data.filter((x) => x.status == 1);
                    var m=this.TicketsData.filter(x=>x['Order Type']=='Complete')
                    var s=this.addonsData.filter(x=>x['Order Type']=='Complete')
                    
                 //   console.log(m)
                    this.ddggrr=[];
                    this.DataForReport=[];
                    this.subject2.next(s)
                    this.subject.next(t);
                    this.subject4.next(m);
                    //this.update();
                },
            },
            {
                id: 'Orders',
                label: this.translate.instant('All Orders'),
                command: (e) => {
                    this.OrderType=e.item.label
                    var t = this.data;
                    var m=this.TicketsData;
                    var s=this.addonsData
                  //  console.log(m)
                    this.ddggrr=[];
                    this.DataForReport=[];
                    this.subject2.next(s)
                    this.subject.next(t);
                    this.subject4.next(m);
                    //this.update();
                },
            },
            {
                id: 'Refund',
                label: this.translate.instant('All Refund Requests'),
                command: (e) => {
                    this.OrderType=e.item.label
                    var t = this.data.filter((x) => x.status == 99);
                    var m=this.TicketsData.filter(x=>x['Order Type']=='Canceled')
                    var s=this.addonsData.filter(x=>x['Order Type']=='Canceled')
                    //   console.log(m)
                       this.DataForReport=[];
                       this.subject2.next(s)
                   // console.log(m)
                    this.ddggrr=[];
                    this.subject.next(t);
                    this.subject4.next(m);
                    //this.update();
                },
            },
        ];

        this.SalesType = [
            {
                id: 'Tickets',
                label: this.translate.instant('Tickets Sales'),
                command: (e) => {
                   this. ConfigureSalestype=e.item.label;
                   this.isticketsSales=true;
                   // this.subject.next(t);
                    //this.update();
                },
            },
            {

                id: 'Add-ons',
                label: this.translate.instant('Add-ons Sales'),
                command: (e) => {
                    this. ConfigureSalestype=e.item.label
                    this.isticketsSales=false;
                },
            },
        ];
        this.dateFilter = [
            {
                id: 'Any Time',
                label: this.translate.instant('Any Time'),
                command: (e) => {
                    this.filtrDate=e.item.id
                    this.cusytomrang = false;
                    var t = this.data;
                    var m=this.TicketsData;
                    var s=this.addonsData;
                    
                    //   console.log(m)
                     
                       this.subject2.next(s)
                    this.subject.next(t);
                    this.subject4.next(m)
                    //this.update();
                },
            },
            {
                id: 'This Month',
                label: this.translate.instant('This Month'),
                command: (e) => {
                    this.filtrDate=e.item.id
                    this.cusytomrang = false;
                    // console.log(this.getDateOntomeZone(this.data[0].orderDate).getMonth())
                    // console.log(this.getDateOntomeZone(new Date(Date.now())).getMonth())
                    var t = this.data.filter(
                        (x) =>
                            this.getDateOntomeZone(x.orderDate).getMonth() ==
                            this.getDateOntomeZone(
                                new Date(Date.now())
                            ).getMonth()
                    );
                    var m= this.TicketsData.filter(
                        (x) =>
                            this.getDateOntomeZone(new Date(x.date)).getMonth() ===
                            this.getDateOntomeZone(
                                new Date(Date.now())
                            ).getMonth()
                    );

                    var s=this.addonsData.filter(
                        (x) =>
                            this.getDateOntomeZone(new Date(x.date)).getMonth() ===
                            this.getDateOntomeZone(
                                new Date(Date.now())
                            ).getMonth()
                    );
                    
            
                     
                       this.subject2.next(s)
                    this.subject.next(t);
this.subject4.next(m);
                    //this.update();
                },
            },
            {
                id: 'This week',
                label: this.translate.instant('This week'),
                command: (e) => {
                    this.filtrDate=e.item.id
                    this.cusytomrang = false;
                    //var dateoflastdayofweek=new Date(Date.now()).setDate(new Date(Date.now()).getDate()+7)
                    var t = this.data.filter(
                        (x) =>
                            this.getDateOntomeZone(x.orderDate).getDate() >=
                            this.getDateOntomeZone( new Date(Date.now())).getDate() &&
                            this.getDateOntomeZone(x.orderDate).getDate() <
                            this.getDateOntomeZone( new Date(Date.now())).getDate() + 7
                    );
                    var m= this.TicketsData.filter(
                        (x) =>
                        this.getDateOntomeZone(x.date).getDate() >=
                        this.getDateOntomeZone( new Date(Date.now())).getDate() &&
                        this.getDateOntomeZone(x.date).getDate() <
                        this.getDateOntomeZone( new Date(Date.now())).getDate() + 7
                    );
                    var s=this.addonsData.filter(
                        (x) =>
                        this.getDateOntomeZone(x.date).getDate() >=
                        this.getDateOntomeZone( new Date(Date.now())).getDate() &&
                        this.getDateOntomeZone(x.date).getDate() <
                        this.getDateOntomeZone( new Date(Date.now())).getDate() + 7
                    );
                    
            
                     
                       this.subject2.next(s)
                    this.subject.next(t);
                    this.subject4.next(m);
                  
                    //this.update();
                },
            },
            {
                id: 'Custom Date Range',
                label: this.translate.instant('Custom Date Range'),
                command: (e) => {
                    this.filtrDate=e.item.id
                    this.cusytomrang = true;
                },
            },
            {
                id: 'This Year',
                label: this.translate.instant('This Year'),
                command: (e) => {
                    this.filtrDate=e.item.id
                    this.cusytomrang = false;
                    var t = this.data.filter(
                        (x) =>
                            this.getDateOntomeZone(x.orderDate).getFullYear() ==
                            new Date(Date.now()).getFullYear()
                    );
                    var m= this.TicketsData.filter(
                        (x) =>
                        this.getDateOntomeZone(x.date).getFullYear() ==
                        new Date(Date.now()).getFullYear()
                    );
                    var s=this.addonsData.filter(
                        (x) =>
                        this.getDateOntomeZone(x.date).getFullYear() ==
                        new Date(Date.now()).getFullYear()
                    );
                    
            
                     
                       this.subject2.next(s)
                    this.subject.next(t);
this.subject4.next(m);
                 
                    //this.update();
                },
            },
        ];
        this.GetDataFromDb();

    //    this.GenerateColsFotTable(this.dataFromDb)
    }
    ngOnInit(): void {

        this.ReportType='Report type';
        this.OrderType='Order type';
        this.ConfigureSalestype='Configure Sales type'
        this.filtrDate='DATE'
        this.Perpage2='Per page'
        var o=this.EventSrv.state$.subscribe(event=>{
             this.id=event.id;

             console.log('eventsrv state')
             this.updateLang();
             this.GetDataFromDb();
             var l = this.$getSubject.subscribe((x) => {
                //  console.log(x);
                  //this.GenerateColsFotTable(x)
                  this.datatt = x;
              });
              var j=this.$getSubject2.subscribe((x)=>{
                  this.DataForReport=x;
              })
    
            var m=  this.$getSubject4.subscribe(x=>{
               
                console.log('state')
                  this.ddggrr=x;
              },)
               this.subs.push(m)
              this.subs.push(l);
              this.GetSalesReportData("kkk");
              this.$getSubject1.subscribe(x=>{
                  
                this.SalesSumrepData=x;
              })

        })
        this.subs.push(o)
       
        

      
    }
    //func to get data from backend
    GetDataFromDb() {
        this.http
            .get<Order[]>(
                environment.Api + 'd/At/g/'+this.id
            )
            .subscribe((item) => {
                this.dataFromDb = item;
                 
                this.GenerateColsFotTable(item);
                console.log(item);
                this.TicketsData=[];

                item.forEach((x) => {
                    //this.getlistfor(x);
                    this.GetAddOnsDataForReport(x)
                    this.GetDataForReport(x);
                    this.GeTticketsOrderProprtDate(x);
                });
                // console.log(this.data)
                this.subject.next(this.data);
                this.subject2.next(this.addonsData);
                this.subject4.next(this.TicketsData);
            });
    }
 

    GetAddOnsDataForReport(item:Order){
         var d=[];
         var i=this.addonsData.length
         item.addons.forEach((x)=>{
          //  console.log(x)
         
          var   l = {
                        SortId: item.orderNum ,
                        Id:x.id,
                        date:item.orderDate,
                        '#': i,
                        'Order #': item.orderNum,
                        'Order Date':
                            item.orderDate,
                           
                        
                        'Add-Ons': x.aname,
                        Quantity: x.aqty,
                        Total: x.afinalPrice,
                        'Antares fees': x.aantaresFees,
                        Fees: x.afeesPaid,
                        'Antares Payment Processing Fees': x.apaymentFees,
                        'Shipping Fees': x.ashippingFees,
                        'Order Type':
                            item.status == 1 ? 'Complete' : 'Canceled',
                            'Shipping Address':x.shippingAddress!=null? x.shippingAddress.address :item.userEmail, 
                            'Country':x.shippingAddress!=null?x.shippingAddress.country :item.userEmail,
                            'City':x.shippingAddress!=null?x.shippingAddress.city :item.userEmail,
                            'First name':item.userFirstName,
                             'Last name':item.userLastName,
                             'Email':item.userEmail

                    };
                    this.addonsData.push(l);  
                    i++;

         })
       
    }
    //fun to convser data to tickets sales report data
    GetDataForReport(item: Order) {
        //  var orderFormAn= this.getattendess(item);
        var d = [];
        var i = this.data.length;
        item.tickets.forEach((x) => {
            // console.log('first x in getdata that give to get attendees',x);
            var orderFormAn = this.GetAttendess(x);
            orderFormAn.forEach((y, j) => {
                var l;
                if (j == 0) {
                    l = {
                        SortId: item.orderNum + '-' + x.name,
                        '#': i,
                        'Order #': item.orderNum,
                        'Order Date': 
                            item.orderDate,
                         
                        'Ticket type': x.name,
                        Quantity: x.qty,
                        Total: x.finalPrice,
                        'Antares fees': x.antaresFees,
                        Fees: x.feesPaid,
                        'Antares Payment Processing Fees': x.paymentFees,
                        'Shipping Fees': x.shippingFees,
                        'Attendees Count': 0,
                        'Order Type':
                            item.status == 1 ? 'Complete' : 'Canceled',
                        'Attendee Status': JSON.parse(x.attendees).order[j]
                            .status,
                        'Attendee #': JSON.parse(x.attendees).order[j]
                            .attendeesNum,
                            'Shipping Address':x.shippingAddress!=null? x.shippingAddress.address :item.userEmail, 
                            'Country':x.shippingAddress!=null?x.shippingAddress.country :item.userEmail,
                            'City':x.shippingAddress!=null?x.shippingAddress.city :item.userEmail

                    };
                    l['Attendees Count'] = orderFormAn.length;
                } else {
                    l = {
                        SortId: item.orderNum + '-' + x.name,
                        '#': i,
                        'Order #': '',
                        'order Date': '',
                        'Ticket type': '',
                        Quantity: '',
                        Total: '',
                        'Antares fees': '',
                        Fees: '',
                        'Antares Payment Processing Fees': '',
                        'Shipping Fees': '',
                        'Attendees Count': '',
                        'Order Type': '',
                        'Attendee Status': JSON.parse(x.attendees).order[j]
                            .status,
                        'Attendee #': JSON.parse(x.attendees).order[j]
                            .attendeesNum,
                          'Shipping Address':x.shippingAddress!=null? x.shippingAddress.address :item.userEmail, 
                          'Country':x.shippingAddress!=null?x.shippingAddress.country :item.userEmail,
                          'City':x.shippingAddress!=null?x.shippingAddress.city :item.userEmail
                    };
                }
                var filteredPropertyNames = Object.keys(y).filter(
                    (key) => y[key] !== null
                );
                this.AddProperties(filteredPropertyNames, l, y);
                this.data.push(l);
                i++;
            });
        });
    }


    //function for get data for tickets bu tickets type
    GeTticketsOrderProprtDate(item:Order){
        // console.log(item)
        var d = [];
    
         
        item.tickets.forEach((x) => {

          var  l = {
                 date:item.orderDate,
                'Order #': item.orderNum,
                'Order Date': 
                    item.orderDate,
                 
               
                'Ticket type': x.name,
                Quantity: x.qty,
                Total: x.finalPrice,
                'Antares fees': x.antaresFees,
                Fees: x.feesPaid,
                'Antares Payment Processing Fees': x.paymentFees,
                'Shipping Fees': x.shippingFees,
                'Attendees Count': 0,
                'Order Type':
                    item.status == 1 ? 'Complete' : 'Canceled',
               
              
                    'Shipping Address':x.shippingAddress!=null? x.shippingAddress.address :item.userEmail, 
                    'Country':x.shippingAddress!=null?x.shippingAddress.country :item.userEmail,
                    'City':x.shippingAddress!=null?x.shippingAddress.city :item.userEmail,
                'Atendees':[]
            };
            var orderFormAn = this.GetTicketsAttendess(x);
        //    console.log(orderFormAn);

            l['Atendees']=orderFormAn;
            l['Attendees Count'] = orderFormAn.length;
         
            // orderFormAn.forEach((y, j) => {
              
            //     if (j == 0) {
                    
            //         l['Attendees Count'] = orderFormAn.length;
            //     } else {
                   
            //     }
            //     var filteredPropertyNames = Object.keys(y).filter(
            //         (key) => y[key] !== null
            //     );
            //     this.AddProperties(filteredPropertyNames, l, y);
            
               this.TicketsData.push(l);
            //     i++;
            // });
        });
    }
//function to get tickets all atenDees
GetTicketsAttendess(item: Ticket) {

    //console.log(item)
    var orderformData = JSON.parse(item.attendees);
    //console.log( orderformData)
   // console.log(orderformData)
    var filteredPropertyNames = Object.keys(
        orderformData.order[0].Answer
    ).filter((key) => orderformData.order[0].Answer[key] !== null);
  //  console.log(filteredPropertyNames);
    var resOrderForm = [];
    orderformData.order.forEach((x) => {
        var l = { 
            'Attendee #':x.attendeesNum,
            'Attendee Status':x.status
        };
        this.AddProperties(filteredPropertyNames, l, x.Answer);
        resOrderForm.push(l);
    });

   
    return resOrderForm;
}

    //func for get attendees for each tickets and mack new row data
    GetAttendess(item: Ticket) {
        //console.log(item)
        var orderformData = JSON.parse(item.attendees);
       // console.log( orderformData)
       // console.log(orderformData)
        var filteredPropertyNames = Object.keys(
            orderformData.order[0].Answer
        ).filter((key) => orderformData.order[0].Answer[key] !== null);
      //  console.log(filteredPropertyNames);
        var resOrderForm = [];
        orderformData.order.forEach((x) => {
            var l = { };
            this.AddProperties(filteredPropertyNames, l, x.Answer);
            resOrderForm.push(l);
        });
        return resOrderForm;
    }
    //fun for add some prop name:value to object
    AddProperties(prop, object, item): void {
        return prop.reduce((obj, propertyName) => {
            obj[propertyName] = this.GetObjectPropertyValue(propertyName, item);
            return obj;
        }, object);
    }
    //fun to return all  object prop
    GetObjectPropertyValue(propertyName: string, item): any {
        return item[propertyName];
    }

    //fun to get orderform filed and convert it to column
    GenerateColsFotTable(x) {
        // console.log(x)
        var newCol = JSON.parse(this.dataFromDb[0].orderFormAn);
        // console.log(newCol)
        //console.log(newCol.order[0])
        var filteredPropertyNames;
        // console.log(Array.isArray(newCol))
        if(Array.isArray(newCol)){
            filteredPropertyNames = Object.keys(
                newCol[0].order[0].Answer
            ).filter((key) => newCol[0].order[0].Answer[key] !== null);
        }
        else{
           filteredPropertyNames = Object.keys(
                newCol.order[0].Answer
            ).filter((key) => newCol.order[0].Answer[key] !== null);
        }
        var orderformcols = filteredPropertyNames.map((p) => ({
            field: p,
            header:this.translate.instant(p.charAt(0).toUpperCase() + p.slice(1)),
        }));
        this.dynmiccols = [];
        orderformcols.forEach((x) => {
            //this.cols.push(x)
            this.dynmiccols.push(x);
        });
        this._DynmicOrderForm = this.dynmiccols;
        this.allColumn = [...this.cols, ...this._DynmicOrderForm];
        //console.log(this.allColumn)
        //console.log(this._DynmicOrderForm)
    }

    getDateOntomeZone(d: Date | number) {
       // console.log(d)
         var eventOffset = this.EventSrv.offSet * 60 ;
        // console.log(eventOffset)
        // var eventOffset = 60;
        var MyDate = new Date(d).setMinutes(
            new Date(d).getMinutes() + eventOffset
        );
        return new Date(MyDate);
    }
    checkifhaveattend(columns) {
        return (
            columns.find((x) => x.field == 'Attendee Status') != undefined ||
            columns.find((x) => x.field == 'Attendee #') != undefined
        );
    }
    addSomeCoulmn(e) {
       // console.log(e);
    }

    //func to sort data in tickets report  pefor export it
    groupRowsBySortId(data: any[]): any[] {
        const groupedData = [];
        const sortIds = new Set(data.map((obj) => obj.SortId));

        sortIds.forEach((sortId) => {
            const group = {
                SortId: sortId,
                Rows: [],
            };

            const firstRow = data.find((obj) => obj.SortId === sortId);
            group.Rows.push(firstRow);

            const firstRowProps = Object.keys(firstRow);

            data.filter(
                (obj) => obj.SortId === sortId && obj != firstRow
            ).forEach((row) => {
                const newRow = {};
                Object.keys(row).forEach((prop) => {
                    if (
                        firstRow[prop] === row[prop] &&
                        prop != 'attendeStatus'
                    ) {
                        newRow[prop] = '';
                    } else {
                        newRow[prop] = row[prop];
                    }
                });
              //  console.log(newRow);
                //if (Object.keys(newRow).length > 0) {
                group.Rows.push(newRow);
                //  }
            });

            groupedData.push(group);
        });

        return groupedData;
    }
    exportToExcel(data: any[], filename: string): void {
        const groupedData = this.groupRowsBySortId(data);
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            groupedData.reduce((result, group) => {
                //  result.push({ Group: group.Group }); // Add a row for the group
                result.push(...group.Rows); // Add the rows of the group
                return result;
            }, []),
            { skipHeader: false }
        );
        // Hide the sortId column
        worksheet['!cols'] = [{ hidden: true }];

        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });

        FileSaver.saveAs(
            blob,
            filename + '_export_' + new Date().getTime() + '.xlsx'
        );
    }
    getAddDataFOrexport(){
        var allitem=[];
    this.DataForReport.forEach(el=>{
        var t={
            
         
            'Order #':  el['Order #'],
            'Order Date':  el['Order Date'],
            'Add-Ons': el['Add-Ons'],
            Quantity:  el['Quantity'],
            Total:  el['Total'],
            Fees:  el['Fees'],
            'Antares fees': el['Antares fees'],
            'Antares Payment Processing Fees':  el['Antares Payment Processing Fees'],
            'Shipping Fees':  el['Shipping Fees'],
            'Order Type': el['Order Type'],
                'Shipping Address': el['Shipping Address'], 
                'Country': el['Country'],
                'City': el['City'],
                'First name': el['First name'],
                 'Last name': el['Last name'],
                 'Email': el['Email']

        }
        allitem.push(t)
    });
    return allitem
    }
    exportAddToExcel(): void {
      var data= this.getAddDataFOrexport();
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            data,
            { skipHeader: false }
        );
        // Hide the sortId column
      //  worksheet['!cols'] = [{ hidden: true }];

        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });

        FileSaver.saveAs(
            blob,
            'Add-ons_Sales_export_' + new Date().getTime() + '.xlsx'
        );
          
        
    }


    getDataFOrexport(){
        var allitem=[];
    this.SalesSumrepData.forEach(el=>{
        var t={
             ' Sales type':el.salesType,
           'Sales':el.name,
            'Price ':el.price,
           'Quantity ':el.qty,
           'Gross ':el.fees,
           'Antares Fees ':el.antaresFees,
           'Antares Payment Processing Fees ':el.paymentfees,
           'Shipping Fees ':el.shippingfees,
            'Net Sales ':el.fees,
             'Qty Tickets Refunded ':el.refunded,
            'Gross Refunded ':el.grossRefunded
        }
        allitem.push(t)
    });
    return allitem
    }
    exportsalesToExcel(): void {
      var data= this.getDataFOrexport();
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            data,
            { skipHeader: false }
        );
        // Hide the sortId column
      //  worksheet['!cols'] = [{ hidden: true }];

        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });

        FileSaver.saveAs(
            blob,
            'SalesSummary_export_' + new Date().getTime() + '.xlsx'
        );
          
        
    }

    edit(s){
      //console.log(s);
        // var orderNumindex = s.SortId.indexOf('-');
        // var orderNum = s.SortId.substring(0, orderNumindex) as number;
        // var ticket = s.SortId.substring(orderNumindex + 1);

       // console.log(orderNum);
      //  console.log(ticket);
      var orderNum = s[0]['Order #'] as number;
      var ticket = s[0]['Ticket type'] ;
        var t = this.dataFromDb.find((x) => x.orderNum == orderNum).tickets;
        var selectedtickets = t.find((x) => x.name == ticket).attendees;
        var mmm = JSON.parse(selectedtickets);
        this.editeSrv.myticket = mmm;
        this.editeSrv.attendeesnum = s[1]['Attendee #'];
        this.editeSrv.ticketID = t.find((x) => x.name == ticket).id;
      //  console.log(this.editeSrv.attendeesnum);
      //  console.log(t);
      //  console.log(selectedtickets);
       // console.log(mmm);
        this.router.navigate(['Edit-attendee', this.editeSrv.attendeesnum], {
            relativeTo: this.activatedRoute.parent,
        });
    }
    canseld(s) {
        //console.log(s)
        var url = environment.Api + 'd/At/c/a';
        //var orderNumindex = s.SortId.indexOf('-');
        //var orderNum = s.SortId.substring(0, orderNumindex) as number;
      //  var ticket = s.SortId.substring(orderNumindex + 1);
      var orderNum = s[0]['Order #'] as number;
      var ticket = s[0]['Ticket type'] ;
        var t = this.dataFromDb.find((x) => x.orderNum == orderNum).tickets;
        var selectedtickets = t.find((x) => x.name == ticket).attendees;
        var mmm = JSON.parse(selectedtickets);
       // console.log(mmm)
        var l = mmm.order.find((x) => x.attendeesNum == s[1]['Attendee #']);
      //  console.log(l)
        l.status = 'canceled';
        var f = {
            ticket: t.find((x) => x.name == ticket).id,
            order: JSON.stringify(mmm),
        };
        this.http.post(url, f).subscribe((x) => {
            this.datatt = [];
            this.DataForReport=[];
            this.ddggrr = [];
            this.TicketsData=[];
            this.GetDataFromDb();
        });
    }

  

    getsumTotal(name){
        var total=0;
        for (let customer of this.SalesSumrepData) {
          if (customer.salesType === name) {
              total+=customer.fees;
          }
      }
      return total;
      }

      getsum2Total(name){
        var total=0;
        for (let customer of this.SalesSumrepData) {
          if (customer.salesType === name) {
              total+=customer.gross;
          }
      }
      return total;
      }
    
      getRemainAMount(name){
        var total=0;
        for (let customer of this.SalesSumrepData) {
          if (customer.salesType === name) {
              total+=customer.remainQty;
          }
      }


     
      return total;
      }

      getallSalesTotal(){
        var total=0;
        for (let customer of this.SalesSumrepData) {
                total+=customer.fees;
        }
        return total;
      }
      getallSales2Total(){
        var total=0;
        for (let customer of this.SalesSumrepData) {
                total+=customer.gross;
        }
        return total;
      }

      getallRemainTotal(){
        var total=0;
        for (let customer of this.SalesSumrepData) {
            if(customer.salesType!='Offline Sales'&&customer.salesType!='AddOns Promo Sales'&&customer.salesType!='Tickets Promo Sales')
                total+=customer.remainQty;
        }
        return total;
      }
      getremainperQty(c:salessummaryDto){
        var str=""
        // if(c.salesType!='Offline Sales'&&c.salesType!='AddOns Promo Sales'&&c.salesType!='Tickets Promo Sales'){
            str= c.qty+"/"+c.remainQty;
        // }
        // else{
        //     str=c.soldItem.toString()
        // }
        return str;
       
}
      
      canseldAddons(x){
        var url = environment.Api + 'd/At/c/a/i/';
        this.http.get(url+x.id).subscribe(x=>{
            this.datatt = [];
            this.DataForReport=[];
            this.GetDataFromDb();
        });
      }
}
