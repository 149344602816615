<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">

                    <h3>{{'AddDateTitl'|translate}}</h3>
                    <p>{{'AddDateDisc'|translate}}</p>
                    <div class="btn-box">
                        <a (click)="goBack()" routerLink="/" class="rounded-pill save-btn"> <i style="font-size: 1.1rem"
                                class="fa-solid fa-clock"></i>{{'AddDate'|translate}}<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
