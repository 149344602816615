import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-antares-logo',
  templateUrl: './upload-antares-logo.component.html',
  styleUrls: ['./upload-antares-logo.component.scss']
})
export class UploadAntaresLogoComponent {

}
