import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Table,TableService } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { EditAttendeesServiceService } from '../edit-attendees-info/edit-attendees-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { distinct } from 'rxjs/operators';
import { salessummaryDto } from '../attendees-mangment.component';
//import fs from 'fs';

export interface AtendeelistticketVm
{
      id               :string;
      name             :string;
      qty              :number;  
      deliveryMethods  :string;
      attendees        :string;
      qrImage          :string;
      finalprice       :number;
}
export interface attendeslistRepDto
{
   orderId       :  string ;
   status        :  number ;  
   orderNum      :  number ;   
   orderDate     :  Date 
   atendesNum    :  string ;
   orderFormAn   :  string ;
   userEmail     :  string ;
   userLastName  :  string ;
   userFirstName :  string ;
   paymentway    :  string ;
    tickets :AtendeelistticketVm[];
 
}







@Component({
    selector: 'app-attendinglistco',
    templateUrl: './attendinglistco.component.html',
    styleUrls: ['./attendinglistco.component.scss'],
})
export class AttendinglistcoComponent implements OnInit {
    id: string = '';
    @ViewChild('op') op: OverlayPanel;
    @ViewChild('ticketsTable') ticketsTable: Table;

    dataFromDb: attendeslistRepDto[];
    /*...seearch for sales summary report,,*/
    
  
    ReportType: string = 'AtTendees status';
    OrderType: string = 'Order type';
    ConfigureSalestype: string = 'Configure Sales type';
    filtrDate: string = 'DATE';
    Perpage2: string = 'Per page';
    data: any[] = [];
    TicketsData: any[] = [];
    organizers: any;
    curData: any;
    reportType: any[] = []; //the prop that hold the item of dropdown item for  report type
    orderType: any[] = []; //the prop that hold option of order type
    dateFilter: any[] = []; //the prop that hold option for date filter
    url = environment.Api;
    datatt: any[] = [];
    //prop that hold selected date range for filter data bu date
    cusytomrang: boolean = false;
    rangeDates: Date[];
    //prop that hold the  selected value of item in per page

    allColumn: any[] = []; //prop that hold all clumn of tickets sales type

    //first coles for any tickets sales type
    cols: any[] = [
        { field: 'Order#', header: 'Order #' },
        { field: 'Order Date', header: 'Order Date' },
        { field: 'First name', header: 'First name' },
        { field: 'Last name', header: 'Last name' },
        { field: 'Email', header: 'Email' },
        { field: 'Quantity', header: 'Quantity' },
        { field: 'Ticket type', header: 'Ticket type' },
        { field: 'OrderType', header: 'OrderType' },
        { field: 'Attendee Status', header: 'Attendee Status' },
        { field: 'qr', header: 'Qr code' },
        
   
    ];
    //the prop hold all orderform col name

    ConfigesalesOption: DropDownItem[] = [];
    SalesType: { id: string; label: any; command: (item: any) => void }[];
    subs: Subscription[] = [];
    //the prop  hold true if report for tickets or hold false if report for add_ons
    isticketsSales: boolean = true;
    //the prop for define that the report for sales summary
    isSalesReport: boolean = false;
    /** the prop will j-hold add ons datafor addons report
     */

    addonsData: any[] = [];
    DataForReport: any[] = [];
    private subject2: Subject<any[]> = new ReplaySubject<any[]>(1);

    get $getSubject2(): Observable<any[]> {
        return this.subject2.asObservable();
    }
   
 
    SalesSumrepData: salessummaryDto[] = [];
    private subject1: Subject<any[]> = new ReplaySubject<any[]>(1);
    get $getSubject1(): Observable<any[]> {
        return this.subject1.asObservable();
    }
    GetSalesReportData(id) {
        this.http
            .get<salessummaryDto[]>(environment.Api + 'd/At/g/t/s/' + this.id)
            .subscribe((x) => {
                console.log(x);
                this.subject1.next(x);
            });
    }
    //filtertickets reportdata
    getFilter(e) {
        var tes = e.target.value;
        var m = this.data.filter(
            (x) =>
                x['Order #'] == tes ||
                x['Ticket type'].includes(tes) ||
              
                x['Atendees'].some((item) => {
                    for (let prop in item) {
                        if (item[prop] === tes || item[prop].includes(tes)) {
                            return true;
                        }
                    }
                    return false;
                })
        );
     
    }
    /** perpage option  and  filter data for ticket sales  by date option */

    filterdata(e) {
        //  console.log(this.rangeDates);
        var t = this.data.filter(
            (x) =>
                moment(this.getDateOntomeZone(x['Order Date'])).isSameOrAfter(
                    this.rangeDates[0]
                ) &&
                moment(this.getDateOntomeZone(x['Order Date'])).isSameOrBefore(
                    this.rangeDates[1]
                )
        );
     
     

        this.subject2.next(t);
    }
    //** subject for ticket sales data  and methhodes that return it* */
  
      exportTikToCSv(){
        this.ticketsTable.exportCSV();
      }
    
    constructor(
        private translate: TranslateService,
        private http: HttpClient,
        public EventSrv: EventStepsService,
        private datePipe: DatePipe,
        private editeSrv: EditAttendeesServiceService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.translate.onLangChange.subscribe(() => {
            this.updateLang();
            m.locale(this.translate.currentLang);
        });
    }
    ngOnDestroy(): void {
        this.subs.forEach((x) => {
            x.unsubscribe();
        });
    }

    setDisablyView(e) {
        // to show edit attendess and cancel option in sales tickets report
        //  console.log(e);
        this.op.toggle(e);
    }
    //func to great composit key for grouping the data in ticket sales report

    //fun to display date in tale
    getDateOntomeZoneformat(d, x) {
        m.locale();
        var eventOffset = this.EventSrv.offSet * 60;

        // var eventOffset = 60;
        var MyDate = new Date(d).setMinutes(
            new Date(d).getMinutes() + eventOffset
        );
        var l = '';
        switch (x) {
            case 'full':
                if (
                    new Date(MyDate).getFullYear() ==
                    new Date(Date.now()).getFullYear()
                ) {
                    l = m(new Date(MyDate)).format('ddd MMMM D hh:mm a');
                    // this.datePipe.transform(
                    //     new Date(MyDate),
                    //     'EEE MMMM d hh:mm a'
                    // );
                } else {
                    l = m(new Date(MyDate)).format('ddd MMMM D,y hh:mm a');
                    //  this.datePipe.transform(
                    //     new Date(MyDate),
                    //     'EEE MMMM d,y hh:mm a'
                    // );
                }
                break;
            case 'month':
                l = this.datePipe.transform(new Date(MyDate), 'MMM');
                break;
            case 'day':
                l = this.datePipe.transform(new Date(MyDate), 'dd');
                break;
        }
        return l;

        // return new Date(MyDate).toLocaleString();
    }
    //
    updateLang() {
        this.reportType = [
            {
                id: 'Attending',
                label: this.translate.instant('Attending'),
                command: (e) => {
                    // console.log(e)
                    this.ReportType = e.item.label;
                    var t = this.data.filter(
                        (x) =>
                            x['Attendee Status'] ==
                            e.item.id
                    );
                
                 

                    this.subject2.next(t);

                    // this.allColumn = [...this.cols, ...this._DynmicOrderForm];
                    
                },
            },
            {
                id: 'canceled',
                label: this.translate.instant('Not attending'),
                command: (e) => {
                
                    this.ReportType = e.item.label;
                    var t = this.data.filter(
                        (x) =>
                            x['Attendee Status'] ==
                            e.item.id
                    );
                
                 

                    this.subject2.next(t);
                },
            },
        ];

        this.dateFilter = [
            {
                id: 'Any Time',
                label: this.translate.instant('Any Time'),
                command: (e) => {
                    this.filtrDate = e.item.id;
                    this.cusytomrang = false;
                    var t = this.data;
                   

                    //   console.log(m)

                    this.subject2.next(t);
                  
                    //this.update();
                },
            },
            {
                id: 'This Month',
                label: this.translate.instant('This Month'),
                command: (e) => {
                    this.filtrDate = e.item.id;
                    this.cusytomrang = false;
                    // console.log(this.getDateOntomeZone(this.data[0].orderDate).getMonth())
                    // console.log(this.getDateOntomeZone(new Date(Date.now())).getMonth())
                    var t = this.data.filter(
                        (x) =>
                            this.getDateOntomeZone(x['Order Date']).getMonth() ==
                            this.getDateOntomeZone(
                                new Date(Date.now())
                            ).getMonth()
                    );
                
                 

                    this.subject2.next(t);
                  
                    //this.update();
                },
            },
            {
                id: 'This week',
                label: this.translate.instant('This week'),
                command: (e) => {
                    this.filtrDate = e.item.id;
                    this.cusytomrang = false;
                    //var dateoflastdayofweek=new Date(Date.now()).setDate(new Date(Date.now()).getDate()+7)
                    var t = this.data.filter(
                        (x) =>
                            this.getDateOntomeZone(x['Order Date']).getDate() >=
                                this.getDateOntomeZone(
                                    new Date(Date.now())
                                ).getDate() &&
                            this.getDateOntomeZone(x['Order Date']).getDate() <
                                this.getDateOntomeZone(
                                    new Date(Date.now())
                                ).getDate() +
                                    7
                    );
                 

                    this.subject2.next(t);
                   

                    //this.update();
                },
            },
            {
                id: 'Custom Date Range',
                label: this.translate.instant('Custom Date Range'),
                command: (e) => {
                    this.filtrDate = e.item.id;
                    this.cusytomrang = true;
                },
            },
            {
                id: 'This Year',
                label: this.translate.instant('This Year'),
                command: (e) => {
                    this.filtrDate = e.item.id;
                    this.cusytomrang = false;
                    var t = this.data.filter(
                        (x) =>
                            this.getDateOntomeZone(x['Order Date']).getFullYear() ==
                            new Date(Date.now()).getFullYear()
                    );
                 

                    this.subject2.next(t);
                  

                    //this.update();
                },
            },
        ];
        // this.GetDataFromDb();

        //    this.GenerateColsFotTable(this.dataFromDb)
    }
    ngOnInit(): void {
        this.ReportType = 'Attendees status';

        this.filtrDate = 'DATE';

        var o = this.EventSrv.state$.subscribe((event) => {
            this.id = event.id;

            this.updateLang();
            this.getAttendeesdata(this.id);
            //this.GetDataFromDb();
            var l = this.$getSubject2.subscribe((x) => {
                  console.log(x);
                //this.GenerateColsFotTable(x)
                this.datatt = x;

            });

            this.subs.push(l);

            this.$getSubject1.subscribe((x) => {
                this.SalesSumrepData = x;
            });
        });
        this.subs.push(o);
    }
    //get data for report from back
    getAttendeesdata(id: string) {
        this.http
            .get<attendeslistRepDto[]>(environment.Api + 'd/At/g/t/l/' + id)
            .subscribe((item) => {
                this.dataFromDb = item;
                console.log(item);
                this.TicketsData = [];

                item.forEach((x) => {
                    this.GetDataForReport(x);
                });
                this.subject2.next(this.data);
            });
    }

    //fun to convser data to atendees report data
    GetDataForReport(item: attendeslistRepDto) {
        var d = [];
        var i = this.data.length;
        item.tickets.forEach((x) => {
            var orderFormAn = this.GetAttendess(x);
            orderFormAn.forEach((y, j) => {
                var l;

                l = {
                    'sortId':item.orderNum+JSON.parse(x.attendees).order[j].attendeesNum,
                    'Order#': item.orderNum,
                    'Order Date': item.orderDate,
                    'OrderType': item.paymentway,
                    'Ticket type': x.name,
                    'Quantity': x.qty,
                    'Total': x.finalprice,
                    'Attendee Status': JSON.parse(x.attendees).order[j].status,
                    'Attendee #': JSON.parse(x.attendees).order[j].attendeesNum,
                     'qr': x.qrImage,
                 
                };

                var filteredPropertyNames = Object.keys(y).filter(
                    (key) => y[key] !== null
                );
                this.AddProperties(filteredPropertyNames, l, y);
                this.data.push(l);
                i++;
            });
        });
    }

    //func for get attendees for each tickets and mack new row data
    GetAttendess(item: AtendeelistticketVm) {
        //console.log(item)
        var orderformData = JSON.parse(item.attendees);
        // console.log( orderformData)
        // console.log(orderformData)
        var filteredPropertyNames = Object.keys(
            orderformData.order[0].Answer
        ).filter((key) => orderformData.order[0].Answer[key] !== null);
        //  console.log(filteredPropertyNames);
        var resOrderForm = [];
        orderformData.order.forEach((x) => {
            var l = {};
            this.AddProperties(filteredPropertyNames, l, x.Answer);
            resOrderForm.push(l);
        });
        return resOrderForm;
    }
    //fun for add some prop name:value to object
    AddProperties(prop, object, item): void {
        return prop.reduce((obj, propertyName) => {
            obj[propertyName] = this.GetObjectPropertyValue(propertyName, item);
            return obj;
        }, object);
    }
    //fun to return all  object prop
    GetObjectPropertyValue(propertyName: string, item): any {
        return item[propertyName];
    }

    //fun to get orderform filed and convert it to column

    getDateOntomeZone(d: Date | number) {
        // console.log(d)
        var eventOffset = this.EventSrv.offSet * 60;
        // console.log(eventOffset)
        // var eventOffset = 60;
        var MyDate = new Date(d).setMinutes(
            new Date(d).getMinutes() + eventOffset
        );
        return new Date(MyDate);
    }


    //func to sort data in tickets report  pefor export it
    edit(s) {
        console.log(s);
        // var orderNumindex = s.SortId.indexOf('-');
        // var orderNum = s.SortId.substring(0, orderNumindex) as number;
        // var ticket = s.SortId.substring(orderNumindex + 1);

        // console.log(orderNum);
        //  console.log(ticket);
        var orderNum = s['Order#'] as number;
        var ticket =s['Ticket type'];
        var t = this.dataFromDb.find((x) => x.orderNum == orderNum).tickets;
        var selectedtickets = t.find((x) => x.name == ticket).attendees;
        var mmm = JSON.parse(selectedtickets);
        this.editeSrv.myticket = mmm;
        this.editeSrv.attendeesnum = s['Attendee #'];
        this.editeSrv.ticketID = t.find((x) => x.name == ticket).id;
        //  console.log(this.editeSrv.attendeesnum);
        //  console.log(t);
        //  console.log(selectedtickets);
        // console.log(mmm);
        this.router.navigate(['Edit-attendee', this.editeSrv.attendeesnum], {
            relativeTo: this.activatedRoute.parent,
        });
    }
    canseld(s) {
        //console.log(s)
        var url = environment.Api + 'd/At/c/a';
        //var orderNumindex = s.SortId.indexOf('-');
        //var orderNum = s.SortId.substring(0, orderNumindex) as number;
        //  var ticket = s.SortId.substring(orderNumindex + 1);
        var orderNum = s['Order#'] as number;
        var ticket = s['Ticket type'];
        var t = this.dataFromDb.find((x) => x.orderNum == orderNum).tickets;
        var selectedtickets = t.find((x) => x.name == ticket).attendees;
        var mmm = JSON.parse(selectedtickets);
        // console.log(mmm)
        var l = mmm.order.find((x) => x.attendeesNum == s['Attendee #']);
        //  console.log(l)
        l.status = 'canceled';
        var f = {
            ticket: t.find((x) => x.name == ticket).id,
            order: JSON.stringify(mmm),
        };
        this.http.post(url, f).subscribe((x) => {
            this.datatt = [];
            this.DataForReport = [];
             this.data=[];
            this.TicketsData = [];
            this.getAttendeesdata(this.id);
        });
    }

   
    // getDataFOrexport(){
    //     var allitem=[];
    // this.SalesSumrepData.forEach(el=>{
    //     var t={
    //          ' Sales type':el.salesType,
    //        'Sales':el.name,
    //         'Price ':el.price,
    //        'Quantity ':el.qty,
    //        'Gross ':el.fees,
    //        'Antares Fees ':el.antaresFees,
    //        'Antares Payment Processing Fees ':el.paymentfees,
    //        'Shipping Fees ':el.shippingfees,
    //         'Net Sales ':el.fees,
    //          'Qty Tickets Refunded ':el.refunded,
    //         'Gross Refunded ':el.grossRefunded
    //     }
    //     allitem.push(t)
    // });
    // return allitem
    // }
    exportsalesToExcel(): void {
      var data=this.data;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            data,
            { skipHeader: false }
        );
        // Hide the sortId column
      //  worksheet['!cols'] = [{ hidden: true }];

        const workbook: XLSX.WorkBook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });

        FileSaver.saveAs(
            blob,
            'attendesslisrt_export_' + new Date().getTime() + '.xlsx'
        );
          
        
    }
}
