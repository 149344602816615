import { Component, OnInit } from '@angular/core';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { EventsService } from 'src/app/services/events.service';
import { AddnewAttendeesOfflineService } from '../../addnew-attendees-offline.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-addnew-attendess-offline',
  templateUrl: './addnew-attendess-offline.component.html',
  styleUrls: ['./addnew-attendess-offline.component.scss']
})

export class AddnewAttendessOfflineComponent  implements OnInit{
subs:Subscription[]=[]

  ngOnInit(): void {

   this.srv.initializeForm()
  
  }
  constructor( private eventsService: EventsService, private srv:AddnewAttendeesOfflineService){

  }
 
}
