<p-toast></p-toast>
<div class="container">
   
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Newlogo'|translate}}</h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="">
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <h2 class="fw-bold">{{PageTitle|translate}}</h2> -->

                <div>
                    <!-- <h6 class="fw-bold  ">Help <span class="text-primary help-btn" (click)="showHelpStepsModal()" ><i class="fa-regular fa-circle-question"></i></span></h6> -->
                    <!-- <p-dialog header="How to start" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                    [draggable]="false" [resizable]="false" >
                    <p class="m-0"><b class="text-primary">Step 1: </b> Hkas sakjfas fjkasf sajfs afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 2: </b> Okas sakjfas fjkasf sajfss adas s ad afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 3: </b> Lasfas sakjfas fjkasf sajfs ad sa dasd afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 4: </b> Kasdkas sakjfas fjkasf sajfsas dsa dsa sa d dsa dsa dsa d afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 5: </b> Bkas sakjfas fjkasf sajfssa dsa as  afjasf</p>
                        <ng-template pTemplate="footer">
                        <p-button icon="pi pi-check" (click)="helpStepsModal=false" label="Ok" styleClass="p-button-text"></p-button>
                        </ng-template>
                </p-dialog> -->
                </div>

                    </div>

                    <div class="row">
                           
                        <form [formGroup]="form" (submit)="submit()" autocomplete="off">
   
                            
                        
                                
                        
                                    <div class="p-input-filled col-sm-12 col-md-4 mb-2">
                                        <span class="p-float-label">
                                            <p-dropdown   inputId="langtype"
                                            pTooltip="{{'select Languge that you want to add' |translate}}" showDelay="1000" tooltipPosition="top"
                                             [ngClass]="{
                                                    'ng-invalid ng-dirty':
                                                       form.get('cultureId')?.touched &&
                                                        form.get('cultureId')?.invalid||(submitted&& form.get('cultureId').invalid)
                                                }" [styleClass]="'w-100'" formControlName="cultureId" [autoDisplayFirst]="false"
                                                [options]="Langueses" optionLabel="name" optionValue="id" #ticketsType></p-dropdown>
                                            <label class="form-lable font-size-14" for="langtype">{{'Language'|translate}}</label>
                                        </span>
                                        <small class="p-error" *ngIf="form.get('cultureId')?.touched &&
                                        form.get('cultureId')?.invalid||(submitted&& form.get('cultureId').invalid)
                                            " >{{'Languges'|translate}}</small>
                            
                                    </div>
                                    <div class="col-12">
                                        <app-my-image-uploader formControlName="image" [aspectRatio]="4.5 / 1.6" [quality]="'100'"
                                            [format]="'png'"></app-my-image-uploader>
                                    </div>
                                    <small class="p-error" *ngIf="(form.get('image')?.touched &&
                                    form.get('image')?.invalid)  || (form.get('image')?.invalid && submitted)
                                        ">{{'logoInpt002ErrMsg'|translate}}</small>
                                    <div class="row text-center py-1">
                                        <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                                            <i class="fa-regular fa-triangle-exclamation"></i>
                                            <!-- Recommended image size: <b> 2160 x
                                                    1080px</b> -->
                                            {{'LogoInpt002Dsc001'|translate}}
                                        </div>
                                        <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                                            <i class="fa-regular fa-triangle-exclamation"></i>
                                            <!-- Maximum file size: <b>10MB</b> -->
                                            {{'logoInpt002Dsc002'|translate}}
                                        </div>
                                        <div class="col-12 col-md-4 text-danger" style="font-size:14px">
                                            <i class="fa-regular fa-triangle-exclamation"></i>
                                            <!-- Supported image files: <b>JPEG or PNG</b> -->
                                            {{'logoInpt002Dsc003'|translate}}
                                        </div>
                                    </div>
                               
                            
                           
                            <div class=" d-flex  justify-content-center">
                                <div class="banner-btn rounded-pill mx-1">
                        
                                    <button class="rounded-pill save-btn ms-1" (click)="cancel()">{{'CanclBtn'|translate}}
                                        <span></span></button>
                                    <button class="rounded-pill next-btn  ms-1 mt-md-0 mt-1">{{'SaveBtn'|translate}} <i class="pi mt-1"
                                            [class.pi-angle-right]="document.documentElement.dir === 'ltr'"
                                            [class.pi-angle-left]="document.documentElement.dir === 'rtl'"></i><span></span></button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>


