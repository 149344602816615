<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Languages'|translate}}</h1>
        </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>

        <div class="">
            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data" [rows]="5"
                [paginator]="data.length>5" [globalFilterFields]="['name','cultureId','uiCulture']" [tableStyle]="{'max-width':'100%'}"
                [rowHover]="true" dataKey="id"  [totalRecords]="data.length/5"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true" class=""
               >
                <ng-template pTemplate="caption">

                    <div>

                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                            <div class="  ms-md-3  mb-sm-0  mb-2">
                                <button pButton pRipple class="rounded-pill add-new-btn mr-2" (click)="show()"><i
                                        class="fa-solid fa-plus"></i> {{'New'|translate}}<span></span></button>
                            </div>
                            <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pTooltip="{{'type to search'|translate}}" showDelay="1000"
                                        tooltipPosition="left" pInputText type="text" class="form-control"
                                        (input)="dt.filterGlobal($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>
                        <th class="fw-bold text-center">{{'Code'|translate}}</th>
                        <th pSortableColumn="title" style="min-width:7rem" class="fw-bold text-center">
                            {{'Name'|translate}} </th>
                        <th pSortableColumn="totalQty" style="min-width:7.8rem" class="fw-bold text-center ">
                            {{'Ui Culture'|translate}} </th>
                         <th pSortableColumn="totalQty" style="min-width:7.8rem" class="fw-bold text-center ">
                            {{'Published'|translate}} </th>
                            <th pSortableColumn="totalQty" style="min-width:7.8rem" class="fw-bold text-center ">
                                {{'Is pimary'|translate}} </th>


                        <th class="fw-bold text-center "> {{'TcktTblTh006'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-t>
                    <tr>
                        <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'Code'|translate}}</span> <span
                                class="text-lenght">{{t.cultureId}}</span></td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold "> {{'Name'|translate}}</span>
                            {{t.name}}</td>
                            <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold "> {{'Ui Culture'|translate}}</span>
                            {{t.uiCulture}}</td>
                        <td class="text-md-center padding-custom text-end">
                            <div class=" action-custom">
                                <button pButton pRipple  class="rounded-pill add-new-btn mr-2" [ngStyle]="{'background':t.status==0 ? 'rgb(211 47 47 / 92%)'  : 'rgb(4 205 4 / 60%)'}"
                                (click)="publish(t.cultureId)">{{t.status==0? ('Active'|translate):'Not active'|translate}} <span></span></button>

                            </div>
                           </td>
                           <td class="text-md-center padding-custom text-end">
                            <div class=" action-custom">
                                <button pButton pRipple  class="rounded-pill add-new-btn mr-2" [ngStyle]="{'background':t.priority==0 ? 'rgb(211 47 47 / 92%)'  : 'rgb(4 205 4 / 60%)'}"
                                (click)="changPrimary(t.cultureId)">{{t.priority==0? ('Primary'|translate):'Not primary'|translate}} <span></span></button>

                            </div>
                           </td>



                        <td class="text-md-center padding-custom text-end">
                            <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                            [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary mx-2" (click)="edit(t.cultureId)"></button>
                                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    (click)="remove($event,t.cultureId)"></button>
                            </div>
                            <p-confirmPopup></p-confirmPopup>
                            <!-- <button pButton pRipple icon="pi pi-trash"
                            class="p-button-rounded p-button-danger m-1"
                            (click)="remove($event,event.id)"></button> -->


                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        {{'TcktTblFooter01'|translate}} {{data ? data.length : 0 }} .
                    </div>
                </ng-template>
            </p-table>

        </div>

    </div>
</div>

<!-- <div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="">
            <p-toast></p-toast>


            <div class=" custom-table-border">


            </div>

        </div>
    </div>
</div> -->




