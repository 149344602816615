import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EventStepsService } from 'src/app/services/event-steps.service';
import { environment } from 'src/environments/environment';
import { EditAttendeesServiceService } from '../../../events/event-steps/attendees-mangment/edit-attendees-info/edit-attendees-service.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-update-attendees-global',
  templateUrl: './update-attendees-global.component.html',
  styleUrls: ['./update-attendees-global.component.scss']
})
export class UpdateAttendeesGlobalComponent {
  result:any
  id: string;
  value:any
formData:any[]=[];
isSubmitting:boolean=false
constructor(private location: Location, private fb:FormBuilder,private http:HttpClient,private editeSrv:EditAttendeesServiceService,
private router:Router,private activatedRoute:ActivatedRoute,private EventSrv:EventStepsService){

}
ngOnInit(): void {


  var param= this.activatedRoute.paramMap.subscribe((param)=>{
   // console.log(param)
     this.id=param.get('Attendee');
     var eventid=param.get('Id');
   // console.log(this.editeSrv.myticket)
    this.value= this.editeSrv.myticket.order.find(x=>x.attendeesNum==this.id).Answer;
    var p= this.http
    .get(environment.Api + 'd/e/ordform/'+eventid)

    .subscribe((x) => {
  // console.log(x)
    this.formData = JSON.parse(x['form']);
   // console.log(x)
   // console.log( this.formData )
    
    });

  })



}

// get orderForm() 
//    {
//        return this.form1.get('order') as FormGroup;
//    }


// intialForm(id){
//   this.form1=this.fb.group({
//     id:[id],
//     order:this.fb.group({})
//   });
// }
GetResult(x){
//console.log(x)
this.result=x
}
getValud(x){
 // console.log(x)
  
}
submit(d){
  var url= environment.Api+'d/At/c/a'
    var t=this.editeSrv.myticket.order.find(x=>x.attendeesNum==this.id)
    t.Answer=this.result
   // console.log(this.editeSrv.myticket)

    var f={ticket: this.editeSrv.ticketID,
           order: JSON.stringify(this.editeSrv.myticket)}

         //  console.log(f)
   

          
   this.http.post(url,f).subscribe(x=>{x})
  // this.router.navigate([''], { relativeTo: this.activatedRoute.parent.parent });
   this.location.back();
   //this.router.navigate(['mmmm',this.editeSrv.attendeesnum], { relativeTo: this.activatedRoute.parent });


}
}
