import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component ,Inject, OnInit,} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { localstringDto, localstringsingleDto } from 'src/app/Interfaces/attrDto';
import { LoaderService } from 'src/app/services/loader.service';
import { LocalStringSrvService } from 'src/app/services/local-string-srv.service';
const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])
const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-localstring',
  templateUrl: './localstring.component.html',
  styleUrls: ['./localstring.component.scss'],
  animations: [fadeOut]
})
export class LocalstringComponent  implements OnInit{
  data:localstringDto[]=[];
  typertform!: FormGroup;
  index: number=0;
  totalRecourd:number=0;
  submitted:boolean=false;
  Langueses: DropDownItem[]=[];
  mylocal: localstringsingleDto[]=[];
 subs:Subscription[]=[];
 searchText:string="";
  constructor(private activatedRoute:ActivatedRoute,
    private route:Router, private attrSrv:LocalStringSrvService,public loader : LoaderService, public messageService : MessageService,
    @Inject(DOCUMENT) public document: Document,private conSrv: ConfirmationService,private fb:FormBuilder,private translate:TranslateService){
      this.initializForm("","","");
     var l= this.translate.onLangChange.subscribe(() => {
        this.updateLang();
      });
      this.subs.push(l)
  }

remove(s){
  this.attrSrv.DeletLocalString(s).subscribe(x=>{
    this.attrSrv.getAlltype(this.index);
    this.messageService.add({ severity: 'success', summary: 'remove Successfully' });

  },(error)=>{
    this.messageService.add({ severity: 'danger', summary: error });
  });
}


  updateLang() {
    this.attrSrv.getAlltype(this.index,this.searchText);
  }

  changform(e){
    //console.log(e);
     var data=this.mylocal.find(x=>x.cultureId==e.value);
     if(data==undefined){
     
      this.typertform.controls['text'].setValue("");
      this.typertform.controls['cultureId'].setValue(e.value);
     }else{
      this.typertform.controls['resKey'].setValue(data.resKey);
      this.typertform.controls['cultureId'].setValue(data.cultureId);
      this.typertform.controls['text'].setValue(data.text);
     }
  }

  checkComplet(id){
    if(id==this.Langueses.length){
      return true
    }
    else{
      return false;
    }

  }
  initializForm(resKey:string,lan:string,text:string){
    this.typertform=this.fb.group({
      resKey:[resKey],
      cultureId:[lan],
      text:[text]
    })
  }
  ngOnInit(): void {
    this.loader.showLoader1();
    this.loader.showLoader();
    var l= this.attrSrv.GetAllSystemLang().subscribe(x=>{
      this.Langueses=x;
    })
    this.attrSrv.getAlltype();
    this.attrSrv.$getSubject.subscribe(x=>{
     // console.log(x)
      this.data=x.data;
      this.totalRecourd=x.totalAcount;
      this.loader.hideLoader();
      this.loader.hideLoader1();
    })
  }
  // edit(id:string){
  //   this.route.navigate([id],{relativeTo: this.activatedRoute});
  // }
search(e,t){
  this.attrSrv.getAlltype(0,this.searchText);
  
}
  getEvent(e:LazyLoadEvent){
    var l=e.first/5;
    this.index=l;
    this.attrSrv.getAlltype(l,this.searchText);
  }

  onRowEditInit(p:localstringDto) {
  //  console.log(p)

   var k= this.attrSrv.GetAtrrSingle(p.resKey).subscribe(y=>{
        this.mylocal=y;
        var data=y.find(m=>m.cultureId=='en')
        this.initializForm(data.resKey,data.cultureId,data.text)
      })
      this.subs.push(k);
  // this.initialForm(p.attrId,p.parentId,p.name,p.status==0?false:true,p.order,p.cultureId);
  //  console.log(this.typertform.value)
   // this.clonedProducts[product.id] = {...product};
}
onRowEditSave(){
// console.log(this.typertform.value)

 var data:localstringsingleDto={
  resKey       :this.typertform.controls['resKey'].value,
  cultureId      :this.typertform.controls['cultureId'].value,
   text            :this.typertform.controls['text'].value,
  }
  // console.log(data)
  var y= this.attrSrv.PostnewAttr(data).subscribe(x=>{

    this.attrSrv.getAlltype(this.index);
     this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });

   },(error)=>{
     this.messageService.add({ severity: 'danger', summary: error });
   });
   this.subs.push(y);
}
onRowEditCancel(product, ri){
  this.initializForm("","","")
}


show(){
  this.route.navigate(["0"],{relativeTo: this.activatedRoute});
}
}
