import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-entreprise',
  templateUrl: './entreprise.component.html',
  styleUrls: ['./entreprise.component.scss']
})
export class EntrepriseComponent {
    value: any

    contactUsForm = this.fb.group({
        email: ['',Validators.required],
        firstName: ['',Validators.required],
        lastName:['',Validators.required],
        organizationName:['',Validators.required],
        message:['',Validators.required]

})

constructor(private fb:FormBuilder){

}

contactUs(){
  //  console.log(this.contactUsForm.value)
}
}
