import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { AttrDto } from 'src/app/Interfaces/attrDto';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';


export interface getlogointrface{
   logoId   :string;
   cultureId:string;
   image    :string;
   fileName:string;
}

const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])



const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
@Component({
  selector: 'app-logomangment',
  templateUrl: './logomangment.component.html',
  styleUrls: ['./logomangment.component.scss'],
  animations: [fadeOut]
})

export class LogomangmentComponent {
  url=environment.Api
  data:getlogointrface[]=[];
  totalRecourd:number=0;
   subs:Subscription[]=[];
  private subject: Subject<getlogointrface[]> = new ReplaySubject<getlogointrface[]>(1);

  get $getSubject(): Observable<getlogointrface[]> {
    return this.subject.asObservable();
  }
  constructor(private activatedRoute:ActivatedRoute,public http:HttpClient,
    private route:Router,public loader : LoaderService, public messageService : MessageService,
    @Inject(DOCUMENT) public document: Document,private conSrv: ConfirmationService,
    private translate:TranslateService){
      
     var t= this.translate.onLangChange.subscribe(() => {
      this.getalllogo()
      
      });
      this.subs.push(t)
  }

  ngOnDestroy(): void {
   this.subs.forEach(x=>{
    x.unsubscribe();
   })
  }

 getalllogo(){
  this.http.get<getlogointrface[]>(environment.Api+'d/loc/logo/all').subscribe(x=>{
    this.subject.next(x)
  })
 }


  
  



  ngOnInit(): void {
    this.loader.showLoader1();
    this.loader.showLoader();
     this.getalllogo();
     var m=this.$getSubject.subscribe(c=>{
      this.data=c;
      this.loader.hideLoader();
      this.loader.hideLoader1();
     })
  
   
  
    this.subs.push(m);
  }

  remove(event, id) {

    // this.conSrv.confirm({
    //   target: event.target,
    //   message: 'Are you sure that you want to proceed?',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //  var del=    this.attrSrv.DeletAttr(id).subscribe(x => {
    //     this.attrSrv.getAlltype(10102,0,this.searchText);
    //       this.messageService.add({ severity: 'success', summary: 'Deleted successfully' });
    //     },(eror)=>{
    //       this.messageService.add({ severity: 'danger', summary: eror.message });
    //     });
    //     this.subs.push(del);
    //     // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
    //   },
    //   reject: () => {
    //     this.messageService.add({ severity: 'danger', summary: 'you not confirm delete operation' });
    //   }
    // });
  }
  // edit(id){
  //   this.route.navigate([id],{relativeTo: this.activatedRoute});
  // }
  show(){
     this.route.navigate(["newLogo"],{relativeTo: this.activatedRoute});
  }







}
