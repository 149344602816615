<form [formGroup]="srv.searchForm">
    <div class="seconde-nav d-md-none d-block">
        <button type="text" class="btn btn-primary filter-btn-custom border border-white"
            (click)="sidebarVisible = true" icon=""><i
                class="fa-regular fa-filter-list fa-flip-horizontal"></i></button>
    </div>
    <div class="container-fluid p-0 ps-4 " >
        <div class="d-md-none d-block">
            <p-sidebar [(visible)]="sidebarVisible" position="left" [transitionOptions]="'0.3ms'" [baseZIndex]="10000">
                <div class="filter-title d-flex justify-content-center rounded-custom">
                    <h3 class="fw-bold m-0 ">{{'SrhPgFltr'|translate}} </h3> <h3 class="ps-2"> <i class="fa-regular fa-filter-list fa-flip-horizontal"></i></h3>
                    <!-- <div class="d-block">

                        <button pButton pRipple type="button" class="save-btn rounded-pill text-center"
                            (click)="submit()"><i class="pi pi-search"></i> Search by filters<span></span></button>
                    </div> -->
                </div>
                <div class="filter-base ">
                    <div class="">
                        <div class="d-flex w-100 mb-2">
                            <p-inputSwitch formControlName="org" inputId="showFollowOnly"
                                (onChange)="getorgEvent()"></p-inputSwitch>
                            <label class="ms-2" for="showFollowOnly">{{'SrhPgFltrSwtch001'|translate}}</label>
                        </div>
                        <div class="d-flex  w-100 mb-2 ">
                            <p-inputSwitch formControlName="online" inputId="searchOnlineEvents"
                                (onChange)="setlocationSearchonline()"></p-inputSwitch>
                            <label class="ms-2" for="searchOnlineEvents">{{'SrhPgFltrSwtch002'|translate}}</label>
                        </div>
                    </div>
                    <h6 class="fw-bold mt-3">{{'SrhPgFltrTitl001'|translate}}</h6>
                    <div class="">
                        <div class="d-flex flex-wrap w-100">
                            <div class="pe-1">
                                <input type="radio" class="btn-check col" id="today1" name="date"
                                    (click)="GetDate('today',false)" [value]="false" />
                                <label class="btn btn-outline-primary mb-2" for="today1">{{'SrhPgFltrTitl001Chois001'|translate}}</label>
                            </div>
                            <div class="pe-1">
                                <input type="radio" class="btn-check" id="tomorrow1" name="date"
                                    (click)="GetDate('tomorrow',false)" [value]="false" />
                                <label class="btn btn-outline-primary mb-2" for="tomorrow1">{{'SrhPgFltrTitl001Chois002'|translate}}</label>
                            </div>
                            <div class="pe-1">
                                <input type="radio" class="btn-check" id="thisWeek1" name="date" [value]="false"
                                    (click)="GetDate('thisWeek',false)" />
                                <label class="btn btn-outline-primary mb-2" for="thisWeek1">{{'SrhPgFltrTitl001Chois003'|translate}}</label>
                            </div>
                            <div class="pe-1">
                                <input type="radio" class="btn-check" id="thisMonth1" name="date" [value]="false"
                                    (click)="GetDate('thisMonth',false)" />
                                <label class="btn btn-outline-primary mb-2" for="thisMonth1">{{'SrhPgFltrTitl001Chois004'|translate}}</label>
                            </div>
                            <div class="pe-1">
                                <input type="radio" class="btn-check" id="nextMonth1" name="date" [value]="false"
                                    (click)="GetDate('nextMonth',false)" />
                                <label class="btn btn-outline-primary mb-2" for="nextMonth1">{{'SrhPgFltrTitl001Chois005'|translate}}</label>
                            </div>
                            <div class="pe-1">
                                <input type="radio" class="btn-check" id="anytime" name="date" [value]="srv.searchForm.get('start').value==''&&srv.searchForm.get('end').value==''"
                                    (click)="GetDate('anytime',false)" />
                                <label class="btn btn-outline-primary mb-2" for="anytime">{{'SrhPgFltrTitl001Chois006'|translate}}</label>
                            </div>
                        </div>
                        <div class="p-input-filled  my-2 pe-2" [formGroup]="Form1">
                            <span class="p-float-label">
                                <p-calendar #cli touchUI="true" [touchUI]="screenSize" selectionMode="range"
                                    pTooltip="Start date for event" showDelay="1000" tooltipPosition="bottom"
                                    appendTo="body" inputId="salesStart" [styleClass]="'form-control m-0 p-0 '"
                                    [inputStyleClass]="'form-control'" [formControl]="Form1.get('date')"
                                    (onClose)="getDate($event)">
                                </p-calendar>
                                <label class="form-label font-size-14" for="salesStart">
                                    {{'SrhPgFltrTitl001Inpt001'|translate}}</label>
                            </span>
                        </div>
                    </div>
                    <h6 class="fw-bold mt-3">{{'SrhPgFltrTitl002'|translate}}</h6>
                    <div class="d-flex flex-wrap w-100">
                        <div class="pe-1">
                            <input type="radio" class="btn-check " id="price" name="price"
                                [value]="srv.searchForm.get('free').value&&!srv.searchForm.get('paid').value"  (click)="getPrice('free')"/>
                            <label class="btn btn-outline-primary mb-2" for="price">{{'SrhPgFltrTitl002Chois001'|translate}}</label>
                        </div>
                        <div class="pe-1">
                            <input type="radio" class="btn-check" id="price1"  name="price"
                                [value]=" !srv.searchForm.get('free').value&&srv.searchForm.get('paid').value" (click)="getPrice('paid')" />
                            <label class="btn btn-outline-primary mb-2" for="price1">{{'SrhPgFltrTitl002Chois002'|translate}}</label>
                        </div>
                        <div class="pe-1">
                            <input type="radio" class="btn-check" id="price11"  name="price"
                                [value]=" !srv.searchForm.get('free').value&&!srv.searchForm.get('paid').value"
                                 (click)="getPrice('all')" />
                            <label class="btn btn-outline-primary mb-2" for="price11">{{'SrhPgFltrTitl002Chois003'|translate}}</label>
                        </div>
                    </div>
                    <h6 class="fw-bold mt-3">{{'BscInfoTitle002Slct002'|translate}}</h6>
                    <div class="row">
                        <div class="p-input-filled col-12  mb-3 pe-4">
                            <span class="p-float-label">
                                <p-dropdown inputId="TicketType4" [autoDisplayFirst]="false" [filter]="true"
                                    filterBy="name" [showClear]="true" id="event-category" [styleClass]="'w-100'"
                                    pTooltip="Select the category of your event" showDelay="1000"
                                    appendTo="body"
                                    tooltipPosition="bottom" [styleClass]="'w-100'" formControlName="cat"
                                    [options]="categories" optionLabel="name" optionValue="Id" (onChange)="filter()"></p-dropdown>
                                <label class="form-label font-size-14" for="event-category">{{'BscInfoTitle002Slct002'|translate}}</label>
                            </span>
                        </div>
                    </div>
                    <h6 class="fw-bold ">{{'BscInfoTitle002Slct001'|translate}}</h6>
                    <div class="row ">
                        <div class="p-input-filled col-12 mb-3  pe-4">
                            <span class="p-float-label">
                                <p-dropdown inputId="TicketType1" [filter]="true" filterBy="name"
                                appendTo="body" [showClear]="true"
                                    pTooltip="Select the type of your event" showDelay="1000"
                                    tooltipPosition="bottom" id="event-type" [styleClass]="'w-100'"
                                    formControlName="typ" [autoDisplayFirst]="false" [options]="types"
                                    optionLabel="name" optionValue="Id" #typeId (onChange)="filter()"></p-dropdown>
                                <label class="form-label font-size-14" for="event-type">{{'BscInfoTitle002Slct001'|translate}}</label>
                            </span>
                        </div>
                    </div>
                    <h6 class="fw-bold ">{{'BscInfoTitle001Slct002'|translate}}</h6>
                    <div class="row">
                        <div class="p-input-filled col-12 mb-3 pe-4">
                            <span class="p-float-label">
                                <p-dropdown [showClear]="true" inputId="currencyId" id="event-type" [filter]="true"
                                    pTooltip="Select the currency for this event" showDelay="1000"
                                    tooltipPosition="bottom" [styleClass]="'w-100'" formControlName="cur"
                                    [autoDisplayFirst]="false" [options]="curData" optionLabel="name"
                                    appendTo="body" optionValue="Id" #typeId  (onChange)="filter()"></p-dropdown>
                                <label class="form-label font-size-14" for="curId">{{'BscInfoTitle001Slct002'|translate}}</label>
                            </span>
                        </div>
                    </div>
                </div>
            </p-sidebar>
        </div>
        <div class="row d-lg-flex">
            <div class="row col-lg-8 col-12 p-0 m-0 mt-3">
                      <div class="col-lg-12 col-md-12 d-block  position-sticky p-0 m-0 " *ngIf="showFilters">
                        <div class="filter-title row mt-md-0 mt-3  justify-content-between rounded-custom" style="box-shadow: none;">
                            <div class="d-flex flex-column col-sm-8 col-12">
                                <div class="p-input-filled mb-2 pe-2">
                                    <span class="p-input-icon-left w-100">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" formControlName="name" class="form-control"
                                            placeholder="{{'SrhPgSrhInpt001'|translate}}" />
                                    </span>
                                </div>
                                <div class=" d-flex flex-column ">
                                    <div class=" w-100 text-break"   *ngIf="!UpdateLocation&&!srv.searchForm.get('online').value">
                                        <span class=" text-primary d-block cursor-pointer  " (click)="changLocal($event)">
                                            <i class="fa-sharp fa-solid fa-location-dot text-primary fs-1 pe-2"></i>
                                            {{srv.searchForm.get('address').value!=""?srv.searchForm.get('address').value:'SrhPgSrhInpt002'|translate}}
                                        </span>
                                    </div>
                                    <div class="d-flex  align-items-center my-1"  *ngIf="UpdateLocation">
                                        <span class=" text-primary d-block" >
                                            <i class="fa-sharp fa-solid fa-location-dot fs-1 text-primary pe-2"></i>
                                        </span>
                                        <div class="p-input-filled w-100 pe-2">
                                            <AutocompleteComponent (problem)="getProblem($event)"   (setAddress)=" getAddressAuto($event)"
                                             pTooltip="{{'SrhPgSrhInpt002Pop'|translate}}" showDelay="1000" tooltipPosition="top"  addressType="geocode" [addressValue]=""   ></AutocompleteComponent>
                                        </div>
                                    </div>
                                    <div class="card  border border-primary history-base p-0 " *ngIf="UpdateLocation">
                                        <div class="py-1 " [ngClass]="{'card-base':oldLocation.length>3}">
                                            <div class="d-flex flex-column my-1"  *ngIf="UpdateLocation">
                                                <span class=" text-primary d-block font-size-26 ps-sm-4 ps-3 ms-3" (click)="getcurrentLocation()" >
                                                    <i class="fa-solid fa-location-crosshairs cursor-pointer text-primary pe-2" > </i>{{'SrhPgSrhInpt002Dsc001'|translate}}
                                                </span>
                                            </div>
                                            <div class="d-flex flex-column my-1"  *ngIf="UpdateLocation">
                                                <span class=" text-primary d-block font-size-26 ps-sm-4 ps-3 ms-3" (click)="setDeFaultLocation('Dubai')" >
                                                    <i class="fa-solid fa-location-crosshairs cursor-pointer text-primary pe-2" > </i>Dubai
                                                </span>
                                            </div>
                                            <div class="d-flex flex-column my-1"  *ngIf="UpdateLocation">
                                                <span class=" text-primary d-block font-size-26 ps-sm-4 ps-3 ms-3" (click)="setDeFaultLocation('selectLocation')" >
                                                    <i class="fa-solid fa-location-crosshairs cursor-pointer text-primary pe-2" > </i>{{'SrhPgSrhInpt002Dsc002'|translate}}
                                                </span>
                                            </div>
                                            <ng-container  *ngFor=" let item of oldLocation" >
                                                <div class="d-flex flex-column my-1"  *ngIf="item!=''">
                                                    <span class=" text-primary d-block font-size-26 ps-sm-4 ps-3 ms-3" (click)="setDeFaultLocation(item)" >
                                                        <i class="fa-solid fa-location-crosshairs cursor-pointer text-primary pe-2" > </i>{{item}}
                                                    </span>
                                                </div>
                                                <!-- <div   *ngIf="item.name!=null||(item.address!='')"
                                                class="card-2-modified p-0 mx-2 mb-3 "  >
                                                <div class="row d-flex    revers-cols col mb-2 pt-sm-3 " [ngClass]="{'justify-content-between ':item.name!=null&&item.address!='' ,
                                                     'justify-content-start ':!(item.name!=null&&(item.address!=''))}">

                                                    <div class="col-lg col-md-12 col  text-break" *ngIf="item.name!=null" >
                                                        <a (click)="gotosearch(item)" >
                                                            <span class=" text-primary d-block " >

                                                                <i class="fa-light fa-clock-rotate-left text-primary pe-2"></i>
                                                                <span class=" text-black">

                                                              {{item.name}}
                                                                </span>
                                                            </span>
                                                        </a>

                                                    </div>
                                                    <div class="col-lg col-md-12 col " *ngIf="item.address!=''"  [ngClass]="{'text-end  text-break':item.name!=null }">
                                                      <a (click)="gotosearch(item)">
                                                        <span class=" text-primary d-block " >

                                                            <i class="fa-regular fa-location-dot text-primary pe-2"></i>
                                                            <span class=" text-black">

                                                          {{item.online?"Online":item.address}}
                                                            </span>
                                                        </span>
                                                      </a>
                                                    </div>

                                                </div>
                                            </div> -->
                                            </ng-container>
                                            <!-- <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                <div class="pagination-area text-center">

                                                    <p-button label="See more" styleClass="p-button-link" (click)="GetMoreData()"></p-button>
                                                </div>
                                            </div>
                                            </div> -->
                                        </div>
                                    </div>
                                   </div>
                            </div>
                            <!-- <h3 class="fw-bold m-0 ">Filters </h3> <h3 class="ps-2"> <i class="fa-regular fa-filter-list fa-flip-horizontal"></i></h3> -->
                            <div class="d-flex justify-content-center col-sm-4 col-12 align-items-center">
                                <div class="d-block">
                                    <button pButton pRipple type="button" class="save-btn rounded-pill text-center"
                                        (click)="submit()"><i class="pi pi-search"></i> {{'SrhPgBtn001'|translate}} <span></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="row col-lg-12 col-md-12 d-block d-lg-none h-5 p-0 " style="height: 400px;" *ngIf="!srv.searchForm.get('online').value">
                            <div #gmap1 style="width:100%;height:100%" #addresstext></div>
                        </div>
                        <div class="row p-0 m-0">
                            <div class="col-lg-4 col-md-4 d-md-block d-none position-sticky ps-3 py-0 m-0 " *ngIf="showFilters">
                                <div class="filter-base ">
                                    <div class="">
                                       <div class=" d-flex justify-content-start my-3 ">
                                        <h3 class="fw-bold m-0 ">{{'SrhPgFltr'|translate}} </h3> <h3 class="ps-2"> <i class="fa-regular fa-filter-list fa-flip-horizontal"></i></h3>
                                    </div>
                                    </div>
                                    <div class="">
                                        <div class="d-flex w-100 mb-2">
                                            <p-inputSwitch formControlName="org" inputId="showFollowOnly"
                                                (onChange)="getorgEvent()"></p-inputSwitch>
                                            <label class="ms-2" for="showFollowOnly">{{'SrhPgFltrSwtch001'|translate}}</label>
                                        </div>
                                        <div class="d-flex  w-100 mb-2 ">
                                            <p-inputSwitch formControlName="online" inputId="searchOnlineEvents"
                                                (onChange)="setlocationSearchonline()"></p-inputSwitch>
                                            <label class="ms-2" for="searchOnlineEvents">{{'SrhPgFltrSwtch002'|translate}}</label>
                                        </div>
                                    </div>
                                    <h6 class="fw-bold mt-3">{{'SrhPgFltrTitl001'|translate}}</h6>
                                    <div class="">
                                        <div class="d-flex flex-wrap w-100">
                                            <div class="pe-1">
                                                <input type="radio" class="btn-check col" id="today1" name="date"
                                                    (click)="GetDate('today',false)" [value]="false" />
                                                <label class="btn btn-outline-primary mb-2" for="today1">{{'SrhPgFltrTitl001Chois001'|translate}}</label>
                                            </div>
                                            <div class="pe-1">
                                                <input type="radio" class="btn-check" id="tomorrow1" name="date"
                                                    (click)="GetDate('tomorrow',false)" [value]="false" />
                                                <label class="btn btn-outline-primary mb-2" for="tomorrow1">{{'SrhPgFltrTitl001Chois002'|translate}}</label>
                                            </div>
                                            <div class="pe-1">
                                                <input type="radio" class="btn-check" id="thisWeek1" name="date" [value]="false"
                                                    (click)="GetDate('thisWeek',false)" />
                                                <label class="btn btn-outline-primary mb-2" for="thisWeek1">{{'SrhPgFltrTitl001Chois003'|translate}}</label>
                                            </div>
                                            <div class="pe-1">
                                                <input type="radio" class="btn-check" id="thisMonth1" name="date" [value]="false"
                                                    (click)="GetDate('thisMonth',false)" />
                                                <label class="btn btn-outline-primary mb-2" for="thisMonth1">{{'SrhPgFltrTitl001Chois004'|translate}}</label>
                                            </div>
                                            <div class="pe-1">
                                                <input type="radio" class="btn-check" id="nextMonth1" name="date" [value]="false"
                                                    (click)="GetDate('nextMonth',false)" />
                                                <label class="btn btn-outline-primary mb-2" for="nextMonth1">{{'SrhPgFltrTitl001Chois005'|translate}}</label>
                                            </div>
                                            <div class="pe-1">
                                                <input type="radio" class="btn-check" id="anytime" name="date" [value]="srv.searchForm.get('start').value==''&&srv.searchForm.get('end').value==''"
                                                    (click)="GetDate('anytime',false)" />
                                                <label class="btn btn-outline-primary mb-2" for="anytime">{{'SrhPgFltrTitl001Chois006'|translate}}</label>
                                            </div>
                                        </div>
                                        <div class="p-input-filled  my-2 pe-2" [formGroup]="Form1">
                                            <span class="p-float-label">
                                                <p-calendar #cli touchUI="true" [touchUI]="screenSize" selectionMode="range"
                                                    pTooltip="Start date for event" showDelay="1000" tooltipPosition="bottom"
                                                    appendTo="body" inputId="salesStart" [styleClass]="'form-control m-0 p-0 '"
                                                    [inputStyleClass]="'form-control'" [formControl]="Form1.get('date')"
                                                    (onClose)="getDate($event)">
                                                </p-calendar>
                                                <label class="form-label font-size-14" for="salesStart">
                                                    {{'SrhPgFltrTitl001Inpt001'|translate}}</label>
                                            </span>
                                        </div>
                                    </div>
                                    <h6 class="fw-bold mt-3">{{'SrhPgFltrTitl002'|translate}}</h6>
                                    <div class="d-flex flex-wrap w-100">
                                        <div class="pe-1">
                                            <input type="radio" class="btn-check " id="price" name="price"
                                                [value]="srv.searchForm.get('free').value&&!srv.searchForm.get('paid').value"  (click)="getPrice('free')"/>
                                            <label class="btn btn-outline-primary mb-2" for="price">{{'SrhPgFltrTitl002Chois001'|translate}}</label>
                                        </div>
                                        <div class="pe-1">
                                            <input type="radio" class="btn-check" id="price1"  name="price"
                                                [value]=" !srv.searchForm.get('free').value&&srv.searchForm.get('paid').value" (click)="getPrice('paid')" />
                                            <label class="btn btn-outline-primary mb-2" for="price1">{{'SrhPgFltrTitl002Chois002'|translate}}</label>
                                        </div>
                                        <div class="pe-1">
                                            <input type="radio" class="btn-check" id="price11"  name="price"
                                                [value]=" !srv.searchForm.get('free').value&&!srv.searchForm.get('paid').value"
                                                 (click)="getPrice('all')" />
                                            <label class="btn btn-outline-primary mb-2" for="price11">{{'SrhPgFltrTitl002Chois003'|translate}}</label>
                                        </div>
                                    </div>
                                    <!-- <div class="d-flex flex-wrap w-100">
                                        <div class="pe-1">
                                            <input type="radio" class="btn-check " id="price" formControlName="free"
                                                [value]="true"  (click)="getPrice(true)"/>
                                            <label class="btn btn-outline-primary mb-2" for="price">Free</label>
                                        </div>
                                        <div class="pe-1">
                                            <input type="radio" class="btn-check" id="price1"  formControlName="free"
                                                [value]="false" (click)="getPrice(false)" />
                                            <label class="btn btn-outline-primary mb-2" for="price1">Paid</label>
                                        </div>
                                    </div> -->
                                    <h6 class="fw-bold mt-3">{{'BscInfoTitle002Slct002'|translate}}</h6>
                                    <div class="row">
                                        <div class="p-input-filled col-12  mb-3 pe-4">
                                            <span class="p-float-label">
                                                <p-dropdown inputId="TicketType4" [autoDisplayFirst]="false" [filter]="true"
                                                    filterBy="name" [showClear]="true" id="event-category" [styleClass]="'w-100'"
                                                    pTooltip="Select the category of your event" showDelay="1000"
                                                    appendTo="body"
                                                    tooltipPosition="bottom" [styleClass]="'w-100'" formControlName="cat"
                                                    [options]="categories" optionLabel="name" optionValue="Id" (onChange)="filter()"></p-dropdown>
                                                <label class="form-label font-size-14" for="event-category">{{'BscInfoTitle002Slct002'|translate}}</label>
                                            </span>
                                        </div>
                                    </div>
                                    <h6 class="fw-bold ">{{'BscInfoTitle002Slct001'|translate}}</h6>
                                    <div class="row ">
                                        <div class="p-input-filled col-12 mb-3  pe-4">
                                            <span class="p-float-label">
                                                <p-dropdown inputId="TicketType1" [filter]="true" filterBy="name"
                                                appendTo="body" [showClear]="true"
                                                    pTooltip="Select the type of your event" showDelay="1000"
                                                    tooltipPosition="bottom" id="event-type" [styleClass]="'w-100'"
                                                    formControlName="typ" [autoDisplayFirst]="false" [options]="types"
                                                    optionLabel="name" optionValue="Id" #typeId (onChange)="filter()"></p-dropdown>
                                                <label class="form-label font-size-14" for="event-type">{{'BscInfoTitle002Slct001'|translate}}</label>
                                            </span>
                                        </div>
                                    </div>
                                    <h6 class="fw-bold ">{{'BscInfoTitle001Slct002'|translate}}</h6>
                                    <div class="row">
                                        <div class="p-input-filled col-12 mb-3 pe-4">
                                            <span class="p-float-label">
                                                <p-dropdown [showClear]="true" inputId="currencyId" id="event-type" [filter]="true"
                                                    pTooltip="Select the currency for this event" showDelay="1000"
                                                    tooltipPosition="bottom" [styleClass]="'w-100'" formControlName="cur"
                                                    [autoDisplayFirst]="false" [options]="curData" optionLabel="name"
                                                    appendTo="body" optionValue="Id" #typeId  (onChange)="filter()"></p-dropdown>
                                                <label class="form-label font-size-14" for="curId">{{'BscInfoTitle001Slct002'|translate}}</label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Filter-Section-End -->
                            <!-- Event-Cards-Start -->
                            <div class="col-lg-8 col-md-8   p-0 m-0">
                                <div class="card-base py-3">
                                    <div @fadeOut style="position: absolute; top: 0; right: 0; left: 0;bottom: 0; z-index: 9999; height: 100vh; background-color: #fff;"
                                    class="d-flex justify-content-center align-items-center" *ngIf="showloader">
                                   <p-progressSpinner></p-progressSpinner>
                            </div>
                                    <ng-container *ngFor="let item of dataEvents">
                                        <app-search-event-card [item]="item" (likedClicked)="Like($event)" [filters]="srv.searchForm.value"></app-search-event-card>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                      </div>
                <!-- Filter-Section-Start -->

                

            </div>
            <div class="col-lg-4 col-0 d-lg-block d-none  p-0 " *ngIf="!srv.searchForm.get('online').value">
                <div #gmap style="width:100%;height:100%" #addresstext></div>
            </div>
        </div>
    </div>
</form>
