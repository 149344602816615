
<p-toast></p-toast>
<div class="container">
   
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Local string'|translate}}</h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="">
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <h2 class="fw-bold">{{PageTitle|translate}}</h2> -->

                <div>
                    <!-- <h6 class="fw-bold  ">Help <span class="text-primary help-btn" (click)="showHelpStepsModal()" ><i class="fa-regular fa-circle-question"></i></span></h6> -->
                    <!-- <p-dialog header="How to start" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                    [draggable]="false" [resizable]="false" >
                    <p class="m-0"><b class="text-primary">Step 1: </b> Hkas sakjfas fjkasf sajfs afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 2: </b> Okas sakjfas fjkasf sajfss adas s ad afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 3: </b> Lasfas sakjfas fjkasf sajfs ad sa dasd afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 4: </b> Kasdkas sakjfas fjkasf sajfsas dsa dsa sa d dsa dsa dsa d afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 5: </b> Bkas sakjfas fjkasf sajfssa dsa as  afjasf</p>
                        <ng-template pTemplate="footer">
                        <p-button icon="pi pi-check" (click)="helpStepsModal=false" label="Ok" styleClass="p-button-text"></p-button>
                        </ng-template>
                </p-dialog> -->
                </div>

                    </div>

                    <div class="row">
                           
                        <form [formGroup]="srv.myForm" (submit)="sumbit()" autocomplete="off" >
                            <div class="row mb-3">
                                <div class="p-input-filled col-sm-3 col-md-3 mb-2">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="cultureId" pTooltip="{{'Culture id'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  srv.myForm.get('cultureId')?.touched &&
                                                    srv.myForm.get('cultureId')?.invalid||(submitted&& srv.myForm.get('cultureId').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #ticketName  [readOnly]=" srv.myForm.get('cultureId').value!=''" />
                                        <label class="form-lable font-size-14" for="TicketName">{{'Culture id'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="srv.myForm.get('cultureId')?.touched &&
                                    srv.myForm.get('cultureId')?.invalid||(submitted&& srv.myForm.get('cultureId').invalid)
                                        " >{{'TcktFrmInfoInpt001ErrMsg'|translate}}</small>
                        
                                </div>
                                <div class="p-input-filled col-sm-3 col-md-3 mb-2">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="name" pTooltip="{{'Name'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  srv.myForm.get('name')?.touched &&
                                                    srv.myForm.get('name')?.invalid||(submitted&& srv.myForm.get('name').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #ticketName  />
                                        <label class="form-lable font-size-14" for="TicketName">{{'Name'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="srv.myForm.get('name')?.touched &&
                                    srv.myForm.get('name')?.invalid||(submitted&& srv.myForm.get('name').invalid)
                                        " >{{'TcktFrmInfoInpt001ErrMsg'|translate}}</small>
                        
                                </div>
                                <div class="p-input-filled col-sm-3 col-md-3 mb-2">
                                    <span class="p-float-label">
                                        <input type="text" formControlName="uiCulture" pTooltip="{{'Ui Culture'|translate}}"
                                        showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                  srv.myForm.get('uiCulture')?.touched &&
                                                    srv.myForm.get('uiCulture')?.invalid||(submitted&& srv.myForm.get('uiCulture').invalid)
                                            }" [class.ng-invalid.ng-dirty]="" #ticketName   />
                                        <label class="form-lable font-size-14" for="TicketName">{{'Ui Culture'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="srv.myForm.get('uiCulture')?.touched &&
                                    srv.myForm.get('uiCulture')?.invalid||(submitted&& srv.myForm.get('uiCulture').invalid)
                                        " >{{'TcktFrmInfoInpt001ErrMsg'|translate}}</small>
                        
                                </div>
                                <div class="p-input-filled col-sm-3 col-md-3 mb-2">
                                    <div class="row justify-content-between mt-md-2">
                                      
                                 
                                        <div class="col-6">
                                            <p-inputSwitch inputId="Etic" ariaLabelledBy="lab1"
                                                pTooltip="{{'Is active'|translate}}" showDelay="1000"
                                                tooltipPosition="bottom" formControlName="status"   class="me-2"></p-inputSwitch>
                                            <label class="form-check-label  " id="lab1"
                                                for="Etic">{{'Is active'|translate}}</label>
                                        </div>

                                        <div class="col-6">
                                            <p-inputSwitch inputId="Etic" ariaLabelledBy="lab1"
                                                pTooltip="{{'Is Primary'|translate}}" showDelay="1000"
                                                tooltipPosition="bottom" formControlName="priority"   class="me-2"></p-inputSwitch>
                                            <label class="form-check-label  " id="lab1"
                                                for="Etic">{{'Is Primary'|translate}}</label>
                                        </div>
                                   
                                
                        
                                   
                                    </div>
                        
                                </div>
                                
                            
                            
                            </div>

                          
                           
                            <div class="col-12 d-flex justify-content-center">
                                <div class="banner-btn rounded-pill">
                                    <button pButton pRipple pTooltip="Cancel and dismiss changes" type="button" showDelay="1000"
                                        tooltipPosition="bottom" class="rounded-pill save-btn text-center ms-1"
                                        (click)="cancel()">{{'CanclBtn'|translate}} <span></span></button>
                                    <button pButton pRipple type="Save and finish " showDelay="1000" tooltipPosition="bottom"
                                        class="rounded-pill save-btn text-center ms-1 mt-md-0 mt-1">{{'FnshBtn'|translate}}
                                        <span></span></button>
                                </div>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </div>

</div>






