<p-carousel [value]="SelectedCategory.subCategory" styleClass="custom-carousel mt-5 " [numVisible]="5" [dir]="'ltr'"
    [numScroll]="1" [circular]="true" [autoplayInterval]="3000" [responsiveOptions]="responsiveOptions"
    [showIndicators]="false">
    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 text-center section-title">
                <h2>{{'Explore within'|translate}}{{SelectedCategory.name}}</h2>
            </div>
        </div>
    </ng-template>
    <ng-template let-product pTemplate="item">



        <div class="product-item">
            <div class="product-item-content">

                <!-- <div class="mb-3 ">
                    <a [routerLink]="['/category/',product.id]"> </a>
                </div> -->
                <a class="w-100 h-100 custom-padd" [routerLink]="['/category/',id,product.id]">
                    <h4 class="mb-1 fw-bold  text-primary">{{product.name}}</h4>
                </a>


            </div>
        </div>
    </ng-template>
</p-carousel>

<p-carousel [value]="resently" [numVisible]="3" [numScroll]="1" [circular]="true" [dir]="'ltr'"
    [responsiveOptions]="responsiveOptions" styleClass="mt-5" [showIndicators]="false">
    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 text-center section-title">
                <h2> {{'popular events'|translate}}</h2>
            </div>


        </div>
        <div class="row">
            <div class="col-12 text-center section-title" *ngIf="resently.length==0">
                <h4>{{'There is no events yet in this section'|translate}}</h4>
            </div>


        </div>
    </ng-template>
    <ng-template let-product pTemplate="item">

        <div class="mx-2 my-2">


            <app-event-card class="d-flex justify-content-center align-items-center" [item]="product"
                (likedClicked)="Like($event)"></app-event-card>
        </div>
    </ng-template>


</p-carousel>

<p-carousel [value]="WeekluEvents" [showIndicators]="false" [numVisible]="3" [numScroll]="1" [circular]="true"
    [dir]="'ltr'" [responsiveOptions]="responsiveOptions" styleClass="mt-5">
    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 text-center">
                <div class="row">
                    <div class="col-12 text-center section-title">
                        <h2>{{'This week'|translate}}</h2>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center section-title" *ngIf="WeekluEvents.length==0">
                <h4> {{'There is no events yet in this section'|translate}}</h4>
            </div>


        </div>
    </ng-template>
    <ng-template let-product pTemplate="item">
        <!-- <div class="mx-2 my-2 "*ngIf="WeekluEvents.length<=2" style="max-width:33.3333%;" >


            <app-event-card [item]="product" (likedClicked)="Like($event)"></app-event-card>
        </div> -->
        <div class="mx-2 my-2">
            <!-- //*ngIf="resently.length>3" -->

            <app-event-card class="d-flex justify-content-center align-items-center" [item]="product"
                (likedClicked)="Like($event)"></app-event-card>
        </div>
    </ng-template>
</p-carousel>


<!-- <p-carousel [value]="products" [numVisible]="3" [numScroll]="3" [circular]="true"
    [responsiveOptions]="responsiveOptions" styleClass="mt-5">
    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 text-center">
                <div class="row">
                    <div class="col-12 text-center section-title">
                        <h2>Organizers to follow</h2>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template let-product pTemplate="item">
        <div class="mx-2 my-2">
            <app-organizer-card [item]="" (likedClicked)="Like($event)"></app-organizer-card>
        </div>
    </ng-template>
</p-carousel> -->

<p-carousel [value]="OnlineEvents" [showIndicators]="false" [numVisible]="3" [numScroll]="1" [circular]="true"
    [dir]="'ltr'" [responsiveOptions]="responsiveOptions" styleClass="mt-5">
    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 text-center section-title">
                <h2>{{'Online'|translate}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center section-title" *ngIf="OnlineEvents.length==0">
                <h4> {{'There is no events yet in this section'|translate}}</h4>
            </div>


        </div>
    </ng-template>
    <ng-template let-product pTemplate="item">

        <div class="mx-2 my-2">
            <!-- //*ngIf="resently.length>3" -->

            <app-event-card class="d-flex justify-content-center align-items-center" [item]="product"
                (likedClicked)="Like($event)"></app-event-card>
        </div>
    </ng-template>
</p-carousel>
<p-carousel [value]="FreeEvents" [numVisible]="3" [showIndicators]="false" [numScroll]="3" [circular]="true"
    [dir]="'ltr'" [responsiveOptions]="responsiveOptions" styleClass="mt-5">
    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 text-center section-title">
                <h2>{{'Free'|translate}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center section-title" *ngIf="FreeEvents.length==0">
                <h4> {{'There is no events yet in this section'|translate}}</h4>
            </div>


        </div>
    </ng-template>
    <ng-template let-product pTemplate="item">

        <div class="mx-2 my-2">
            <!-- //*ngIf="resently.length>3" -->

            <app-event-card class="d-flex justify-content-center align-items-center" [item]="product"
                (likedClicked)="Like($event)"></app-event-card>
        </div>
    </ng-template>
</p-carousel>
<!-- <p-carousel [value]="data1" [numVisible]="10" [numScroll]="3" [circular]="false"
    [responsiveOptions]="responsiveOptions">
    <ng-template pTemplate="header">
        <h5>Basic</h5>
    </ng-template>
    <ng-template let-product pTemplate="item">
        <div class="product-item">
            <div class="product-item-content-cat" style="background-color: aqua;">
                <div class="col-4" style="background-color:rgb(0, 8, 255); "></div>
            </div>
        </div>
        <div class="product-item">
            <div class="product-item-content-cat" style="background-color: aqua;">
                <div class="col-4" style="background-color:rgb(0, 8, 255); "></div>
            </div>
        </div>
    </ng-template>
</p-carousel> -->


<p-carousel [value]="todayEvents" [showIndicators]="false" [numVisible]="3" [numScroll]="1" [circular]="true"
    [dir]="'ltr'" [responsiveOptions]="responsiveOptions" styleClass="mt-5">
    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 text-center section-title">
                <h2>{{'Today'|translate}}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center section-title" *ngIf="todayEvents.length==0">
                <h4> {{'There is no events yet in this section'|translate}}</h4>
            </div>


        </div>
    </ng-template>
    <ng-template let-product pTemplate="item">

        <div class="mx-2 my-2">
            <!-- //*ngIf="resently.length>3" -->

            <app-event-card class="d-flex justify-content-center align-items-center" [item]="product"
                (likedClicked)="Like($event)"></app-event-card>
        </div>
    </ng-template>
</p-carousel>
<!-- <p-carousel [value]="products" [numVisible]="3" [numScroll]="3" [circular]="true"
    [responsiveOptions]="responsiveOptions" styleClass="my-5">
    <ng-template pTemplate="header">
        <div class="row">
            <div class="col-12 text-center section-title">
                <h2>Festivals</h2>
            </div>
        </div>
    </ng-template>
    <ng-template let-product pTemplate="item">
        <div class="mx-2 my-2">
            <app-event-card [item]="product" (likedClicked)="Like($event)"></app-event-card>
        </div>
    </ng-template>
</p-carousel> -->

<div class="row justify-content-center">
    <div class="row">
        <div class="col-12 text-center section-title">
            <h2>{{'Explore within type'|translate}} </h2>
        </div>
    </div>

    <div class="col-lg-3 col-sm-4 col-6 " *ngFor="let item of types">
        <a href="javascript:;" class="card   d-flex justify-content-center align-items-center  type-box"
            (click)="gotosearch(item.Id)">
            <span class="fw-bold mb-0">{{item.name}}</span>
        </a>
    </div>


</div>


<app-features-style-one></app-features-style-one>