import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './components/pages/error/error.component';
import { EventsComponent } from './components/pages/events/events.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { LandingPageComponent } from './components/pages/landing-page/landing-page.component';
import { BasicInfoComponent } from './components/pages/events/event-steps/basic-info/basic-info.component';
import { EventStepsComponent } from './components/pages/events/event-steps/event-steps.component';
import { OrganizersComponent } from './components/pages/organizers/organizers.component';
import { CreateEventProfileComponent } from './components/pages/events/event-steps/create-event-profile/create-event-profile.component';
import { TicketsComponent } from './components/pages/events/event-steps/tickets/tickets.component';
import { EventDateTimeComponent } from './components/pages/events/event-steps/event-date-time/event-date-time.component';
import { EventAddressComponent } from './components/pages/events/event-steps/event-address/event-address.component';
import { PromoteComponent } from './components/pages/events/event-steps/tickets/promote/promote.component';
import { OrderFormComponent } from './components/pages/order-form/order-form.component';
import { OrderFormBuilderComponent } from './components/pages/events/event-steps/order-form-builder/order-form-builder.component';
import { AddOnsComponent } from './components/pages/events/event-steps/tickets/add-ons/add-ons.component';
import { TicketsStepsComponent } from './components/pages/events/event-steps/tickets/tickets-steps/tickets-steps.component';
import { TicketsFormComponent } from './components/pages/events/event-steps/tickets/tickets-form/tickets-form.component';
import { InfoComponent } from './components/pages/events/event-steps/tickets/tickets-form/info/info.component';
import { TicketDateComponent } from './components/pages/events/event-steps/tickets/tickets-form/ticket-date/ticket-date.component';
import { TicketSettingsComponent } from './components/pages/events/event-steps/tickets/tickets-form/ticket-settings/ticket-settings.component';
import { InitialTicketFormComponent } from './components/pages/events/event-steps/tickets/tickets-form/initial-ticket-form/initial-ticket-form.component';
import { GroupInfoComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-group-form/group-info/group-info.component';
import { VarietiesComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-group-form/varieties/varieties.component';
import { AddonGroupFormComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-group-form/addon-group-form.component';
import { AddonSettingsComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-settings/addon-settings.component';
import { AddonSalesDateComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-sales-date/addon-sales-date.component';
import { AddonInfoComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-info/addon-info.component';
import { AddonInitComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-init/addon-init.component';
import { AddonFormComponent } from './components/pages/events/event-steps/tickets/add-ons/addon-form/addon-form.component';
import { PromoteformComponent } from './components/pages/events/event-steps/tickets/promoteform/promoteform.component';
import { Step1Component } from './components/pages/events/event-steps/tickets/promoteform/step1/step1.component';
import { Step2Component } from './components/pages/events/event-steps/tickets/promoteform/step2/step2.component';
import { Step3Component } from './components/pages/events/event-steps/tickets/promoteform/step3/step3.component';
import { InterestComponent } from './components/pages/interest/interest.component';
import { EventPageComponent } from './components/pages/event-page/event-page.component';
import { CalenderFormStep1Component } from './components/pages/events/event-steps/calender/calender-steps/calender-form-step1/calender-form-step1.component';
import { CalenderFormStep2Component } from './components/pages/events/event-steps/calender/calender-steps/calender-form-step2/calender-form-step2.component';
import { CalenderFormStep3Component } from './components/pages/events/event-steps/calender/calender-steps/calender-form-step3/calender-form-step3.component';
import { CalenderStep4Component } from './components/pages/events/event-steps/calender/calender-steps/calender-step4/calender-step4.component';
import { CalenderStepsComponent } from './components/pages/events/event-steps/calender/calender-steps/calender-steps.component';
import { ClenderStepsPageComponent } from './components/pages/events/event-steps/calender/clender-steps-page/clender-steps-page.component';
import { CalenderComponent } from './components/pages/events/event-steps/calender/calender.component';
import { OrganizerProfileComponent } from './components/pages/organizer-profile/organizer-profile.component';
import { LoginComponent } from './components/shared/login/login.component';
import { RegisterComponent } from './components/shared/register/register.component';
import { OnlineEventPageComponent } from './components/pages/events/event-steps/online-event-page/online-event-page.component';
import { ClenderUpdateSingleInstansComponent } from './components/pages/events/event-steps/calender/clender-update-single-instans/clender-update-single-instans.component';
import { FormStep1Component } from './components/pages/organizers/organizer-form/form-step1/form-step1.component';
import { FormStep2Component } from './components/pages/organizers/organizer-form/form-step2/form-step2.component';
import { OrganizerFormComponent } from './components/pages/organizers/organizer-form/organizer-form.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { EntrepriseComponent } from './components/pages/entreprise/entreprise.component';
import { RedirectComponent } from './components/shared/redirect/redirect.component';
import { PreviewComponent } from './components/pages/organizers/preview/preview.component';
import { TeamManageComponent } from './components/pages/organizers/team-manage/team-manage.component';
import { TeamMembersComponent } from './components/pages/organizers/team-manage/team-members/team-members.component';
import { TeamRolesComponent } from './components/pages/organizers/team-manage/team-roles/team-roles.component';
import { PromoCodesStepsComponent } from './components/pages/events/event-steps/tickets/promote/promo-codes-steps/promo-codes-steps.component';
import { AddOnsStepComponent } from './components/pages/events/event-steps/tickets/add-ons/add-ons-step/add-ons-step.component';
import { RolesFormComponent } from './components/pages/organizers/team-manage/team-roles/roles-form/roles-form.component';
import { RolesTableComponent } from './components/pages/organizers/team-manage/team-roles/roles-table/roles-table.component';
import { InviteTableComponent } from './components/pages/organizers/team-manage/invite-table/invite-table.component';
import { BrowseEventsComponent } from './components/pages/browse-events/browse-events.component';
import { PublishEventComponent } from './components/pages/events/event-steps/publish-event/publish-event.component';
import { ComingSoonComponent } from './components/shared/coming-soon/coming-soon.component';
import { OrderEventComponent } from './components/pages/order-event/order-event.component';
import { OrganizerStepsComponent } from './components/pages/organizers/organizer-steps/organizer-steps.component';
import { RouteGuard } from './gurad/route.guard';
import { EditAddGuard } from './gurad/edit-add.guard';
import { NotAllowedComponent } from './components/pages/not-allowed/not-allowed.component';
import { EmailCheckComponent } from './components/shared/register/email-check/email-check.component';
import { RolesStepsComponent } from './components/pages/organizers/team-manage/team-roles/roles-steps/roles-steps.component';
import { AddDateComponent } from './components/pages/add-date/add-date.component';
import { OnlineComponent } from './components/pages/online/online.component';
import { FavoritesComponent } from './components/pages/favorites/favorites.component';
import { UserOrderComponent } from './components/pages/user-order/user-order.component';
import { UserOrderPreviewComponent } from './components/pages/user-order/user-order-preview/user-order-preview.component';
import { StepOneSearchComponent } from './components/pages/browse-events/step-one-search/step-one-search.component';
import { ResetPasswordFormComponent } from './components/shared/register/reset-password/reset-password-form/reset-password-form.component';
import { ResetPasswordComponent } from './components/shared/register/reset-password/reset-password.component';
import { FeaturesFilterComponent } from './components/common/features-style-one/features-style-filter/features-filter/features-filter.component';
import { SubEventsforMaineventsComponent } from './components/pages/events/event-steps/sub-eventsfor-mainevents/sub-eventsfor-mainevents.component';
import { EventDashboardComponent } from './components/pages/events/event-steps/event-dashboard/event-dashboard.component';
import { CopyAddOnsGroupComponent } from './components/pages/events/event-steps/tickets/add-ons/copy-add-ons-group/copy-add-ons-group.component';
import { WaitListComponent } from './components/pages/events/event-steps/wait-list/wait-list.component';
import { OrderConfirmationComponent } from './components/pages/events/event-steps/order-confirmation/order-confirmation.component';
import { TicketsMsgComponent } from './components/pages/order-event/tickets-msg/tickets-msg.component';
import { ConfirmationComponent } from './components/pages/order-event/confirmation/confirmation.component';
import { EventTypeComponent } from './components/Admin/event-type/event-type.component';
import { TypeFormComponent } from './components/Admin/type-form/type-form.component';
import { LocalstringComponent } from './components/Admin/localstring/localstring.component';
import { LocalstringformComponent } from './components/Admin/localstringform/localstringform.component';
import { CategoryEventComponent } from './components/Admin/category-event/category-event.component';
import { CategoryEventformComponent } from './components/Admin/category-eventform/category-eventform.component';
import { SubCategoryEventComponent } from './components/Admin/sub-category-event/sub-category-event.component';
import { SubCategoryEventformComponent } from './components/Admin/sub-category-eventform/sub-category-eventform.component';
import { EventTagComponent } from './components/Admin/event-tag/event-tag.component';
import { EventTagformComponent } from './components/Admin/event-tagform/event-tagform.component';
import { CurrencyMangmaentComponent } from './components/Admin/currency-mangmaent/currency-mangmaent.component';
import { CurrencyFormComponent } from './components/Admin/currency-form/currency-form.component';
import { SystemLanguegesmangComponent } from './components/Admin/system-languegesmang/system-languegesmang.component';
import { SyslangformsrvService } from './components/Admin/system-langueges-form/syslangformsrv.service';
import { SystemLanguegesFormComponent } from './components/Admin/system-langueges-form/system-langueges-form.component';
import { AdminDashboardComponent } from './components/Admin/admin-dashboard/admin-dashboard.component';
import { OrdersReportComponent } from './components/pages/events/event-steps/attendees-mangment/orders-report/orders-report.component';
import { AttendeesMAngmentComponent } from './components/pages/events/event-steps/attendees-mangment/attendees-mangment.component';
import { TryrComponent } from './components/shared/tryr/tryr.component';
import { AntaresPersentageComponent } from './components/Admin/antares-persentage/antares-persentage.component';
import { PaymentPersentageComponent } from './components/Admin/payment-persentage/payment-persentage.component';
import { ShippingPersentageComponent } from './components/Admin/shipping-persentage/shipping-persentage.component';
import { EditAttendeesInfoComponent } from './components/pages/events/event-steps/attendees-mangment/edit-attendees-info/edit-attendees-info.component';
import { UserProfileFormComponent } from './components/pages/user-profile-form/user-profile-form.component';
import { ProfileFormComponent } from './components/shared/profile-form/profile-form.component';
import { JsonGetShippingAddressComponent } from './components/shared/json-get-shipping-address/json-get-shipping-address.component';
import { AddNewAttendeesComponent } from './components/pages/events/event-steps/attendees-mangment/add-new-attendees/add-new-attendees.component';
import { AddnewAteendeesinfoComponent } from './components/pages/events/event-steps/attendees-mangment/add-new-attendees/addnew-ateendeesinfo/addnew-ateendeesinfo.component';
import { AddnewAttendessOfflineComponent } from './components/pages/events/event-steps/attendees-mangment/add-new-attendees/addnew-ateendeesinfo/addnew-attendess-offline/addnew-attendess-offline.component';
import { DesingCoComponent } from './components/shared/desing-co/desing-co.component';
import { UserHomeProfileComponent } from './components/pages/user-home-profile/user-home-profile.component';
import { UserProfileHomeEditComponent } from './components/pages/user-home-profile/user-profile-home-edit/user-profile-home-edit.component';
import { AdduserprofilephotoComponent } from './components/shared/adduserprofilephoto/adduserprofilephoto.component';
import { USerProfilePrviewComponent } from './components/pages/user-home-profile/user-profile-prview/user-profile-prview.component';
import { UserByTicketsComponent } from './components/pages/user-by-tickets/user-by-tickets.component';
import { UserByAddonsComponent } from './components/pages/user-by-addons/user-by-addons.component';
import { USerpriviewOrderinstanceComponent } from './components/pages/userpriview-orderinstance/userpriview-orderinstance.component';
import { ConfirmationOfflineOrderComponent } from './components/pages/events/event-steps/attendees-mangment/add-new-attendees/addnew-ateendeesinfo/confirmation-offline-order/confirmation-offline-order.component';
import { PermissiontransateMangmentComponent } from './components/Admin/permissiontransate-mangment/permissiontransate-mangment.component';
import { LogomangmentComponent } from './components/Admin/logomangment/logomangment.component';
import { LogoAddFormComponent } from './components/Admin/logo-add-form/logo-add-form.component';
import { AttendinglistcoComponent } from './components/pages/events/event-steps/attendees-mangment/attendinglistco/attendinglistco.component';
import { ReportingMenuComponent } from './components/pages/reporting-menu/reporting-menu.component';
import { SalesReportingComponent } from './components/pages/reporting-menu/sales-reporting/sales-reporting.component';
import { AttendingBasicReportngComponent } from './components/pages/reporting-menu/attending-basic-reportng/attending-basic-reportng.component';
import { BoxOfficeReportingComponent } from './components/pages/reporting-menu/box-office-reporting/box-office-reporting.component';
import { InventoryReportingComponent } from './components/pages/reporting-menu/inventory-reporting/inventory-reporting.component';
import { SalesByTicketTypeReportComponent } from './components/pages/reporting-menu/sales-reporting/sales-by-ticket-type-report/sales-by-ticket-type-report.component';
import { compareByFieldSpecs } from '@fullcalendar/core/internal';
import { UpdateAttendeesGlobalComponent } from './components/pages/reporting-menu/sales-reporting/update-attendees-global/update-attendees-global.component';
import { SalesByAddonsTypeReportComponent } from './components/pages/reporting-menu/sales-reporting/sales-by-addons-type-report/sales-by-addons-type-report.component';
//FeaturesFilterComponent
const routes: Routes = [
    { path: '', component: LandingPageComponent },

    { path: 'your-order/:id', component: USerpriviewOrderinstanceComponent },
    { path: 'home', component: UserHomeProfileComponent },
    { path: 'update-profile-image', component: AdduserprofilephotoComponent },
    { path: 'ert', component: UserHomeProfileComponent },

    { path: 'At', component: UserByAddonsComponent },

    {
        path: 'Admin-Dashboard',
        canActivate: [RouteGuard],
        data: { roleType: 'de0f642f-ad5c-469b-9e68-6f542d6cdb92' },
        component: AdminDashboardComponent,
        children: [
            {
                path: 'Type',
                children: [
                    { path: '', component: EventTypeComponent },
                    { path: ':id', component: TypeFormComponent },
                ],
            },
            {
                path: 'persentage',
                children: [{ path: '', component: AntaresPersentageComponent }],
            },
            {
                path: 'Payement-Persentage',
                children: [{ path: '', component: PaymentPersentageComponent }],
            },
            {
                path: 'Shipping-Cost',
                children: [
                    { path: '', component: ShippingPersentageComponent },
                ],
            },
            {
                path: 'categories',
                children: [
                    { path: '', component: CategoryEventComponent },
                    {
                        path: ':id',
                        children: [
                            { path: '', component: CategoryEventformComponent },
                            {
                                path: 'sub-Category',
                                children: [
                                    {
                                        path: '',
                                        component: SubCategoryEventComponent,
                                    },
                                    {
                                        path: ':subId',
                                        component:
                                            SubCategoryEventformComponent,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'Local-string',
                children: [
                    { path: '', component: LocalstringComponent },
                    { path: ':id', component: LocalstringformComponent },
                ],
            },
            {
                path: 'Tags',
                children: [
                    { path: '', component: EventTagComponent },
                    { path: ':id', component: EventTagformComponent },
                ],
            },
            {
                path: 'Currencies',
                children: [
                    { path: '', component: CurrencyMangmaentComponent },
                    { path: ':id', component: CurrencyFormComponent },
                ],
            },
            {
                path: 'languages',
                children: [
                    { path: '', component: SystemLanguegesmangComponent },
                    { path: ':id', component: SystemLanguegesFormComponent },
                ],
            },
            {
                path: 'logoes',
                children: [
                    { path: '', component: LogomangmentComponent },
                    { path: 'newLogo', component: LogoAddFormComponent },
                ],
            },
        ],
    },

    {
        path: 'Reporting',
        children: [
            { path: '', component: ReportingMenuComponent },
            {
                path: 'edit-attendees/:Id/:Attendee',
                component: UpdateAttendeesGlobalComponent,
            },
            {
                path: 'Sales',
                children: [
                    { path: '', component: SalesReportingComponent },
                    {
                        path: 'by-Ticket',
                        component: SalesByTicketTypeReportComponent,
                    },
                    {
                        path: 'by-add-ons',
                        component: SalesByAddonsTypeReportComponent,
                    },
                ],
            },
            {
                path: 'Attendees',
                children: [
                    { path: '', component: AttendingBasicReportngComponent },
                ],
            },
            {
                path: 'boxOffice',
                children: [
                    { path: '', component: BoxOfficeReportingComponent },
                ],
            },
            {
                path: 'Inventory',
                children: [
                    { path: '', component: InventoryReportingComponent },
                ],
            },
        ],
    },

    { path: 'login', component: LoginComponent },
    { path: 'login/:id', component: LoginComponent },
    { path: 'Profile', component: USerProfilePrviewComponent },
    { path: 'User-profile', component: UserProfileHomeEditComponent },
    { path: 'User-Tickets', component: UserByTicketsComponent },
    { path: 'User-Add-ons', component: UserByAddonsComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'register/:id', component: RegisterComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'resetPassword', component: ResetPasswordComponent },
    {
        path: 'resetPasswordConfirmation/:id/:email',
        component: ResetPasswordFormComponent,
    },
    { path: 'enterprise', component: EntrepriseComponent },
    {
        path: 'search',
        children: [
            { path: '', component: BrowseEventsComponent },
            { path: 'filter', component: BrowseEventsComponent },
        ],
    },
    {
        path: 'category/:id',
        children: [
            { path: '', component: FeaturesFilterComponent },
            { path: ':SubId', component: FeaturesFilterComponent },
        ],
    },
    { path: 'interests', component: InterestComponent },
    { path: 'favorites', component: FavoritesComponent },
    { path: 'ert', component: OrderFormComponent },
    {
        path: 'user-order',
        children: [
            { path: '', component: UserOrderComponent },
            { path: 'preview/:id', component: UserOrderPreviewComponent },
        ],
    },
    {
        path: 'organizers',
        canActivate: [RouteGuard],
        data: { claimType: 0 },
        component: OrganizerStepsComponent,
        children: [
            { path: '', component: OrganizersComponent },

            {
                path: 'organizer-form/:id',
                component: OrganizerFormComponent,
                canActivate: [EditAddGuard],
                data: { add: 10105030103, edit: 10105030202 },
                children: [
                    {
                        path: 'step1',
                        component: FormStep1Component,
                    },
                    {
                        path: 'step2',
                        component: FormStep2Component,
                    },
                ],
            },
            {
                path: 'team-manage/:id',
                canActivate: [RouteGuard],
                data: { claimType: 10105030201 },
                component: TeamManageComponent,
                children: [
                    {
                        path: 'team-roles',
                        component: RolesStepsComponent,
                        children: [
                            {
                                path: '',
                                component: TeamRolesComponent,
                                children: [
                                    {
                                        path: 'roles-form',
                                        component: RolesFormComponent,
                                    },
                                    {
                                        path: 'roles-table',
                                        component: RolesTableComponent,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: 'team-members',
                        component: TeamMembersComponent,
                    },
                    {
                        path: 'invite-member',
                        component: InviteTableComponent,
                    },
                ],
            },
            { path: 'preview/:id', component: PreviewComponent },
        ],
    },

    {
        path: 'events',
        canActivate: [RouteGuard],
        data: { claimType: 0 },
        children: [
            { path: '', component: EventsComponent },
            { path: 'event-details/:id', component: LandingPageComponent },
            {
                path: 'manage-event/:id',
                component: EventStepsComponent,
                canActivate: [RouteGuard],
                data: { claimType: 10105030101 },
                children: [
                    { path: 'basic-info', component: BasicInfoComponent },
                    { path: 'location', component: EventAddressComponent },
                    {
                        path: 'tickets',
                        component: TicketsStepsComponent,
                        canActivate: [RouteGuard],
                        data: { claimType: 10105030103 },
                        children: [
                            { path: '', component: TicketsComponent },
                            {
                                path: 'ticket-form',
                                component: TicketsFormComponent,
                                children: [
                                    {
                                        path: 'initial',
                                        component: InitialTicketFormComponent,
                                    },
                                    {
                                        path: 'info',
                                        component: InfoComponent,
                                    },
                                    {
                                        path: 'date',
                                        component: TicketDateComponent,
                                    },
                                    {
                                        path: 'settings',
                                        component: TicketSettingsComponent,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'Add-ons',
                        component: AddOnsStepComponent,
                        children: [
                            { path: '', component: AddOnsComponent },
                            {
                                path: 'addon-from',
                                component: AddonFormComponent,
                                children: [
                                    {
                                        path: 'initial',
                                        component: AddonInitComponent,
                                    },
                                    {
                                        path: 'info',
                                        component: AddonInfoComponent,
                                    },
                                    {
                                        path: 'sales-date',
                                        component: AddonSalesDateComponent,
                                    },
                                    {
                                        path: 'settings',
                                        component: AddonSettingsComponent,
                                    },
                                ],
                            },
                            {
                                path: 'addon-group-form',
                                component: AddonGroupFormComponent,
                                children: [
                                    {
                                        path: 'group-info',
                                        component: GroupInfoComponent,
                                    },
                                    {
                                        path: 'vareities',
                                        component: VarietiesComponent,
                                    },
                                ],
                            },
                            {
                                path: 'copyAddOns',
                                component: CopyAddOnsGroupComponent,
                            },
                        ],
                    },
                    {
                        path: 'Promo-codes',
                        component: PromoCodesStepsComponent,
                        canActivate: [RouteGuard],
                        data: { claimType: 10105030103 },
                        children: [
                            { path: '', component: PromoteComponent },
                            {
                                path: 'promo-form',
                                component: PromoteformComponent,
                                children: [
                                    {
                                        path: 'step1',
                                        component: Step1Component,
                                    },
                                    {
                                        path: 'step2',
                                        component: Step2Component,
                                    },
                                    {
                                        path: 'step3',
                                        component: Step3Component,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'SubEvents',
                        component: SubEventsforMaineventsComponent,
                    },
                    { path: 'profile', component: CreateEventProfileComponent },
                    {
                        path: 'online-page',
                        component: OnlineEventPageComponent,
                    },
                    { path: 'timing', component: EventDateTimeComponent },
                    {
                        path: 'publish',
                        canActivate: [RouteGuard],
                        data: { claimType: 10105030102 },
                        component: PublishEventComponent,
                    },
                    {
                        path: 'order-form',
                        component: OrderFormBuilderComponent,
                    },
                    {
                        path: 'calendar',
                        component: ClenderStepsPageComponent,
                        children: [
                            { path: '', component: CalenderComponent },
                            {
                                path: 'calender-steps',
                                component: CalenderStepsComponent,
                                children: [
                                    {
                                        path: 'step1',
                                        component: CalenderFormStep1Component,
                                    },
                                    {
                                        path: 'step2',
                                        component: CalenderFormStep2Component,
                                    },
                                    {
                                        path: 'step3',
                                        component: CalenderFormStep3Component,
                                    },
                                    {
                                        path: 'step4',
                                        component: CalenderStep4Component,
                                    },
                                ],
                            },
                            {
                                path: 'single-update/:eventId',
                                component: ClenderUpdateSingleInstansComponent,
                            },
                        ],
                    },
                    { path: 'wait-list', component: WaitListComponent },
                    {
                        path: 'Order-confirmation',
                        component: OrderConfirmationComponent,
                    },
                    { path: 'Dashboard', component: EventDashboardComponent },
                    { path: 'Marketing', component: ComingSoonComponent },
                    {
                        path: 'Manage-Attendees',
                        component: AttendeesMAngmentComponent,
                        children: [
                            { path: '', component: OrdersReportComponent },
                            {
                                path: 'Edit-attendee/:Attendee',
                                component: EditAttendeesInfoComponent,
                            },
                            {
                                path: 'attendes',
                                component: AttendinglistcoComponent,
                            },
                            {
                                path: 'new-Attendee',
                                component: AddnewAttendessOfflineComponent,
                                children: [
                                    {
                                        path: '',
                                        component: AddNewAttendeesComponent,
                                    },
                                    {
                                        path: 'AttendeesInfo',
                                        component: AddnewAteendeesinfoComponent,
                                    },
                                    {
                                        path: 'confirm/:OrderId',
                                        component:
                                            ConfirmationOfflineOrderComponent,
                                    },
                                ],
                            },
                        ],
                    },

                    // {path: 'new-Attendee', component: AddnewAttendessOfflineComponent,children:[
                    //     {path: '', component: AddNewAttendeesComponent},
                    //     {path: 'AttendeesInfo', component: AddnewAteendeesinfoComponent},
                    // ]},
                ],
            },
        ],
    },
    {
        path: 'event/:id',
        children: [
            { path: '', component: EventPageComponent },
            {
                path: 'order',
                children: [
                    { path: '', component: OrderEventComponent },
                    { path: 'wait-List', component: TicketsMsgComponent },
                    {
                        path: 'confirmation/:OrderId',
                        component: ConfirmationComponent,
                    },
                ],
            },
            { path: 'online', component: OnlineComponent },
        ],
    },
    { path: 'single-events', component: EventsDetailsComponent },
    { path: 'zain/:id', component: EventPageComponent },
    { path: 'organizer11/:id', component: OrganizerProfileComponent },
    { path: 'redirect/:id', component: RedirectComponent },
    { path: 'emailCheck', component: EmailCheckComponent },
    { path: 'notallowed', component: NotAllowedComponent },
    { path: 'addDate', component: AddDateComponent },
    // Here add new pages component
    { path: '**', component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
