import { Component, OnDestroy, OnInit, ViewChild,Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { EventDetailsPageService } from './event-details-page.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Observable, ReplaySubject, Subject, Subscriber, Subscription } from 'rxjs';
import { DOCUMENT, DatePipe, Location } from '@angular/common';
import { UserActionsService } from 'src/app/services/user-actions.service';
import { EventCard } from '../landing-page/landing-page.service';
import { Orgnizer, OrgnizersService } from 'src/app/services/orgnizers.service';
import * as m from 'moment';
import 'moment/locale/pt-br';
import { TranslateService } from '@ngx-translate/core';

export interface EventPageDto {

  id?: any;
  coverImg?: string;
  startDate?: Date;
  endDate?: Date;
  displayStart?: boolean;
  displayEnd?: boolean;
  title?: string;
  organizerName?: string;
  organizerLinks?: OrganizerLink[];
  organizerFollowers?: number;
  priceRange?: PriceRange;
  location?: string;
  lat?: number;
  lng?: number;
  isOnline?: boolean;
  description?: string;
  tags?: string[];
  eventLinks?: EventLink[];
  curSymbol?: string;
  orgId?: string;
  isReccuring?:boolean;
  isSub?:boolean;
  isSeries?:boolean;
}
export interface OrganizerLink {
  attrId?: number;
  linkUrl?: string;
  icon?: string;
}
export interface PriceRange {
  minValue?: number;
  maxValue?: number;
}
export interface EventLink {
  attrId?: number;
  linkUrl?: string;
  icon?: string;
}


@Component({
  selector: 'app-event-page',
  templateUrl: './event-page.component.html',
  styleUrls: ['./event-page.component.scss']
})
export class EventPageComponent implements OnInit, OnDestroy {
 // liked: boolean = false;
 helpStepsModal : boolean = false;
 sub:Subscription[]=[];
  SubEvnt: EventCard[]=[];
  OrgData: Orgnizer;

  LogoUrl:string="";
  private subject: Subject<string> = new ReplaySubject<string>(1);

get $getSubject(): Observable<string> {
  return this.subject.asObservable();
}
  constructor(public _sanitizer: DomSanitizer,
    private eventPageService: EventDetailsPageService,
    public activatedRoute: ActivatedRoute,
    private loc: Location,
    public ua: UserActionsService,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,private orgsrv : OrgnizersService ,
    @Inject(DOCUMENT) public document: Document,
    private translate:TranslateService
  ) {
    this.translate.onLangChange.subscribe(() => {
      m.locale(this.translate.currentLang);
      if(this.id!=""){
        this.getEventDate(this.id)

       
          var s = localStorage.getItem(this.translate.currentLang+1) as string;
          this.subject.next(s);
      
      }
      
    });
  }
  getOrgnizerData(id){
    this.orgsrv.getOneOrg(id).subscribe(x => {this.OrgData = x;
      // this.getLinks(x.links);
      // this.ua.getFollowerList().subscribe(list=>{
      //   if(list.includes(id)){
      //       this.IsFollowing = true;
      //   }});
    })
  }

  showContactModal(){
    this.helpStepsModal = true
  }
  ngOnDestroy(): void {
    this.sub.forEach(e=>{
      e.unsubscribe();
    })
    clearInterval(this.myInterval);
    clearInterval(this.enteval);
  }
  enteval!: any;
  mapProblem = false
  days: any;
  hours: any;
  minutes: any;
  seconds: any;
  myInterval: any;
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  oo: any;
  data: EventPageDto = {};
  isStarted: boolean = false;
  isEnded: boolean = false;
  followed: boolean;
  orgEvents: EventCard[] = [];
  url = environment.Api;
  counter: number = 0;
  visible: boolean;
  liked: boolean = false;
  apiLoaded = false;
  id:string=""
  ngOnInit(): void {


    
    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.register('/sw.js').then(function(registration) {
    //     console.log('ServiceWorker registration successful with scope: ', registration.scope);
    //   }, function(err) {
    //     console.log('ServiceWorker registration failed: ', err);
    //   });
    // }

    var s = localStorage.getItem(this.translate.currentLang+1) as string;
          this.subject.next(s);
          this.$getSubject.subscribe(x=>{
            this.LogoUrl=environment.Api+x;
          })
    var param= this.activatedRoute.paramMap.subscribe((param)=>{
      this. id=param.get('id');
      this.getEventDate(this.id)
      this.eventPageService.postPageView(this.id).subscribe(x=>{
       // console.log(x)
      })
    })
    this.sub.push(param);
 
  }

getEventDate(id){
  var event= this.eventPageService.getEventDetails(id).subscribe(x => {
    this.data = x;
    //console.log(x);
    this.getOrgnizerData(x.orgId);
    //getOrgsubEvents
    this.oo = 'https://maps.google.com/maps?q=' +
      this.makeItSafeHtml(this.data.lat) +
      ',' +
      this.makeItSafeHtml(this.data.lng) +
      '&hl=es&z=14&amp;output=embed';
    try {
      this.showMap2(x.lat, x.lng, x.location);
    }
    catch {
      this.mapProblem = true;
    }
    this.enteval = setInterval(() => {
      this.checkedDate(this.getDateOntomeZone2(this.data?.startDate), this.getDateOntomeZone2(this.data.endDate))
    }, 0)
    this.myInterval = setInterval(() => {
     // console.log(this.getDateOntomeZone(this.data?.startDate))
      this.commingSoonTime(this.getDateOntomeZone2(this.data?.startDate));
    }, 0);
    this.data.description = JSON.parse(x.description);

    var followers= this.ua.getFollowerList().subscribe(list => {
      if (list.includes(x.orgId)) {
        this.followed = true;
      }});
      this.sub.push(followers)

     var likedev= this.ua.getLikedEventList().subscribe(y => {
        this.ua.LikedEvents = y as string[];
        if (y.includes(x.id)) {
          this.liked = true;
        }else  {
          this.liked = false;
        }
        
      });
      this.sub.push(likedev)

      this.getinstanse(x.orgId, x.id, 6, this.counter)
    // var orgevent= this.eventPageService.getOrgEvents(x.orgId, x.id, 6, this.counter).subscribe(x => this.orgEvents = x)
     if(x.isReccuring||x.isSeries||x.isSub){

      var m=this.eventPageService.getOrgsubEvents(x.id, x.id, 6, this.counter).subscribe(y=>{this.SubEvnt=y })
     }
    // this.sub.push(orgevent)

  });
  this.sub.push(event)
}
getinstanse(orgId,id,n,counter){
  var orgevent= this.eventPageService.getOrgEvents(orgId, id, n,counter).subscribe(x => this.orgEvents = x)
   
     this.sub.push(orgevent)
}

likeEvent(id: string){
  var mm= this.ua.postLike(id).subscribe(x => {
    var l= this.ua.getLikedEventList().subscribe(y => {
      this.ua.LikedEvents = y as string[];
    });
    this.sub.push(l)
})
this.sub.push(mm)}

  Like(id: string) {
    var mm=  this.ua.postLike(id).subscribe(x => {
      var l= this.ua.getLikedEventList().subscribe(y => {
        this.ua.LikedEvents = y as string[];
        this.liked = !this.liked;

      });
      this.sub.push(l)
    });
    this.sub.push(mm)
  }

  goBack() {

    this.loc.back();
  }

  getUrlid(x):string{
    let str = x;
    const index = str.indexOf('v');
    str = str.slice(index + 2, str.length);
    const url = 'https://www.youtube.com/embed/' + str;
    return str
  }

  makeItSafe(x) :SafeResourceUrl{
    let str = x;
    const index = str.indexOf('v');
    str = str.slice(index + 2, str.length);
    const url = 'https://www.youtube.com/embed/' + str;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  makeItSafeHtml(x) {
    return this._sanitizer.bypassSecurityTrustHtml(x);
  }

  // getDateOntomeZone(d: Date) {
  //   var t = new Date(Date.now()).getTimezoneOffset()
  //   var MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (new Date(Date.now()).getTimezoneOffset()));
  //   return new Date(MyDate).toLocaleString();
  // }
  getDateOntomeZone(d){
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d ,hh:mm a');
        return l;
    }else{
        var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
        return l;
    }


    
   // return new Date(MyDate).toLocaleString();
}


getDateOntomeZoneformat2(d){
  
  m.locale();
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    var l="";
  if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        l= m(new Date(MyDate)).format('ddd MMMM D , hh:mm a');
  
    }else{
       l=  m(new Date(MyDate)).format( 'ddd MMMM D,y, hh:mm a');
  
    }
    
    return l
  
  
  
  
   // return new Date(MyDate).toLocaleString();
  }
getDateOntomeZone2(d){
  let t = new Date(Date.now()).getTimezoneOffset();
  let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
  // if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
  //     var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d hh:mm a');
  //     return l;
  // }else{
  //     var l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
  //     return l;
  // }
  return new Date(MyDate).toLocaleString();
}
  checkedDate(x, y) {
    // console.log("start:"+x )
    // console.log("end:"+y )
    // console.log("end:"+new Date(y) )
    const Datenow = new Date(Date.now());
    // console.log("itstarted",moment(Datenow).isSameOrAfter(new Date(x)) && moment(Datenow).isBefore(new Date(y)) )
    // console.log("itfinshd",moment(Datenow).isSameOrAfter(new Date(y))) 
    if (moment(Datenow).isSameOrAfter(new Date(x)) && moment(Datenow).isBefore(new Date(y))) {
      this.isStarted = true;
      this.isEnded = false;
      clearInterval(this.myInterval);
    } else {
      if ( moment(Datenow).isSameOrAfter(new Date(y))) {
        this.isStarted = false;
        this.isEnded = true;
        clearInterval(this.myInterval);
      }
      else{
        if (moment(Datenow).isBefore(new Date(x))) {
          this.isStarted = false;
          this.isEnded = false;
         
        }
      }
    }
  }



  commingSoonTime = (x) => {
  
    const endTimeParse = (Date.parse(x)) / 1000;
    const now = new Date(Date.now());
    const nowParse = (Date.parse(now.toString()) / 1000);
    const timeLeft = (endTimeParse - nowParse) < 0 ? 0 : (endTimeParse - nowParse);
    const days = Math.floor(timeLeft / 86400);
    let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
    let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
    let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
    if (hours < 10) { hours = 0 + hours; }
    if (minutes < 10) { minutes = 0 + minutes; }
    if (seconds < 10) { seconds = 0 + seconds; }
    this.days = days;
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  follow(x,eventId) {
  var ff=  this.ua.postFollow(x).subscribe(x => {this.followed = !this.followed
    this.getEventDate(eventId)});
    this.sub.push(ff)

  }

  showMap2(lat, lng, address) {

    const mapProp = {
      center: new google.maps.LatLng(lat, lng),
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    // set center
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    // take marker
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      title: address,

    });
    const geocoder = new google.maps.Geocoder();

    marker.setMap(this.map);



  }

}
