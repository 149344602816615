import { DOCUMENT, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild,Inject } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AttrbuteService } from 'src/app/services/attrbute.service';

import { AuthService } from 'src/app/services/auth.service';
import { IntersetService } from 'src/app/services/interset.service';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }]
})
export class NavbarComponent implements OnInit {
    currentYear: number;
    @ViewChild('lan') lan:OverlayPanel;
    // location2 ='';
    // routerSubscription2: any;
    // visible: boolean ;

    LogoUrl:string="";
    private subject: Subject<string> = new ReplaySubject<string>(1);

  get $getSubject(): Observable<string> {
    return this.subject.asObservable();
  }
    @Output() OnLanguageChange = new EventEmitter<string>();
    constructor(public auth : AuthService,private attrSrv:AttrbuteService,
         private router: Router,private offcanvasService: NgbOffcanvas
         ,private intreSrv:IntersetService,
         @Inject(DOCUMENT) public document: Document,private transalte:TranslateService
         ) {
        this.currentYear = new Date().getFullYear();
        this.transalte.onLangChange.subscribe(x=>{
            var s = localStorage.getItem(this.transalte.currentLang+1) as string;
            this.subject.next(s);
        })
    }
    countries: DropDownItem[];
    langugeUi:DropDownItem[];
    selectedCountry: string = "ar";

    classApplied = false;


    selectedChange(e){
    //    console.log(e.value)
        this.OnLanguageChange.next(e.value);
    }
    ngOnInit(): void {

        this.attrSrv.GetAllSystemLang().subscribe(x=>{
            this.countries = x;
          //  console.log(x)
        })
        this.attrSrv.GetAllSystemLangUi().subscribe(x=>{
            this.langugeUi = x;
           // console.log(x)
        })
        var s = localStorage.getItem(this.transalte.currentLang+1) as string;
        this.subject.next(s);
        this.$getSubject.subscribe(x=>{
            this.LogoUrl=environment.Api+x;
        })

    
    }

    logout(){
        this.offcanvasService.dismiss('Cross click');

        this.auth.logout();
        


    }
    toggleClass() {
        this.classApplied = !this.classApplied;
    }
    // getIfShown(x){
    //     return !(this.location2.includes(x) )
    //   }


    //   recallJsFuntions() {
    //     this.routerSubscription2 = this.router.events
    //     .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
    //     .subscribe(event => {

    //         this.location2 = this.router.url;
    //         console.log(this.location2);
    //         if (!(event instanceof NavigationEnd)) {
    //             return;
    //         }
    //         window.scrollTo(0, 0);
    //     });
    // }
    showLan(e){
        this.lan.show(e,e.target);
    }
    onRowSelect(e,p){
       // console.log(e)
        this.OnLanguageChange.next ( e.data.code)
        this.selectedCountry=e.data.code,


        p.hide();

    }
    selectLang(event,code){
       // console.log( 'fromNave',code)
       // console.log( 'fromNave',code)
       var ui=this.langugeUi.find(x=>x.id==code);
       localStorage.setItem('loclizetionUI',ui.name)
        this.OnLanguageChange.emit(code);

        this.selectedCountry=code

    }
}

