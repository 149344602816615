<div class="container">
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'Tags'|translate}}</h1>
        </div>
        <div *ngIf="loader.show1" @fadeOut class="d-flex justify-content-center align-items-center"
            style="border-radius: 25px;top : 0;position: absolute;z-index: 1050;background-color:#fff;width: 100%;height: -webkit-fill-available;right: 0;bottom: 0;left: 0;">


        </div>


        <p-toast></p-toast>

        <div class="">
            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data"  [rows]="5"
            [paginator]="totalRecourd>5"  [lazy]="true" [totalRecords]="totalRecourd" (onLazyLoad)="getEvent($event)" [globalFilterFields]="['name']" [tableStyle]="{'max-width':'100%'}"
                [rowHover]="true" dataKey="attrId"  editMode="row"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [showCurrentPageReport]="true" class=""
               >
                <ng-template pTemplate="caption">

                    <div>

                        <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">

                            <div class="  ms-md-3  mb-sm-0  mb-2">
                                <button pButton pRipple class="rounded-pill add-new-btn mr-2" (click)="show()"><i
                                        class="fa-solid fa-plus"></i> {{'New'|translate}}<span></span></button>
                            </div>
                            <div class="p-input-filled me-md-3 ">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pTooltip="{{'type to search'|translate}}" showDelay="1000"
                                        tooltipPosition="left" pInputText type="text" class="form-control"
                                        [(ngModel)]="searchText"
                                        (input)="search($event.target.value, 'contains')"
                                        placeholder="{{'Search...'|translate}}" />
                                </span>
                            </div>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="header" class="">
                    <tr>
                        <th class="fw-bold text-center"></th>
                        <th class="fw-bold text-center">{{'Tag'|translate}}</th>
                        <th class="fw-bold text-center">{{'Language'|translate}}</th>
                        <th pSortableColumn="order" style="min-width:7rem" class="fw-bold text-center">
                            {{'Order'|translate}}</th>
                        <th pSortableColumn="totalQty" style="min-width:7.8rem" class="fw-bold text-center ">
                            {{'PblshTblBtn001Title001'|translate}} </th>

                        <th class="fw-bold text-center "> {{'TcktTblTh006'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-t  let-editing="editing" let-ri="rowIndex">
                    <!-- <tr>
                        <td class="text-md-center padding-custom text-lenght-larg text-end"><span
                                class="headers-cutom text-start fw-bold ">{{'Category'|translate}}</span> <a [routerLink]="t.attrId+'/sub-Category'" ><span
                                class="text-lenght">{{t.name}}</span></a></td>
                        <td class="text-md-center padding-custom text-end"><span
                                class="headers-cutom text-start fw-bold "> {{'Order'|translate}}</span>
                            {{t.order}}</td>

                        <td class="text-md-center padding-custom text-end">
                            <div class=" action-custom">
                                <button pButton pRipple  class="rounded-pill add-new-btn mr-2" [ngStyle]="{'background':t.status==0 ? 'rgb(211 47 47 / 92%)'  : 'rgb(4 205 4 / 60%)'}"
                                (click)="publish(t.attrId)">{{t.status==0? ('Active'|translate):'Not active'|translate}} <span></span></button>

                            </div>
                           </td>


                        <td class="text-md-center padding-custom text-end">
                            <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                            [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                                <button pButton pRipple icon="pi pi-pencil"
                                    class="p-button-rounded p-button-primary mx-2" (click)="edit(t.attrId)"></button>
                                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    (click)="remove($event,t.attrId)"></button>
                            </div>
                            <p-confirmPopup></p-confirmPopup>



                        </td>
                    </tr> -->
                    <tr [pEditableRow]="t">
                        <td  class="text-md-center padding-custom text-lenght-larg text-end">
                            <span
                            class="headers-cutom text-start fw-bold "> </span> <span
                            class="text-lenght text-center"> <i  class="fa-regular fa-circle-check " [ngClass]="{'text-success':checkComplet(t.misc01),
                       'text-warning':!checkComplet(t.misc01) }"></i></span>

                        </td>
                   <td  class="text-md-center padding-custom text-lenght-larg text-end">
                    <p-cellEditor>
                        <ng-template pTemplate="input" [formGroup]="typertform" >
                            <div class="p-input-filled  mb-2">
                                <span class="p-float-label">
                                    <input type="text" formControlName="name" pTooltip="{{'Tag'|translate}}"
                                    showDelay="1000" tooltipPosition="top" pInputText class="form-control" [ngClass]="{
                                            'ng-invalid ng-dirty':
                                            typertform.get('name')?.touched &&
                                              typertform.get('name')?.invalid||(submitted&& typertform.get('name').invalid)
                                        }" [class.ng-invalid.ng-dirty]="" #ticketName />
                                    <label class="form-lable font-size-14" for="TicketName">{{'Tag'|translate}}</label>
                                </span>
                                <small class="p-error" *ngIf="typertform.get('name')?.touched &&
                                typertform.get('name')?.invalid||(submitted&& typertform.get('name').invalid)
                                    " >{{'TagsMasg'|translate}}</small>

                            </div>
                        </ng-template>
                        <ng-template pTemplate="output">
                            <span
                                class="headers-cutom text-start fw-bold ">{{'Tag'|translate}}</span> <a [routerLink]="t.attrId+'/sub-Category'" ><span
                                class="text-lenght">{{t.name}}</span></a>
                        </ng-template>
                    </p-cellEditor>
                    </td>

                    <td  class="text-md-center padding-custom text-lenght-larg text-end">
                        <p-cellEditor>
                            <ng-template pTemplate="input" [formGroup]="typertform" >
                                <div class="p-input-filled  mb-2">
                                    <span class="p-float-label">
                                        <p-dropdown (onChange)="changform($event,t.attrId)"  inputId="langtype" appendTo="body"
                                        pTooltip="{{'TcktFrmInfoSlct001Pop'|translate}}" showDelay="1000" tooltipPosition="top"
                                         [ngClass]="{
                                                'ng-invalid ng-dirty':
                                                typertform.get('cultureId')?.touched &&
                                                typertform.get('cultureId')?.invalid||(submitted&& typertform.get('cultureId').invalid)
                                            }" [styleClass]="'w-100'" formControlName="cultureId" [autoDisplayFirst]="false"
                                            [options]="Langueses" optionLabel="name" optionValue="id" #ticketsType></p-dropdown>
                                        <label class="form-lable font-size-14" for="langtype">{{'Languages'|translate}}</label>
                                    </span>
                                    <small class="p-error" *ngIf="typertform.get('cultureId')?.touched &&
                                    typertform.get('cultureId')?.invalid||(submitted&& typertform.get('cultureId').invalid)
                                        " >{{'Language'|translate}}</small>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span
                                class="headers-cutom text-start fw-bold ">{{'Language'|translate}}</span> <span
                                class="text-lenght">{{t.cultureId}}</span>
                            </ng-template>
                        </p-cellEditor>
                        </td>

                        <td  class="text-md-center padding-custom text-lenght-larg text-end">


                            <p-cellEditor>
                                <ng-template pTemplate="input" [formGroup]="typertform" >
                                    <div class="p-input-filled  mb-2">
                                        <span class="p-float-label">
                                            <p-inputNumber pTooltip="{{'Order'|translate}} " showDelay="1000"
                                                tooltipPosition="bottom" inputId="inputnumber1" [styleClass]="'w-100'" class="form-control m-0 p-0"
                                                [inputStyleClass]="'form-control'" formControlName="order"
                                                [ngClass]="{'ng-invalid ng-dirty':typertform.get('order')?.touched && typertform.get('order')?.invalid||(submitted&& typertform.get('order').invalid)}"
                                                #minNumber></p-inputNumber>
                                            <label for="inputnumber1">{{'Order'|translate}}</label>
                                        </span>
                                        <small class="p-error" *ngIf="(typertform.get('order')?.touched &&
                                        typertform.get('order')?.invalid)||(submitted&& typertform.get('order').invalid)
                                            ">{{'Order'|translate}}</small>

                                    </div>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span
                                    class="headers-cutom text-start fw-bold "> {{'Order'|translate}}</span>
                                {{t.order}}
                                </ng-template>
                            </p-cellEditor>
                            </td>



                            <td  class="text-md-center padding-custom text-lenght-larg text-end">


                                <p-cellEditor>
                                    <ng-template pTemplate="input" [formGroup]="typertform" >
                                        <div class="p-input-filled  mb-2">

                                                    <p-inputSwitch inputId="Etic" ariaLabelledBy="lab1"
                                                        pTooltip="{{'Is active'|translate}}" showDelay="1000"
                                                        tooltipPosition="bottom" formControlName="status"   class="me-2"></p-inputSwitch>
                                                    <label class="form-check-label  " id="lab1"
                                                        for="Etic">{{'Is active'|translate}}</label>


                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        <div class=" action-custom">
                                            <button pButton pRipple  class="rounded-pill add-new-btn mr-2" [ngStyle]="{'background':t.status==0 ? 'rgb(211 47 47 / 92%)'  : 'rgb(4 205 4 / 60%)'}"
                                            (click)="publish(t.attrId)">{{t.status==0? ('Active'|translate):'Not active'|translate}} <span></span></button>

                                        </div>
                                    </ng-template>
                                </p-cellEditor>
                                </td>

                <td class="text-md-center padding-custom text-end">
                    <div [class.action-custom]="document.documentElement.dir === 'ltr'"
                    [class.action-custom-rtl]="document.documentElement.dir === 'rtl'">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(t)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(t)" class="p-button-rounded p-button-text p-button-success mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(t, ri)" class="p-button-rounded p-button-text p-button-danger"></button>


                    <!-- <button pButton pRipple icon="pi pi-pencil"
                            class="p-button-rounded p-button-primary mx-2" (click)="edit(t.attrId)"></button> -->
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger"
                            (click)="remove($event,t.attrId)"></button>
                    </div>
                    <p-confirmPopup></p-confirmPopup>
                    <!-- <button pButton pRipple icon="pi pi-trash"
                    class="p-button-rounded p-button-danger m-1"
                    (click)="remove($event,event.id)"></button> -->


                </td>




                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        {{'TcktTblFooter01'|translate}} {{data ? data.length : 0 }} .
                    </div>
                </ng-template>
            </p-table>

        </div>

    </div>
</div>

<!-- <div class="events-area pt-100 pb-70">
    <div class="container">
        <div class="">
            <p-toast></p-toast>


            <div class=" custom-table-border">


            </div>

        </div>
    </div>
</div> -->


