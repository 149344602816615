import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CurenciySrvService {
  typeform!:FormGroup;
  constructor( private fb:FormBuilder ) {

    this. initialForm(0,"",0,"","",0,false,'en')
   }
   initialForm(id,code,exRate,name,rateDate,order,status,lan){
    this.typeform=this.fb.group({
         id        :[id],
         code      :[code],
         name      :[name],
         exRate    :[exRate],
         rateDate  :[rateDate],
         status    :[status],
         order     :[order],
         cultureId:[lan]

    })
    }
     clear(){
      this. initialForm(0,"",0,"","",0,false,'en')
     }
}
