import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { AuthService } from 'src/app/services/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { Addon, Ticket } from '../../events/event-steps/attendees-mangment/orders-report/orders-report.component';
import * as m from 'moment';
import 'moment/locale/pt-br';




export interface GlobalOrder {
  orderId: string;
  orderType:string;
  status: number;
  orderNum: number;
  orderDate: string;
  orderFormAn: string;
  userEmail:string
  userFirstName:string
  userLastName:string
  tickets: Ticket[];
  addons: Addon[];
   eventName:string 
 event: EventOrderDto
  eventCurencey:number 
  curenceysympol :string
  exChangRate:number 
}
export interface EventOrderDto{
   entryId                       : string     
   startDate             :Date
   endDate               : Date
   isOnline                   : boolean     
   isRecurring                : boolean     
   isPublished               : boolean     
   isArchived                 : boolean     
   isCompleted                : boolean     
   isPrivate                  : boolean     
   public
   isSeries                   : boolean     
   isSub                      : boolean     
   isMaster                 : boolean     
    displayStartTime          : boolean    
   hasWaitList                : boolean     
   hascustomOrderConfirmation : boolean     
    displayEndTime            : boolean    
   hasBuyerOrderForm          : boolean     
   publishedDate          : Date 
   isRefundable               : boolean     
   viewcount                   : number      
            
}


@Component({
  selector: 'app-sales-reporting',
  templateUrl: './sales-reporting.component.html',
  styleUrls: ['./sales-reporting.component.scss']
})
export class SalesReportingComponent implements OnInit,OnDestroy{
   subs:Subscription[]=[]
  organizers: DropDownItem[]=[];
  events: DropDownItem[]=[];
  datatable:any[]=[];
  orderNum:number=0;
  totalFees:number=0;
  totalsales:number=0;
  numofSales:number=0;
  constructor(
    public eventService: EventsService,
     @Inject(DOCUMENT) public document: Document,
     public loader : LoaderService,
     private router:Router 
     ,private ActiveRout:ActivatedRoute ,private translate:TranslateService,public auth: AuthService,public Http:HttpClient
  ){
      this.translate.onLangChange.subscribe(x=>{
        m.locale(this.translate.currentLang);
      })
  }
  ngOnDestroy(): void {
    
  }
  private subject: Subject<any[]> = new ReplaySubject<any[]>(1);

  get $getSubject(): Observable<any> {
      return this.subject.asObservable();
  }
  ngOnInit(): void {
    var atr3= this.eventService.GetOrganizersByUser().subscribe(x => { this.organizers = x;});
    this.subs.push(atr3);
    var att4=this.eventService.GetUserOrgEvents([]).subscribe(x=>{this.events=x})
     this.subs.push(att4);
     this.$getSubject.subscribe(x=>{
      this.datatable=x
      this.datatable.forEach(y=>{
        this.totalsales= this.totalsales+y.totalprice;
        this.totalFees= this.totalFees+y.totalnetsales;
         
       })
       this.numofSales=x.length;
     })
  }

  
  getevent(e){
    console.log(e)
    var att4=this.eventService.GetUserOrgEvents(e.value).subscribe(x=>{this.events=x})
    this.subs.push(att4);
    }
  getReport(e){
  console.log(e)
  var url=environment.Api+'d/At/g/t/sales';
  var m=this.Http.post<GlobalOrder[]>(url,e.value).subscribe(x=>{
    console.log(x);
   this.orderNum=x.length;
  x.forEach(u=>{
   this.GetTicketsandaddons(u)
  })
  
  this.data.forEach(y=>{
   this.totalsales=+y.totalprice;
   this.totalFees=+y.totalnetsales;
    this.numofSales=+y.Qty;
  })

  this.subject.next(this.data)

    
  })
  
  }
  data=[]
  GetTicketsandaddons(item:GlobalOrder){
   
    item.tickets.forEach(x=>{
      var l= {
          eventName:item.eventName,
          startdate:item.event.startDate,
           paymentType:item.orderType,
           SalesChannal:item.orderType=='payment'?'onlineSales':'offlinesales',
           totalFees:x.antaresFees+x.paymentFees+x.shippingFees,
           totalprice:x.finalPrice,
           totalnetsales:x.finalPrice-(x.antaresFees+x.paymentFees+x.shippingFees),
          ticketType:x.name,
          Qty:x.qty,
          price:x.finalPrice,
      }
      this.data.push(l)
    });
    item.addons.forEach(x=>{
      var l= {
        eventName:item.eventName,
        startdate:item.event.startDate,
         paymentType:item.orderType,
         SalesChannal:item.orderType=='payment'?'onlineSales':'offlinesales',
         totalFees:x.aantaresFees+x.apaymentFees +x.ashippingFees,
         totalprice:x.afinalPrice,
         totalnetsales:x.afinalPrice-(x.aantaresFees+x.apaymentFees+x.ashippingFees),
          ticketType:x.aname,
         Qty:x.aqty,
        price:x.afinalPrice,
    }
    this.data.push(l)
    })


  }
  getDateOntomeZone(d: Date | number) {
    // console.log(d)
      var eventOffset =  new Date(Date.now()).getTimezoneOffset();
     // console.log(eventOffset)
     // var eventOffset = 60;
     var MyDate = new Date(d).setMinutes(
         new Date(d).getMinutes() + eventOffset
     );
     return new Date(MyDate);
 }
 getDateOntomeZoneformat2(d){
  
  m.locale();
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    var l="";
  if(new Date(MyDate).getFullYear()==new Date(Date.now()).getFullYear()){
        l= m(new Date(MyDate)).format('ddd MMMM D , hh:mm a');
  
    }else{
       l=  m(new Date(MyDate)).format( 'ddd MMMM D,y, hh:mm a');
  
    }
    
    return l
  
  
  
  
   // return new Date(MyDate).toLocaleString();
  }



}
