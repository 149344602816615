import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subscription, zip } from 'rxjs';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { CurrenciesSrvService } from 'src/app/services/currencies-srv.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logo-add-form',
  templateUrl: './logo-add-form.component.html',
  styleUrls: ['./logo-add-form.component.scss']
})
export class LogoAddFormComponent  implements OnInit,OnDestroy{
  Langueses: DropDownItem[]=[];
  form!:FormGroup;
  submitted:boolean=false;
  subs:Subscription[]=[]
 constructor( private fb:FormBuilder,private router:Router, private ActiveRout:ActivatedRoute,private translate:TranslateService,private http:HttpClient,
  @Inject(DOCUMENT) public document: Document,public loader : LoaderService,private messageService: MessageService,private attrsrv:CurrenciesSrvService ){
    this.translate.onLangChange.subscribe(x=>{

    })
  }
  ngOnDestroy(): void {
    this.subs.forEach(e=>{
      e.unsubscribe();
    })
  }
  ngOnInit(): void {
    
      this. initializform("0");
    var t=  this.checkifhavelogoginpd().subscribe(x=>{
       this. initializform(x);
       var l=   this.attrsrv.GetAllSystemLang().subscribe(y=>{
        this.Langueses=y;
        if(x=='0'){
          this.Langueses=this.Langueses.filter(z=>z.id=="en");
       }
      })
        this.subs.push(l)
      
      });
      this.subs.push(t)
     
  }
  initializform(logId:string){
    this.form=this.fb.group({
      logoId:[logId],
      cultureId:[Validators.required],
      image:[Validators.required]
    })
  }

  submit(){
    var data =this.form.value ;
    this.http.post(environment.Api+'d/loc/c/logo',data).subscribe(x=>{
      this.router.navigate(['logoes'], { relativeTo: this.ActiveRout.parent.parent});
    })
  }
  cancel(){
    this.initializform("0");
    this.router.navigate(['logoes'], { relativeTo: this.ActiveRout.parent.parent});
  }

  checkifhavelogoginpd(){
     return this.http.get<string>(environment.Api+'d/loc/logo/get');
  }
}
