<div class="container">
    <div class="section-title">
        <span class="sub-title"></span>
        <h2>{{'Reports'|translate}}</h2>
        <i></i>
        <p></p>
    </div>
    <div class="row">
        <div class="text-start">
            <span class="sub-title"></span>
            <h2>{{'Sales'|translate}}</h2>
            <i></i>
            <p>{{'Track sales for your event'|translate}}</p>
        </div>
    
    
        
        <div class="row">
            <div class="col-lg-4 col-12 col-sm-12">
                <div class="single-features-box" >
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Sales summary'|translate}}</h3>
                        <p>{{'An overview of all ticket sales including fees'|translate}}</p>
                    </a>
                 
                </div>
            </div>
            <div class="col-lg-4 col-12 col-sm-12">
                <div class="single-features-box">
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Sales by ticket type'|translate}}</h3>
                        <p>{{'Segment attendee information by ticket type'|translate}} </p>
                    </a>
          
                </div>
            </div>
            <div class="col-lg-4 col-12 col-sm-12">
                <div class="single-features-box">
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Sales by Add-on'|translate}}</h3>
                        <p>{{'Segment attendee information by Add-on'|translate}} </p>
                    </a>
                
                </div>
            </div>
           
        </div>
    </div>


    <div class="row">
        <div class="text-start">
            <span class="sub-title"></span>
            <h2>{{'Attendees'|translate}}</h2>
            <i></i>
            <p>{{'View details about your attendees'|translate}}</p>
        </div>
    
    
        
        <div class="row">
            <div class="col-lg-6 col-12 col-sm-12">
                <div class="single-features-box" >
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Attendee summary'|translate}}</h3>
                        <p>{{'View attendee details like name, email, and tickets'|translate}}  </p>
                    </a>
             
                </div>
            </div>
            <div class="col-lg-6 col-12 col-sm-12">
                <div class="single-features-box">
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Custom questions responses'|translate}}</h3>
                        <p>{{'Review attendee details and responses to custom questions'|translate}}   </p>
                    </a>
                    
                </div>
            </div>
        
      
        </div>
    </div>
    <div class="row">
        <div class="text-start">
            <span class="sub-title"></span>
            <h2>{{'Box office'|translate}}</h2>
            <i></i>
            <p>{{'Monitor your at-the-door sales'|translate}}</p>
        </div>
    
    
        
        <div class="row">
            <div class="col-lg-4 col-12 col-sm-12">
                <div class="single-features-box" >
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Box office summary'|translate}}</h3>
                        <p>{{'Monitor your at-the-door event sales'|translate}}   </p>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-sm-12">
                <div class="single-features-box">
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Attendance'|translate}}</h3>
                        <p>{{'See when attendees checked in to your event'|translate}}</p>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 col-12 col-sm-12">
                <div class="single-features-box">
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Transfers'|translate}}</h3>
                        <p>{{'Review tickets that have been transferred from one attendee to another'|translate}}  </p>
                    </a>
                </div>
            </div>
      
        </div>
    </div>

    <div class="row">
        <div class="text-start">
            <span class="sub-title"></span>
            <h2>{{'Inventory'|translate}}</h2>
            <i></i>
            <p>{{'Track inventory and event capacity'|translate}}</p>
        </div>
    
    
        
        <div class="row">
            <div class="col-lg-6 col-12 col-sm-12">
                <div class="single-features-box" >
                    <a [routerLink]="'/'">
                        <div class="icon">
                            <i class="fa-duotone fa-briefcase "></i>
                        </div>
                        <h3>{{'Audit'|translate}}</h3>
                        <p>{{'Review sold and available seats for your events'|translate}} 
                        </p>
                    </a>
                </div>
            </div>
           
          
        </div>
    </div>
</div>
