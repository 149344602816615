import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { EditAttendeesServiceService } from './edit-attendees-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventStepsService } from 'src/app/services/event-steps.service';

@Component({
  selector: 'app-edit-attendees-info',
  templateUrl: './edit-attendees-info.component.html',
  styleUrls: ['./edit-attendees-info.component.scss']
})
export class EditAttendeesInfoComponent implements OnInit {
   
      result:any
      id: string;
      value:any
  formData:any[]=[];
  isSubmitting:boolean=false
 constructor( private fb:FormBuilder,private http:HttpClient,private editeSrv:EditAttendeesServiceService,
  private router:Router,private activatedRoute:ActivatedRoute,private EventSrv:EventStepsService){

 }
  ngOnInit(): void {

    this.EventSrv.state$.subscribe(event=>{
      var param= this.activatedRoute.paramMap.subscribe((param)=>{
       // console.log(param)
         this.id=param.get('Attendee');
       // console.log(this.editeSrv.myticket)
        this.value= this.editeSrv.myticket.order.find(x=>x.attendeesNum==this.id).Answer;
        var p= this.http
        .get(environment.Api + 'd/e/ordform/'+event.id)
    
        .subscribe((x) => {
      // console.log(x)
        this.formData = JSON.parse(x['form']);
       // console.log(x)
       // console.log( this.formData )
        
        });
  
      })
    })
   
   
  }

  // get orderForm() 
  //    {
  //        return this.form1.get('order') as FormGroup;
  //    }


    // intialForm(id){
    //   this.form1=this.fb.group({
    //     id:[id],
    //     order:this.fb.group({})
    //   });
    // }
    GetResult(x){
   //console.log(x)
   this.result=x
    }
    getValud(x){
     // console.log(x)
      
    }
    submit(d){
      var url= environment.Api+'d/At/c/a'
        var t=this.editeSrv.myticket.order.find(x=>x.attendeesNum==this.id)
        t.Answer=this.result
       // console.log(this.editeSrv.myticket)

        var f={ticket: this.editeSrv.ticketID,
               order: JSON.stringify(this.editeSrv.myticket)}

             //  console.log(f)
       

              
       this.http.post(url,f).subscribe(x=>{x})
       this.router.navigate(['Manage-Attendees'], { relativeTo: this.activatedRoute.parent.parent });
       //this.router.navigate(['mmmm',this.editeSrv.attendeesnum], { relativeTo: this.activatedRoute.parent });


    }
}
