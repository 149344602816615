import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropDownItem } from 'src/app/Interfaces/DropDownItem';
import { langugesDto, systemlangDto } from 'src/app/Interfaces/attrDto';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystemlangServService {

  url=environment.Api;
 
  private subject: Subject<systemlangDto[]> = new ReplaySubject<systemlangDto[]>(1);

  get $getSubject(): Observable<systemlangDto[]> {
    return this.subject.asObservable();
  }
  constructor(public http:HttpClient,
    private router:Router,
    private activatedRoute:ActivatedRoute) { }

    getAlltype(){
      this.http.get<systemlangDto[]>(this.url+'d/cu/all/ac').subscribe(x=> this.subject.next(x));
   
      }

      postattrstauts(id){
        var data:string="";
       return this.http.put(this.url+'d/cu/t/'+ id,data)
      }
      changPrimary(id){
        var data:string="";
       return this.http.put(this.url+'d/cu/r/'+ id,data)
      }

      GetAllSystemLang(){
       return this.http.get<langugesDto[]>(this.url+'d/cu/all/ca').pipe(map(x => {

         var l=x.map(y=>{
            var  t:DropDownItem={id:y.cultureId,name:y.name}
            return t
         })
          return l;
      }))
      }
      GetAtrrSingle(id){
       return this.http.get<systemlangDto>(this.url+'d/cu/g/'+id);
      }

      PostnewAttr(data){
        var url=this.url+'d/cu/c';
        return this.http.post(url, data);
      }

      DeletAttr(id){
        return this.http.delete(this.url+'d/a/d/'+id);
      }
}
