<div class="p-5" style=" border-radius: 1.125rem !important;background-color:#343a40;">
    <h2 class=" text-white text-center text-lg-start" data=""
        style="margin-bottom: 50px; visibility: visible; animation-name: fadeInUp2; font-size: 2rem; font-weight: 600;">
        The nejmy.com platform</h2>
    <div class="row justify-content-center text-center text-md-start ">
        <div class="col-lg col-md-3 col-6  mb-3 platform-img " data-wow-delay=".1s"
            style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
            <a href="https://polaris.nejmy.net" target="_blank"><img
                   class="" src="https://staging.nejmy.net/public/img/logos/polaris/white-en-163.png" alt=""></a>
        </div>
        <div class="col-lg col-md-3 col-6  mb-3 platform-img " data-wow-delay=".3s"
            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
            <a href="https://sirius.nejmy.net" target="_blank"><img
                    src="https://staging.nejmy.net/public/img/logos/sirius/white-en-163.png" alt=""></a>
        </div>
        <div class="col-lg col-md-3 col-6  mb-3 platform-img " data-wow-delay=".5s"
            style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInLeft;">
            <a href="https://rigel.nejmy.net" target="_blank"><img
                    src="https://staging.nejmy.net/public/img/logos/rigel/white-en-163.png" alt=""></a>
        </div>
        <div class="col-lg col-md-3 col-6  mb-3 platform-img " data-wow-delay=".7s"
            style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInUp2;">
            <a href="https://vega.nejmy.net" target="_blank"><img
                    src="https://staging.nejmy.net/public/img/logos/vega/white-en-163.png" alt=""></a>
        </div>
        <div class="col-lg col-md-3 col-6  mb-3 platform-img " data-wow-delay=".5s"
            style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInRight2;">
            <a href="https://canopus.nejmy.net" target="_blank"><img
                    src="https://staging.nejmy.net/public/img/logos/canopus/white-en-163.png" alt=""></a>
        </div>
        <div class="col-lg col-md-3 col-6  mb-3 platform-img " data-wow-delay=".3s"
            style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInRight2;">
            <a href="https://antares.nejmy.net" target="_blank"><img
                    src="../../../../assets/img/Logo/Antares - White (138x47px).png" alt=""></a>
        </div>
        <div class="col-lg col-md-3 col-6  mb-3 platform-img " data-wow-delay=".1s"
            style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
            <a href="https://lana.nejmy.net" target="_blank"><img
                    src="https://staging.nejmy.net/public/img/logos/lana/white-en-163.png" alt=""></a>
        </div>
    </div>
</div>
