import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';

import { environment } from 'src/environments/environment';

const leaveTrans = transition(':leave', [
  style({
      opacity: 1
  }),
  animate('1s ease-out', style({
      opacity: 0
  }))
])
const fadeOut = trigger('fadeOut', [
  leaveTrans
]);
 export interface per{
   amount:number;
   attrId:number;
 } 
 export interface percentagegetDto{
  id   :string
  date :Date
  per :string
 }
@Component({
  selector: 'app-antares-persentage',
  templateUrl: './antares-persentage.component.html',
  styleUrls: ['./antares-persentage.component.scss'],
  animations: [fadeOut]
})
export class AntaresPersentageComponent implements OnInit {
  Myform!:FormGroup;
  data: percentagegetDto[]=[];
  InitialForm(){
    this.Myform=this.fb.group({
      attrId:[1011701],
      amount:[0,Validators.required]
    })
  }
  constructor(private fb:FormBuilder,private http:HttpClient, public messageService : MessageService, public loader : LoaderService,private datePipe: DatePipe){}
  ngOnInit(): void {
    this.InitialForm();
    this.getallantaresPesentage()
  }
  post(){
   var url=environment.Api+'d/e/p';
   var data=this.Myform.value as per;
   this.http.post(url,data).subscribe(x=>{

      
    this.messageService.add({ severity: 'success', summary: 'Saved Successfully' });
    this.getallantaresPesentage()

  },(error)=>{
    this.messageService.add({ severity: 'danger', summary: error });
  });
  
    

  }
  getallantaresPesentage(){
    var url=environment.Api+'d/e/p/g/p/1011701';
    this.http.get<percentagegetDto[]>(url).subscribe(x=>{
      this.data=x
    })
  }
  getDateOntomeZoneformat(d){
    let t = new Date(Date.now()).getTimezoneOffset();
    let MyDate = new Date(d).setMinutes(new Date(d).getMinutes() - (t));
    var l="";
    l= this.datePipe.transform(new Date(MyDate), 'EEE MMMM d,y hh:mm a');
    return l
  }
}
