import { Component, Input, OnInit, Type } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
export interface DataCols{
  header:string;
  field:string;
  sort:boolean;
  filter:boolean;
  cssclass:string;
  render(data:any):string;
}
export interface getComp{
  (x:string):Type<any>;
}
@Component({
  selector: 'app-oredata-tabls',
  templateUrl: './oredata-table.component.html',
  styleUrls: ['./oredata-table.component.scss']
})

export class OredataTableComponent implements OnInit {

  @Input() cols:DataCols[]=[];
  @Input() data:any[];
  @Input() action:any[]=[];
  @Input() pageRow:number;
  @Input() isPagination:true;
  @Input() tableStyle:any;
  @Input() title:string;

   constructor(private rout:Router,public dialogService:DialogService ){}
  filterCol:string[];
  ngOnInit(): void {
    var t=this.cols.filter(x=>
      x.filter===true
    )
    this.filterCol=t.map(x=>x.field)
    //console.log(this.action);
  }

  show(param1,param2,getcompPar:getComp,bbbb){
//     const ref = this.dialogService.open(getcompPar(param1), {
//         data:{
//             parent:param2,
//             id:param1
//         },
//       header: 'Choose a Car',
//       width: '70%'
//   });
bbbb(param1);

  }
  open(param:any){
  //  console.log(param)
     this.rout.navigate(['/events'])
  }
}
