
<p-toast></p-toast>
<div class="container">
   
    <div class="card custom-table-border">
        <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
            <h1 class="fw-bold text-white mt-2">{{'ShippingCost01'|translate}}</h1>
            </div>
        <div class="card-body">
            <div class="card-body p-0">
                <div class="">
                    <div class="d-flex justify-content-between align-items-center">
                        <!-- <h2 class="fw-bold">{{PageTitle|translate}}</h2> -->

                <div>
                    <!-- <h6 class="fw-bold  ">Help <span class="text-primary help-btn" (click)="showHelpStepsModal()" ><i class="fa-regular fa-circle-question"></i></span></h6> -->
                    <!-- <p-dialog header="How to start" [(visible)]="helpStepsModal" [modal]="true" class="bg-dark"
                    [draggable]="false" [resizable]="false" >
                    <p class="m-0"><b class="text-primary">Step 1: </b> Hkas sakjfas fjkasf sajfs afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 2: </b> Okas sakjfas fjkasf sajfss adas s ad afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 3: </b> Lasfas sakjfas fjkasf sajfs ad sa dasd afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 4: </b> Kasdkas sakjfas fjkasf sajfsas dsa dsa sa d dsa dsa dsa d afjasf</p>
                    <p class="m-0"><b class="text-primary">Step 5: </b> Bkas sakjfas fjkasf sajfssa dsa as  afjasf</p>
                        <ng-template pTemplate="footer">
                        <p-button icon="pi pi-check" (click)="helpStepsModal=false" label="Ok" styleClass="p-button-text"></p-button>
                        </ng-template>
                </p-dialog> -->
                </div>

                    </div>

                    <div class="row">
                           
                        <form [formGroup]="Myform" (submit)="post()" autocomplete="off" >
                            <div class="row mb-3">

                                <div  class="p-input-filled col-sm-12 col-md-6  mb-2">
                                    <div class="p-inputgroup">
                                        <span class="p-inputgroup-addon currency-custom ">
                                           #
                                            <!-- [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}">
                                            {{prosrvform.promoteCodeForm.get('isPercent').value?"%":eventStepSrv.currancysymbole}} -->
                                        </span>
                                        <span class="p-float-label">
                                            <!-- <input type="text" pInputText  /> -->
                                            <p-inputNumber pTooltip="{{'ShippingPrmoFrmStp3Inpt002Pop'|translate}}" showDelay="1000"
                                                tooltipPosition="bottom" inputId="discountAmount" [useGrouping]="false" [styleClass]="'w-100'"
                                                class="w-100" [inputStyleClass]="'form-control'"  formControlName="amount">
                                            </p-inputNumber>
                                            <label class="form-label font-size-14" for="withoutgrouping">
                                                {{'ShippingCost01'|translate}}
                                              <!--  {{
                                                 prosrvform.promoteCodeForm.get('isPercent').value
                                                ? ('PrmoFrmStp3Inpt002Titl002'|translate)
                                                : 'PrmoFrmStp3Inpt002Titl001'
                                                |translate}} --></label>
                                        </span>
                                    </div>
                                    
                                </div>
                             

                              
                               
                            
                            </div>

                            
                           
                            <div class="col-12 d-flex justify-content-center">
                                <div class="banner-btn rounded-pill">
                                    
                                    <button pButton pRipple type="Save and finish " showDelay="1000" tooltipPosition="bottom"
                                        class="rounded-pill save-btn text-center ms-1 mt-md-0 mt-1">{{'SavBtn'|translate}}
                                        <span></span></button>
                                </div>
                            </div>
                        </form>


                    </div>
                </div>
            </div>
        </div>
    </div>

</div>