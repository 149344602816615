<form [formGroup]="form1"    autocomplete="off">

    <div class="container">
        <div class="card custom-table-border">
            <div class="card-header border-0 text-center bg-primary rounded-top-23 ">
                <h1 class="fw-bold text-white mt-2">{{'Manageattendees(Add Attendees)'|translate}}</h1>
            </div>
            <div class="card-body">
                <div class="card-body p-0">



                    <div class="row d-flex justify-content-center ">
                        <div class="col-sm-12 col-md-12 mb-3">
                            <div class="d-flex  flex-column justify-content-start">
                                <h4 class="fw-bold  mt-2">{{'AddAttendees'|translate}}</h4>
                                <p>{{'AddAttendessDes001'|translate}}</p>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-sm-flex d-block align-items-center justify-content-sm-between text-center">
                                <div class="  ms-md-4 mb-sm-0  mb-2 ">
                                    <button pButton pRipple class="rounded-pill add-new-btn mr-2"
                                        (click)="menu3.toggle($event)">
                                        {{selectedtype01|translate}}<span></span>
                                    </button>
                                    <small class="p-error" *ngIf="(form1.get('type')?.invalid && Submitted)">{{'OrderTypeDescription'|translate}}</small>
                                    <p-menu #menu3 appendTo="body" [popup]="true" [model]="orderType"></p-menu>
                                
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="form1.get('type')?.value!=null">
                            <p-table #dt responsiveLayout="stack" [breakpoint]="'991px'" [value]="data"
                                [tableStyle]="{'max-width':'100%'}" [rowHover]="true" dataKey="attrId" editMode="row"
                                [showCurrentPageReport]="true" class="">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th >{{'addAttendTicketType'|translate}}</th>
                                        <th >{{'addAttendSold'|translate}}</th>
                                        <th >{{'addAttendPrice'|translate}}</th>
                                        <th >{{'addAttendQuantity'|translate}}</th>
                                        <th>{{'addAttendSalesprice'|translate}}</th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-p>
                                    <tr>
                                        <td pEditableColumn>
                                            <p-cellEditor>

                                                <ng-template pTemplate="input" [formGroup]="form1">
                                                    <div class="p-input-filled ">
                                                        <span class="p-float-label">
                                                            <p-dropdown inputId="TicketType1" [filter]="true" 
                                                            filterBy="name" [showClear]="true"  appendTo="body"
                                                             pTooltip="{{'poptickettypeattendee'|translate}}"showDelay="1000"
                                                              tooltipPosition="bottom" id="event-type"  [styleClass]="'w-100'" formControlName="ticket" [autoDisplayFirst]="false"
                                                                [options]="types" optionLabel="name" optionValue="id"
                                                                (onChange)="setdata($event)"
                                                               ></p-dropdown>
                                                            <label class="form-label font-size-14" for="event-type">{{'addAttendTicketType'|translate}}</label>
                                                        </span>
                
                                                        <!-- <small class="p-error" *ngIf="form1.get('ticket')?.touched &&
                                                        form1.get('ticket')?.invalid || (form1.get('ticket')?.invalid && Submitted)">{{'tickettypeError'|translate}}</small> -->
                                                    </div>

                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{p.ticket!=""?p.ticket:"Choosetheticket"|translate}}
                                                    <small class="p-error" *ngIf="form1.get('ticket')?.touched &&
                                                        form1.get('ticket')?.invalid || (form1.get('ticket')?.invalid && Submitted)">{{'tickettypeError'|translate}}</small>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td>


                                            {{p.solditem+"/"+p.qty}}


                                        </td>
                                        <td>


                                            {{p.Price+""+p.cursymbole}}


                                        </td>
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input" [formGroup]="form1">
                                                    <input pInputText type="text" formControlName="qty">

                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{ form1.get('qty')?.value?form1.get('qty')?.value:0}}
                                                    <small class="p-error" *ngIf="form1.get('qty')?.touched &&
                                                    form1.get('qty')?.invalid || (form1.get('qty')?.invalid && Submitted)">{{'QtyError'|translate}}</small>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td pEditableColumn *ngIf="form1.get('type')?.value!='Complimentary'">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input" [formGroup]="form1">
                                                    <div class="p-inputgroup">
                                                        <span class="p-inputgroup-addon  " [ngClass]="{' currency-custom':document.documentElement.dir === 'ltr',' currency-custom-rtl':document.documentElement.dir === 'rtl'}">{{p.cursymbole}}</span>
                                                        <span class="p-float-label">
                                                        <!-- <input type="text" pInputText  /> -->
                                                        <p-inputNumber inputId="locale-user" pTooltip="{{'TcktFrmInfoInpt002Pop'|translate}}" showDelay="1000" tooltipPosition="top" [minFractionDigits]="2"
                                                        class="w-100 " [inputStyleClass]="'form-control'" formControlName="value" ></p-inputNumber>
                                                        <small class="p-error" *ngIf="form1.get('value')?.touched &&
                                                        form1.get('value')?.invalid || (form1.get('value')?.invalid && Submitted)">{{'ValueError'|translate}}</small>

                                                      
                                                    </span>
                                                    </div>
                                                </ng-template>
                                                <ng-template pTemplate="output">

                                                    {{ form1.get('value')?.value!=0?form1.get('value')?.value:0}}{{p.cursymbole}}
                                                    <small class="p-error" *ngIf="form1.get('value')?.touched &&
                                                    form1.get('value')?.invalid || (form1.get('value')?.invalid && Submitted)">{{'ValueError'|translate}}</small>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td  *ngIf="form1.get('type')?.value=='Complimentary'">
                                          
                                                    {{ 0}}{{p.cursymbole}}
                                           
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer" let-p>
                                    <tr>
                                        <td style="width:25%">{{'TotalsalesPrice'|translate}}</td>
                                        <td style="width:25%">  {{ form1.get('totalVal')?.value!=0?form1.get('totalVal')?.value:0}}{{data[0].cursymbole}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
    <div class="row" >
        <div class="col-12 d-flex justify-content-lg-center justify-content-center">
        
            <div class="">
                <button  pTooltip="Save your event basic information " showDelay="1000"
                    tooltipPosition="right" class="rounded-pill save-btn " (click)="gotosteptow()">
                  
                    {{'Next'|translate}} <span></span>
                </button>
            </div>
        </div>
    </div>

</form>