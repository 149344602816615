import { Injectable } from '@angular/core';
export interface orderticket{
  ticets:string;
  order:any[]
}
@Injectable({
  providedIn: 'root'
})
export class EditAttendeesServiceService {
  attendeesnum:string;
     myticket:orderticket;
     ticketID:string;


  constructor() {
    
   }
}
